import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import GroupCategories from "highcharts-grouped-categories";
GroupCategories(Highcharts);
HighchartsHeatmap(Highcharts);

import TabbarTeritary from "../../../components/tab-bar/tabbar-teritary/TabbarTeritary";
import { GridLayout } from "../../../layouts";
import { RootState } from "../../../redux/store";
import { AppInsightService } from "../../../services";
import { MultiSelect } from "../../../components";
import { MultiSelectOption } from "../../../interfaces";
import { useTranslation } from "react-i18next";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import useGetFormalCompetenceData from "./hooks/useGetFormalCompetenceByCareUnit";
import CapacityHeatmap from "./Charts/CapacityHeatmap";
import StaffingVsLoadHeatmap from "./Charts/StaffingVsLoadHeatmap";
import DemandAndCapacityChart from "./Charts/DemandAndCapacityChart";
import { CARE_UNIT_TYPES} from "../../../utils/constants/careUnitTypes";
import DemandHeatmap from "./Charts/DemandHeatmap";
import PlannedCompetenceChart from "./Charts/PlannedCompetenceChart";

const Capacity = () => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "Capacity" });
  }, []);
  const { t: translate } = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const weekDays: MultiSelectOption[] = [
    {
      id: uuid(),
      label: translate("Mon"),
      value: 1,
    },

    {
      id: uuid(),
      label: translate("Tue"),
      value: 2,
    },

    {
      id: uuid(),
      label: translate("Wed"),
      value: 3,
    },

    {
      id: uuid(),
      label: translate("Thu"),
      value: 4,
    },

    {
      id: uuid(),
      label: translate("Fri"),
      value: 5,
    },

    {
      id: uuid(),
      label: translate("Sat"),
      value: 6,
    },

    {
      id: uuid(),
      label: translate("Sun"),
      value: 7,
    },
  ];

  const competenceOptions: MultiSelectOption[] = [];

  const competence_share_options: MultiSelectOption[] = [
    {
      id: uuid(),
      label: translate("ÖL"),
      value: "ÖL",
    },

    {
      id: uuid(),
      label: translate("BÖL"),
      value: "BÖL",
    },

    {
      id: uuid(),
      label: translate("SPEC"),
      value: "SPEC",
    },

    {
      id: uuid(),
      label: translate("ST+"),
      value: "ST+",
    },

    {
      id: uuid(),
      label: translate("ST"),
      value: "ST",
    },

    {
      id: uuid(),
      label: translate("RAND"),
      value: "RAND",
    },

    {
      id: uuid(),
      label: translate("LEG"),
      value: "LEG",
    },

    {
      id: uuid(),
      label: translate("AT"),
      value: "AT",
    },

    {
      id: uuid(),
      label: translate("UL"),
      value: "UL",
    },

    {
      id: uuid(),
      label: translate("SSK++"),
      value: "SSK++",
    },

    {
      id: uuid(),
      label: translate("SSK+"),
      value: "SSK+",
    },

    {
      id: uuid(),
      label: translate("SSK"),
      value: "SSK",
    },

    {
      id: uuid(),
      label: translate("SSK Natt"),
      value: "SSK Natt",
    },

    {
      id: uuid(),
      label: translate("USK+"),
      value: "USK+",
    },

    {
      id: uuid(),
      label: translate("USK"),
      value: "USK",
    },

    {
      id: uuid(),
      label: translate("USK Natt"),
      value: "USK Natt",
    },

    {
      id: uuid(),
      label: translate("Övrigt"),
      value: "Övrigt",
    },
  ];

  // Weekdays filter capacity
  const [weekdaySelectOptions] = useState<MultiSelectOption[]>(weekDays);
  const [selectedWeekDaySelectedOptions, setSelectedWeekDaySelectedOptions] = useState<MultiSelectOption[]>(weekDays);

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const careUnitType = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);

  // Competence filter for heatmap
  const formalCompetences = useGetFormalCompetenceData(careUnitId !== null ? careUnitId : 0).data;

  const [competenceShareOptions, setCompetenceShareOptions] = useState<MultiSelectOption[]>(competenceOptions);

  const [selectedCompetenceShareOptions, setSelectedCompetenceShareOptions] =
    useState<MultiSelectOption[]>(competenceOptions);

  const tabs = [translate("Heatmap"), translate("PlannedCompetencePerHour")];

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.Heatmaps);
  const isDemandAndCapacityChartEnabled = useFeatureFlagIsEnabled(FeatureFlags.EDVisualization);

  const handleTabbarClick = (clickedIndex: number) => {
    setSelectedTabIndex(() => clickedIndex);
  };

  useEffect(() => {
    setCompetenceShareOptions(formalCompetences);
    setSelectedCompetenceShareOptions(formalCompetences);
  }, [formalCompetences]);

  const sortWeekDays = (weekdaysToSort: MultiSelectOption[]) => {
    weekdaysToSort.sort((a: MultiSelectOption, b: MultiSelectOption) => {
      return weekDays.indexOf(a) - weekDays.indexOf(b);
    });

    return weekdaysToSort;
  };

  const sortCompetences = (competencesToSort: MultiSelectOption[]) => {
    competencesToSort.sort((a: MultiSelectOption, b: MultiSelectOption) => {
      return competence_share_options.indexOf(a) - competence_share_options.indexOf(b);
    });

    return competencesToSort;
  };

  const handleWeekDaySelect = (option: MultiSelectOption) => {
    const selectedWeekdays = [...selectedWeekDaySelectedOptions, option];
    setSelectedWeekDaySelectedOptions(sortWeekDays(selectedWeekdays));
  };

  const handleWeekDayDeselect = (optionToDeselect: MultiSelectOption) => {
    if (selectedWeekDaySelectedOptions.length > 1) {
      setSelectedWeekDaySelectedOptions(() => {
        return selectedWeekDaySelectedOptions.filter((option) => {
          return option.id !== optionToDeselect.id;
        });
      });
    }
  };

  const handleHeatMapCompetenceSelect = (option: MultiSelectOption) => {
    const selectedCompetences = [...selectedCompetenceShareOptions, option];
    setSelectedCompetenceShareOptions(sortCompetences(selectedCompetences));
  };

  const handleHeatMapCompetenceDeselect = (optionToDeselect: MultiSelectOption) => {
    if (selectedCompetenceShareOptions.length > 1) {
      setSelectedCompetenceShareOptions(
        selectedCompetenceShareOptions.filter((option) => {
          return option.id !== optionToDeselect.id;
        })
      );
    }
  };

  return (
    <GridLayout additionalStyles="mt-0 px-0">
      <div className="col-start-1 col-end-13">
        <div className="mt-0 flex items-center justify-between px-2">
          <TabbarTeritary tabs={tabs} onClick={handleTabbarClick} />
          {selectedTabIndex === 0 && (
            <div className="w-80">
              <MultiSelect
                label={translate("competence")}
                selectedOptions={selectedCompetenceShareOptions}
                options={competenceShareOptions}
                onSelectItem={handleHeatMapCompetenceSelect}
                onRemoveSelectItem={handleHeatMapCompetenceDeselect}
              />
            </div>
          )}

          {selectedTabIndex === 1 && (
            <div className="w-80">
              <MultiSelect
                label={translate("weekday")}
                selectedOptions={selectedWeekDaySelectedOptions}
                options={weekdaySelectOptions}
                onSelectItem={handleWeekDaySelect}
                onRemoveSelectItem={handleWeekDayDeselect}
              />
            </div>
          )}
        </div>
        {selectedTabIndex === 0 && (
          <div className="mt-0 w-full bg-[#F8F8F8] px-2 pt-4 lg:h-[79vh] xl:h-[80vh]">
            <div className="flex h-full">
              {(Number(careUnitType) == CARE_UNIT_TYPES.EMERGENCY) && isDemandAndCapacityChartEnabled && 
              isFeatureFlagEnabled && (
                <div className="w-3/4">
                  <DemandAndCapacityChart selectedCompetenceShareOptions = {selectedCompetenceShareOptions}></DemandAndCapacityChart>
                </div>
              )}
              {(!(Number(careUnitType) == CARE_UNIT_TYPES.EMERGENCY) || !isDemandAndCapacityChartEnabled) && (
                <div className="w-1/3">
                  <CapacityHeatmap selectedCompetenceShareOptions = {selectedCompetenceShareOptions}></CapacityHeatmap>
                </div>
              )}
              {(Number(careUnitType) == CARE_UNIT_TYPES.EMERGENCY) && !isDemandAndCapacityChartEnabled
                && isFeatureFlagEnabled && (
                <div className="w-1/3">
                  <DemandHeatmap></DemandHeatmap>
                </div>
              )}
              {(Number(careUnitType) == CARE_UNIT_TYPES.EMERGENCY) && isFeatureFlagEnabled && (
                <div className={`${isDemandAndCapacityChartEnabled ? 'w-1/4' : 'w-1/3'}`}>
                  <StaffingVsLoadHeatmap selectedCompetenceShareOptions = {selectedCompetenceShareOptions}></StaffingVsLoadHeatmap>
                </div>
              )}
            </div>
          </div>
        )}
        {selectedTabIndex === 1 && (
          <div className="mt-0 bg-[#F8F8F8] px-2 py-2 lg:h-[79vh] xl:h-[80vh]">
            <PlannedCompetenceChart selectedWeekDaySelectedOptions = {selectedWeekDaySelectedOptions} ></PlannedCompetenceChart>
          </div>
        )}
      </div>
    </GridLayout>
  );
};

export default Capacity;
