import React from "react";
import Productivity from "../features/productivity/Productivities/Productivity";
import Benchmark from "../features/productivity/Benchmarkings/Benchmark";
import Capacity from "../features/productivity/Capacity/Capacity";
import Budget from "../features/productivity/Budget/Budget";
import ShiftsHome from "../features/productivity/WorkShifts/WorkShifts";
import { Navigate } from "react-router-dom";
import Resources from "../features/productivity/Resources/Resources";

const ProductivityRoutes = [
  {
    path: "",
    element: <Navigate to="shifts" replace />,
  },
  {
    path: "shifts",
    element: <ShiftsHome />,
  },
  {
    path: "productivity",
    element: <Productivity />,
  },
  {
    path: "resources",
    element: <Resources />,
  },
  {
    path: "benchmarking",
    element: <Benchmark />,
  },
  {
    path: "capacity",
    element: <Capacity />,
  },
  {
    path: "budget",
    element: <Budget />,
  },
];

export default ProductivityRoutes;
