import { useEffect, useState } from "react";
import ResourceService from "../../../../services/resourceService";

interface ResourceType {
  id: number;
  name: string;
}

const useResourceTypes = () => {
  const [data, setData] = useState<ResourceType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetch = async () => {
    try {
      setIsLoading(true);
      const response = await ResourceService.getResourceTypes();
      setData(response.data);
      setError(null);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    data,
    isLoading,
    error,
    fetch,
  };
};

export default useResourceTypes;
