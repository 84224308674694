import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { AppInsightService, OutpatientHistoricalDataService } from "../../../../../services";
import { AxiosResponse } from "axios";
import { HistoricalStatResponse } from "../../../../../interfaces/production-plan/outpatient/historical-stats";
import { useNumberFormat } from "../../../../../hooks/use-number-format";

const HistoricalData = () => {
  const { thousandSeparate } = useNumberFormat();
  useEffect(() => {
    AppInsightService.trackPageView({ name: "HistoricalData - Outpatient" });
  }, []);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const { t: translate } = useTranslation();

  const [stats, setStats] = useState<HistoricalStatResponse>({
    carriedOutVisits: 0,
    totalVisists: 0,
  });

  useEffect(() => {
    const getHistoricalData = async () => {
      if (careUnitId !== null && year !== null) {
        const visits: AxiosResponse<HistoricalStatResponse> = await OutpatientHistoricalDataService.getHistoricalData(
          careUnitId
        );

        setStats(() => visits.data);
      }
    };

    getHistoricalData();
  }, [careUnitId, year]);

  return (
    <div className="px-4">
      <div className="sidearea__text--container">
        <h1 className="sidearea__text--title">{translate("total_visits_last_year")}</h1>
        <p className="sidearea__text--value">{thousandSeparate(stats.totalVisists, " ") || 0}</p>

        <h1 className="sidearea__text--title">{translate("production_to_the_last_year_to_date")}</h1>
        <p className="sidearea__text--value">{thousandSeparate(stats.carriedOutVisits, " ") || 0}</p>
      </div>
    </div>
  );
};

export default HistoricalData;
