import { useTranslation } from "react-i18next";

import { AxisLabelObject } from "../../../../../interfaces/highcharts/AxisLabelObject";
import { OrgOverviewChartData } from "../../../../../interfaces/analytics/orgOverviewChartData";

const useOverviewChartConfig = (
  productivityPLan: number[],
  productivityTarget: number[],
  careUnitList: string[],
  sortedDataSet: OrgOverviewChartData[]
) => {
  const { t: translate } = useTranslation();

  const generateLegendIconForStaffingSeries = (isSeriesVisible: boolean) => {
    let imagePath = "";

    imagePath = "/images/orgOverview/legend.png";

    const imageDimension = "width = '16px' height = '12px'>";

    const img: string =
      `<div class='w-4 h-full ${!isSeriesVisible && "opacity-50"}'> <img src =` +
      imagePath +
      " " +
      imageDimension +
      "  " +
      "</div>";

    return `<div class='flex gap-2 items-center'> ${img} ${translate("staffing")} </div>`;
  };

  const getFlagSeriesData = (sortedDataSet: OrgOverviewChartData[]) => {
    return sortedDataSet.map((d) => {
      if (d.productvtyplanvstarget >= -0.4 && d.productvtyplanvstarget <= 0.4) {
        return {
          y: d.productvtyplanvstarget,
          title: "",
          fillColor: "transparent",
          color: "transparent",
          shape: "square",
          enableMouseTracking: false,
          style: {
            stroke: "transparent",
            color: "transparent",
            fontSize: "0px",
          },
        };
      }

      const percentage = Math.round(d.productvtyplanvstarget);
      const title = `${percentage}`;
      const fillColor = percentage < 0 ? "#EB9B9D" : "#A9C9A0";

      const shape = percentage < 1 ? "url(/images/orgOverview/downarrow.png)" : "url(/images/orgOverview/uparrow.png)";

      return {
        y: d.productvtyplanvstarget,
        title: title,
        fillColor,
        shape,
        style: {
          color: "#000000",
          fontSize: "8px",
        },
      };
    });
  };

  const options = {
    chart: {
      backgroundColor: "transparent",
      style: {
        fontFamily: "Open Sans",
      },

      paddingTop: 200,

      events: {
        render(this: Highcharts.Chart) {
          const series = this.series.filter((s: Highcharts.Series) => {
            return s.options.type === "flags";
          });

          series.forEach((s: Highcharts.Series) => {
            s.data.forEach((flag: Highcharts.Point) => {
              const graphic = flag.graphic as Highcharts.SVGElement & { text: Highcharts.SVGElement };

              graphic?.text.attr({ x: 4.5, y: -12 });
            });
          });
        },
      },
    },
    title: {
      text: undefined,
    },
    xAxis: {
      title: {
        text: undefined,
      },

      labels: {
        style: {
          fontSize: 14,
        },
      },

      categories: careUnitList,
    },
    yAxis: {
      title: {
        text: undefined,
      },

      labels: {
        style: {
          fontSize: 14,
        },
      },

      min: 0,
      max: Math.max(...productivityTarget, ...productivityPLan) + 15,
    },
    legend: {
      useHTML: true,
      verticalAlign: "top",
      itemStyle: {
        fontSize: 14,
        fontWeight: "bold",
      },
      style: {
        fontSize: 14,
        fontWeight: "bold",
      },

      labelFormatter: function (this: AxisLabelObject) {
        if (this.name === translate("staffing")) {
          const chart = this.chart;

          let isFlagSeriesVisible = false;

          chart.series.forEach((s: Highcharts.Series) => {
            if (s.name === translate("staffing") && s.visible) {
              isFlagSeriesVisible = true;
            }
          });

          return generateLegendIconForStaffingSeries(isFlagSeriesVisible);
        }

        return this.name;
      },
    },
    series: [
      {
        type: "column",
        name: translate("plan"),
        data: productivityPLan,
        id: "productivityPlanSeries",
        color: "#44546a",
      },

      {
        type: "flags",
        name: translate("staffing"),
        legendSymbol: "none",
        data: getFlagSeriesData(sortedDataSet),
        onSeries: "productivityPlanSeries",
        color: "#5F86B3",
      },

      {
        type: "line",
        name: translate("goal"),
        data: productivityTarget,
        color: "#E5A32B",
        marker: {
          enabled: false,
        },
      },
    ],
  };

  return { options };
};

export default useOverviewChartConfig;
