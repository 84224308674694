import React, { useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import { MultiSelect, Select } from "../../../components";
import { ISelectOption, MultiSelectOption } from "../../../interfaces";
import {
  NumberOfBeds,
  CareBedsSeriesData,
  DataFetchingEntityTypes,
} from "../../../interfaces/analytics/number-of-beds";
import { RootState } from "../../../redux/store";
import AnalyticsService from "../../../services/analyticsService";
import OrgService from "../../../services/orgService";
import { Site } from "../../../interfaces/orgstructure";
import { FILTER_TYPES } from "../../../utils/constants/careBedsPlan";
import CarebedsPlanChart from "./CareBedsPlanChart";

const CareBedsPlan = () => {
  const { t: translate } = useTranslation();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const careUnitLabel = useSelector((state: RootState) => state.globalFilter.filterCareUnitLabel);

  const currentCareProviderId = useSelector((state: RootState) => state.globalFilter.careProviderId);

  const filteringOptions = [
    {
      label: translate("select_all"),
      value: FILTER_TYPES.SELECT_ALL,
    },
    {
      label: translate("deselect_all"),
      value: FILTER_TYPES.DESELECT_ALL,
    },
  ];

  const [numberofCareBedsChartSeries, setNumberofCareBedsChartSeries] = useState<any>([]);
  const [sites, setSites] = useState<ISelectOption[]>([]);
  const [chartData, setChartData] = useState<any>();
  const [dataLoadedCareUnit, setdataLoadedCareUnit] = useState<number | null>();
  const [visibleCareUnits, setVisibleCareUnits] = useState<string[]>([]);
  const [selectedFilteringOption, setSelectedFilteringOption] = useState<ISelectOption>(filteringOptions[0]);
  const [selectedSite, setSelectedSite] = useState<ISelectOption>();

  const siteList = sites;

  const filterSelection = (value: string) => {
    const chart = chartComponentRef.current?.chart;

    const visibleItems: string[] = [];

    if (chart) {
      chart.series.forEach((series) => {
        if (value === FILTER_TYPES.SELECT_ALL) {
          series.show();

          if (series.name !== translate("last_52_weeks")) {
            visibleItems.push(series.name);
          }
        } else if (value === FILTER_TYPES.DESELECT_ALL) {
          series.hide();
        }
      });

      // Get the line chart data when user selects all
      if (value === FILTER_TYPES.SELECT_ALL) {
        setVisibleCareUnits(visibleItems);
        getData(visibleItems);
      }
    }
  };

  const handleFilterationTypeInputSelect = (selectedOption: ISelectOption) => {
    setSelectedFilteringOption(selectedOption);
    filterSelection(selectedOption.value);
  };

  const handleTypesOfInputSelect = (selectedOption: ISelectOption) => {
    setSelectedSite(selectedOption);
    resetVisibleCareUnits();
  };

  useEffect(() => {
    chartComponentRef.current?.chart.series.forEach((series) => {
      if (series.name !== translate("last_52_weeks") && !series.visible) {
        series.show();
      }
    });
  }, [selectedSite?.value]);

  const resetVisibleCareUnits = () => {
    setVisibleCareUnits([]);
  };

  const setCareBedChartSeries = (existingData: CareBedsSeriesData[], historicData: CareBedsSeriesData) => {
    setNumberofCareBedsChartSeries([
      ...existingData,
      {
        type: "line",
        name: translate("last_52_weeks"),
        data: historicData,
        color: "#AD1E23",
        shadow: {
          enabled: true,
          color: "white",
          opacity: 1,
          offsetX: 0,
          offsetY: 0,
          width: 4,
        },
      },
    ]);
  };

  const getData = (visibleCareUnits: string[]) => {
    fetchHistoricDataSeries("VISIBLE_CARE_UNITS", chartData, visibleCareUnits);
    setVisibleCareUnits(visibleCareUnits);
  };

  const fetchHistoricDataSeries = (
    dataFetchingEntity: DataFetchingEntityTypes,
    existingData: CareBedsSeriesData[],
    visibleCareUnits: string[] | null
  ) => {
    const careUnitLabels: string[] | null = careUnitLabel ? [careUnitLabel] : null;

    AnalyticsService.getNoOfHistoricCareBeds(
      dataFetchingEntity === "SELECTED_SITE" ? Number(selectedSite?.value) : null,
      dataFetchingEntity === "VISIBLE_CARE_UNITS"
        ? visibleCareUnits
        : dataFetchingEntity === "NAVBAR_SELECTED_CARE_UNIT"
        ? careUnitLabels
        : null,
      null,
      year,
      scenario,
      selectedWeekDaySelectedOptions.map((option) => option.value) as number[]
    )
      .then((res) => {
        setCareBedChartSeries(existingData, res);
      })
      .catch((err) => {
        throw err;
      });
  };

  const weekDays: MultiSelectOption[] = [
    {
      id: uuid(),
      label: translate("Mon"),
      value: 1,
    },

    {
      id: uuid(),
      label: translate("Tue"),
      value: 2,
    },

    {
      id: uuid(),
      label: translate("Wed"),
      value: 3,
    },

    {
      id: uuid(),
      label: translate("Thu"),
      value: 4,
    },

    {
      id: uuid(),
      label: translate("Fri"),
      value: 5,
    },

    {
      id: uuid(),
      label: translate("Sat"),
      value: 6,
    },

    {
      id: uuid(),
      label: translate("Sun"),
      value: 7,
    },
  ];

  // Weekdays selection logic
  const [weekdaySelectOptions] = useState<MultiSelectOption[]>(weekDays);
  const [selectedWeekDaySelectedOptions, setSelectedWeekDaySelectedOptions] = useState<MultiSelectOption[]>(weekDays);

  const handleWeekDaySelect = (option: MultiSelectOption) => {
    const selectedWeekdays = [...selectedWeekDaySelectedOptions, option];
    setSelectedWeekDaySelectedOptions(sortWeekDays(selectedWeekdays));
  };

  const sortWeekDays = (weekdaysToSort: MultiSelectOption[]) => {
    weekdaysToSort.sort((a: MultiSelectOption, b: MultiSelectOption) => {
      return weekDays.indexOf(a) - weekDays.indexOf(b);
    });

    return weekdaysToSort;
  };

  const handleWeekDayDeselect = (optionToDeselect: MultiSelectOption) => {
    if (selectedWeekDaySelectedOptions.length > 1) {
      setSelectedWeekDaySelectedOptions(() => {
        return selectedWeekDaySelectedOptions.filter((option) => {
          return option.id !== optionToDeselect.id;
        });
      });
    }
  };

  useEffect(() => {
    /* 
    Effect to fetch data on initial load and when siteid changes 
    */
    if (selectedSite?.value !== undefined) {
      AnalyticsService.getNumberOfCareBedsByWeekdays(
        Number(selectedSite?.value),
        careUnitId,
        null,
        year,
        scenario,
        selectedWeekDaySelectedOptions.map((option) => option.value) as number[]
      ).then((res) => {
        const data = res.data.map((bed: NumberOfBeds) => {
          return {
            type: "column",
            name: bed.careUnit,
            data: bed.numberOfBeds,
          };
        });
        setdataLoadedCareUnit(careUnitId);
        setChartData(data);

        if (selectedSite?.value === "0" && visibleCareUnits.length === 0) {
          fetchHistoricDataSeries("NAVBAR_SELECTED_CARE_UNIT", data, null);
        } else if (selectedSite?.value !== "0" && visibleCareUnits.length === 0) {
          fetchHistoricDataSeries("SELECTED_SITE", data, null);
        }
      });
    }
  }, [selectedSite, year, scenario, selectedWeekDaySelectedOptions]);

  //NOTE: This is the original useEffect that was here before the weekdays toggle
  // useEffect(() => {
  //   /*
  //   Effect to fetch data on initial load and when siteid changes
  //   */
  //   AnalyticsService.getNumberOfCareBeds(
  //     Number(selectedSite.value),
  //     careUnitId,
  //     null,
  //     year,
  //     scenario,
  //     false
  //   ).then((res) => {
  //     const data = res.data.map((bed: NumberOfBeds) => {
  //       return {
  //         type: "column",
  //         name: bed.careUnit,
  //         data: bed.numberOfBeds,
  //       };
  //     });
  //     setdataLoadedCareUnit(careUnitId);
  //     setChartData(data);

  //     if (selectedSite.value === "0" && visibleCareUnits.length === 0) {
  //       fetchHistoricDataSeries("NAVBAR_SELECTED_CARE_UNIT", data, null);
  //     } else if (selectedSite.value !== "0" && visibleCareUnits.length === 0) {
  //       fetchHistoricDataSeries("SELECTED_SITE", data, null);
  //     }
  //   });
  // }, [careUnitId, selectedSite, year, scenario]);

  useEffect(() => {
    if (currentCareProviderId) {
      OrgService.getAllSitesAsync().then((res: AxiosResponse<Site[]>) => {
        const data: ISelectOption[] = res.data.map((site: Site) => {
          return {
            label: site.siteName,
            value: String(site.id),
          };
        });
        setSites(data);
      });
    }
  }, [currentCareProviderId]);

  useEffect(() => {
    if (dataLoadedCareUnit !== careUnitId && careUnitId !== null) {
      setSelectedSite({ label: translate("choose"), value: "0" });
    }
  }, [careUnitId]);

  return (
    <div className="h-full w-full lg:h-[79vh] xl:h-[80vh]">
      <>
        <div className="flex h-full w-full">
          <div className="h-full w-5/6">
            <CarebedsPlanChart
              chartComponentRef={chartComponentRef}
              chartData={numberofCareBedsChartSeries}
              getData={getData}
              pointWidth={15}
            />
          </div>
          <div className="flex h-full w-1/6 flex-col gap-4 px-4">
            <div className="w-full">
              <Select
                placeholder={translate("Site")}
                options={siteList}
                onSelectOption={handleTypesOfInputSelect}
                selectedOption={selectedSite}
              />
            </div>
            <div className="w-full">
              <MultiSelect
                label={translate("weekday")}
                selectedOptions={selectedWeekDaySelectedOptions}
                options={weekdaySelectOptions}
                onSelectItem={handleWeekDaySelect}
                onRemoveSelectItem={handleWeekDayDeselect}
              />
            </div>
            <div className="w-full">
              <Select
                placeholder={translate("filter_type")}
                options={filteringOptions}
                onSelectOption={handleFilterationTypeInputSelect}
                selectedOption={selectedFilteringOption}
              />
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default CareBedsPlan;
