export const productionShares = [
  "ProductionShareEdVisit",
  "ProductionShareOutpatientVisit",
  "ProductionShareWard",
  "ProductionShareSurgery",
  "Total - Direct care",
  "ProductionShareConsultant",
  "ProductionShareStExternalTraining",
  "ProductionShareNonClinicalTime",
  "ProductionShareManagement",
  "Total",
];

export const productionSharesColors: { [key: string]: string } = {
  ProductionShareEdVisit: "#5B7852",
  ProductionShareOutpatientVisit: "#5B7852",
  ProductionShareWard: "#5B7852",
  ProductionShareSurgery: "#5B7852",
  "Total - Direct care": "#5B7852",
  ProductionShareConsultant: "#364355",
  ProductionShareResidentTraining: "#365B9D",
  ProductionShareNonClinicalTime: "#C2830F",
  ProductionShareManagement: "#992319",
  Total: "#375330",
};