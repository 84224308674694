import React from "react";
import FollowUp from "../features/people/FollowUp/FollowUp";
import AbsencePlan from "../features/people/AbsencePlan/AbsencePlan";
import StaffHome from "../features/people/StaffHome/StaffHome";
import { Navigate } from "react-router-dom";

const PeopleRoutes = [
  {
    path: "",
    element: <Navigate to="staffing" replace />,
  },
  {
    path: "staffing",
    element: <StaffHome />,
  },
  {
    path: "attendence",
    element: <FollowUp />,
  },
  {
    path: "absence",
    element: <AbsencePlan />,
  },
];

export default PeopleRoutes;
