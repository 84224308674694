import React from "react";
import { ChatMessageProps } from "../../../interfaces/props/chatMessageProps";
import { AiChatMessageType } from "../../../interfaces/chat/ChatMessage";

const ChatMessage = ({ message }: ChatMessageProps): JSX.Element => {
  if (message.messageType === AiChatMessageType.Header) {
    return <HeaderMessage message={message} />;
  } else if (message.messageType === AiChatMessageType.System) {
    return <SystemMessage message={message} />;
  } else if (message.messageType === AiChatMessageType.User) {
    return <UserMessage message={message} />;
  } else {
    return <DefaultMessage message={message} />;
  }
};

const HeaderMessage = ({ message }: ChatMessageProps): JSX.Element => {
  return (
    <div className="mb-2 ml-auto flex flex-col gap-2 font-bold">
      {message.paragraphs.map((r, index) => {
        return <div key={index}>{r}</div>;
      })}
    </div>
  );
};

const SystemMessage = ({ message }: ChatMessageProps): JSX.Element => {
  return (
    <div className={"mb-2 mr-auto flex flex-col gap-4 p-2 text-sm"}>
      {message.paragraphs.map((r, index) => {
        return <div key={index}>{r}</div>;
      })}
    </div>
  );
};

const UserMessage = ({ message }: ChatMessageProps): JSX.Element => {
  return (
    <div className={"mb-2 ml-auto flex flex-col gap-4 bg-chat-message-blue p-2 text-sm text-white"}>
      {message.paragraphs.map((r, index) => {
        return <div key={index}>{r}</div>;
      })}
    </div>
  );
};

const DefaultMessage = ({ message }: ChatMessageProps): JSX.Element => {
  return (
    <div className="mb-2 mr-auto flex flex-col gap-4 p-2 text-sm">
      {message.paragraphs.map((r, index) => {
        return (
          <div key={index}>
            <div>{r}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ChatMessage;
