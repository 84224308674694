import { InpatientPlanChartPutRequest } from "../interfaces/productivity/inpatient-plan-data-props";
import HttpCommonService from "./httpCommonService";

const ProductionInpatientService = {
  getChartParameters: async (id: number | null, year: number, scenario: number) => {
    return await HttpCommonService.getAsync(`api/inpatientplans/${id}/${year}/${scenario}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  updateChartParameters: async (data: InpatientPlanChartPutRequest) => {
    return await HttpCommonService.putAsync("api/inpatientplans", data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default ProductionInpatientService;
