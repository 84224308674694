export class ChatMessage {
  id: number;
  paragraphs: string[];
  messageType: AiChatMessageType;

  constructor(id: number, paragraphs: string[], messageType: AiChatMessageType) {
    this.id = id;
    this.paragraphs = paragraphs;
    this.messageType = messageType;
  }

  text(): string {
    return this.paragraphs.join(". ");
  }
}

export enum AiChatMessageType {
  Header = "Header",
  User = "User",
  System = "System",
}
