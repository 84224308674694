import { useState, useCallback } from "react";
import { AiChatMessageType, ChatMessage } from "../../../interfaces/chat/ChatMessage";
import { each } from "highcharts";
import { forEach } from "lodash";

export function useChatMessages(initialMessages: ChatMessage[] = []) {
  const [messages, setMessages] = useState<ChatMessage[]>(initialMessages);

  const addMessage = useCallback((text: string[], messageType: AiChatMessageType) => {
    const newMessage = new ChatMessage(Date.now(), text, messageType);

    setMessages((prevMessages) => [...prevMessages, newMessage]);
  }, []);

  const appendMessage = useCallback((chunck: string, messageType: AiChatMessageType, index: number) => {
    const paragraphs = chunck.split("<br />");

    forEach(paragraphs, (text: string, i: number) => {
      const formatText = text.replace("<br />", "");

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const currentMessage = updatedMessages[index];

        if (!currentMessage) {
          const newMessage = new ChatMessage(index + 1, [formatText], messageType);
          updatedMessages.push(newMessage);
        } else {
          if (i > 0) {
            updatedMessages[index].paragraphs.push(formatText);
          } else {
            updatedMessages[index].paragraphs[updatedMessages[index].paragraphs.length - 1] += formatText;
          }
        }
        return updatedMessages;
      });
    });
  }, []);

  const addUserMessage = useCallback(
    (pharagraphs: string[]) => {
      addMessage(pharagraphs, AiChatMessageType.User);
    },
    [addMessage]
  );

  const addAiMessage = useCallback(
    (pharagraphs: string[]) => {
      addMessage(pharagraphs, AiChatMessageType.System);
    },
    [addMessage]
  );

  const appendAiMessage = useCallback(
    (pharagraph: string, index: number) => {
      appendMessage(pharagraph, AiChatMessageType.System, index);
    },
    [addMessage]
  );

  const addHeaderMessage = useCallback(
    (pharagraphs: string[]) => {
      addMessage(pharagraphs, AiChatMessageType.Header);
    },
    [addMessage]
  );

  const resetMessages = useCallback(() => {
    setMessages(initialMessages);
  }, [initialMessages]);

  return {
    messages,
    addUserMessage,
    addAiMessage,
    appendAiMessage,
    addHeaderMessage,
    resetMessages,
  };
}
