import HttpCommonService from "./httpCommonService";

const UserRoleService = {
  getUserRolesAsync: async (path: string) => {
    return await HttpCommonService.getAsync(path)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default UserRoleService;
