import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import CompetenceFilters from "./Filter/CompetenceFilters";
import { FteTotalChartData } from "../../../../interfaces/productivity";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { ProductivityService } from "../../../../services";
import { useTranslation } from "react-i18next";
import { ProductivityCompetenceResponse } from "../../../../interfaces/productivity/productivity-competence-response";
import { WaterfallChartData } from "../../../../interfaces/common/chartdata";
import { AxiosResponse } from "axios";
import { ISelectOption } from "../../../../interfaces";
import { CARE_UNIT_TYPES } from "../../../../utils/constants/careUnitTypes";
import { PhysicianSubUnits } from "../../../../components";
import Highcharts from "highcharts";
import { useNumberFormat } from "../../../../hooks/use-number-format";

const CompetenceWaterfall = () => {
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const careUnitTypeId = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);

  const { t: translate, i18n } = useTranslation();
  const { localize, round } = useNumberFormat();

  const typesOfInputs: ISelectOption[] = [
    {
      label: translate("fte"),
      value: "fte",
    },
    {
      label: translate("cost") + " (kSEK)",
      value: "cost",
    },
    {
      label: translate("working_hours"),
      value: "working_hours",
    },
  ];

  const typesOfCompetence: ISelectOption[] = [
    {
      label: translate("detailCompetence"),
      value: "WorkCompetence",
    },
    {
      label: translate("competence"),
      value: "FormalCompetence",
    },
    {
      label: translate("competenceGroup"),
      value: "FormalCompetenceGroup",
    },
    {
      label: translate("professionGroup"),
      value: "ProfessionGroup",
    },
  ];

  const [totalsByCompetence, setTotalsByCompetence] = useState<FteTotalChartData[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedInput, setSelectedInput] = useState<ISelectOption>(typesOfInputs[0]);
  const [selectedCompetenceInput, setSelectedCompetenceInput] = useState<ISelectOption>(typesOfCompetence[0]);

  useEffect(() => {
    if (careUnitId !== null) {
      setLoading(true);
      ProductivityService.getTotalsByCompetences(
        selectedInput?.value,
        selectedCompetenceInput?.value,
        careUnitId,
        scenario
      ).then((res: AxiosResponse<ProductivityCompetenceResponse[]>) => {
        const competenceFormattedData: WaterfallChartData[] = [];

        res.data.forEach((data: ProductivityCompetenceResponse) => {
          const competenceFteSum = {
            color: data.formalCompetenceColor,
            name: data.competenceTypeShortName,
            sortOrder: data.formalCompetenceSortOrder,
            y: round(data.total, 1),
          };

          competenceFormattedData.push(competenceFteSum);
        });

        competenceFormattedData.sort((a: WaterfallChartData, b: WaterfallChartData) => a.sortOrder - b.sortOrder);

        const translated = competenceFormattedData.map((data: WaterfallChartData) => {
          return {
            ...data,
            name: translate(data.name),
          };
        });

        setTotalsByCompetence(translated);
      });

      setLoading(false);
    }
  }, [careUnitId, selectedInput.value, selectedCompetenceInput.value, scenario]);

  const competenceOptions = {
    chart: {
      type: "waterfall",
      style: {
        fontFamily: "Open Sans",
        textOutline: 0,
      },
      backgroundColor: "transparent",
    },

    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        if (selectedInput.value === "cost") {
          return this.key + "</br>" + localize(Number(this.y), i18n.language) + " K";
        } else {
          return this.key + "</br>" + localize(Number(this.y), i18n.language);
        }
      },
    },

    title: {
      text: undefined,
    },

    xAxis: {
      type: "category",
      categories: totalsByCompetence?.map((x) => x.name),
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      title: {
        text: translate(selectedInput.value),
        style: {
          fontSize: 16,
        },
      },

      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject): string | number {
          if (selectedInput.value === "cost") {
            return this.value + "K";
          } else {
            return this.value;
          }
        },

        style: {
          fontSize: 16,
        },
      },
    },

    legend: {
      enabled: false,
    },

    series: [
      {
        data: totalsByCompetence,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          inside: false,
          style: {
            textOutline: 0,
            fontSize: 16,
            fontWeight: "normal",
          },
        },
        pointPadding: 0,
      },
    ],
  };

  const handleTypesOfInputSelect = (selectedOption: ISelectOption) => {
    setSelectedInput(selectedOption);
  };

  const handleCompetenceInputSelect = (selectedOption: ISelectOption) => {
    setSelectedCompetenceInput(selectedOption);
  };

  return (
    <>
      <div className="h-full w-5/6">
        {!loading && (
          <HighchartsReact
            containerProps={{ style: { height: "100%" } }}
            highcharts={Highcharts}
            options={competenceOptions}
          />
        )}
      </div>

      <div className="ml-5 flex  w-1/6 flex-col justify-between">
        <CompetenceFilters
          handleCompetenceInputSelect={handleCompetenceInputSelect}
          handleTypesOfInputSelect={handleTypesOfInputSelect}
          selectedCompetenceInput={selectedCompetenceInput}
          selectedInput={selectedInput}
          typesOfCompetence={typesOfCompetence}
          typesOfInputs={typesOfInputs}
        />

        <div>{careUnitTypeId === CARE_UNIT_TYPES.PHYSICIAN && <PhysicianSubUnits />}</div>
      </div>
    </>
  );
};

export default CompetenceWaterfall;
