import { useEffect, useState } from "react";
import {
  ProductionOutcomeReponse,
  ProductionPlanPhasedResponse,
  RecoveryPlanReponse,
} from "../../../../../../interfaces/responses/productionvsanalyticsresponse";
import { OutpatientWeeklyStat } from "../../../../../../interfaces/production-plan/outpatient/outPatientWeeklyStat";
import { ProdVsPlanTableData } from "../../../../../../interfaces/analytics/prodVsPlan/prodVsPlanTableData";

const useDataTableData = (
  productionData: ProductionOutcomeReponse,
  plannedData: ProductionPlanPhasedResponse,
  recoveryData: RecoveryPlanReponse[],
  referralsAndQueueData: OutpatientWeeklyStat[],
  isWeeklyDataLoading: boolean,
  isProductionDataLoading: boolean
): ProdVsPlanTableData[] => {
  const [data, setData] = useState<ProdVsPlanTableData[]>([]);

  useEffect(() => {
    const dataNewData: {
      name: string;
      [key: number]: number;
    }[] = [
      {
        name: "Referrals",
      },
      {
        name: "Delayed Contacts",
      },
      {
        name: "Production",
      },
      {
        name: "Plan",
      },
      {
        name: "Gap",
      },
      {
        name: "Recovery Plan",
      },
    ];
    if (!isWeeklyDataLoading && !isProductionDataLoading && productionData && plannedData && recoveryData) {
      for (let i = 0; i < 52; i++) {
        dataNewData[5][i] = recoveryData[i]?.recoveryPlan ?? 0;
        dataNewData[4][i] = productionData.outcome[i + 1] - plannedData[i + 1];
        dataNewData[3][i] = plannedData[i + 1] ?? 0;
        dataNewData[2][i] = productionData.outcome[i + 1] ?? 0;
        dataNewData[1][i] = referralsAndQueueData[i]?.queueOutcome;
        dataNewData[0][i] = referralsAndQueueData[i]?.referralOutcome;
      }
    }

    setData(dataNewData);
  }, [productionData, plannedData, recoveryData, referralsAndQueueData, isWeeklyDataLoading, isProductionDataLoading]);

  return data;
};

export default useDataTableData;
