import React, { useEffect } from "react";
import { OrgOverviewChartData } from "../../../../../interfaces/analytics/orgOverviewChartData";

const useOverviewChartData = (data: OrgOverviewChartData[]) => {
  const [productivityPLan, setProductivityPlan] = React.useState<number[]>([]);
  const [productivityTarget, setProductivityTarget] = React.useState<number[]>([]);
  const [careUnitList, setCareUnitList] = React.useState<string[]>([]);
  const [sortedDataSet, setSortedDataSet] = React.useState<OrgOverviewChartData[]>([]);

  useEffect(() => {
    const sortedDataSet = data.sort((a, b) => {
      if (a.productivityTarget !== b.productivityTarget) {
        return a.productivityTarget - b.productivityTarget;
      }
      return a.productvityPlan - b.productvityPlan;
    });

    setProductivityPlan(sortedDataSet.map((d) => d.productvityPlan));
    setProductivityTarget(sortedDataSet.map((d) => d.productivityTarget));
    setCareUnitList(sortedDataSet.map((d) => d.careUnitName));
    setSortedDataSet(sortedDataSet);
  }, [data]);

  return { productivityPLan, productivityTarget, careUnitList, sortedDataSet };
};

export default useOverviewChartData;
