import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "../../interfaces/userstate";
import AuthUser from "../../interfaces/auth/authUser";

const createInitialState = (): UserState => {
  const storageVal = localStorage.getItem("user");
  let userData: AuthUser;

  if (storageVal) {
    userData = JSON.parse(storageVal);
    return {
      user: storageVal ? (userData as AuthUser) : null,
      error: null,
      isLoggedIn: !!userData.id,
      roleType: userData.roles[0].type,
      role: userData.roles[0].role,
      orgIds: userData.roles.filter((r) => r.careUnitId !== null).map((r) => r.careUnitId as number),
    };
  } else {
    return {
      user: null,
      error: null,
      isLoggedIn: false,
      roleType: null,
      role: null,
      orgIds: null,
    };
  }
};

const name = "auth";
const initialState = createInitialState();

const slice = createSlice({
  name,
  initialState,
  reducers: {
    login: (state: UserState, action: any) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      state.roleType = action.payload.roles[0].type;
      state.role = action.payload.roles[0].role;

      state.orgIds =
        action.payload.roles[0].type === "Hospital"
          ? action.payload.roles.map((role: any) => {
              return role.hospitalId;
            })
          : action.payload.roles[0].type === "Department"
          ? action.payload.roles.map((role: any) => {
              return role.departmentId;
            })
          : action.payload.roles[0].type === "CareUnit"
          ? action.payload.roles.map((role: any) => {
              return role.careUnitId;
            })
          : action.payload.roles.map(() => {
              return null;
            });
    },
    logout: (state) => {
      localStorage.removeItem("user");
      state.user = null;
      state.isLoggedIn = false;
      state.roleType = null;
      state.role = null;
      state.orgIds = null;
    },
  },
});

export const { login, logout } = slice.actions;
export const authReducer = slice.reducer;
