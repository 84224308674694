import { useState, useEffect } from "react";
import { DashboardService } from "../../../../services";
import { ChartDataYearFormat, QueueDetails } from "../../../../interfaces/production-plan";
import { useTranslation } from "react-i18next";
import { WaitingListProps } from "../../../../interfaces/props/wating-list";
import { ChartData } from "../../../../interfaces/common/chartdata";
import { QueueCategory } from "../../../../interfaces/production-plan/outpatient/queue/queueDetail";

export const useChartData = ({ filterCareUnits, year }: WaitingListProps) => {
  const { t: translate } = useTranslation();
  const [queueDetailsFetchedData, setQueueDetails] = useState<ChartData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  let prevLevelNumber = "0";
  const [queueCategoriesFetchedData, setQueueCategories] = useState<ChartDataYearFormat[]>([]);

  const queueCategoriesColors: string[] = [
    "#2C461E",
    "#638B53",
    "#739768",
    "#A9C9A0",
    "#F5C773",
    "#BD4B4F",
    "#8A181C",
    "#C9C9C9",
    "#747474",
  ];

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    DashboardService.getQueueDetails(filterCareUnits.units, year as number).then((res) => {
      const data = res.details.map((queue: QueueDetails, index: number) => {
        return {
          name: getTranslation(queue.level, index - 1 >= 0 ? res.details[index - 1].level : "0"),
          data: queue.outcome,
          color: queueCategoriesColors[index],
        };
      });

      const modifiedChartCategories: ChartDataYearFormat[] = [];
      res.categories.map((category: QueueCategory) => {
        modifiedChartCategories.push({
          name: category.year,
          categories: category.weeks,
        });
      });
      setQueueDetails(data);
      setQueueCategories(modifiedChartCategories);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [filterCareUnits]);

  const getTranslation = (level: string, prevLevel: string) => {
    if (!isNaN(Number(prevLevel))) {
      prevLevelNumber = prevLevel;
    }

    if (!isNaN(Number(level))) {
      if (level === "9999") {
        return `${translate("first_visit")} > ${"180"} ${translate("days")}`;
      } else if (prevLevelNumber == "0") {
        return `${translate("first_visit")} < ${level} ${translate("days")}`;
      } else {
        return `${translate("first_visit")} ${prevLevelNumber} - ${level} ${translate("days")}`;
      }
    } else {
      return translate(level);
    }
  };

  return [queueDetailsFetchedData, queueCategoriesFetchedData, isLoading] as const;
};
