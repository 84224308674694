import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CapacityService } from "../../../../services";
import { PlannedCompPerHourHeatMapResponse } from "../../../../interfaces/capacity";
import { AxiosResponse } from "axios";
import { CapacityData, CapacityLineChartData } from "../../../../interfaces/capacity/heatmap-data";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { MultiSelectOption } from "../../../../interfaces";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { HeatMap2Response } from "../../../../interfaces/capacity/heat-map-data-response";
import LoadingWithGrey from "../../../../components/loading/LoadingWithGrey";
import { hoursOfTheDay } from "../../../../utils/highcharts/capacity/constants";

const DemandAndCapacityChart = ({
    selectedCompetenceShareOptions,
}: {
    selectedCompetenceShareOptions: MultiSelectOption[];
}) => {
    const { t: translate } = useTranslation();
    const [capacityData, setCapacityData] = useState<CapacityData[]>([]);
    const [capacityLineChartData, setCapacityLineChartData] = useState<CapacityLineChartData[]>([]);
    const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
    const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
    const [isChartLoading, setIsChartLoading] = useState<boolean>(false);

    const competenceBarChartCategories = [
        {
          name: translate("Mon"),
          categories: hoursOfTheDay,
        },
        {
          name: translate("Tue"),
          categories: hoursOfTheDay,
        },
        {
          name: translate("Wed"),
          categories: hoursOfTheDay,
        },
        {
          name: translate("Thu"),
          categories: hoursOfTheDay,
        },
        {
          name: translate("Fri"),
          categories: hoursOfTheDay,
        },
        {
          name: translate("Sat"),
          categories: hoursOfTheDay,
        },
        {
          name: translate("Sun"),
          categories: hoursOfTheDay,
        },
    ];

    const chartOptions = {
        chart: {
          animation: false,
          marginTop: 80,
          marginBottom: 60,
          plotBorderWidth: 0,
          backgroundColor: "transparent",
          style: {
            fontFamily: "Open Sans",
            textOutline: 0,
            fontSize: 12,
          },
        },
      
        title: {
          text: null,
          style: {
            fontSize: 12,
          },
        },
      
        tooltip: {
          shared: true,
          formatter: function (this: Highcharts.TooltipFormatterContextObject): string{
            const point = this.point as Highcharts.Point;
            const category = point.category.toString();
            const [hour, weekday] = category.split(",").map(s => s.trim());

            let tooltipInfo = 
              `${weekday} <br />
               ${hour.padStart(2, "0")}:00 <br />`;

            this.points?.forEach((point) => {
              tooltipInfo += `${point.series.name}: ${point.y}%<br/>`;
          });  
            return tooltipInfo;
          },
        },
      
        xAxis: {
          reversed: false,
          categories: competenceBarChartCategories,
          labels: {
            style: {
              fontSize: 12,
            },
          },
        },
        yAxis: {
          reversed: false,
          labels: {
            formatter: function (this: Highcharts.AxisLabelsFormatterContextObject):string {
                return this.value +'%';
            },
            style: {
              fontSize: 12,
            },
          },
          title: {
            text: null,
          },
        },
      
        legend: {
            enabled: true,
            verticalAlign: "top",
            itemStyle: {
              fontSize: 12,
            },
            style: {
              fontSize: 12,
            },
          },
      
        series: [
          {
            type: "area",
            name: translate("demand"),
            data: capacityLineChartData.map(d => d.value),
            color: "#BFBFBF",
            marker: {
                enabled: false
            }
          },
          {
            type: "line",
            name: translate("Capacity"),
            data: capacityData.map(d => d.value),
            color: "#00283C",
            marker: {
                enabled: false
            }
          }
        ],
      };

      useEffect(() => {
        if (careUnitId !== null && selectedCompetenceShareOptions.length > 0) {
          setIsChartLoading(true);
    
          const competences = selectedCompetenceShareOptions.map((share) => {
            return String(share.value);
          });
    
          CapacityService.getWeeklyWorkingHoursHeatMapData(competences, careUnitId, scenario).then(
            (res: AxiosResponse<PlannedCompPerHourHeatMapResponse>) => {
    
              const data = res.data.weeklyWorkingHours.map((item: number[]) => {
                return {
                  day: item[0],
                  hour: item[1],
                  value: item[2],
                };
              });
    
              setCapacityData(data);
              setIsChartLoading(false);
            }
          );
        }
      }, [careUnitId, selectedCompetenceShareOptions]);

      useEffect(() => {
        if (careUnitId !== null) {  
            CapacityService.getWeeklyWorkingHoursHeatMap2Data(careUnitId).then((res: AxiosResponse<HeatMap2Response>) => {
                const data = res.data.workloadValues.map((item: number[]) => {
                    return {
                        day: item[0],
                        hour: item[1],
                        value: item[2],
                    };
                });
    
                setCapacityLineChartData(data);
          });
        }
      }, [careUnitId]);

      return (
        <>
            {!isChartLoading ? (
                <HighchartsReact
                    containerProps={{ style: { height: "100%" } }}
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            )
            : (
                <div className="flex h-full items-center justify-center">
                  <LoadingWithGrey sizeInPixel={40} />
                </div>
              )}
        </>
    )
};

export default DemandAndCapacityChart;