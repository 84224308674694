import range from "lodash/range";

const useAdjustResultsColumnHeader = (): {
  (col: number, TH: HTMLElement, row: number, colCount: number): void;
} => {
  const adjustResultsColumnHeaderColours = (col: number, TH: HTMLElement, row: number, colCount: number) => {
    const lightBlueHeaderIndexes = range(0, colCount);

    const lightBlueHeaders = [...lightBlueHeaderIndexes];

    TH.classList.add("remove-filter");

    if (lightBlueHeaders.includes(col)) {
      TH.classList.add("light-blue-column");
    }

    if (col === 0 && row === 0) {
      TH.classList.add("activity-category-header");
    }

    if (row === 0) {
      TH.classList.add("activity-main-header");
    }

    TH.classList.add("htMiddle");
  };

  return adjustResultsColumnHeaderColours;
};

export default useAdjustResultsColumnHeader;
