import { useState } from "react";
import { ProductionVsPlanService } from "../../../../../../services";
import { AxiosResponse } from "axios";
import { OutPatientMultiSelectRequest } from "../../../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";
import { ProdVsPlanData, ProdVsPlanDataList } from "../../../../../../interfaces/analytics/prodVsPlan/prodVsPlanData";
import {
  BookedData,
  ProductionOutcomeData,
  ProductionPlanPhasedData,
  RecoveryPlanData,
} from "../../../../../../interfaces/analytics/prodVsPlan/chartData";

const useProductionVsPlanData = (
  productionPlanType: number,
  careUnitsList: number[],
  year: number,
  scenarioId: number,
  timePeriodType: number,
  outPatientMultiSelectFilters: OutPatientMultiSelectRequest | null | undefined
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [recoveryPlan, setRecoveryPlan] = useState<RecoveryPlanData[]>();
  const [areaChartData, setAreaChartData] = useState<ProductionPlanPhasedData[]>();
  const [columnChartData, setColumnChartData] = useState<ProductionOutcomeData>();
  const [bookedData, setBookedData] = useState<BookedData[]>();
  const [referralData, setReferralData] = useState<number[]>();
  const [delayedContactData, setDelayedContactData] = useState<number[]>();
  const [ytd, setYtd] = useState<number>(0);

  const fetchProdVsPlanData = async () => {
    setIsLoading(true);

    await ProductionVsPlanService.getProdVsPlanData(
      careUnitsList,
      year,
      scenarioId,
      productionPlanType,
      timePeriodType,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.professionIds &&
          outPatientMultiSelectFilters.professionIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.professionIds
        : [],
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.contactTypeIds &&
          outPatientMultiSelectFilters.contactTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.contactTypeIds
        : [],
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.typesOfVisit &&
          outPatientMultiSelectFilters.typesOfVisit.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.typesOfVisit
        : [],
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.typesOfEmergency &&
          outPatientMultiSelectFilters.typesOfEmergency.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.typesOfEmergency
        : [],
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.contactReasonGroupingIds &&
          outPatientMultiSelectFilters.contactReasonGroupingIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.contactReasonGroupingIds
        : [],
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.careTypeIds &&
          outPatientMultiSelectFilters.careTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.careTypeIds
        : [],
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.medicalSpecialtyIds &&
          outPatientMultiSelectFilters.medicalSpecialtyIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.medicalSpecialtyIds
        : []
    )
      .then((response: AxiosResponse<ProdVsPlanData>) => {
        const mapRecoveryPlan = (rp: ProdVsPlanDataList) => ({
          recoveryPlan: rp.recoveryPlan !== null ? Math.round(parseFloat(rp.recoveryPlan.toString())) : null,
          id: rp.timePeriod,
        });

        const mapProductionPlan = (rp: ProdVsPlanDataList) => ({
          productionPlan: rp.productionPlan,
          id: rp.timePeriod,
        });

        const mapProductionOutcome = (rp: ProdVsPlanDataList) => ({
          outcome: rp.production,
          id: rp.timePeriod,
        });

        const mapBookedData = (rp: ProdVsPlanDataList) => ({
          bookedValue: rp.booked,
          id: rp.timePeriod,
        });

        const mapReferrals = (rp: ProdVsPlanDataList) => rp.refferal;
        const mapDelayedVisits = (rp: ProdVsPlanDataList) => rp.waiting;
        const mapIsWithinLimit = (rp: ProdVsPlanDataList) => rp.production >= rp.productionPlan;

        const processResponseData = (data: ProdVsPlanDataList[]) => {
          const recoveryPlan = data.map(mapRecoveryPlan);
          setRecoveryPlan(recoveryPlan);

          const productionPlanData = data.map(mapProductionPlan);
          setAreaChartData(productionPlanData);

          const referrals = data.map(mapReferrals);
          setReferralData(referrals);

          const delayedVisits = data.map(mapDelayedVisits);
          setDelayedContactData(delayedVisits);

          const bookedData = data.map(mapBookedData);
          setBookedData(bookedData);

          const outcome: ProductionOutcomeData = {
            outcomeData: data.map(mapProductionOutcome),
            isWithinLimit: data.map(mapIsWithinLimit),
          };

          setColumnChartData(outcome);
        };

        processResponseData(response.data.getActualVsPlans);
        setYtd(Math.round(response.data.ytd * 100));
        setIsLoading(false);
      })
      .catch((error) => {
        setRecoveryPlan([]);
        console.error(error);
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    recoveryPlan,
    areaChartData,
    columnChartData,
    bookedData,
    referralData,
    delayedContactData,
    fetchProdVsPlanData,
    ytd,
  };
};

export default useProductionVsPlanData;
