import React, { useEffect, useState } from "react";
import { DashboardService } from "../../../services";
import { useTranslation } from "react-i18next";
import {
  KpiValues,
  StaffingChartData,
  StaffingChartDataWithColors,
  StaffingResponse,
} from "../../../interfaces/dashboard/staffing";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import { FilterCareUnits, RootState } from "../../../redux/store";
import { useMediaQuery } from "../../../hooks";
import { SCREEN_SIZES } from "../../../utils/constants/mediaQueries";
import { getChartFontSize } from "../../../utils/highcharts/common";
import LoadingWithGrey from "../../../components/loading/LoadingWithGrey";
import { useNavigate } from "react-router-dom";
import StaffingKpis from "./staffing-kpis";
import { useNumberFormat } from "../../../hooks/use-number-format";

const StaffingComponent = ({ filterCareUnits }: { filterCareUnits: FilterCareUnits }) => {
  const { t: translate, i18n } = useTranslation();
  const { localize, round } = useNumberFormat();
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const careUnitLabel = useSelector((state: RootState) => state.globalFilter.filterCareUnitLabel);
  const [staffingChartData, setStaffingChartData] = useState<StaffingChartData>({ seriesList: [], chartData: [] });

  const kpiInitialState = {
    SSK: 0,
    USK: 0,
    Other: 0,
  };

  const competenceShares = ["SSK", "USK", "SPEC", "ST/LEG/AT/UL", "Övrigt"];

  const [modelVaules, setModelVaules] = useState<KpiValues>(kpiInitialState);
  const [diffVaules, setDiffVaules] = useState<KpiValues>(kpiInitialState);
  const [staffingData, setStaffingData] = useState<StaffingResponse>();
  const isLargeScreen = useMediaQuery(SCREEN_SIZES.lg);

  const chartFontSize = getChartFontSize(true, isLargeScreen);

  const [isChartDataLoading, setIsChartDataLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleChartClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      (event.target as HTMLElement).closest(".highcharts-legend-item") ||
      (event.target as HTMLElement).closest(".highcharts-legend")
    ) {
      return;
    }

    navigate("/productivity");
  };

  useEffect(() => {
    const fetchChartData = async () => {
      setIsChartDataLoading(true);
      await DashboardService.getTotalByCompetenceTypeForStaffing(
        filterCareUnits.units,
        scenario,
        filterCareUnits.careUnitType
      )
        .then((res: StaffingResponse) => {
          res.chartData.sort((a, b) => {
            if (a.sortOrder != null && b.sortOrder != null) {
              return a.sortOrder - b.sortOrder;
            }
            return 0;
          });
          setChartColors(res);
          setStaffingData(res);
          res.chartData.map((x) => {
            !competenceShares.includes(x.name) ? competenceShares.push(x.name) : x;
          });
        })
        .catch((err) => {
          console.error(err);
        });
      setIsChartDataLoading(false);
    };

    if (filterCareUnits !== undefined && filterCareUnits.units.length > 0 && filterCareUnits.units[0] !== 0) {
      fetchChartData();
    }
  }, [filterCareUnits, scenario]);

  const processKPIData = () => {
    setModelVaules(kpiInitialState);
    setDiffVaules(kpiInitialState);

    const seriesList = staffingData?.seriesList;
    const dataList = staffingData?.chartData;

    if (seriesList && seriesList.length > 0 && dataList && dataList.length > 0) {
      const sskData = dataList.filter((x) => x.name === "SSK");
      const uskData = dataList.filter((x) => x.name === "USK");
      const otherData = dataList.filter((x) => x.name !== null && x.name !== "SSK" && x.name !== "USK");

      const modelIndex = seriesList && seriesList.findIndex((x) => x === "Modellbehov");
      const actualIndex = seriesList && seriesList.findIndex((x) => x === "Tillgänglighet per kompetens");

      if (modelIndex >= 0) {
        setModelVaules({
          SSK:
            sskData.length > 0 && sskData[0].data.length > 0
              ? sskData.reduce((n, { data }) => n + data[modelIndex], 0)
              : 0,
          USK:
            uskData.length > 0 && uskData[0].data.length > 0
              ? uskData.reduce((n, { data }) => n + data[modelIndex], 0)
              : 0,
          Other:
            otherData.length > 0 && otherData[0].data.length > 0
              ? otherData.reduce((n, { data }) => n + data[modelIndex], 0)
              : 0,
        });
      }

      if (actualIndex >= 0) {
        setDiffVaules({
          SSK:
            sskData.length > 0 && sskData[0].data.length > 0
              ? sskData.reduce((n, { data }) => n + data[actualIndex], 0)
              : 0,
          USK:
            uskData.length > 0 && uskData[0].data.length > 0
              ? uskData.reduce((n, { data }) => n + data[actualIndex], 0)
              : 0,
          Other:
            otherData.length > 0 && otherData[0].data.length > 0
              ? otherData.reduce((n, { data }) => n + data[actualIndex], 0)
              : 0,
        });
      }
    }
  };

  useEffect(() => {
    processKPIData();
  }, [staffingData]);

  const otherChartColors = [
    "#A9C9A0",
    "#00283C",
    "#681215",
    "#BD4B4F",
    "#EB9B9D",
    "#B67D16",
    "#F5C773",
    "#747474",
    "#AC9170",
  ];

  const getRandomColor = (): string => {
    const min = Math.ceil(0);
    const max = Math.floor(otherChartColors.length);
    const number = Math.floor(Math.random() * (max - min + 1)) + min;
    return otherChartColors[number];
  };

  const setChartColors = (res: StaffingResponse) => {
    const modifiedChartData: StaffingChartData = {
      seriesList: res.seriesList,
      chartData: [],
    };

    const chartDataWithColors: StaffingChartDataWithColors[] = [];

    res?.chartData?.forEach((element) => {
      const staffingData: StaffingChartDataWithColors = {
        color: element.color != null ? element.color : getRandomColor(),
        data: element.data,
        name: element.name,
      };
      chartDataWithColors.push(staffingData);
    });

    modifiedChartData.chartData = chartDataWithColors;
    setStaffingChartData(modifiedChartData);
  };

  const staffingChartOptions = {
    chart: {
      type: "bar",
      height: "50%",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: staffingChartData.seriesList.map((x) => translate(x).replace(/\n/g, "<br />")),
      labels: {
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          // eslint-disable-next-line
          // @ts-ignore
          const valueName = this.value.name;

          if (valueName !== undefined && valueName === careUnitLabel) {
            return (
              `<div><span style="color: #548147; font-weight: bold; font-size: ${chartFontSize}px">` +
              valueName +
              "</span></div>"
            );
          } else if (valueName !== undefined && valueName !== careUnitLabel) {
            return `<span style="font-weight: normal; font-size: ${chartFontSize}px">` + valueName + "</span>";
          } else {
            return valueName;
          }
        },
      },
    },
    yAxis: {
      reversedStacks: false,
      min: 0,
      title: {
        text: "",

        style: {
          fontSize: chartFontSize,
        },
      },
      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },
    tooltip: {
      headerFormat: "",
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const point = this.point as Highcharts.Point;
        const tooltipInfo = `<br /> ${translate("no_of_ftes")} ${localize(round(point.y || 0, 1), i18n.language)}`;
        return tooltipInfo;
      },
    },
    legend: {
      maxHeight: isLargeScreen && 40,
      verticalAlign: "top",
      itemStyle: {
        fontSize: chartFontSize,
      },
      style: {
        fontSize: chartFontSize,
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        point: {},
      },
    },
    series: staffingChartData.chartData,
  };

  const isLoading = isChartDataLoading;

  return (
    <>
      {isLoading && (
        <div className="dashboard__chart__loading-container">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      )}
      <div className={`${isLoading ? "hidden" : "flex"}  w-full cursor-pointer`} onClick={handleChartClick}>
        <div className="flex w-full flex-col">
          <div className="chart-title">
            <span>{translate("staffing")}</span>
          </div>

          <div className="chart-bg chart-bg flex-1">
            <HighchartsReact
              highcharts={Highcharts}
              options={staffingChartOptions}
              containerProps={{ style: { height: "100%" } }}
            />
          </div>

          <div className="chart-kpi-bottom">
            <StaffingKpis ModelValues={modelVaules} DiffValues={diffVaules} />
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffingComponent;
