import React, { useCallback, useEffect, useState } from "react";
import Highcharts from "highcharts";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingWithGrey from "../../../components/loading/LoadingWithGrey";
import { FilterCareUnits, RootState } from "../../../redux/store";
import { BENCHMARKING } from "../../../utils/constants/routes";
import "../styles/common-chart-styles.css";
import ProductivityKpis from "./productivity-kpis";
import WaterfallKpiService from "../../../services/kpiService";
import { WaterfallKpi } from "../../../interfaces/productivity";
import { InpatientProductivityKps } from "../../../interfaces/productivity/waterfall-kpi";
import { CARE_UNIT_TYPES, CareUnitTypeEnum } from "../../../utils/constants/careUnitTypes";
import HighchartsReact from "highcharts-react-official";
import {
  BenchmarkDataObj,
  ChartData,
  ChartDataObject,
  ChartDataResponse,
} from "../../../interfaces/productivity/benchmarking-chart-types";
import useMediaQuery from "../../../hooks/use-media-query";
import { SCREEN_SIZES } from "../../../utils/constants/mediaQueries";
import { useNumberFormat } from "../../../hooks/use-number-format";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import DashboardService from "../../../services/dashboardService";
import { competence_shares } from "../../../utils/highcharts/capacity/constants";

const ProductionComponent = ({ filterCareUnits }: { filterCareUnits: FilterCareUnits }) => {
  const { t: translate, i18n } = useTranslation();
  const { localize, round } = useNumberFormat();

  const navigate = useNavigate();

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.BenchmarkGoals);
  const isActualProductivityFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.ActualProductivity);

  const globalFilter = useSelector((state: RootState) => state.globalFilter);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const [isChartDataLoading, setIsChartDataLoading] = useState<boolean>(true);

  const [isKpiDataLoading, setIsKpiDataLoading] = React.useState<boolean>(true);

  const [inpatientKpis, setInpatientKpis] = useState<InpatientProductivityKps>({
    costPerCareDay: 0,
    plannedProductivity: 0,
    patientsPerRn: 0,
    actualProductivity: null,
  });

  const [outpatientKpis, setOutpatientKpis] = useState<WaterfallKpi[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const isLargeScreen = useMediaQuery(SCREEN_SIZES.lg);

  const chartFontSize = isLargeScreen ? 12 : 16;

  const axisItemsFontSize = chartFontSize;

  const [benchmarkChartData, setBenchmarkChartData] = useState<ChartData>({
    careUnits: [],
    chartData: [],
  });

  const selectedCareLevel = {
    label: "all",
    value: "",
    isSelected: false,
  };

  const selectedMedicalSpeciality = {
    label: "all",
    value: "",
    isSelected: false,
  };

  const selectedCompetenceInput = {
    label: translate("competenceGroupLvl2"),
    value: "competenceGroupLvl2",
  };

  const selectedCompetenceType = {
    label: translate("all"),
    value: "",
  };

  const selectedContactType = {
    label: translate("all"),
    value: "",
  };

  const selectedInput = {
    label: translate("working_hours"),
    value: "working_hours",
  };

  const endDateErrors: string[] = [];

  useEffect(() => {
    fetchKpis();
  }, [filterCareUnits, scenario]);

  useEffect(() => {
    if (!isChartDataLoading && !isKpiDataLoading) {
      if (isLoading) {
        setIsLoading(false);
      }
    } else {
      if (!isLoading) {
        setIsLoading(true);
      }
    }
  }, [isChartDataLoading, isKpiDataLoading]);

  //calling APIs and response modifiers in initial load and care unit change
  useEffect(() => {
    // Effect for dashboard chart
    if (filterCareUnits && filterCareUnits.units.length > 0 && scenario > 0 && year && year > 0) {
      const fetchData = async () => {
        const productionTypes =
          filterCareUnits?.careUnitType === CARE_UNIT_TYPES.OUTPATIENT
            ? [
                "ProductionShareOutpatientVisit",
                "ProductionShareStExternalTraining",
                "ProductionShareManagement",
                "ProductionShareNonClinicalTime",
              ]
            : [
                "ProductionShareWard",
                "ProductionShareStExternalTraining",
                "ProductionShareManagement",
                "ProductionShareNonClinicalTime",
              ];

        const chartData = await fetchBMChartData(productionTypes);
        await processChartData(chartData as ChartDataResponse);
      };

      filterCareUnits?.careUnitType !== null && fetchData();
    }
  }, [scenario, filterCareUnits]);

  const fetchKpis = () => {
    const kpisIds = ["WorkingHoursPerCareDay/Visit", "Patients/DailyVisitsPerFTE", "WorkingHoursKpi"];

    if (filterCareUnits && filterCareUnits.units.length > 0) {
      setIsKpiDataLoading(true);
      if (filterCareUnits.careUnitType === CareUnitTypeEnum.OUTPATIENT) {
        WaterfallKpiService.getWaterfallOutPatientKPIs(filterCareUnits.units, year, scenario).then((res) => {
          const newKpis: WaterfallKpi[] = [];
          const resData: WaterfallKpi[] = res as WaterfallKpi[];

          kpisIds.map((r) => {
            const items = resData.filter((rr) => rr.kpiName == r);
            if (items && items.length > 0) {
              newKpis.push(items[0]);
            } else {
              const emaptyKPI: WaterfallKpi = { kpiName: r, kpiValue: 0 };
              newKpis.push(emaptyKPI);
            }
          });

          setOutpatientKpis(() => newKpis);
          setIsKpiDataLoading(false);
        });
      } else {
        WaterfallKpiService.getWaterfallInPatientKPIs(filterCareUnits.units, year, scenario).then(
          (res: WaterfallKpi[]) => {
            if (res.length > 0) {
              const newKpis: InpatientProductivityKps = {
                costPerCareDay: Math.round(res.find((kpi) => kpi.kpiName === "StaffingCostPerCareDay")?.kpiValue || 0),
                plannedProductivity: Number(res.find((kpi) => kpi.kpiName === "WorkingHoursPerCareDay")?.kpiValue || 0),
                patientsPerRn: Number(res.find((kpi) => kpi.kpiName === "PatientsPerFTE")?.kpiValue || 0),
                actualProductivity: res.find((kpi) => kpi.kpiName === "ActualProductivity")?.kpiValue || null,
              };

              setInpatientKpis(() => newKpis);
              setIsKpiDataLoading(false);
            }
          }
        );
      }
    }
  };

  //chart data loader
  const fetchBMChartData = async (selectedProdTypes: string[]) => {
    setIsChartDataLoading(true);
    const productionTypeNames: string[] = selectedProdTypes;

    let result: ChartData = {
      chartData: [],
      careUnits: [],
    };

    const careLevel = selectedCareLevel && selectedCareLevel?.value === "" ? null : Number(selectedCareLevel?.value);

    const medicalSpeciality =
      selectedMedicalSpeciality && selectedMedicalSpeciality?.value === ""
        ? null
        : Number(selectedMedicalSpeciality?.value);

    const profession =
      selectedCompetenceType && selectedCompetenceType.value === "" ? null : selectedMedicalSpeciality?.value;
    const contactType = selectedContactType && selectedContactType.value === "" ? null : selectedContactType?.value;

    if (endDateErrors && endDateErrors.length < 1) {
      if (filterCareUnits && filterCareUnits.units.length > 0) {
        await DashboardService.getDashboardBenchmarkingData(
          filterCareUnits.units,
          year as number,
          selectedCompetenceInput.value,
          careLevel,
          medicalSpeciality,
          selectedInput.value,
          profession,
          contactType,
          productionTypeNames,
          scenario,
          filterCareUnits.careUnitType
        ).then((res) => {
          const data: ChartDataResponse = res;

          data?.chartData?.sort((a: ChartDataObject, b: ChartDataObject) => {
            return competence_shares.indexOf(a.name) - competence_shares.indexOf(b.name);
          });
          result = data;
        });
      }
    }

    return result;
  };

  //defining chart colors
  const processChartData = async (data: ChartDataResponse) => {
    const modifiedChartData: ChartData = {
      careUnits: data?.careUnits,
      chartData: [],
    };

    data?.chartData.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));

    data?.chartData.map((dataObj: ChartDataObject) => {
      const st: BenchmarkDataObj = {
        id: uuid(),
        color: dataObj.color,
        data: dataObj.data as number[],
        name: dataObj.name,
        sortOrder: dataObj.sortOrder,
      };
      modifiedChartData.chartData.push(st);
    });

    if (isFeatureFlagEnabled) {
      if (filterCareUnits?.careUnitType === CARE_UNIT_TYPES.INPATIENT) {
        const targetProductivitySeries = {
          data: data.tagetProductivity?.map((tp) => {
            const careUnitName = tp.careUnitName;
            const index = modifiedChartData.careUnits.indexOf(careUnitName);
            return [index, tp.targetProductivity];
          }),
          name: translate("target_productivity"),
          id: uuid(),
          color: "#F25C05",
          type: "scatter",
          zIndex: 10000,
        };

        modifiedChartData.chartData.push(targetProductivitySeries);
      }
    }

    if (
      isActualProductivityFeatureFlagEnabled &&
      filterCareUnits?.careUnitType === CARE_UNIT_TYPES.INPATIENT &&
      filterCareUnits?.units.length === 1
    ) {
      data.actualProuctivity.forEach((ap) => {
        //ap.actualProductivity = _.round(ap.actualProductivity, 1);
        const index = modifiedChartData.chartData.findIndex((cd) => cd.name === ap.formalCompetenceName);

        if (index !== -1) {
          (modifiedChartData.chartData[index].data as number[]).push(ap.actualProductivity);
        } else {
          const data = modifiedChartData.careUnits.length > 0 ? [0, ap.actualProductivity] : [ap.actualProductivity];

          const st: BenchmarkDataObj = {
            id: uuid(),
            color: ap.formalCompetenceColor,
            data: data,
            name: ap.formalCompetenceName,
            sortOrder: ap.sortOrder,
          };
          modifiedChartData.chartData.push(st);
        }
      });

      modifiedChartData.careUnits.push("Actual");
    }

    setBenchmarkChartData(modifiedChartData);
    setIsChartDataLoading(false);
  };

  const handleChatClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (
      (event.target as HTMLElement).closest(".highcharts-legend-item") ||
      (event.target as HTMLElement).closest(".highcharts-legend")
    ) {
      return;
    }
    navigate(`/productivity/${BENCHMARKING}`);
  }, []);

  const benchmarkingOptions = {
    chart: {
      type: "bar",
      height: "50%",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: benchmarkChartData.careUnits,
      labels: {
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          // eslint-disable-next-line
          // @ts-ignore
          const valueName = this.value.name;

          if (valueName !== undefined && valueName === globalFilter.filterCareUnitLabel) {
            return (
              `<div><span style="color: #548147; font-weight: bold; font-size: ${axisItemsFontSize}px">` +
              valueName +
              "</span></div>"
            );
          } else if (valueName !== undefined && valueName !== globalFilter.filterCareUnitLabel) {
            return `<span style="font-weight: normal; font-size: ${axisItemsFontSize}px">` + valueName + "</span>";
          } else {
            return valueName;
          }
        },
      },
      title: "",
    },
    yAxis: {
      reversedStacks: false,
      min: 0,
      title: {
        text: "",

        style: {
          fontSize: chartFontSize,
        },
      },
      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },
    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: chartFontSize,
      },
      style: {
        fontSize: chartFontSize,
      },
    },
    plotOptions: {
      scatter: {
        marker: {
          symbol: "circle",
        },
      },
      series: {
        stacking: "normal",
        point: {},
      },
    },
    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const point = this.point as Highcharts.Point;
        const tooltipValue = localize(round(point.y || 0, 1), i18n.language);
        return `${point.series.name} ${tooltipValue}`;
      },
    },
    series: benchmarkChartData.chartData,
  };

  return (
    <>
      {isLoading && (
        <div className="dashboard__chart__loading-container">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      )}
      {!isLoading && (
        <div className={"flex !h-full w-full cursor-pointer"} onClick={handleChatClick}>
          <div className="flex h-full w-full flex-col">
            <div className="chart-title">
              <span>{translate("productivity")}</span>
            </div>
            <div className="chart-bg">
              <div className="h-full w-full ">
                {!isChartDataLoading && (
                  <>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={benchmarkingOptions}
                      containerProps={{
                        style: { height: "100%", width: "100%" },
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="chart-kpi-bottom">
              <ProductivityKpis
                outpatientKpis={outpatientKpis}
                inpatientKpis={inpatientKpis}
                careUnitType={filterCareUnits.careUnitType}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductionComponent;
