import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchKPIs } from "./hooks/useFetchKPIs";
import { WaitingListProps } from "../../../interfaces/props/wating-list";
import { WaitingListKPIs } from "../../../interfaces/dashboard/waitinglist-kpi";

const WaitingListKpis = ({ filterCareUnits }: WaitingListProps) => {
  const { t: translate } = useTranslation();
  const [waitingList, setWatingList] = useState<WaitingListKPIs>({
    above90: 0,
    returnVisitAfterGoalDate: 0,
  });

  const [waitingListData] = useFetchKPIs({
    filterCareUnits,
  });

  useEffect(() => {
    if (waitingListData) {
      setWatingList(waitingListData);
    } else {
      setWatingList({
        above90: 0,
        returnVisitAfterGoalDate: 0,
      });
    }
  }, [waitingListData]);

  return (
    <div>
      <div className="sidearea__text--container-v3">
        <h1 className="sidearea__text--title-v3">
          {translate("first_visit")} {">"} {"90"} {translate("days")}
        </h1>
        <p className="sidearea__text--value-v3">{waitingList.above90}</p>
      </div>
      <div className="sidearea__text--container-v3">
        <h1 className="sidearea__text--title-v3">{translate("return_visit_after_goal_date")}</h1>
        <p className="sidearea__text--value-v3">{waitingList.returnVisitAfterGoalDate}</p>
      </div>
    </div>
  );
};

export default WaitingListKpis;
