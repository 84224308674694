import WorkCompetence from "../../../../../interfaces/competence/WorkCompetence";
import { HandsOnTableColumn } from "../../../../../interfaces/handsOnTable/handsOnTableColumn";
import { nullStringValidator } from "../../../../../utils/handsontable-configuration/validators/validators";

export const useGetActivityShiftTableColumns = (
  culture: string,
  workCompetences: WorkCompetence[]
): HandsOnTableColumn[] => {
  const cols: HandsOnTableColumn[] = [
    {
      meta: {
        data: "activity",
        nullable: false,
        validator: nullStringValidator,
      },
      headerTitle: "activity-analysis-shift-activity",
      width: 200,
    },
    {
      meta: {
        data: "workCompetenceId",
        type: "keyValueDropdown",
        source: workCompetences.map((competence) => {
          return {
            _id: competence.id,
            label: competence.shortName,
          };
        }) as any,
        editorParams: { width: 200 },
      },
      headerTitle: "competence_group",
      width: 200,
    },
    {
      meta: {
        data: "timePerActivity",
        type: "numeric",
      },
      headerTitle: "activity-analysis-shift-timePerOccasion",
      width: 130,
    },
    {
      meta: {
        data: "activitiesPerWeek",
        type: "numeric",
      },
      headerTitle: "activity-analysis-shift-occasionsPerWeek",
      width: 130,
    },
    {
      meta: {
        data: "numberPresence",
        type: "numeric",
      },
      headerTitle: "activity-analysis-shift-numberPressence",
      width: 130,
    },
    {
      meta: { data: "weeksPerYear", type: "numeric" },
      headerTitle: "activity-analysis-shift-weeksPerYear",
      width: 130,
    },
    {
      meta: {
        data: "timeSpent",
        type: "numeric",
        numericFormat: {
          culture: culture,
          pattern: {
            mantissa: 0,
          },
        },
        readOnly: true,
      },
      headerTitle: "activity-analysis-shift-timeSpent",
      width: 130,
    },
    {
      meta: {
        className: "htRight",
        data: "weeklyWorkingHours",
        type: "numeric",
        // validator: numberGreaterThanZeroValidator,
        // renderer: removeTotalValidationErrorFromTextCellRenderer,
      },
      headerTitle: "activity-analysis-shift-weeklyWorkingHours",
      width: 130,
    },
    {
      meta: {
        data: "fte",
        type: "numeric",
        numericFormat: {
          culture: culture,
          pattern: {
            mantissa: 1,
          },
        },
        readOnly: true,
      },
      headerTitle: "activity-analysis-shift-fteProductionPlan",
      width: 130,
    },
  ];

  return cols;
};
