import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeatMap2Data } from "../../../../interfaces/capacity/heatmap-data";
import { hoursOfTheDay } from "../../../../utils/highcharts/capacity/constants";
import { CapacityService } from "../../../../services";
import { AxiosResponse } from "axios";
import { HeatMap2Response } from "../../../../interfaces/capacity/heat-map-data-response";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import LoadingWithGrey from "../../../../components/loading/LoadingWithGrey";

const DemandHeatmap = () => {
    const { t: translate } = useTranslation();
    const [chartData, setChartData] = useState<HeatMap2Data>({
        data: [],
      });
    const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
    const [isChartLoading, setIsChartLoading] = useState<boolean>(false);

    const daysOfWeek = [
        translate("Mon"),
        translate("Tue"),
        translate("Wed"),
        translate("Thu"),
        translate("Fri"),
        translate("Sat"),
        translate("Sun"),
      ];
    
    const chartOptions = {
        chart: {
          type: "heatmap",
          marginTop: 80,
          marginBottom: 60,
          plotBorderWidth: 1,
          backgroundColor: "transparent",
          style: {
            fontFamily: "Open Sans",
            textOutline: 0,
            fontSize: 12,
          },
        },
    
        tooltip: {
          formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
            const point = this.point as Highcharts.Point;
    
            const tooltipInfo = `
            ${daysOfWeek[point.x]} <br />
            ${point.y} <br /> 
            ${point.value}%`;
            return tooltipInfo;
          },
        },
    
        title: {
          text: translate("heatmap_2_title"),
          style: {
            fontSize: 16,
          },
        },
    
        xAxis: {
          categories: [
            translate("Mon"),
            translate("Tue"),
            translate("Wed"),
            translate("Thu"),
            translate("Fri"),
            translate("Sat"),
            translate("Sun"),
          ],
          opposite: true,
          labels: {
            style: {
              fontSize: 12,
            },
            rotation: 0,
          },
        },
    
        yAxis: {
          categories: hoursOfTheDay,
          title: null,
          reversed: true,
          labels: {
            style: {
              fontSize: 12,
            },
          },
        },
    
        accessibility: {
          point: {},
        },
    
        colorAxis: {
          stops: [
            [0, "#7EA4CF"],
            [0.5, "#426FA2"],
            [1, "#00283C"],
          ],
          labels: {
            format: "{value}%",
          },
        },
    
        legend: {
          align: "right",
          layout: "vertical",
          margin: 0,
          verticalAlign: "top",
          y: 25,
          symbolHeight: 280,
          style: {
            fontSize: 16,
          },
        },
    
        series: [
          {
            ...chartData,
            borderWidth: 1,
            borderColor: "#ADADAD",
            dataLabels: {
              enabled: true,
              color: "white",
              formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                return this.point.value + "%";
              },
              style: {
                textOutline: 0,
                fontWeight: "normal",
                fontSize: 12,
              },
            },
          },
        ],
    
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                yAxis: {
                  labels: {},
                },
              },
            },
          ],
        },
      };

      useEffect(() => {
        if (careUnitId !== null) {
            setIsChartLoading(true);
    
          CapacityService.getWeeklyWorkingHoursHeatMap2Data(careUnitId).then((res: AxiosResponse<HeatMap2Response>) => {
            setChartData(() => ({
              data: res.data.workloadValues,
            }));
            
            if (res.data.workloadValues.length !== 0) {
                setIsChartLoading(false);
            }
          });
        }
      }, [careUnitId]);

      return (
        <>
            {!isChartLoading ? (
                <HighchartsReact
                    containerProps={{ style: { height: "100%" } }}
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            )
            :
            (
                <div className="flex h-full items-center justify-center">
                  <LoadingWithGrey sizeInPixel={40} />
                </div>
            )}
        </>
      )
};

export default DemandHeatmap;