import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { HideToggle } from "../../../components";
import { ISelectOption } from "../../../interfaces";
import { useTranslation } from "react-i18next";
import { CareUnitTypeEnum } from "../../../utils/constants/careUnitTypes";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import InpatientOverview from "./InpatientOverview/InpatientOverview";
import OutpatientOverview from "./OutpatientOverview/OutpatientOverview";

const OrganizationalOverviewHome = () => {
  const { t: translate } = useTranslation();
  const selectedCareUnitType = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.OutpatientOrgOverview);
  const isOrgOverviewFilterEnabled = useFeatureFlagIsEnabled(FeatureFlags.OrgOverviewFilter);

  const types_of_plan: ISelectOption[] = [
    {
      label: translate("outpatient"),
      value: "1",
    },

    {
      label: translate("inpatient"),
      value: "2",
    },
  ];

  const [isInpatientSelected, setIsInpatientSelected] = useState<boolean>(
    selectedCareUnitType === CareUnitTypeEnum.INPATIENT
  );

  const toggleCareUnitTypeAndFetch = () => {
    setIsInpatientSelected(!isInpatientSelected);
  };

  useEffect(() => {
    if (selectedCareUnitType) {
      const isInpatientTypeSelected = selectedCareUnitType === CareUnitTypeEnum.INPATIENT;
      if (isInpatientTypeSelected !== isInpatientSelected && isOrgOverviewFilterEnabled) {
        setIsInpatientSelected(isInpatientTypeSelected);
      }
    }
  }, [selectedCareUnitType]);

  return (
    <div className="flex h-full w-full flex-col ">
      {isFeatureFlagEnabled ? (
        <div className="flex justify-end px-[1.406rem] pt-[0.75rem]">
          <div className="flex">
            <div className="flex items-center">
              <span
                className={`mb-2 px-4 lg:text-[12px] xl:text-[16px] ${
                  !isInpatientSelected ? "font-semibold text-[#000]" : ""
                }`}
              >
                {translate(types_of_plan[0].label)}
              </span>
              <div>
                <HideToggle isVisible={isInpatientSelected} onToggle={toggleCareUnitTypeAndFetch} isFlipType={true} />
              </div>
              <span
                className={`mb-2 px-4 lg:text-[12px] xl:text-[16px] ${
                  isInpatientSelected ? "font-semibold text-[#000]" : ""
                }`}
              >
                {translate(types_of_plan[1].label)}
              </span>
            </div>
          </div>
        </div>
      ) : null}
      <div className="h-[calc(100%-64px)] w-full  flex-1">
        {isFeatureFlagEnabled && !isInpatientSelected ? <OutpatientOverview /> : <InpatientOverview />}
      </div>
    </div>
  );
};

export default OrganizationalOverviewHome;
