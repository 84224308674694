import { useState, useEffect } from "react";
import ProductivityService from "../../../../../../services/productivityService";
import { AxiosResponse } from "axios";
import { PhysicianUnitsProductivityComparisonData } from "../../../../../../interfaces/productivity/physicianUnitsProductivityComparisonData";

const usePhysicianUnitComparisonData = (careUnitId: number, scenarioId: number, year: number) => {
  const [data, setData] = useState<PhysicianUnitsProductivityComparisonData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response: AxiosResponse<PhysicianUnitsProductivityComparisonData> =
          await ProductivityService.getPhysicianComparison(careUnitId, scenarioId, year);
        setData(response.data);
      } catch (err) {
        console.error("An error occurred while fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [careUnitId, scenarioId, year]);

  return { data, loading };
};

export default usePhysicianUnitComparisonData;
