const validator = (regex: RegExp, value: string) => {
  return regex.test(value);
};

export const timeCellValidator = (value: string, callback: (arg0: boolean) => void) => {
  const regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$|null/;

  if (value === " " || value === "") {
    callback(true);
  } else {
    callback(validator(regex, value));
  }
};

export const positiveNumberValidator = (value: string, callback: (arg0: boolean) => void) => {
  const regex = /^[.]?[0-9]+[.]?[0-9]*$|null/;

  if (value === " " || value === "") {
    callback(true);
  } else {
    callback(validator(regex, value));
  }
};

export const zeroOrOneValidator = (value: string, callback: (arg0: boolean) => void) => {
  const regex = /^[0-9]\d*$/;
  callback(validator(regex, value));
};

export const numberBetWeenZeroAndOneValidator = (value: string, callback: (arg0: boolean) => void) => {
  const regex = /^(0(\.\d+)?|1(\.0+)?)$|null/;

  if (value === " " || value === "") {
    callback(true);
  } else {
    callback(validator(regex, value));
  }
};

export const numberBetWeenZeroAndHundredValidator = (value: string, callback: (arg0: boolean) => void) => {
  const valueNumber: number = parseFloat(value);

  if (value === "" || value === null) {
    callback(true);
  } else {
    if (!Number.isNaN(valueNumber)) {
      if (valueNumber > 100 || valueNumber < 0) {
        callback(false);
      } else {
        callback(true);
      }
    } else {
      callback(false);
    }
  }
};

export const numberBetWeenZeroAndOneInAvailabilityValidator = (value: string, callback: (arg0: boolean) => void) => {
  const regex = /^(?:0*(?:\.\d+)?|1(\.0*)?)$/;

  if (value === " " || value === "") {
    callback(true);
  } else {
    callback(validator(regex, value));
  }
};

export const numberBetweenZeroandFourtyValidator = (value: number, callback: (arg0: boolean) => void) => {
  const twoDecimalsRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
  if (value === null) {
    callback(false);
  } else {
    if (typeof value === "number") {
      callback(value >= 0 && value <= 40 && twoDecimalsRegex.test(value.toString()));
    } else {
      callback(false);
    }
  }
};

export const numberBetweenZeroandFourValidator = (value: number, callback: (arg0: boolean) => void) => {
  if (value === null) {
    callback(false);
  } else {
    callback(value >= 0 && value <= 4);
  }
};

export const numberBetweenZeroandThreeValidator = (value: number, callback: (arg0: boolean) => void) => {
  if (value === null) {
    callback(false);
  } else {
    callback(value >= 0 && value <= 3);
  }
};

export const numberBetweenZeroandSixtyValidator = (value: string, callback: (arg0: boolean) => void) => {
  const val = parseFloat(value);
  callback(val >= 0 && val <= 60);
};

export const numberBetWeenZeroAndPointZeroOneValidator = (value: string, callback: (arg0: boolean) => void) => {
  const val = parseFloat(value);
  callback(val >= 0 && val <= 0.01);
};

export const numberBetWeenOneAndTwoValidator = (value: string, callback: (arg0: boolean) => void) => {
  const val = parseFloat(value);
  callback(val >= 1 && val <= 2);
};

export const numberBetweenZeroand366Validator = (value: number, callback: (arg0: boolean) => void) => {
  callback(value >= 0 && value <= 366);
};

export const nullStringValidator = (value: string, callback: (arg0: boolean) => void) => {
  if (value === "" || value == null) {
    callback(false);
  } else {
    callback(true);
  }
};

export const nullStringStringLengthValidator = (value: string, callback: (arg0: boolean) => void) => {
  if (value === "" || value == null) {
    callback(false);
  } else {
    if (value.length > 20) {
      callback(false);
    } else {
      callback(true);
    }
  }
};

export function numberGreaterThanZeroValidator(value: number, callback: (arg0: boolean) => void) {
  callback(value > 0);
}

export const keyValueListValidator = function (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  this: any,
  value: string | number | null | undefined,
  callback: (isValid: boolean) => void
) {
  let valueToValidate = value;

  if (valueToValidate === null || valueToValidate === void 0) {
    valueToValidate = "";
  }

  if (this.allowEmpty && valueToValidate === "") {
    callback(true);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback(this.source.find(({ _id }: any) => _id === value) ? true : false);
  }
};
