import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ProductionVsPlanComponent from "../features/home/production-vs-plan/production-vs-plan-component";
import PlanVsRealComponent from "../features/home/plan-vs-real-component/plan-vs-real-component";
import ProductionComponent from "../features/home/productivity/productivity-component";
import { RootState, setFilterDashboardCareUnitType } from "../redux/store";
import StaffingComponent from "../features/home/staffing/staffing-component";
import BudgetComponent from "../features/home/budget/budget-component";
import { HideToggle } from "../components";
import { ISelectOption } from "../interfaces";
import { useTranslation } from "react-i18next";
import CommentModal from "../features/home/comment/comment-modal";
import CommentButton from "../components/comment-button/comment-button";
import WaitingList from "../features/home/waiting-list/wating-list";
import { CareUnitTypeEnum } from "../utils/constants/careUnitTypes";

const messageIcon = (
  <svg className="comment-button__svg" viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
    <path d="M12 2A10 10 0 002 12a9.89 9.89 0 002.26 6.33l-2 2a1 1 0 00-.21 1.09A1 1 0 003 22h9a10 10 0 000-20zm0 18H5.41l.93-.93a1 1 0 000-1.41A8 8 0 1112 20z" />
  </svg>
);

const Homepage = () => {
  const dispatch = useDispatch();

  const globalFilter = useSelector((state: RootState) => state.globalFilter);

  const [isInpatientSelected, setIsInpatientSelected] = useState<boolean>(
    globalFilter.filterCareUnits.careUnitType === CareUnitTypeEnum.INPATIENT
  );

  const { t: translate } = useTranslation();
  const [commentWindowVisibility, setCommentWindowVisibility] = useState<boolean>(false);
  const [isCommentAvailable, setIsCommentAvailable] = useState<boolean>(false);

  const types_of_plan: ISelectOption[] = [
    {
      label: translate("outpatient"),
      value: "1",
    },

    {
      label: translate("inpatient"),
      value: "2",
    },
  ];

  const [, setSelectedPlanType] = useState<ISelectOption>(
    globalFilter.filterCareUnits.careUnitType === CareUnitTypeEnum.INPATIENT ? types_of_plan[1] : types_of_plan[0]
  );

  const setCommentStatus = (status: boolean) => {
    setIsCommentAvailable(() => status);
  };

  const toogleTypeOfVisitFilterVisibilityAndFetch = () => {
    // setSelectedPlanType(isInpatientSelected ? types_of_plan[0] : types_of_plan[1]);
    // setIsInpatientSelected(!isInpatientSelected);

    dispatch(
      setFilterDashboardCareUnitType(isInpatientSelected ? CareUnitTypeEnum.OUTPATIENT : CareUnitTypeEnum.INPATIENT)
    );
  };

  const toogleCommentWindow = () => {
    setCommentWindowVisibility(!commentWindowVisibility);
  };

  useEffect(() => {
    if (isInpatientSelected !== (globalFilter.filterCareUnits.careUnitType === CareUnitTypeEnum.INPATIENT)) {
      setSelectedPlanType(isInpatientSelected ? types_of_plan[0] : types_of_plan[1]);
      setIsInpatientSelected(!isInpatientSelected);
    }
  }, [globalFilter.filterCareUnits.careUnitType]);

  return (
    <div className="home-container flex max-h-full flex-col px-[1.406rem] pt-[0.75rem]">
      <div className="flex justify-end">
        <div className="flex">
          <>
            {globalFilter.filterCareUnits.units.length === 1 ? (
              <CommentButton
                hasNewComment={isCommentAvailable ? true : false}
                icon={messageIcon}
                onClick={toogleCommentWindow}
              />
            ) : null}

            <CommentModal
              selectedCareUnits={globalFilter.filterCareUnits.units}
              isOpen={commentWindowVisibility}
              onClose={toogleCommentWindow}
              setCommentStatus={setCommentStatus}
            />
          </>

          <div className="flex items-center">
            <span
              className={`mb-2 px-4 lg:text-[12px] xl:text-[16px] ${
                !isInpatientSelected ? "font-semibold text-[#000]" : ""
              }`}
            >
              {translate(types_of_plan[0].label)}
            </span>
            <div>
              <HideToggle
                isVisible={isInpatientSelected}
                onToggle={toogleTypeOfVisitFilterVisibilityAndFetch}
                isFlipType={true}
              />
            </div>
            <span
              className={`mb-2 px-4 lg:text-[12px] xl:text-[16px] ${
                isInpatientSelected ? "font-semibold text-[#000]" : ""
              }`}
            >
              {translate(types_of_plan[1].label)}
            </span>
          </div>
        </div>
      </div>

      {globalFilter.filterCareUnits.units.length > 0 ? (
        <div className="chart-container grid max-h-full w-full grid-rows-2 gap-3">
          <div className="flex !h-full max-h-full !w-full flex-1 gap-3">
            <div className="max-h-full w-full rounded-[0.438rem] border-2 border-solid">
              <ProductionVsPlanComponent filterCareUnits={globalFilter.filterCareUnits} />
            </div>
            <div className="!h-full max-h-full w-full rounded-[0.438rem] border-2 border-solid">
              {isInpatientSelected && <PlanVsRealComponent filterCareUnits={globalFilter.filterCareUnits} />}
              {!isInpatientSelected && <WaitingList filterCareUnits={globalFilter.filterCareUnits} />}
            </div>
          </div>
          <div className="grid grid-cols-6 gap-3">
            <div className="col-span-2 h-full rounded-[0.438rem] border-2 border-solid">
              <div className="!h-full w-full">
                <ProductionComponent filterCareUnits={globalFilter.filterCareUnits} />
              </div>
            </div>
            <div className="col-span-2 h-full rounded-[0.438rem] border-2 border-solid">
              <div className="h-full">
                <BudgetComponent filterCareUnits={globalFilter.filterCareUnits} />
              </div>
            </div>
            <div className="col-span-2 rounded-[0.438rem] border-2 border-solid">
              <div className="!h-full w-full">
                <StaffingComponent filterCareUnits={globalFilter.filterCareUnits} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Homepage;
