import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "../../../../../components";
import { CompetenceFilterProps } from "../../../../../interfaces/productivity";

const CompetenceFilters = ({
  typesOfInputs,
  selectedInput,
  typesOfCompetence,
  selectedCompetenceInput,
  handleCompetenceInputSelect,
  handleTypesOfInputSelect,
}: CompetenceFilterProps) => {
  const { t: translate } = useTranslation();
  return (
    <div>
      <div className="chart-info-area__item__container">
        <Select
          placeholder={translate("type_of_input")}
          options={typesOfInputs}
          onSelectOption={handleTypesOfInputSelect}
          selectedOption={selectedInput}
        />
      </div>
      <div className="chart-info-area__item__container">
        <Select
          placeholder={translate("type_of_output")}
          options={typesOfCompetence}
          onSelectOption={handleCompetenceInputSelect}
          selectedOption={selectedCompetenceInput}
        />
      </div>
    </div>
  );
};

export default CompetenceFilters;
