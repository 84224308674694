import HotTable from "@handsontable/react";
import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components";
import ExpandableButton from "../../../../components/expandable-button";
import ControlIcon from "../../../../components/control-icon/control-icon";
import { ControlIconTypes } from "../../../../components/control-icon/icon-types";
import useGetActivityShiftsData from "../hooks/useGetActivityShiftsData";
import useGetActivityShiftTableConfigs from "./hooks/useGetActivityShiftTableConfigs";
import { ActivityAnalysisOutsideShiftProps } from "../../../../interfaces/props/activity-analysis-outside-shift";
import { ActivityAnalysisSubmitEventRef } from "../../../../interfaces/analytics/activity-analysis-submit-event-ref";
import { ActivityAnalysisOutSideShiftsConfigReturntype } from "../../../../interfaces/analytics/activity-analysis-outside-shifts-returntype";
import ActivityAnalysisService from "../../../../services/activityAnalysisService";
import { ActivityAnalysisShift } from "../../../../interfaces/analytics/activity-analysis-shift";
import useGetWorkCompetencesForCareUnit from "../hooks/useGetWorkCompetencesForCareUnit";

const ActivityAnalysisOutsideShifts = forwardRef<ActivityAnalysisSubmitEventRef, ActivityAnalysisOutsideShiftProps>(
  (
    {
      careUnitId,
      year,
      scenarioId,
      setIsSubmitting,
      setSubmitStatus,
      onNextButtonClick,
      onPrevButtonClick,
      isEditedRef,
    }: ActivityAnalysisOutsideShiftProps,
    ref
  ) => {
    const isWithinShifts = false;

    const hook = useGetActivityShiftsData(careUnitId, scenarioId, year, isWithinShifts, isEditedRef);

    const workCompetences = useGetWorkCompetencesForCareUnit(careUnitId);

    const configs: ActivityAnalysisOutSideShiftsConfigReturntype = useGetActivityShiftTableConfigs(
      hook.data,
      hook.setData,
      isWithinShifts,
      isEditedRef,
      workCompetences
    );

    const saveOutsideShiftData = async () => {
      setIsSubmitting(true);

      const sumRowRemoved = hook.data.filter((x) => x?.id !== "total_row");

      const modifiedData = sumRowRemoved as ActivityAnalysisShift[];

      await ActivityAnalysisService.postActivityAnalysisShift(
        careUnitId as number,
        scenarioId,
        year,
        false,
        modifiedData
      )
        .then(() => {
          setIsSubmitting(false);
          setSubmitStatus(1);
          hook.fetch();
        })
        .catch(() => {
          setIsSubmitting(false);
          setSubmitStatus(2);
        });
    };

    useImperativeHandle(ref, () => ({
      ...ref,
      saveOutsideShiftData,
    }));

    const handleAddNewRowClick = () => {
      configs.addNewRow();
    };

    const { t: translate } = useTranslation();

    const handleNextButtonClick = () => {
      onNextButtonClick();
    };

    const handlePrevButtonClick = () => {
      onPrevButtonClick();
    };

    return (
      <div className="col-start-1 col-end-13">
        <div className="rounded px-2">
          <div className="h-fit">
            <div className="relative h-[65vh] overflow-y-scroll">
              <HotTable data={hook.data} {...configs.props} />
            </div>
            <div className="mt-4 flex w-full items-center justify-between gap-4 pr-4">
              <ExpandableButton text={translate("add_new_row")} onClick={handleAddNewRowClick} />

              <div className="flex items-center gap-4">
                <Button
                  onClick={handlePrevButtonClick}
                  text={translate("prev")}
                  variant={"button--secondary-optional"}
                  additionalStyles={"xl:w-[10.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[8.313rem]"}
                  icon={<ControlIcon type={ControlIconTypes.perv} />}
                />
                <Button
                  onClick={handleNextButtonClick}
                  text={translate("next")}
                  variant={"button--secondary-optional-with-border"}
                  additionalStyles={"xl:w-[10.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[8.313rem] float-right"}
                  icon={<ControlIcon type={ControlIconTypes.next} />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

ActivityAnalysisOutsideShifts.displayName = "ActivityAnalysisOutsideShifts";

export default ActivityAnalysisOutsideShifts;
