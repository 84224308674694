import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";

import { AxisLabelObject } from "../../../../../interfaces/highcharts/AxisLabelObject";
import { ProductionVsPlanService } from "../../../../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useMediaQuery } from "../../../../../hooks";
import { SCREEN_SIZES } from "../../../../../utils/constants/mediaQueries";
import { getChartFontSize } from "../../../../../utils/highcharts/common";
import { ProductionVsPlanChartProps } from "../../../../../interfaces/props";
import LoadingWithGrey from "../../../../../components/loading/LoadingWithGrey";
import { CareUnitTypeEnum } from "../../../../../utils/constants/careUnitTypes";
import { ProdVsPlanData, ProdVsPlanDataList } from "../../../../../interfaces/analytics/prodVsPlan/prodVsPlanData";
import {
  BookedData,
  ProductionOutcomeData,
  ProductionPlanPhasedData,
  RecoveryPlanData,
} from "../../../../../interfaces/analytics/prodVsPlan/chartData";

const ProductionVsPlanDashboardChartV2 = ({
  year,
  filterCareUnits,
  chartTitle,
  isPlacedOnDashboard,
  outPatientMultiSelectFilters,
  onChartDataLoading,
}: ProductionVsPlanChartProps) => {
  const { t: translate } = useTranslation();
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const [chartLoaded, setChartLoaded] = useState<boolean>(false);
  const [recoveryPlan, setRecoveryPlan] = useState<RecoveryPlanData[]>();
  const [areaChartData, setAreaChartData] = useState<ProductionPlanPhasedData[]>();
  const [columnChartData, setColumnChartData] = useState<ProductionOutcomeData>();
  const [bookedData, setBookedData] = useState<BookedData[]>();

  const isLargeScreen = useMediaQuery(SCREEN_SIZES.lg);

  const chartFontSize = getChartFontSize(isPlacedOnDashboard ? true : false, isLargeScreen);

  const generateLegendIcon = (name: string, isSeriesVisible: boolean) => {
    let imagePath = "";

    if (name === translate("plan")) {
      imagePath = "/images/circle.svg";
    } else if (name === translate("outcome")) {
      imagePath = "/images/circle-svg-colored.svg";
    } else if (name === translate("recovery_plan")) {
      imagePath = "/images/circle-svg-yellow.svg";
    } else if (name === translate("booked")) {
      imagePath = "/images/circle-svg-blue.svg";
    }

    const imageDimension =
      name === translate("outcome") ? "width = '48px' height = '40px'>" : "width = '16px' height = '16px'>";

    const img: string =
      `<div class="highcharts-custom-legend ${isPlacedOnDashboard && "dashboard"} ${
        !isSeriesVisible && "highcharts-custom-selected"
      }"> <img src =` +
      imagePath +
      " " +
      imageDimension +
      "  " +
      name +
      "</div>";

    return img;
  };

  const areaChartOptions = {
    chart: {
      backgroundColor: "white",
      className: "product_vs_plan_chart",
      style: {
        fontFamily: "Open Sans",
        textOutline: 0,
        fontSize: chartFontSize,
      },
    },

    title: {
      text: chartTitle,
      style: {
        fontSize: "20px",
      },
    },

    xAxis: {
      allowDecimals: false,
      max: 52,
      min: 1,
      startOnTick: false,
      tickInterval: 2,
      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },
    yAxis: {
      title: {
        text:
          filterCareUnits.careUnitType === CareUnitTypeEnum.OUTPATIENT
            ? translate("no_of_visits")
            : translate("no_of_care_episodes"),
        style: {
          fontSize: chartFontSize,
        },
      },

      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },

    legend: {
      useHTML: true,
      verticalAlign: "top",
      symbolWidth: 0,
      margin: 30,
      itemStyle: {
        fontSize: chartFontSize,
      },

      style: {
        fontSize: chartFontSize,
      },

      labelFormatter: function (this: AxisLabelObject): string | undefined {
        const name = this.name;

        const chart = this.chart;

        let isPlanSeriesVisible = false;
        let isOutcomeSeriesVisible = false;
        let isRecoveryPlanSeriesVisible = false;
        let isBookedSeriesVisible = false;

        chart.series.forEach((s: Highcharts.Series) => {
          if (s.name === translate("plan") && s.visible) {
            isPlanSeriesVisible = true;
          } else if (s.name === translate("outcome") && s.visible) {
            isOutcomeSeriesVisible = true;
          } else if (s.name === translate("recovery_plan") && s.visible) {
            isRecoveryPlanSeriesVisible = true;
          } else if (
            s.name === translate("booked") &&
            s.visible &&
            filterCareUnits.careUnitType === CareUnitTypeEnum.OUTPATIENT
          ) {
            isBookedSeriesVisible = true;
          }
        });

        if (name === translate("plan")) {
          return generateLegendIcon(name, isPlanSeriesVisible);
        } else if (name === translate("outcome")) {
          return generateLegendIcon(name, isOutcomeSeriesVisible);
        } else if (name === translate("recovery_plan")) {
          return generateLegendIcon(name, isRecoveryPlanSeriesVisible);
        } else if (name === translate("booked")) {
          return generateLegendIcon(name, isBookedSeriesVisible);
        }
      },
    },

    tooltip: {
      shared: false,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        let tooltipInfo = "";
        const point = this.point as Highcharts.Point;
        const series = this.series as Highcharts.Series;
        const otherSeriesData = series.chart.series
          .filter((s) => s.name !== series.name)
          .map((s) => s.data.filter((d) => d.x === point.x));

        if (this.series.name === translate("outcome") && otherSeriesData[0][0] !== undefined) {
          tooltipInfo = `${translate("week")} ${point.x} <br /> ${translate("plan")} ${
            otherSeriesData[0][0].y
          } <br /> ${translate("outcome")} ${point.y}`;
        } else if (this.series.name === translate("recovery_plan") && otherSeriesData[0][0] !== undefined) {
          tooltipInfo = `
            ${translate("week")} ${point.x} <br /> 
            ${translate("plan")} ${otherSeriesData[0][0].y} <br /> 
            ${translate("booked")} : ${otherSeriesData[2][0].y} <br />
            ${translate("recovery_plan")} ${point.y}
          `;
        }
        return tooltipInfo;
      },
    },

    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      series: {
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        pointWidth: 6,
      },
      column: {
        borderWidth: 0,
      },
    },

    series: [
      {
        type: "area",
        name: translate("plan"),
        data: areaChartData?.map((ad) => {
          return { x: Number(ad.id), y: ad.productionPlan };
        }),
        color: "#BFBFBF",
        enableMouseTracking: false,
      },
      {
        type: "column",
        name: translate("outcome"),
        data: columnChartData?.outcomeData.map((outcomeData, index) => {
          return {
            x: Number(outcomeData.id),
            y: outcomeData.outcome,
            color: columnChartData.isWithinLimit[index] ? "#4A6541" : "#BE2A23",
          };
        }),
        color: "#4A6541",
      },
      {
        type: "column",
        name: translate("booked"),
        data: bookedData?.map((data) => {
          return {
            x: Number(data.id),
            y: data.bookedValue,
          };
        }),
        color: "#2F4F74",
        enableMouseTracking: false,
      },
      {
        type: "line",
        name: translate("recovery_plan"),
        data: recoveryPlan?.map((rp) => {
          return {
            y: rp.recoveryPlan,
            x: Number(rp.id),
          };
        }),
        color: "#E5A32B",
        marker: {
          enabled: false,
        },
      },
    ],
  };

  const readyToFetch = (): boolean => {
    if (
      filterCareUnits &&
      filterCareUnits.units.length > 0 &&
      filterCareUnits.units[0] !== 0 &&
      year > 0 &&
      scenario > 0 &&
      filterCareUnits.careUnitType
    ) {
      if (filterCareUnits.careUnitType === CareUnitTypeEnum.INPATIENT || isPlacedOnDashboard) {
        return true;
      } else {
        if (outPatientMultiSelectFilters) {
          return (
            outPatientMultiSelectFilters.professionIds.length > 0 &&
            outPatientMultiSelectFilters.contactTypeIds.length > 0 &&
            outPatientMultiSelectFilters.typesOfVisit.length > 0 &&
            outPatientMultiSelectFilters.typesOfEmergency.length > 0 &&
            outPatientMultiSelectFilters.contactReasonGroupingIds.length > 0 &&
            outPatientMultiSelectFilters.careTypeIds.length > 0 &&
            outPatientMultiSelectFilters.medicalSpecialtyIds.length > 0
          );
        }
      }
    }

    return false;
  };

  useEffect(() => {
    if (readyToFetch()) {
      fetchProdVsPlanData(filterCareUnits.careUnitType, filterCareUnits.units, year, scenario);
    }
  }, [scenario, filterCareUnits]);

  const fetchProdVsPlanData = async (
    productionPlanType: number,
    careUnitsList: number[],
    year: number,
    scenarioId: number
  ) => {
    const timePeriodType = 1;

    onChartDataLoading && onChartDataLoading(true);
    setChartLoaded(false);

    await ProductionVsPlanService.getProdVsPlanData(
      careUnitsList,
      year,
      scenarioId,
      productionPlanType,
      timePeriodType,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.professionIds &&
          outPatientMultiSelectFilters.professionIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.professionIds
        : [],
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.contactTypeIds &&
          outPatientMultiSelectFilters.contactTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.contactTypeIds
        : [],
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.typesOfVisit &&
          outPatientMultiSelectFilters.typesOfVisit.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.typesOfVisit
        : [],
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.typesOfEmergency &&
          outPatientMultiSelectFilters.typesOfEmergency.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.typesOfEmergency
        : [],
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.contactReasonGroupingIds &&
          outPatientMultiSelectFilters.contactReasonGroupingIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.contactReasonGroupingIds
        : [],
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.careTypeIds &&
          outPatientMultiSelectFilters.careTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.careTypeIds
        : [],
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.medicalSpecialtyIds &&
          outPatientMultiSelectFilters.medicalSpecialtyIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.medicalSpecialtyIds
        : []
    )
      .then((response: AxiosResponse<ProdVsPlanData>) => {
        const mapRecoveryPlan = (rp: ProdVsPlanDataList) => ({
          recoveryPlan: rp.recoveryPlan !== null ? Math.round(parseFloat(rp.recoveryPlan.toString())) : null,
          id: rp.timePeriod,
        });

        const mapProductionPlan = (rp: ProdVsPlanDataList) => ({
          productionPlan: rp.productionPlan,
          id: rp.timePeriod,
        });

        const mapProductionOutcome = (rp: ProdVsPlanDataList) => ({
          outcome: rp.production,
          id: rp.timePeriod,
        });

        const mapBookedData = (rp: ProdVsPlanDataList) => ({
          bookedValue: rp.booked,
          id: rp.timePeriod,
        });

        const mapIsWithinLimit = (rp: ProdVsPlanDataList) => rp.production >= rp.productionPlan;

        const processResponseData = (data: ProdVsPlanDataList[]) => {
          const recoveryPlan = data.map(mapRecoveryPlan);
          setRecoveryPlan(recoveryPlan);

          const productionPlanData = data.map(mapProductionPlan);
          setAreaChartData(productionPlanData);

          const bookedData = data.map(mapBookedData);
          setBookedData(bookedData);

          const outcome: ProductionOutcomeData = {
            outcomeData: data.map(mapProductionOutcome),
            isWithinLimit: data.map(mapIsWithinLimit),
          };

          setColumnChartData(outcome);
        };

        processResponseData(response.data.getActualVsPlans);
      })
      .catch((error) => {
        setRecoveryPlan([]);
        console.error(error);
      });

    setChartLoaded(true);
    onChartDataLoading && onChartDataLoading(false);
  };

  return (
    <div className="!h-full max-h-full">
      {chartLoaded ? (
        <HighchartsReact
          containerProps={{ style: { height: "100%" } }}
          highcharts={Highcharts}
          options={areaChartOptions}
        />
      ) : (
        <div className="flex h-full items-center justify-center">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      )}
    </div>
  );
};

export default ProductionVsPlanDashboardChartV2;
