import { useTranslation } from "react-i18next";

import { nullStringValidator } from "../../../../../utils/handsontable-configuration/validators/validators";
import { HandsOnTableColumn } from "../../../../../interfaces/handsOnTable/handsOnTableColumn";
import WorkCompetence from "../../../../../interfaces/competence/WorkCompetence";
import { PlanningObjectSchema } from "../../../../../interfaces/planning-object/planningObjectSchema";

const useActivityAnalysisProductionColumns = (
  culture: string,
  workCompetences: WorkCompetence[],
  filterGroups: PlanningObjectSchema[]
) => {
  const { t: translate } = useTranslation();

  const cols: HandsOnTableColumn[] = [
    {
      meta: {
        data: "planningObjectId",
        type: "keyValueDropdown",
        source: filterGroups?.map((group) => {
          return {
            _id: group.id,
            label: translate(group.planningObjectName),
          };
        }) as any,
        editorParams: { width: 200 },
      },
      headerTitle: "patient_flow",
      width: 200,
    },
    {
      meta: {
        data: "workCompetenceId",
        type: "keyValueDropdown",
        source: workCompetences.map((competence) => {
          return {
            _id: competence.id as number,
            label: competence.shortName as string,
          };
        }) as any,
        editorParams: { width: 200 },
      },
      headerTitle: "competence_group",
      width: 200,
    },
    {
      meta: {
        data: "timePerContact",
        type: "numeric",
        nullable: false,
        validator: nullStringValidator,
      },
      headerTitle: "time-per-contact",
      width: 130,
    },
    {
      meta: {
        data: "productionPlan",
        type: "numeric",
        readOnly: true,
      },
      headerTitle: "production-plan",
      width: 130,
    },
    {
      meta: {
        data: "timeSpent",
        type: "numeric",
        numericFormat: {
          culture: culture,
          pattern: {
            mantissa: 1,
          },
        },
        readOnly: true,
      },
      headerTitle: "time-spent",
      width: 100,
    },
    {
      meta: {
        data: "weeklyWorkingHours",
        type: "numeric",
        numericFormat: {
          culture: culture,
          pattern: {
            mantissa: 1,
          },
        },
        className: "htRight",
      },
      headerTitle: "weekly-working-hours",
      width: 150,
    },
    {
      meta: {
        data: "fteProductionPlan",
        type: "numeric",
        numericFormat: {
          culture: culture,
          pattern: {
            mantissa: 1,
          },
        },
        readOnly: true,
      },
      headerTitle: "fte-production-plan",
      width: 100,
    },
  ];

  return cols;
};

export default useActivityAnalysisProductionColumns;
