import React from "react";
import {
  ForgotPassword,
  ForgotPasswordConfirmation,
  Login,
  ResetPassword,
  CompleteProfile,
} from "../features/authentication/pages";
import { Navigate } from "react-router-dom";

const AuthRoutes = [
  {
    path: "",
    element: <Navigate to="login" replace />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "forgot-password/email-sent/:email",
    element: <ForgotPasswordConfirmation />,
  },
  {
    path: "reset-password",
    element: <ResetPassword />,
  },
  {
    path: "complete-profile",
    element: <CompleteProfile />,
  },
];

export default AuthRoutes;
