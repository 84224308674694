import { useTranslation } from "react-i18next";
import { HandsOnTableColumn } from "../../../../interfaces/handsOnTable/handsOnTableColumn";
import { nullStringValidator } from "../../../../utils/handsontable-configuration/validators/validators";
import { PlanningObjectSchema } from "../../../../interfaces/planning-object/planningObjectSchema";

interface UseResourceColumnsProps {
  resourceTypes: Array<{ id: number; name: string }>;
  planningObjects: PlanningObjectSchema[];
}

const useResourceColumns = ({ resourceTypes, planningObjects }: UseResourceColumnsProps) => {
  console.log("resourceTypes", resourceTypes);

  const cols: HandsOnTableColumn[] = [
    {
      meta: {
        data: "name",
        type: "text",
        validator: nullStringValidator,
      },
      headerTitle: "Name",
      width: 200,
    },
    {
      meta: {
        data: "resourceTypeId",
        type: "dropdown",
        source: resourceTypes.map((t) => t.name),
      },
      headerTitle: "Type",
      width: 150,
    },
    {
      meta: {
        data: "fromTime",
        type: "time",
        timeFormat: "HH:mm",
        correctFormat: true,
      },
      headerTitle: "From",
      width: 100,
    },
    {
      meta: {
        data: "toTime",
        type: "time",
        timeFormat: "HH:mm",
        correctFormat: true,
      },
      headerTitle: "To",
      width: 100,
    },
    {
      meta: {
        data: "occupancy",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 2,
          },
        },
      },
      headerTitle: "Occupancy",
      width: 100,
    },
    {
      meta: {
        data: "planningObjectId",
        type: "multiSelect",
        selectOptions: planningObjects.map((obj) => obj.planningObjectName),
      },
      headerTitle: "Planning Object",
      width: 200,
    },
    {
      meta: {
        data: "hours",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 2,
          },
        },
        readOnly: true,
      },
      headerTitle: "Hours",
      width: 100,
    },
  ];

  return cols;
};

export default useResourceColumns;
