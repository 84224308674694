import { useRef } from "react";
import { DataTableProps } from "../../../../interfaces/props/data-table-props";
import { HandsOnTableColumn } from "../../../../interfaces/handsOnTable/handsOnTableColumn";
import { useTranslation } from "react-i18next";
import { HotTableClass as HotTable } from "@handsontable/react";
import { Resource } from "../../../../interfaces/resources/resource";

const useResourceTableConfig = (cols: HandsOnTableColumn[], data: Resource[]) => {
  const { t: translate } = useTranslation();

  const dataTable = useRef<HotTable>(null);

  const props: DataTableProps = {
    afterGetColHeader: (col, TH) => {
      TH.classList.add("dark-blue-column");
      TH.classList.add("htMiddle");
    },
    colHeaders: cols.map((c) => translate(c.headerTitle).replace("\n", "<br />")),
    columns: cols.map((c) => c.meta),
    columnSorting: true,
    colWidths: cols.map((c) => c.width),
    contextMenu: true,
    data: data,
    // dataSchema: defaultData,
    dropdownMenu: ["filter_by_condition", "filter_action_bar", "filter_by_value"],
    // formulas: {
    //   engine: HyperFormula,
    // },
    height: "65vh",
    // language: culture,
    licenseKey: "non-commercial-and-evaluation",
    manualColumnResize: true,
    // modifyColWidth: modifyColWidth,
    manualRowMove: false,
    numericFormat: {
      //   culture: culture,
      pattern: {},
    },
    ref: dataTable,
    rowHeaders: true,
    rowHeaderWidth: 30,
    rowHeights: 35,
    wordWrap: false,
  };

  return { props, table: dataTable };
};

export default useResourceTableConfig;
