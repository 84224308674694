import { useEffect, useState } from "react";
import OutpatientPlanService from "../../../../services/outpatientPlanService";
import { OutpatientDimensions } from "../../../../interfaces/production-plan";

const useGetOutpatientDimensions = (careUnitId: number | null, careUnitTypeId: number | null) => {
  const [outpatientDimensions, setOutpatientDimensions] = useState<OutpatientDimensions>({
    professions: [],
    contactTypes: [],
    contactReasonGroupings: [],
    careTypes: [],
    medicalSpecialties: [],
  });

  const fetchData = async () => {
    if (careUnitId !== null) {
      await OutpatientPlanService.getDimensionsForFilters([careUnitId])
        .then((res: OutpatientDimensions) => {
          setOutpatientDimensions(res);
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [careUnitId, careUnitTypeId]);

  return {
    data: outpatientDimensions,
    fetch: fetchData,
  };
};

export default useGetOutpatientDimensions;
