import { useEffect, useState } from "react";
import { ActivityAnalysisProductionSchema } from "../../../../../interfaces/analytics/activityAnalysisProductionSchema";
import { ActivityAnalysisService } from "../../../../../services/activityAnalysisService";
import { useTranslation } from "react-i18next";
import { ActivityAnalysisIsEditedRefType } from "../../../../../interfaces/analytics/activity-analysis-is-edited-ref-type";
import { ReturnGetActivityProductionData } from "../../../../../interfaces/analytics/returnGetActivityProductionData";

const useGetActivityProductionData = (
  careUnitId: number,
  scenarioId: number,
  year: number,
  isEditedRef: React.MutableRefObject<ActivityAnalysisIsEditedRefType>
): ReturnGetActivityProductionData<ActivityAnalysisProductionSchema[]> => {
  const { t: translate } = useTranslation();
  const [data, setData] = useState<ActivityAnalysisProductionSchema[]>([]);

  const fetchTableData = async () => {
    await ActivityAnalysisService.getActivityAnalysisProduction(careUnitId, scenarioId, year)
      .then((response) => {
        const data: ActivityAnalysisProductionSchema[] = response.data.map((production, index) => {
          const row = index + 1;
          const productionRow: ActivityAnalysisProductionSchema = {
            workCompetenceId: production.workCompetenceId,

            fteProductionPlan: `=IF(F${row}=0,0,E${row}/F${row})`,
            timeSpent: `=((C${row}/60)*D${row})/52`,
            id: production.id,
            productionPlan: production.productionPlan,
            timePerContact: production.timePerContact,
            weeklyWorkingHours: production.weeklyWorkingHours,
            planningObjectId: production.planningObjectId,
            isProductionPlanReloaded: production.isProductionPlanReloaded,
            planningObjectName:
              production.planningObjectName === "other_planning_object"
                ? translate(production.planningObjectName || "")
                : production.planningObjectName,
          };
          return productionRow;
        });

        const noOfRows = data.length;

        if (noOfRows > 0) {
          data.push({
            id: "total_row",
            planningObjectName: translate("total"),
            workCompetenceId: 0,
            timePerContact: "",
            fteProductionPlan: `=SUM(G1:G${noOfRows})`,
            productionPlan: "",
            timeSpent: `=SUM(E1:E${noOfRows})`,
            weeklyWorkingHours: "",
            planningObjectId: translate("total"),
            isProductionPlanReloaded: false,
          } as ActivityAnalysisProductionSchema);
        }

        setData([...data]);

        isEditedRef.current = {
          ...isEditedRef.current,
          production: false,
        };
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (careUnitId && scenarioId) {
      fetchTableData();
    }
  }, [careUnitId, scenarioId, year]);

  return { data: data, fetch: fetchTableData, setData };
};

export default useGetActivityProductionData;
