import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import { Trans, useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";
import fill from "lodash/fill";
import more from "highcharts/highcharts-more";
import draggable from "highcharts/modules/draggable-points";
import { useSelector } from "react-redux";

import { GridLayout } from "../../../layouts";
import TabbarTeritary from "../../../components/tab-bar/tabbar-teritary/TabbarTeritary";
import { Button, HideToggle, Input, Select } from "../../../components";
import { ISelectOption, MultiSelectOption } from "../../../interfaces";
import InpatientPlanService from "../../../services/inpatientPlanService";
import { RootState } from "../../../redux/store";
import { InpatientWeeklyPhasingResponse } from "../../../interfaces/production";
import {
  InpatientPlanChartPutRequest,
  InpatientPlanChartRequest,
} from "../../../interfaces/productivity/inpatient-plan-data-props";
import ProductionInpatientService from "../../../services/productionInpatientService";
import "./InpatientPlan.css";
import { CURRENT_PLAN, FLAT_100, INPATIENT_PHASING_CHART_DATA } from "../../../utils/constants/inpatient-plan";
import { WeekdayPhasing } from "../../../interfaces/production-plan";
import NotificationFloat from "../../../components/NotificationFloat/NotificationFloat";
import InpatientPlanResult from "./Results/inpatientPlanResult";
import ControlIcon from "../../../components/control-icon/control-icon";
import { ControlIconTypes } from "../../../components/control-icon/icon-types";
import WeekdayPhasingSet from "./WeekdayPhasing/weekdayPhasing";
import PhasingChart from "./PhasingChart/phasingChart";
import InpatientResultPane from "./Results/inpatientResultPane";
import CareEpisodePlan from "./CareEpisodesPlan/careEpisodesPlan";
import LosPlan from "./LosPlan/losPlan";
import NotificationBox from "../../../components/NotificationBox/NotificationBox";
import { AxiosResponse } from "axios";
import { InpatientChartParametersResponse } from "../../../interfaces/responses";
import { ProductionPlanChartParametersData } from "../../../interfaces/production-plan/inpatient-plan";
import { PhasingTemplate } from "../../../interfaces/production-plan/phasing-template";
import { Link } from "react-router-dom";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import PhasingService from "../../../services/phasingService";
import { useNumberFormat } from "../../../hooks/use-number-format";

if (typeof Highcharts === "object") {
  more(Highcharts);
  draggable(Highcharts);
}

const InPatientPlan = () => {
  const { t: translate, i18n } = useTranslation();
  const { localize } = useNumberFormat();

  const isLosFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.LOSReferenceKpi);

  const [type_of_templates, SetTypeOfTemplates] = useState<ISelectOption[]>([]);

  const tabs = [translate("care_episodes"), translate("los"), translate("Phasing"), translate("Carebeds")];

  const nextIcon = <ControlIcon type={ControlIconTypes.next} />;
  const prevIcon = <ControlIcon type={ControlIconTypes.perv} />;

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const [historicData, setHistoricData] = useState<number[]>([]);
  const [chartData, setChartData] = useState<number[]>([]);
  const [losHistoricAverage, setlosHistoricAverage] = useState<number>(0);
  const [isDefaultLosPlan, setIsDefaultLosPlan] = useState<boolean>(false);
  const [minDate, setMinDate] = useState<string>("");
  const [maxDate, setMaxDate] = useState<string>("");
  const [nextAvailableTabIndex, setNextAvailableTabIndex] = useState<number>(0);
  const [isInpatientPhasingSaving, setIsInpatientPhasingSaving] = useState<boolean>(false);
  const [isInpatientChartParametersSaving, setIsInpatientChartParametersSaving] = useState<boolean>(false);
  const [weekDayPhasing, setWeekdayPhasing] = useState<WeekdayPhasing>({
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
  });
  const [selectedTemplate, setSelectedTemplates] = useState<ISelectOption>(type_of_templates[0]);

  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [isSaveEditNotificationOpen, setIsSaveEditNotificationOpen] = useState<boolean>(false);

  const [productionRequestBeforeEdit, setProductionRequestBeforeEdit] = useState<ProductionPlanChartParametersData>({
    productionPlan: 0,
    losPlan: 0,
    occupancyRatePlan: 0,
    id: 0,
  });

  const [weekDayPhasingBeforeEdit, setWeekdayPhasingBeforeEdit] = useState<WeekdayPhasing>({
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
  });

  const [selectedWeekdayIds, setSelectedWeekdayIds] = useState<number[]>([]);

  const selectedTemplateBeforeChangeTemplate = type_of_templates[0];

  const [phasingChartDataBeforeEdit, setPhasingChartDataBeforeEdit] = useState<number[]>([]);

  const [productionRequest, setProductionRequest] = useState<ProductionPlanChartParametersData>({
    productionPlan: 0,
    losPlan: 0,
    occupancyRatePlan: 90,
    id: 0,
  });
  const [productionRequestResults, setProductionRequestResults] = useState<ProductionPlanChartParametersData>({
    productionPlan: 0,
    losPlan: 0,
    occupancyRatePlan: 0,
    id: 0,
  });

  const [isDefaultValue, setIsDefaultValue] = useState(false);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const isCareUnitReadOnly = useSelector((state: RootState) => state.permission.careUnitReadOnly);

  const fetchCurrentPlanWeekdayPhasingData = (careUnitId: number, year: number, scenarioId: number) => {
    InpatientPlanService.getWeekdayPhasing(careUnitId, year, scenarioId).then(
      (data: InpatientWeeklyPhasingResponse) => {
        setWeekdayPhasing({
          monday: data[0] >= 0 ? data[0] : 100,
          tuesday: data[0] >= 0 ? data[1] : 100,
          wednesday: data[0] >= 0 ? data[2] : 100,
          thursday: data[0] >= 0 ? data[3] : 100,
          friday: data[0] >= 0 ? data[4] : 100,
          saturday: data[0] >= 0 ? data[5] : 100,
          sunday: data[0] >= 0 ? data[6] : 100,
        });

        setWeekdayPhasingBeforeEdit({
          monday: data[0] >= 0 ? data[0] : 100,
          tuesday: data[0] >= 0 ? data[1] : 100,
          wednesday: data[0] >= 0 ? data[2] : 100,
          thursday: data[0] >= 0 ? data[3] : 100,
          friday: data[0] >= 0 ? data[4] : 100,
          saturday: data[0] >= 0 ? data[5] : 100,
          sunday: data[0] >= 0 ? data[6] : 100,
        });
      }
    );
  };

  const fetchTemplateWeekdayPhasingData = (templateId: number) => {
    PhasingService.getWeekdayPhasingWithTemplates(templateId).then((data: InpatientWeeklyPhasingResponse) => {
      setWeekdayPhasing({
        monday: data[0] >= 0 ? data[0] : 100,
        tuesday: data[1] >= 0 ? data[1] : 100,
        wednesday: data[2] >= 0 ? data[2] : 100,
        thursday: data[3] >= 0 ? data[3] : 100,
        friday: data[4] >= 0 ? data[4] : 100,
        saturday: data[5] >= 0 ? data[5] : 100,
        sunday: data[6] >= 0 ? data[6] : 100,
      });

      setWeekdayPhasingBeforeEdit({
        monday: data[0] >= 0 ? data[0] : 100,
        tuesday: data[1] >= 0 ? data[1] : 100,
        wednesday: data[2] >= 0 ? data[2] : 100,
        thursday: data[3] >= 0 ? data[3] : 100,
        friday: data[4] >= 0 ? data[4] : 100,
        saturday: data[5] >= 0 ? data[5] : 100,
        sunday: data[6] >= 0 ? data[6] : 100,
      });
    });
  };

  const fetchCurrentPlanWeeklyPhasingData = (careUnitId: number, year: number, scenarioId: number) => {
    InpatientPlanService.getWeeklyPhasing(careUnitId, year, scenarioId).then((res: InpatientWeeklyPhasingResponse) => {
      if (res.length === 0) {
        setSelectedTemplates(type_of_templates[1]);
        convertToFlatData();
      } else {
        setSelectedTemplates(type_of_templates[0]);
        setHistoricData([...res]);
        setChartData(res);
        setPhasingChartDataBeforeEdit([...res]);
        localStorage.setItem(INPATIENT_PHASING_CHART_DATA, JSON.stringify([...res]));
      }
    });
  };

  const fetchTemplateWeeklyPhasing = (templateId: number) => {
    PhasingService.getWeeklyPhasingWithTemplates(templateId).then((res: number[]) => {
      setChartData(res);
      setPhasingChartDataBeforeEdit([...res]);
      localStorage.setItem(INPATIENT_PHASING_CHART_DATA, JSON.stringify([...res]));
    });
  };

  useEffect(() => {
    /* Effect to get weekly and weekday phasing data for phasing chart */

    if (careUnitId && selectedTabIndex !== 3) {
      fetchCurrentPlanWeeklyPhasingData(careUnitId, year, scenario);
      fetchCurrentPlanWeekdayPhasingData(careUnitId, year, scenario);
    }
  }, [careUnitId, year, selectedTabIndex, scenario]);

  useEffect(() => {
    const typeOfTemplates: ISelectOption[] = [];

    typeOfTemplates.push({
      label: translate("current_plan"),
      value: CURRENT_PLAN,
    });

    PhasingService.getPhasingTemplates()
      .then((res) => {
        const data = res.data.map((template: PhasingTemplate) => {
          return {
            label: template.name,
            value: template.id,
          };
        });

        SetTypeOfTemplates([...typeOfTemplates, ...data]);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const updateWeeklyPhasing = async () => {
    if (!isCareUnitReadOnly) {
      setIsInpatientPhasingSaving(true);
      const weekdayPhasingKeys: (keyof WeekdayPhasing)[] = Object.keys(weekDayPhasing) as (keyof WeekdayPhasing)[];

      const weekDayTotals: number[] = weekdayPhasingKeys.map((key) => Number(weekDayPhasing[key]));
      const data = JSON.parse(localStorage.getItem(INPATIENT_PHASING_CHART_DATA) ?? "");
      if (careUnitId) {
        setChartData(() => data);
        setPhasingChartDataBeforeEdit(() => data);

        setWeekdayPhasingBeforeEdit(() => weekDayPhasing);

        await InpatientPlanService.updateWeeklyPhasing(careUnitId, year, data, weekDayTotals, scenario)
          .then(() => {
            setIsInpatientPhasingSaving(false);
          })
          .catch(() => {
            setIsInpatientPhasingSaving(() => false);
          });
      }
    }
  };

  const handlePhasingChartUnmount = () => {
    resetSelectedTemplate();
    removeChartDataFromLocalStorage();
  };

  const resetSelectedTemplate = () => {
    setSelectedTemplates(type_of_templates[0]);
  };

  const removeChartDataFromLocalStorage = () => {
    localStorage.removeItem(INPATIENT_PHASING_CHART_DATA);
  };

  useEffect(() => {
    if (selectedTabIndex === 0 || selectedTabIndex === 1 || selectedTabIndex === 3) {
      ProductionInpatientService.getChartParameters(careUnitId, year, scenario)
        .then((res: AxiosResponse<InpatientChartParametersResponse>) => {
          const inpatientPlan = res.data;
          if (selectedTabIndex === 0 || selectedTabIndex === 1) {
            if (inpatientPlan.id === 0) {
              setIsDefaultValue(true);
              setProductionRequest({
                productionPlan: 0,
                losPlan: 0,
                occupancyRatePlan: 90,
                id: 0,
              });
            } else {
              const currentPlan: ProductionPlanChartParametersData = {
                productionPlan: inpatientPlan.productionPlan,
                losPlan: inpatientPlan.losPlan,
                occupancyRatePlan: Math.round(inpatientPlan.occupancyRatePlan * 100),
                id: inpatientPlan.id,
              };

              setProductionRequest(currentPlan);
              setProductionRequestBeforeEdit(currentPlan);
              setIsDefaultValue(false);
            }
          } else if (selectedTabIndex === 3) {
            if (res === null || inpatientPlan === null) {
              setProductionRequestResults({
                productionPlan: 0,
                losPlan: 0,
                occupancyRatePlan: 0,
                id: 0,
              });
            } else {
              setProductionRequestResults({
                productionPlan: inpatientPlan.productionPlan,
                losPlan: inpatientPlan.losPlan,
                occupancyRatePlan: inpatientPlan.occupancyRatePlan,
                id: inpatientPlan.id,
              });
            }
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [careUnitId, selectedTabIndex, year, scenario]);

  useEffect(() => {
    if (productionRequest.occupancyRatePlan === 0) {
      setIsSaveButtonDisabled(true);
    } else {
      setIsSaveButtonDisabled(false);
    }
  }, [productionRequest.occupancyRatePlan]);

  useEffect(() => {
    if (i18n.language === "sv") {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ",",
        },
      });
    } else {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ".",
        },
      });
    }
  }, [i18n]);

  const saveInpatientPlanData = async () => {
    if (careUnitId) {
      const chartData: InpatientPlanChartRequest = {
        id: productionRequest.id,
        careUnit: careUnitId,
        year: year,
        scenarioId: scenario,
        losPlan: productionRequest.losPlan,
        occupancyRatePlan: productionRequest.occupancyRatePlan / 100,
        productionPlan: productionRequest.productionPlan,
      };

      await ProductionInpatientService.getChartParameters(careUnitId, year, scenario).then((res) => {
        if (!(res === null) && !(res.data === null)) {
          chartData.id = res.data.id;
        }
      });

      const data: InpatientPlanChartPutRequest = {
        year: year,
        scenarioId: scenario,
        updateInpatintPlanDto: chartData,
      };

      await ProductionInpatientService.updateChartParameters(data)
        .then(() => {
          isDefaultLosPlan && setIsDefaultLosPlan(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleTabbarClick = (nextIndex: number) => {
    if (selectedTabIndex === 0 || selectedTabIndex === 1) {
      displayInpatientPlanSaveNotification(nextIndex);
    } else if (selectedTabIndex === 2) {
      displayInpatientPhasingSaveNotification(nextIndex);
    } else {
      setSelectedTabIndex(() => nextIndex);
    }
  };

  const displayInpatientPlanSaveNotification = async (clickedIndex: number) => {
    if (isDefaultValue) {
      setNextAvailableTabIndex(() => clickedIndex);
      setIsSaveEditNotificationOpen(true);
      return;
    }

    if (
      isEdited &&
      selectedTabIndex === 0 &&
      (productionRequest.occupancyRatePlan !== productionRequestBeforeEdit.occupancyRatePlan ||
        productionRequest.productionPlan !== productionRequestBeforeEdit.productionPlan)
    ) {
      setNextAvailableTabIndex(() => clickedIndex);
      setIsSaveEditNotificationOpen(true);
    } else if (
      (isEdited && selectedTabIndex === 1 && productionRequest.losPlan !== productionRequestBeforeEdit.losPlan) ||
      isDefaultLosPlan
    ) {
      setNextAvailableTabIndex(() => clickedIndex);
      setIsSaveEditNotificationOpen(true);
    } else {
      setSelectedTabIndex(() => clickedIndex);
    }

    // setSelectedTabIndex(() => clickedIndex);
  };

  const displayInpatientPhasingSaveNotification = async (nextIndex: number) => {
    let hasEdited = false;
    const hasPhasingTemplateChanged = selectedTemplate.value !== selectedTemplateBeforeChangeTemplate.value;

    const hasWeekdayPhasingEdited = JSON.stringify(weekDayPhasing) !== JSON.stringify(weekDayPhasingBeforeEdit);

    const chartData = JSON.parse(localStorage.getItem(INPATIENT_PHASING_CHART_DATA) ?? "");

    chartData.forEach((data: string, i: number) => {
      if (Number(data) !== phasingChartDataBeforeEdit[i]) hasEdited = true;
    });

    if (hasEdited || hasWeekdayPhasingEdited || hasPhasingTemplateChanged) {
      setChartData(chartData);
      setIsSaveEditNotificationOpen(true);
      setNextAvailableTabIndex(() => nextIndex);
    } else {
      setSelectedTabIndex(() => nextIndex);
    }
  };

  const savePhasingChanges = async () => {
    await updateWeeklyPhasing();
  };

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);

  const handlePhasingTemplatesSelect = (selectedTemplate: ISelectOption) => {
    if (selectedTemplate.value === CURRENT_PLAN && careUnitId) {
      fetchCurrentPlanWeeklyPhasingData(careUnitId, year, scenario);
    }

    if (selectedTemplate.value === FLAT_100) {
      convertToFlatData();
    } else {
      fetchTemplateWeeklyPhasing(Number(selectedTemplate.value));
      fetchTemplateWeekdayPhasingData(Number(selectedTemplate.value));
    }

    setSelectedTemplates(selectedTemplate);
  };

  const getHistoricLOSAverage = (average: number, minDate: string, maxDate: string) => {
    if (!isNaN(average)) {
      setlosHistoricAverage(average);
    } else {
      setlosHistoricAverage(0);
    }

    setMinDate(minDate);
    setMaxDate(maxDate);

    if (productionRequest.losPlan === 0 && !isNaN(average)) {
      setProductionRequest((prevState) => {
        return {
          ...prevState,
          losPlan: parseFloat(average.toFixed(1).toString()),
        };
      });

      setIsDefaultLosPlan(true);
    }
  };

  const setLastWeekCareEpisodeAverage = (average: number) => {
    if (productionRequest.productionPlan === 0 && !isNaN(average)) {
      setProductionRequest((prevState) => {
        return {
          ...prevState,
          productionPlan: parseInt(average.toFixed(0).toString()),
        };
      });

      setProductionRequestBeforeEdit((prevState) => {
        return {
          ...prevState,
          productionPlan: parseInt(average.toFixed(0).toString()),
        };
      });
    }
  };

  const convertToFlatData = () => {
    const flatData = fill(Array(52), 100);
    setChartData(flatData);
    setPhasingChartDataBeforeEdit(flatData);

    localStorage.setItem(INPATIENT_PHASING_CHART_DATA, JSON.stringify(flatData));
    setHistoricData(flatData);
    setWeekdayPhasing({
      monday: 100,
      tuesday: 100,
      wednesday: 100,
      thursday: 100,
      friday: 100,
      saturday: 100,
      sunday: 100,
    });

    setWeekdayPhasingBeforeEdit({
      monday: 100,
      tuesday: 100,
      wednesday: 100,
      thursday: 100,
      friday: 100,
      saturday: 100,
      sunday: 100,
    });
  };

  const handleWeekdayPhasingChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    setWeekdayPhasing((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleWeekdayPhasingLeftIconClick = (key: string) => {
    const value = Number(weekDayPhasing[key as keyof WeekdayPhasing]);

    if (!(value <= 0)) {
      setWeekdayPhasing((prevState) => {
        return {
          ...prevState,
          [key]: value - 10,
        };
      });
    }
  };

  const handleWeekdayPhasingRightIconClick = (key: string) => {
    const value = Number(weekDayPhasing[key as keyof WeekdayPhasing]);
    const numberToIncrement = value + 10;

    setWeekdayPhasing((prevState) => {
      return {
        ...prevState,
        [key]: numberToIncrement,
      };
    });
  };

  const handleProductionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEdited(true);
    const { name, value } = e.target;

    if (name === "productionPlan") {
      if (value === "") {
        setProductionRequest((prevState) => {
          return {
            ...prevState,
            [name]: parseInt("0"),
          };
        });
      } else {
        setProductionRequest((prevState) => {
          return {
            ...prevState,
            [name]: parseInt(value),
          };
        });
      }
    } else {
      setProductionRequest((prevState) => {
        if (value === "") {
          return {
            ...prevState,
            [name]: parseFloat("0"),
          };
        } else {
          if (name === "occupancyRatePlan") {
            if (0 < parseFloat(value) && parseFloat(value) <= 100) {
              return {
                ...prevState,
                [name]: parseFloat(value),
              };
            } else
              return {
                ...prevState,
              };
          } else {
            return {
              ...prevState,
              [name]: parseFloat(value),
            };
          }
        }
      });
    }
  };

  const totalCareEpisodesForResults = Number(productionRequestResults.productionPlan.toFixed(1));

  const occupancyRateForResults = Number((productionRequestResults.occupancyRatePlan * 100).toFixed(1));

  const averageLengthOfStayForResults = productionRequestResults.losPlan;

  const handleSaveButtonClick = async () => {
    if (selectedTabIndex === 0 || selectedTabIndex === 1) {
      setIsInpatientChartParametersSaving(true);
      await saveInpatientPlanData();

      setProductionRequestBeforeEdit(productionRequest);

      await handlePhasingDataSave();
      setIsInpatientChartParametersSaving(false);
    }

    if (selectedTabIndex === 2) {
      await handlePhasingDataSave();
    }
  };

  const handlePhasingDataSave = async () => {
    if (!isCareUnitReadOnly) {
      await savePhasingChanges();
    }

    if (selectedTemplate !== undefined) {
      if (selectedTemplate.value === FLAT_100) {
        setSelectedTemplates(type_of_templates[0]);
      }
    }
  };

  const handleNotificationBoxSaveButtonClick = async () => {
    if (selectedTabIndex === 0 || selectedTabIndex === 1) {
      setIsInpatientChartParametersSaving(true);
      await saveInpatientPlanData();
      setIsEdited(false);
      await handlePhasingDataSave();
      setIsSaveEditNotificationOpen(false);
      setIsInpatientChartParametersSaving(false);
      setSelectedTabIndex(nextAvailableTabIndex);
      setIsDefaultValue(false);
    } else if (selectedTabIndex === 2) {
      await handlePhasingDataSave();
      setSelectedTabIndex(nextAvailableTabIndex);
      setIsEdited(false);
      setIsSaveEditNotificationOpen(false);
    }
  };

  const handleNotificationBoxDiscardSaveButtonClick = () => {
    setIsSaveEditNotificationOpen(false);
    setSelectedTabIndex(nextAvailableTabIndex);
    setIsEdited(false);
    setIsDefaultValue(false);
    setProductionRequest(productionRequestBeforeEdit);
  };

  const [isNeededToGroupedByMedicalSpeciality, setIsNeededToGroupedByMedicalSpeciality] = useState<boolean>(false);

  const handleMedicalSpecialityToggle = () => {
    setIsNeededToGroupedByMedicalSpeciality((prevState) => !prevState);
  };

  const handleResultsWeekdaySelect = (values: MultiSelectOption[]) => {
    const newSelectedWeekdayIds = values.map((value) => Number(value.value));

    if (!isEqual(selectedWeekdayIds, newSelectedWeekdayIds)) {
      setSelectedWeekdayIds(newSelectedWeekdayIds);
    }
  };

  return (
    <GridLayout additionalStyles="mt-0 px-0">
      <div className="col-start-1 col-end-13">
        <div className="mt-0 flex items-center justify-between px-2">
          <TabbarTeritary tabs={tabs} onClick={handleTabbarClick} index={selectedTabIndex} />

          <div className="my-2">
            {!(selectedTabIndex === 3) && !isCareUnitReadOnly && (
              <Button
                disabled={isSaveButtonDisabled}
                text={translate("save_changes")}
                variant={"primary"}
                additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
                onClick={handleSaveButtonClick}
              />
            )}
          </div>
        </div>

        {isSaveEditNotificationOpen && (
          <NotificationBox
            content={translate("do_you_want_to_save_changes")}
            title={translate("save_changes")}
            key={12}
            leftButtonLabel={translate("yes")}
            rightButtonLabel={translate("no")}
            variant="primary"
            onLeftButtonClick={handleNotificationBoxSaveButtonClick}
            onRightButtonClick={handleNotificationBoxDiscardSaveButtonClick}
          />
        )}

        <div className="mt-0 w-full bg-[#F8F8F8] px-2 pt-4 lg:h-[79vh] xl:h-[80vh]">
          <div className="flex h-full w-full flex-row" id="wraper">
            <div className="h-full w-5/6">
              {selectedTabIndex === 0 && (
                <>
                  <div className="absolute right-8 z-10 lg:top-12 xl:top-16">
                    {isInpatientChartParametersSaving && (
                      <NotificationFloat varaint="loading" content={translate("saving_inpatient_parameters") + "..."} />
                    )}
                  </div>

                  <CareEpisodePlan
                    sendLastWeekCareEpisodeAverage={setLastWeekCareEpisodeAverage}
                    isNeededToGroupedByMedicalSpeciality={isNeededToGroupedByMedicalSpeciality}
                  />
                </>
              )}
              {selectedTabIndex === 1 && (
                <>
                  <div className="absolute right-8 z-10 lg:top-12 xl:top-16">
                    {isInpatientChartParametersSaving && (
                      <NotificationFloat varaint="loading" content={translate("saving_inpatient_parameters") + "..."} />
                    )}
                  </div>
                  <LosPlan sendLOSHistoricAverage={getHistoricLOSAverage} />
                </>
              )}
              {selectedTabIndex === 2 && (
                <>
                  <div className="absolute right-8 z-10 lg:top-12 xl:top-16">
                    {isInpatientPhasingSaving && (
                      <NotificationFloat varaint="loading" content={translate("saving_inpatient_data") + "..."} />
                    )}
                  </div>
                  <PhasingChart
                    chartData={chartData}
                    historicData={historicData}
                    onChartUnmount={handlePhasingChartUnmount}
                    setChartData={setChartData}
                  />
                </>
              )}
              {selectedTabIndex == 3 && <InpatientPlanResult selectedWeekdayIds={selectedWeekdayIds} />}
            </div>
            <div className="ml-5 w-1/6">
              {selectedTabIndex === 0 && (
                <>
                  <span className="graph-type-selector p-1-v-2 mb-4 mt-3 block">
                    {translate("production_plan_year")}
                  </span>
                  <div className="mb-4">
                    <Input
                      name="productionPlan"
                      value={productionRequest?.productionPlan?.toString()}
                      handleInputChange={handleProductionInputChange}
                      placeholder={translate("no_of_inpatient_care_episodes_plan")}
                      disabled={isCareUnitReadOnly !== null ? isCareUnitReadOnly : false}
                    />
                  </div>
                  <div className="mb-4 ">
                    <Input
                      name="occupancyRatePlan"
                      value={productionRequest?.occupancyRatePlan?.toString()}
                      handleInputChange={handleProductionInputChange}
                      placeholder={translate("occ_rate_plan")}
                      type="number"
                      disabled={isCareUnitReadOnly !== null ? isCareUnitReadOnly : false}
                    />
                  </div>

                  <div className="p-2-v-1 medical-specialty-toggle-container">
                    <div>
                      <span> {translate("medical_speciality")} </span>
                    </div>
                    <HideToggle
                      onToggle={handleMedicalSpecialityToggle}
                      isVisible={isNeededToGroupedByMedicalSpeciality}
                    />
                  </div>

                  <div className="mt-4 flex w-full items-center gap-4">
                    <Button
                      text={translate("next")}
                      variant={"button--secondary-optional-with-border"}
                      additionalStyles={
                        "invisible xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem] float-right"
                      }
                      icon={nextIcon}
                      disabled={true}
                    />

                    <Button
                      onClick={() => displayInpatientPlanSaveNotification(selectedTabIndex + 1)}
                      text={translate("next")}
                      variant={"button--secondary-optional-with-border"}
                      additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem] float-right"}
                      icon={nextIcon}
                      disabled={productionRequest.occupancyRatePlan === 0 ? true : false}
                    />
                  </div>
                </>
              )}

              {selectedTabIndex === 1 && (
                <>
                  <span className="graph-type-selector p-1-v-2 mb-4 mt-3 block">
                    {translate("production_plan_year")}
                  </span>
                  <div className="mb-4">
                    <Input
                      name="losPlan"
                      value={productionRequest?.losPlan.toString()}
                      handleInputChange={handleProductionInputChange}
                      placeholder={translate("los_plan")}
                      type="number"
                      disabled={isCareUnitReadOnly !== null ? isCareUnitReadOnly : false}
                    />
                  </div>

                  <div className="mt-4 flex items-center gap-4">
                    <Button
                      onClick={() => displayInpatientPlanSaveNotification(selectedTabIndex - 1)}
                      text={translate("prev")}
                      variant={"button--secondary-optional"}
                      additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
                      icon={prevIcon}
                    />

                    <Button
                      onClick={() => displayInpatientPlanSaveNotification(selectedTabIndex + 1)}
                      text={translate("next")}
                      variant={"button--secondary-optional-with-border"}
                      additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
                      icon={nextIcon}
                    />
                  </div>
                </>
              )}
              {selectedTabIndex == 3 && (
                <InpatientResultPane
                  averageLengthOfStayForResults={averageLengthOfStayForResults}
                  occupancyRateForResults={occupancyRateForResults}
                  totalCareEpisodesForResults={totalCareEpisodesForResults}
                  onWeekdaySelect={handleResultsWeekdaySelect}
                />
              )}
              {selectedTabIndex === 1 && losHistoricAverage !== 0 && (
                <>
                  <div className="px-4">
                    <div className="sidearea__text--container">
                      <h1 className="sidearea__text--title">{translate("average_los")}</h1>
                      <h1 className="sidearea__text--title mt-2">
                        {"("}
                        {translate("from")} {new Date(minDate).toISOString().split("T")[0]} {translate("to")}{" "}
                        {new Date(maxDate).toISOString().split("T")[0]}
                        {")"}
                      </h1>
                      <p className="sidearea__text--value">
                        {localize(Number(losHistoricAverage?.toFixed(1)), i18n.language)}
                      </p>
                    </div>

                    {isLosFeatureFlagEnabled && (
                      <div className="sidearea__text--container">
                        <h1 className="sidearea__text--title">
                          {translate("average_length_of_stay_for_same_case_mix_of_primary_icd_diagnoses_in_sweden")}
                        </h1>
                        <h1 className="sidearea__text--title mt-2">
                          {"("}
                          {translate("from")} {new Date(minDate).toISOString().split("T")[0]} {translate("to")}{" "}
                          {new Date(maxDate).toISOString().split("T")[0]}
                          {")"}
                        </h1>
                        <p className="sidearea__text--value">
                          {localize(Number((losHistoricAverage * 0.9).toFixed(1)), i18n.language)}
                        </p>

                        <p>
                          <Trans i18nKey="the_national_board_of_health_and_welfare_national_patient_register_2023_click_for_more_in_depth_analysis">
                            Link <Link className="underline" to="/analytics/icd-analysis"></Link>
                          </Trans>
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
              {selectedTabIndex === 2 && (
                <>
                  <span className="graph-type-selector p-1-v-2 mb-4 mt-3 block">{translate("plan_phasing")}</span>

                  <div className="mb-4 mt-4">
                    <Select
                      placeholder={translate("phasing_templates")}
                      options={type_of_templates}
                      onSelectOption={handlePhasingTemplatesSelect}
                      selectedOption={selectedTemplate}
                    />
                  </div>

                  <div className="mt-4">
                    <WeekdayPhasingSet
                      handleWeekdayPhasingChange={handleWeekdayPhasingChange}
                      handleWeekdayPhasingLeftIconClick={
                        isCareUnitReadOnly === false ? handleWeekdayPhasingLeftIconClick : () => ""
                      }
                      handleWeekdayPhasingRightIconClick={
                        isCareUnitReadOnly === false ? handleWeekdayPhasingRightIconClick : () => ""
                      }
                      weekDayPhasing={weekDayPhasing}
                      readonly={isCareUnitReadOnly !== null ? isCareUnitReadOnly : false}
                    />
                  </div>

                  <div className="mt-4 flex items-center gap-4">
                    <Button
                      onClick={() => displayInpatientPhasingSaveNotification(selectedTabIndex - 1)}
                      text={translate("prev")}
                      variant={"button--secondary-optional"}
                      additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
                      icon={prevIcon}
                    />

                    <Button
                      onClick={() => displayInpatientPhasingSaveNotification(selectedTabIndex + 1)}
                      text={translate("next")}
                      variant={"button--secondary-optional-with-border"}
                      additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
                      icon={nextIcon}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </GridLayout>
  );
};

export default InPatientPlan;
