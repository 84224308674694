import range from "lodash/range";

const useAdjustColumnHeader = (): { (col: number, TH: HTMLElement): void } => {
  const adjustShiftsColumnHeaderColours = (col: number, TH: HTMLElement) => {
    const lightBlueHeaderIndexes = range(0, 9);

    const lightBlueHeaders = [...lightBlueHeaderIndexes];

    TH.classList.add("remove-filter");

    if (lightBlueHeaders.includes(col)) {
      TH.classList.add("light-blue-column");
    }

    TH.classList.add("htMiddle");
  };

  return adjustShiftsColumnHeaderColours;
};

export default useAdjustColumnHeader;
