import React, { useCallback, useEffect, useState } from "react";
import * as Highcharts from "highcharts";

import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";

import { ISelectOption, MultiSelectOption } from "../../../../interfaces";
import { PhysicianSubUnits, Select } from "../../../../components";
import { useTranslation } from "react-i18next";
import { ProductionOutcomeReponse, ProductionYTDResponse } from "../../../../interfaces/responses";
import { CARE_UNIT_TYPES, CareUnitTypeEnum } from "../../../../utils/constants/careUnitTypes";
import { OutpatientPlanService, ProductionVsPlanService } from "../../../../services";
import { AxiosResponse } from "axios";
import { OutPatientMultiSelectRequest } from "../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import MultiSelectDropDown from "../../../../components/MultiselectDropdown/MultiSelectDropdown";
import { ProdVsPlanDimentionResponse } from "../../../../interfaces/analytics/prodVsPlanDimentionResponse";
import ProductionVsPlanChart from "./Chart/ProductionVsPlanChart";
import useProductionVsPlanData from "./Chart/hooks/useProductionVsPlanData";
import useDataTableData from "./DataTable/hooks/useDataTableData";
import { RecoveryPlanReponse } from "../../../../interfaces/responses/productionvsanalyticsresponse";
import useGetOutpatientWeeklyData from "./DataTable/hooks/useGetOutpatientWeeklyData";
import clsx from "clsx";
import DataTable from "./DataTable/DataTable";
import LoadingWithGrey from "../../../../components/loading/LoadingWithGrey";

const ProductionVsPlanV1 = () => {
  const { t: translate, i18n } = useTranslation();
  const selectedTabIndex: number | undefined = undefined;

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.ProductionVsPlanFilters);

  const isExplanatoryTableFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.prodVsPlanExplnatoryTable);

  const careUnitTypeId = useSelector((state: RootState) => state.globalFilter.filterCareUnits.careUnitType);

  const globalFilter = useSelector((state: RootState) => state.globalFilter);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const [careUnitList, setCareUnitLists] = useState<number[]>([]);

  const [productionYtd, setProductionYtd] = useState<ProductionYTDResponse>(0);

  const typesOfPlans: ISelectOption[] = [
    {
      label: translate("outpatient"),
      value: "1",
    },

    {
      label: translate("inpatient"),
      value: "2",
    },
  ];

  const [selectedTypeOfPlan, setSelectedTypeOfPlan] = useState<ISelectOption>(
    careUnitTypeId === CareUnitTypeEnum.INPATIENT ? typesOfPlans[1] : typesOfPlans[0]
  );

  const [outPatientMultiSelectRequest, setOutPatientMultiSelectRequest] = useState<OutPatientMultiSelectRequest>({
    careTypeIds: [],
    contactReasonGroupingIds: [],
    contactTypeIds: [],
    medicalSpecialtyIds: [],
    professionIds: [],
    typesOfEmergency: [],
    typesOfVisit: [],
  });

  const type_of_visits: MultiSelectOption[] = [
    {
      id: "0",
      label: translate("follow_up_visit"),
      value: "0",
    },
    { id: "1", label: translate("new_visit"), value: "1" },
  ];

  const type_of_emergency: MultiSelectOption[] = [
    {
      id: "0",
      label: translate("is_planned"),
      value: "0",
    },
    {
      id: "1",
      label: translate("is_emergency"),
      value: "1",
    },
  ];

  const [typeVisitAllOptions, setTypeOfVisitAllOptions] = useState<MultiSelectOption[]>(type_of_visits);
  const [typeVisitInitialSelectedOptions, setTypeOfVisitSelectedOptions] =
    useState<MultiSelectOption[]>(type_of_visits);

  const [contactTypeAllOptions, setContactTypeAllOptions] = useState<MultiSelectOption[]>([]);
  const [contactTypeInitialSelectedOptions, setContactTypeInitialSelectedOptions] = useState<MultiSelectOption[]>([]);

  const [professionAllOptions, setProfessionAllOptions] = useState<MultiSelectOption[]>([]);
  const [professionInitialSelectedOptions, setProfessionInitialSelectedOptions] = useState<MultiSelectOption[]>([]);

  const [contactReasonAllOptions, setContactReasonAllOptions] = useState<MultiSelectOption[]>([]);
  const [contactReasonInitialSelectedOptions, setContactReasonInitialSelectedOptions] = useState<MultiSelectOption[]>(
    []
  );

  const [careTypeAllOptions, setCareTypeAllOptions] = useState<MultiSelectOption[]>([]);
  const [careTypeInitialSelectedOptions, setCareTypeInitialSelectedOptions] = useState<MultiSelectOption[]>([]);

  const [emergencyTypeAllOptions, setEmergencyTypeAllOptions] = useState<MultiSelectOption[]>(type_of_emergency);
  const [emergencyTypeInitialSelectedOptions, setEmergencyTypeInitialSelectedOptions] =
    useState<MultiSelectOption[]>(type_of_emergency);

  const [medicalSpecialtyAllOptions, setMedicalSpecialtyAllOptions] = useState<MultiSelectOption[]>([]);
  const [medicalSpecialtyInitialSelectedOptions, setMedicalSpecialtyInitialSelectedOptions] = useState<
    MultiSelectOption[]
  >([]);

  const {
    fetchData,
    outpatientWeeklyStat,
    isLoading: isWeeklyDataLoading,
  } = useGetOutpatientWeeklyData(careUnitList, year, scenario, outPatientMultiSelectRequest);

  const readyToFetch = (): boolean => {
    if (careUnitList && careUnitList.length > 0 && careUnitList[0] !== 0 && selectedTypeOfPlan) {
      if (selectedTypeOfPlan.value === "2") {
        return true;
      } else {
        return (
          outPatientMultiSelectRequest.professionIds.length > 0 &&
          outPatientMultiSelectRequest.contactTypeIds.length > 0 &&
          outPatientMultiSelectRequest.typesOfVisit.length > 0 &&
          outPatientMultiSelectRequest.typesOfEmergency.length > 0 &&
          outPatientMultiSelectRequest.contactReasonGroupingIds.length > 0 &&
          outPatientMultiSelectRequest.careTypeIds.length > 0 &&
          outPatientMultiSelectRequest.medicalSpecialtyIds.length > 0
        );
      }
    }

    return false;
  };

  useEffect(() => {
    if (careUnitList && careUnitList.length > 0) {
      getDimensionsForProdVsPlan(careUnitList);
    }
  }, [careUnitList]);

  useEffect(() => {
    if (CARE_UNIT_TYPES.INPATIENT === careUnitTypeId) {
      setSelectedTypeOfPlan(typesOfPlans[1]);
    } else if (CARE_UNIT_TYPES.OUTPATIENT === careUnitTypeId) {
      setSelectedTypeOfPlan(typesOfPlans[0]);
    }
  }, [careUnitTypeId]);

  useEffect(() => {
    if (i18n.language === "sv") {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ",",
        },
      });
    } else {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ".",
        },
      });
    }
  }, [i18n.language]);

  const [isDimensionsLoading, setIsDimentionLoading] = useState(true);

  const getDimensionsForProdVsPlan = async (careUnitIdList: number[]) => {
    if (careUnitIdList) {
      setIsDimentionLoading(true);

      await OutpatientPlanService.getDimensionsForFilters(careUnitIdList).then((res: ProdVsPlanDimentionResponse) => {
        if (res != null) {
          const contactTypes: MultiSelectOption[] = res.contactTypes.map((r) => ({
            id: r.id.toString(),
            label: r.name,
            value: r.id.toString(),
          }));

          const professions: MultiSelectOption[] = res.professions.map((r) => ({
            id: r.id.toString(),
            label: r.name,
            value: r.id.toString(),
          }));

          const contactReasons: MultiSelectOption[] = res.contactReasonGroupings.map((r) => ({
            id: r.id.toString(),
            label: r.name,
            value: r.id.toString(),
          }));

          const careTypes: MultiSelectOption[] = res.careTypes.map((r) => ({
            id: r.id.toString(),
            label: r.name,
            value: r.id.toString(),
          }));

          const medicalSpecs: MultiSelectOption[] = res.medicalSpecialties.map((r) => ({
            id: r.id.toString(),
            label: r.name,
            value: r.id.toString(),
          }));

          setContactTypeAllOptions(contactTypes);
          setContactTypeInitialSelectedOptions(contactTypes);

          setProfessionAllOptions(professions);
          setProfessionInitialSelectedOptions(professions);

          setContactReasonAllOptions(contactReasons);
          setContactReasonInitialSelectedOptions(contactReasons);

          setCareTypeAllOptions(careTypes);
          setCareTypeInitialSelectedOptions(careTypes);

          setMedicalSpecialtyAllOptions(medicalSpecs);
          setMedicalSpecialtyInitialSelectedOptions(medicalSpecs);

          setTypeOfVisitAllOptions(type_of_visits);
          setTypeOfVisitSelectedOptions(type_of_visits);

          setEmergencyTypeAllOptions(type_of_emergency);
          setEmergencyTypeInitialSelectedOptions(type_of_emergency);
        }
      });

      setIsDimentionLoading(false);
    }
  };

  const handleTypesOfInputSelect = (selectedOption: ISelectOption) => {
    if (selectedTypeOfPlan && selectedOption.value !== selectedTypeOfPlan.value) {
      setSelectedTypeOfPlan(selectedOption);

      if (selectedOption.value === "2") {
        setOutPatientMultiSelectRequest({
          careTypeIds: [],
          contactReasonGroupingIds: [],
          contactTypeIds: [],
          medicalSpecialtyIds: [],
          professionIds: [],
          typesOfEmergency: [],
          typesOfVisit: [],
        });
      }
    }
  };

  useEffect(() => {
    setCareUnitLists(globalFilter.filterCareUnits.units);
  }, [globalFilter.filterCareUnits]);

  const fetchProductionYtd = async () => {
    if (outPatientMultiSelectRequest && selectedTypeOfPlan) {
      await ProductionVsPlanService.getProductionYtdData(
        Number(selectedTypeOfPlan.value),
        careUnitList,
        Number(selectedTypeOfPlan.value) === 2
          ? null
          : outPatientMultiSelectRequest?.professionIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectRequest?.professionIds,
        Number(selectedTypeOfPlan.value) === 2
          ? null
          : outPatientMultiSelectRequest?.contactTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectRequest?.contactTypeIds,
        Number(selectedTypeOfPlan.value) === 2
          ? null
          : outPatientMultiSelectRequest?.typesOfVisit.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectRequest?.typesOfVisit,
        Number(selectedTypeOfPlan.value) === 2
          ? null
          : outPatientMultiSelectRequest?.typesOfEmergency.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectRequest?.typesOfEmergency,
        Number(selectedTypeOfPlan.value) === 2
          ? null
          : outPatientMultiSelectRequest?.contactReasonGroupingIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectRequest?.contactReasonGroupingIds,
        Number(selectedTypeOfPlan.value) === 2
          ? null
          : outPatientMultiSelectRequest?.careTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectRequest?.careTypeIds,
        Number(selectedTypeOfPlan.value) === 2
          ? null
          : outPatientMultiSelectRequest?.medicalSpecialtyIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectRequest?.medicalSpecialtyIds,
        year,
        scenario
      )
        .then((response: AxiosResponse<ProductionYTDResponse>) => {
          const productionYtd = Number(Math.round(parseFloat(response?.data?.toString()) * 100));

          setProductionYtd(productionYtd);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const isEqual = (arr1: number[], arr2: number[]): boolean => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    arr1 = arr1.sort();
    arr2 = arr2.sort();

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };

  const onTypeVisitChange = useCallback(
    (values: MultiSelectOption[]): void => {
      const isAllSelected = isEqual(
        values.map((r) => Number(r.value)),
        typeVisitAllOptions.map((r) => Number(r.value))
      );

      const valueArray = values.length > 0 ? (isAllSelected ? [-1] : values.map((v) => Number(v.value))) : [-1];

      if (!isDimensionsLoading) {
        setOutPatientMultiSelectRequest((prevValue) => {
          return { ...prevValue, typesOfVisit: valueArray };
        });
      }
    },
    [typeVisitAllOptions]
  );

  const onContactTypeChange = useCallback(
    (values: MultiSelectOption[]): void => {
      const isAllSelected = isEqual(
        values.map((r) => Number(r.value)),
        contactTypeAllOptions.map((r) => Number(r.value))
      );

      const valueArray = values.length > 0 ? (isAllSelected ? [-1] : values.map((v) => Number(v.value))) : [-1];

      if (!isDimensionsLoading) {
        setOutPatientMultiSelectRequest((prevValue) => {
          return { ...prevValue, contactTypeIds: valueArray };
        });
      }
    },
    [contactTypeAllOptions]
  );

  const onProfessionChange = useCallback(
    (values: MultiSelectOption[]): void => {
      const isAllSelected = isEqual(
        values.map((r) => Number(r.value)),
        professionAllOptions.map((r) => Number(r.value))
      );

      const valueArray = values.length > 0 ? (isAllSelected ? [-1] : values.map((v) => Number(v.value))) : [-1];

      if (!isDimensionsLoading) {
        setOutPatientMultiSelectRequest((prevValue) => {
          return { ...prevValue, professionIds: valueArray };
        });
      }
    },
    [professionAllOptions]
  );

  const onContactReasonChange = useCallback(
    (values: MultiSelectOption[]): void => {
      const isAllSelected = isEqual(
        values.map((r) => Number(r.value)),
        contactReasonAllOptions.map((r) => Number(r.value))
      );

      const valueArray = values.length > 0 ? (isAllSelected ? [-1] : values.map((v) => Number(v.value))) : [-1];

      if (!isDimensionsLoading) {
        setOutPatientMultiSelectRequest((prevValue) => {
          return { ...prevValue, contactReasonGroupingIds: valueArray };
        });
      }
    },
    [contactReasonAllOptions]
  );

  const onCareTypeChange = useCallback(
    (values: MultiSelectOption[]): void => {
      const isAllSelected = isEqual(
        values.map((r) => Number(r.value)),
        careTypeAllOptions.map((r) => Number(r.value))
      );

      const valueArray = values.length > 0 ? (isAllSelected ? [-1] : values.map((v) => Number(v.value))) : [-1];

      if (!isDimensionsLoading) {
        setOutPatientMultiSelectRequest((prevValue) => {
          return { ...prevValue, careTypeIds: valueArray };
        });
      }
    },
    [careTypeAllOptions]
  );

  const onEmergencyTypeChange = useCallback(
    (values: MultiSelectOption[]): void => {
      const isAllSelected = isEqual(
        values.map((r) => Number(r.value)),
        emergencyTypeAllOptions.map((r) => Number(r.value))
      );

      const valueArray = values.length > 0 ? (isAllSelected ? [-1] : values.map((v) => Number(v.value))) : [-1];

      if (!isDimensionsLoading) {
        setOutPatientMultiSelectRequest((prevValue) => {
          return { ...prevValue, typesOfEmergency: valueArray };
        });
      }
    },
    [emergencyTypeAllOptions]
  );

  const onMedicalSpecialtyChange = useCallback(
    (values: MultiSelectOption[]): void => {
      const isAllSelected = isEqual(
        values.map((r) => Number(r.value)),
        medicalSpecialtyAllOptions.map((r) => Number(r.value))
      );

      const valueArray = values.length > 0 ? (isAllSelected ? [-1] : values.map((v) => Number(v.value))) : [-1];

      if (!isDimensionsLoading) {
        setOutPatientMultiSelectRequest((prevValue) => {
          return { ...prevValue, medicalSpecialtyIds: valueArray };
        });
      }
    },
    [medicalSpecialtyAllOptions]
  );

  const {
    areaChartData,
    recoveryPlan,
    columnChartData,
    fetchAreaChartData,
    fetchRecoveryPlan,
    isLoading: isProductionPlanLoading,
  } = useProductionVsPlanData(
    selectedTypeOfPlan?.value ? Number(selectedTypeOfPlan.value) : 0,
    careUnitList,
    year,
    scenario,
    outPatientMultiSelectRequest
  );

  const data = useDataTableData(
    columnChartData as ProductionOutcomeReponse,
    areaChartData,
    recoveryPlan as RecoveryPlanReponse[],
    outpatientWeeklyStat,
    isWeeklyDataLoading,
    isProductionPlanLoading
  );

  const fetchChartData = () => {
    fetchAreaChartData();
    fetchRecoveryPlan();

    if (isExplanatoryTableFeatureFlagEnabled && Number(selectedTypeOfPlan.value) === CARE_UNIT_TYPES.OUTPATIENT) {
      fetchData();
    }
  };

  useEffect(() => {
    if (readyToFetch() && Number(selectedTypeOfPlan.value) === CARE_UNIT_TYPES.INPATIENT) {
      fetchChartData();
      fetchProductionYtd();
    }
  }, [scenario, outPatientMultiSelectRequest, selectedTypeOfPlan.value]);

  useEffect(() => {
    if (readyToFetch() && Number(selectedTypeOfPlan.value) === CARE_UNIT_TYPES.OUTPATIENT && !isDimensionsLoading) {
      fetchChartData();
      fetchProductionYtd();
    }
  }, [scenario, outPatientMultiSelectRequest]);

  useEffect(() => {
    if (careUnitTypeId === CARE_UNIT_TYPES.INPATIENT) {
      setOutPatientMultiSelectRequest({
        careTypeIds: [],
        contactReasonGroupingIds: [],
        contactTypeIds: [],
        medicalSpecialtyIds: [],
        professionIds: [],
        typesOfEmergency: [],
        typesOfVisit: [],
      });
    }
  }, [globalFilter.filterCareUnits]);

  return (
    <div className="h-full w-full">
      <>
        <div className="flex h-full w-full">
          {!isProductionPlanLoading || !isWeeklyDataLoading ? (
            <div className="w-full">
              <div
                className={clsx({
                  "h-1/2":
                    Number(selectedTypeOfPlan?.value) === CARE_UNIT_TYPES.OUTPATIENT &&
                    isExplanatoryTableFeatureFlagEnabled,
                  "h-full":
                    Number(selectedTypeOfPlan?.value) === CARE_UNIT_TYPES.INPATIENT ||
                    !isExplanatoryTableFeatureFlagEnabled,
                })}
              >
                {selectedTypeOfPlan && (
                  <ProductionVsPlanChart
                    isPlacedOnDashboard={false}
                    selectedTypeOfPlan={selectedTypeOfPlan}
                    areaChartData={areaChartData}
                    columnChartData={columnChartData}
                    recoveryPlan={recoveryPlan}
                  />
                )}
              </div>

              {Number(selectedTypeOfPlan?.value) === CARE_UNIT_TYPES.OUTPATIENT &&
              isExplanatoryTableFeatureFlagEnabled ? (
                <div className="h-1/2 px-2">
                  <DataTable data={data} />
                </div>
              ) : null}
            </div>
          ) : (
            <div className="flex h-full w-full items-center justify-center">
              <LoadingWithGrey sizeInPixel={40} />
            </div>
          )}

          <div className="flex w-1/6 flex-col  justify-between px-4">
            <div className="flex h-full flex-col gap-4">
              <div className="w-full">
                <Select
                  placeholder={translate("type_of_input")}
                  options={typesOfPlans}
                  onSelectOption={handleTypesOfInputSelect}
                  selectedOption={selectedTypeOfPlan}
                />
              </div>

              {selectedTypeOfPlan &&
                selectedTypeOfPlan.value === "1" &&
                careUnitList.length > 0 &&
                isFeatureFlagEnabled && (
                  <>
                    <MultiSelectDropDown
                      label={translate("type_of_visit")}
                      options={typeVisitAllOptions}
                      initialSelectedOptions={typeVisitInitialSelectedOptions}
                      onValueChange={onTypeVisitChange}
                    />
                    <MultiSelectDropDown
                      label={translate("contact_type")}
                      options={contactTypeAllOptions}
                      initialSelectedOptions={contactTypeInitialSelectedOptions}
                      onValueChange={onContactTypeChange}
                    />
                    <MultiSelectDropDown
                      label={translate("profession")}
                      options={professionAllOptions}
                      initialSelectedOptions={professionInitialSelectedOptions}
                      onValueChange={onProfessionChange}
                    />
                    <MultiSelectDropDown
                      label={translate("contact_reason")}
                      options={contactReasonAllOptions}
                      initialSelectedOptions={contactReasonInitialSelectedOptions}
                      onValueChange={onContactReasonChange}
                    />
                    <MultiSelectDropDown
                      label={translate("care_type")}
                      options={careTypeAllOptions}
                      initialSelectedOptions={careTypeInitialSelectedOptions}
                      onValueChange={onCareTypeChange}
                    />
                    {selectedTabIndex !== 3 && (
                      <MultiSelectDropDown
                        label={translate("type_of_emergency")}
                        options={emergencyTypeAllOptions}
                        initialSelectedOptions={emergencyTypeInitialSelectedOptions}
                        onValueChange={onEmergencyTypeChange}
                      />
                    )}
                    <MultiSelectDropDown
                      label={translate("medical_speciality")}
                      options={medicalSpecialtyAllOptions}
                      initialSelectedOptions={medicalSpecialtyInitialSelectedOptions}
                      onValueChange={onMedicalSpecialtyChange}
                    />
                  </>
                )}

              <div className="mt-0">
                <div className="mb-4">
                  <div className="sidearea__text--title">{translate("production_ytd")}</div>
                  <div
                    className={`sidearea__text--value ${
                      productionYtd < 100 ? "danger" : productionYtd >= 100 && "success"
                    }`}
                  >
                    {productionYtd} %
                  </div>
                </div>
              </div>
            </div>

            <div>{careUnitTypeId === CARE_UNIT_TYPES.PHYSICIAN ? <PhysicianSubUnits /> : null}</div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ProductionVsPlanV1;
