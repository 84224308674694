import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InpatientProductivityAnalysis } from "../../../../../interfaces/analytics/orgOverview/inpatientProductivityAnalysis";
import { useNumberFormat } from "../../../../../hooks/use-number-format";

const OverviewKPI = ({ data }: { data: InpatientProductivityAnalysis[] }) => {
  const { t: translate } = useTranslation();
  const locale = localStorage.getItem("i18nextLng") === "sv" ? "sv-SE" : "en";
  const { thousandSeparate } = useNumberFormat();
  const [overviewKPI, setOverviewKPI] = useState({
    potentialComparedToPlan: 0,
    staffingPotentialTotal: 0,
    costPotentialTotal: 0,
  });

  const calculateKPIValues = (inpatientProdAnalysisData: InpatientProductivityAnalysis[]) => {
    let costModelTotal = 0;
    let staffingPotentialTotal = 0;
    let costPotentialTotal = 0;

    inpatientProdAnalysisData.forEach((item) => {
      costModelTotal += Number(item.costModel);
      staffingPotentialTotal += Number(item.staffingPotential);
      costPotentialTotal += Number(item.costPotential);
    });

    setOverviewKPI({
      ...overviewKPI,
      potentialComparedToPlan: costPotentialTotal / costModelTotal,
      staffingPotentialTotal: staffingPotentialTotal,
      costPotentialTotal: costPotentialTotal,
    });
  };

  useEffect(() => {
    if (data.length > 0) {
      calculateKPIValues(data);
    } else {
      if (
        overviewKPI.costPotentialTotal > 0 ||
        overviewKPI.potentialComparedToPlan > 0 ||
        overviewKPI.staffingPotentialTotal > 0
      ) {
        setOverviewKPI({
          potentialComparedToPlan: 0,
          staffingPotentialTotal: 0,
          costPotentialTotal: 0,
        });
      }
    }
  }, [data]);

  return (
    <div>
      <div>
        <div className="sidearea__text--title">{translate("potential_compared_to_plan")}</div>
        <div className="sidearea__text--value">
          {overviewKPI.potentialComparedToPlan.toLocaleString(locale, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            style: "percent",
          })}
        </div>
      </div>
      <div>
        <div className="sidearea__text--title">{translate("staff_potential")}</div>
        <div className="sidearea__text--value">
          {Number(overviewKPI.staffingPotentialTotal.toFixed(1)).toLocaleString(locale)}
        </div>
      </div>
      <div>
        <div className="sidearea__text--title">{translate("cost_potential")}</div>
        <div className="sidearea__text--value">{thousandSeparate(overviewKPI.costPotentialTotal, " ") || 0}</div>
      </div>
    </div>
  );
};

export default OverviewKPI;
