import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { hoursOfTheDay } from "../../../../utils/highcharts/capacity/constants";
import { HeatMap2Data } from "../../../../interfaces/capacity/heatmap-data";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { CapacityService } from "../../../../services";
import { AxiosResponse } from "axios";
import { HeatMap3Response } from "../../../../interfaces/capacity/heat-map-data-response";
import { MultiSelectOption } from "../../../../interfaces";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import LoadingWithGrey from "../../../../components/loading/LoadingWithGrey";

const StaffingVsLoadHeatmap = (
{
    selectedCompetenceShareOptions,
}: {
    selectedCompetenceShareOptions: MultiSelectOption[];
}) => {

    const { t: translate } = useTranslation();
    const [chartData, setChartData] = useState<HeatMap2Data>({
        data: [],
      });
    const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
    const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
    const [isChartLoading, setIsChartLoading] = useState<boolean>(false);

    const daysOfWeek = [
        translate("Mon"),
        translate("Tue"),
        translate("Wed"),
        translate("Thu"),
        translate("Fri"),
        translate("Sat"),
        translate("Sun"),
      ];
    
    const chartOptions = {
        chart: {
          type: "heatmap",
          marginTop: 80,
          marginBottom: 60,
          plotBorderWidth: 1,
          backgroundColor: "transparent",
          style: {
            fontFamily: "Open Sans",
            textOutline: 0,
            fontSize: 12,
          },
        },
    
        tooltip: {
          formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
            const point = this.point as Highcharts.Point;
    
            const tooltipInfo = `
            ${daysOfWeek[point.x]} <br />
            ${point.y?.toString().padStart(2, "0")}:00 <br /> 
            ${point.value}%`;
            return tooltipInfo;
          },
        },
    
        title: {
          text: translate("heatmap_3_title") ,
          style: {
            fontSize: 16,
          },
        },
    
        xAxis: {
          categories: [
            translate("Mon"),
            translate("Tue"),
            translate("Wed"),
            translate("Thu"),
            translate("Fri"),
            translate("Sat"),
            translate("Sun"),
          ],
          opposite: true,
          lineWidth: 0,
          labels: {
            style: {
              fontSize: 12,
            },
            rotation: 0,
          },
        },
    
        yAxis: {
          categories: hoursOfTheDay,
          title: null,
          lineWidth: 0,
          reversed: true,
          labels: {
            style: {
              fontSize: 12,
            },
          },
        },
    
        accessibility: {
          point: {},
        },
    
        //Do not remove comments inside stops
        colorAxis: {
          min: 0,
          max: 200,
          minColor: "#AD1E23",
          maxColor: "#00283C",
          stops: [
            [0.25, "#AD1E23"], // 50% Below mid point
            [0.425, "#FFF"], // 15% Below mid point
            [0.5, "#FFF"], // mid point == 100%
            [0.575, "#FFF"], // 15% Above mid point
            [0.75, "#426FA2"], // 50% Above mid point
            [1, "#00283C"],
          ],
          labels: {
            format: "{value}%",
          },
        },
    
        legend: {
          align: "right",
          layout: "vertical",
          margin: 0,
          verticalAlign: "top",
          y: 25,
          symbolHeight: 280,
          style: {
            fontSize: 12,
          },
        },
    
        series: [
          {
            ...chartData,
            borderWidth: 1,
            borderColor: "#ADADAD",
            dataLabels: {
              enabled: true,
              formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                return this.point.value + "%";
              },
              style: {
                textOutline: 0,
                fontWeight: "normal",
                fontSize: 12,
              },
            },
          },
        ],
    
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                yAxis: {
                  labels: {},
                },
              },
            },
          ],
        },
      };

      useEffect(() => {
        if (careUnitId !== null && selectedCompetenceShareOptions.length > 0) {
          setIsChartLoading(true);
    
          const competences = selectedCompetenceShareOptions.map((share) => {
            return String(share.value);
          });
    
          CapacityService.getWeeklyWorkingHoursOverWeelyLoadHeatMapData(competences, careUnitId, scenario).then(
            (res: AxiosResponse<HeatMap3Response>) => {
              setChartData(() => ({
                data: res.data.heatMapThreeResults,
              }));
              if (res.data.heatMapThreeResults.length !== 0) {
                setIsChartLoading(false);
              }
            }
          );
        }
      }, [careUnitId, selectedCompetenceShareOptions, scenario]);

      return (
        <>
            {!isChartLoading ? (
                <HighchartsReact
                    containerProps={{ style: { height: "100%" } }}
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            )
            : (
                <div className="flex h-full items-center justify-center">
                  <LoadingWithGrey sizeInPixel={40} />
                </div>
              )}
        </>
    )
};

export default StaffingVsLoadHeatmap;