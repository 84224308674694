import { useRef } from "react";
import { DataTableProps } from "../../../../../../interfaces/props/data-table-props";
import { useTranslation } from "react-i18next";
import useOutcomeVsPlanTableColumns from "./useOutcomeVsPlanTableColumns";
import { HotTableClass as HotTable } from "@handsontable/react";

export interface ColumnHeader {
  colspan: number;
  label: string;
}

const useOutcomeVsPlanGridConfigs = (timePeriodType: number, dateRange: string[]): DataTableProps => {
  const { t: translate } = useTranslation();

  const getWeekday = (date: Date): string => {
    const daysOfWeek = [
      translate("sun"),
      translate("mon"),
      translate("tue"),
      translate("wed"),
      translate("thu"),
      translate("fri"),
      translate("sat"),
    ];

    return daysOfWeek[date.getDay()];
  };

  const cols = useOutcomeVsPlanTableColumns(timePeriodType, dateRange);
  const ref = useRef<HotTable | null>(null);

  const createNestedHeads = () => {
    const dateRanges: string[] = [];
    let i = 0;
    while (i < dateRange.length) {
      const startDate = dateRange[i];
      const endDate = dateRange[i + 6];
      const dateRangeString = startDate + "--" + endDate;
      dateRanges.push(dateRangeString);
      i = i + 7;
    }

    const firstLayer: ColumnHeader[] = [
      {
        colspan: 1,
        label: "",
      },
    ];
    dateRanges.forEach((dateRange) => {
      firstLayer.push({
        colspan: 7,
        label: dateRange,
      });
    });

    const secondLayer: ColumnHeader[] = [
      {
        colspan: 1,
        label: "",
      },
    ];

    dateRange.forEach((date) => {
      const standardDate = new Date(date);

      secondLayer.push({
        colspan: 1,
        label: getWeekday(standardDate),
      });
    });

    const nestedHeaders = [firstLayer, secondLayer];
    return nestedHeaders;
  };

  const props: DataTableProps = {
    language: localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE",
    numericFormat: {
      culture: localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE",
      pattern: {},
    },
    ref,
    columns: cols.map((r) => r.meta),
    colHeaders: timePeriodType === 2 ? true : cols.map((r) => r.headerTitle),
    nestedHeaders: timePeriodType === 2 ? createNestedHeads() : undefined,
    licenseKey: "non-commercial-and-evaluation",
    contextMenu: ["alignment", "copy"],
    fixedColumnsLeft: 1,
    rowHeights: 28,
    height: "100%",
    colWidths: cols.map((x) => x.width),
    readOnly: true,
    rowHeaders: false,
    columnSorting: true,
    manualRowMove: true,
    dropdownMenu: ["filter_by_condition", "filter_action_bar", "filter_by_value"],
    afterGetColHeader: (col: number, TH) => {
      const colConfig = cols[col];

      if (colConfig && colConfig.headerColor) {
        TH.style.backgroundColor = colConfig.headerColor;
        TH.style.color = "white";
      } else {
        TH.classList.add("dark-blue-column--small");
      }

      TH.classList.add("htMiddle");

      if (colConfig && !colConfig.isFilterable) {
        TH.classList.add("remove-filter");
      }
    },
    cells: (row, col) => {
      type cp = {
        className?: string;
        readOnly?: boolean;
      };
      const cp: cp = {};

      cp.className = "htMiddle";

      if (col === 0) {
        cp.className = cp.className + " dark-blue-column--small";
      } else {
        cp.className = cp.className + " htRight";
      }

      return cp;
    },
  };

  return props;
};

export default useOutcomeVsPlanGridConfigs;
