import Handsontable from "handsontable";
import { keyValueListRenderer } from "../renderers/key-value-list-renderer";
import { keyValueListValidator } from "../validators/validators";
import KeyValueListEditor from "../editors/key-value-list-editor";
import { MultiSelectEditor } from "../editors/multiSelectEditor";

export const regitserHandsontableCustomCellTypes = {
  registerKeyValueDropdownCellType: () => {
    Handsontable.cellTypes.registerCellType("keyValueDropdown", {
      editor: KeyValueListEditor,
      validator: keyValueListValidator,
      renderer: keyValueListRenderer,
    });
  },
  registerMultiSelectDropdownCellType: () => {
    Handsontable.cellTypes.registerCellType("multiSelect", {
      editor: MultiSelectEditor,
    });
  },
};
