import React, { useEffect, useRef } from "react";
import { HotTableClass as HotTable } from "@handsontable/react";
import { InpatientProductivityAnalysis } from "../../../../../interfaces/analytics/orgOverview/inpatientProductivityAnalysis";
import { DataTableProps } from "../../../../../interfaces/props/data-table-props";
import useOverviewGridConfigs from "./hooks/useOverviewGridConfigs";
import LoadingWithGrey from "../../../../../components/loading/LoadingWithGrey";

const OverviewGrid = ({ data, isLoading }: { data: InpatientProductivityAnalysis[]; isLoading: boolean }) => {
  const dataTable = useRef<HotTable>(null);
  const tableProps: DataTableProps = useOverviewGridConfigs(dataTable).getConfigs();

  useEffect(() => {
    if (dataTable.current) {
      const hotInstance = dataTable.current?.hotInstance;
      const collapsibleColumnsPlugin = hotInstance?.getPlugin("collapsibleColumns");

      if (collapsibleColumnsPlugin && collapsibleColumnsPlugin.enabled) {
        collapsibleColumnsPlugin.collapseAll();
      }
    }
  }, [tableProps]);

  return (
    <>
      {!isLoading ? (
        <HotTable data={data} {...tableProps} licenseKey="non-commercial-and-evaluation" />
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      )}
    </>
  );
};

export default OverviewGrid;
