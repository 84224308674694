import { useEffect, useState } from "react";
import { ProdVsPlanTableData } from "../../../../../../interfaces/analytics/prodVsPlan/prodVsPlanTableData";
import { useTranslation } from "react-i18next";
import {
  ProductionOutcomeData,
  ProductionPlanPhasedData,
  RecoveryPlanData,
} from "../../../../../../interfaces/analytics/prodVsPlan/chartData";

const useDataTableData = (
  productionData: ProductionOutcomeData,
  plannedData: ProductionPlanPhasedData[],
  recoveryData: RecoveryPlanData[],
  referralData: number[],
  delayedContactData: number[],
  isProductionDataLoading: boolean,
  timePeriodType: number,
  dateRange: string[]
): ProdVsPlanTableData[] => {
  const { t: translate } = useTranslation();
  const [data, setData] = useState<ProdVsPlanTableData[]>([]);

  useEffect(() => {
    const dataNewData: {
      name: string;
      [key: number]: number;
    }[] = [
      {
        name: translate("referrals"),
      },
      {
        name: translate("delayed_contacts"),
      },
      {
        name: translate("production"),
      },
      {
        name: translate("plan"),
      },
      {
        name: translate("gap"),
      },
      {
        name: translate("recovery_plan"),
      },
    ];

    if (timePeriodType === 2) {
      if (!isProductionDataLoading && productionData && plannedData && recoveryData) {
        for (let i = 0; i < dateRange.length; i++) {
          dataNewData[5][i] = recoveryData[i]?.recoveryPlan ?? 0;
          dataNewData[4][i] = productionData.outcomeData[i].outcome - plannedData[i].productionPlan;
          dataNewData[3][i] = plannedData[i].productionPlan ?? 0;
          dataNewData[2][i] = productionData.outcomeData[i].outcome ?? 0;
          dataNewData[1][i] = delayedContactData[i] ?? 0;
          dataNewData[0][i] = referralData[i] ?? 0;
        }
      }
    } else {
      if (!isProductionDataLoading && productionData && plannedData && recoveryData) {
        for (let i = 0; i < 52; i++) {
          dataNewData[5][i] = recoveryData[i]?.recoveryPlan ?? 0;
          dataNewData[4][i] = productionData.outcomeData[i].outcome - plannedData[i].productionPlan;
          dataNewData[3][i] = plannedData[i].productionPlan ?? 0;
          dataNewData[2][i] = productionData.outcomeData[i].outcome ?? 0;
          dataNewData[1][i] = delayedContactData[i] ?? 0;
          dataNewData[0][i] = referralData[i] ?? 0;
        }
      }
    }

    setData(dataNewData);
  }, [productionData, plannedData, recoveryData, referralData, delayedContactData, isProductionDataLoading]);

  return data;
};

export default useDataTableData;
