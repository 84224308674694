import HttpCommonService from "./httpCommonService";
import { PlanningObjectPayload } from "../interfaces/planning-object/planningObject";

export const PlanningObjectService = {
  savePlanningObject: async (data: PlanningObjectPayload) => {
    return await HttpCommonService.postAsync(`api/planning-objects/save`, data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getPlanningObjects: async (careUnitIds: number[]) => {
    const payload = {
      careUnitIds: careUnitIds,
    };
    return await HttpCommonService.postAsync(`api/planning-objects`, payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getDimensionsForPlanningObject: async (planningObjectId: number) => {
    return await HttpCommonService.getAsync(`api/planning-objects/dimensions/${planningObjectId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  deletePlanningObject: async (planningObjectId: number, careUnitId: number) => {
    return await HttpCommonService.deletAsync(`api/planning-objects/delete/${careUnitId}/${planningObjectId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default PlanningObjectService;
