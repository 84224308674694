import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { ISelectOption, MultiSelectOption } from "../../../../interfaces";
import { PlanningObjectSchema } from "../../../../interfaces/planning-object/planningObjectSchema";
import usePhysicianSubUnits from "../../../../components/physician-sub-units/hooks/usePhysicianSubUnits";
import isEqual from "lodash/isEqual";
import { DetailPlanFilterOptionResponse } from "../../../../interfaces/production-plan/outpatient/detailedPlan/detailed-plan";
import PlanningObjectService from "../../../../services/planningObjectService";
import { AxiosResponse } from "axios";
import { PlanningObjectimensions } from "../../../../interfaces/planning-object/planningObjectDimension";
import { HideToggle, MultiSelect, Select } from "../../../../components";
import { OutPatientMultiselectFilterWithTogglesProps } from "../../../../interfaces/production-plan/outpatient/outpatientMultiselectFilterWithToggles";
import { v4 as uuid } from "uuid";
import useGetOutpatientDimensions from "../../OutpatientPlan/Hooks/useGetOutpatientDimensions";

const OutpatientMultiselectFilterWithToggles = ({
  outPatientMultiSelectRequest,
  setOutPatientMultiSelectRequest,
}: OutPatientMultiselectFilterWithTogglesProps) => {
  const { t: translate } = useTranslation();

  const careUnitTypeId = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const outpatientDimensions = useGetOutpatientDimensions(careUnitId, careUnitTypeId);

  // available options
  const type_of_visits: MultiSelectOption[] = [
    {
      id: uuid(),
      label: translate("follow_up_visit"),
      value: "0",
    },
    { id: uuid(), label: translate("new_visit"), value: "1" },
  ];
  const type_of_emergency: MultiSelectOption[] = [
    {
      id: uuid(),
      label: translate("is_planned"),
      value: "0",
    },
    {
      id: uuid(),
      label: translate("is_emergency"),
      value: "1",
    },
  ];

  const [typesOfVisits, setTypesOfVisit] = useState<MultiSelectOption[]>([]);
  const [typesOfEmergency, setTypesOfEmergency] = useState<MultiSelectOption[]>([]);
  const [contactTypes, setContactTypes] = useState<MultiSelectOption[]>([]);
  const [professions, setProfessions] = useState<MultiSelectOption[]>([]);
  const [contactReasons, setContactReasons] = useState<MultiSelectOption[]>([]);
  const [careTypes, setCareTypes] = useState<MultiSelectOption[]>([]);
  const [medicalSpecialties, setMedicalSpecialties] = useState<MultiSelectOption[]>([]);
  const [filterGroupDropDownList, setFilterGroupDropDownList] = useState<ISelectOption[]>([]);
  const [filterGroupList, setFilterGroupList] = useState<PlanningObjectSchema[]>([]);

  // selected options
  const [selectedVisit, setSelectedVisit] = useState<MultiSelectOption[]>([
    { id: uuid(), value: "null", label: translate("all") },
  ]);

  const [selectedTypeOfEmergency, setSelectedTypeOfEmergency] = useState<MultiSelectOption[]>([
    { id: uuid(), value: "null", label: translate("all") },
  ]);

  const [selectedContactType, setSelectedContactType] = useState<MultiSelectOption[]>([
    { id: uuid(), value: "null", label: translate("all") },
  ]);

  const [selectedProfession, setSelectedProfession] = useState<MultiSelectOption[]>([
    {
      id: uuid(),
      value: "null",
      label: translate("all"),
    },
  ]);

  const [selectedContactReason, setSelectedContactReason] = useState<MultiSelectOption[]>([
    {
      id: uuid(),
      value: "null",
      label: translate("all"),
    },
  ]);

  const [selectedCareType, setSelectedCareType] = useState<MultiSelectOption[]>([
    {
      id: uuid(),
      value: "null",
      label: translate("all"),
    },
  ]);

  const [selectedMedicalSpecialties, setSelectedMedicalSpecialties] = useState<MultiSelectOption[]>([
    {
      id: uuid(),
      value: "null",
      label: translate("all"),
    },
  ]);

  const [selectedFilterGroup, setSelectedFilterGroup] = useState<ISelectOption>({
    value: "null",
    label: translate("all"),
  });

  const [selectedVisitFilterVisibility, setSelectedVisitFilterVisibility] = useState<boolean>(false);
  const [selectedContactTypeFilterVisibility, setSelectedContactTypeFilterVisibility] = useState<boolean>(false);
  const [selectedProfessionFilterVisibility, setSelectedProfessionFilterVisibility] = useState<boolean>(false);
  const [selectedContactReasonFilterVisibility, setSelectedContactReasonFilterVisibility] = useState<boolean>(false);
  const [selectedCareTypeFilterVisibility, setSelectedCareTypeFilterVisibility] = useState<boolean>(false);
  const [selectedTypeOfEmergencyFilterVisibility, setSelectedTypeOfEmergencyFilterVisibility] = useState<boolean>(true);
  const [selectedMedicalSpecialtyFilterVisibility, setSelectedMedicalSpecialtyFilterVisibility] =
    useState<boolean>(false);

  const [enabledFilters, setEnabledFilters] = useState<number>(1);

  const { physicanSubUnits: physicianSubUnits } = usePhysicianSubUnits();

  useEffect(() => {
    const mapSelectedValues = (selectedValues: MultiSelectOption[]) =>
      selectedValues.filter((x) => x.value !== "null").map((x) => parseInt(x.value.toString(), 10));

    if (
      selectedVisit.length === typesOfVisits.length - 1 ||
      selectedTypeOfEmergency.length === typesOfEmergency.length - 1 ||
      selectedProfession.length === professions.length - 1 ||
      selectedContactReason.length === contactReasons.length - 1 ||
      selectedCareType.length === careTypes.length - 1 ||
      selectedContactType.length === contactTypes.length - 1 ||
      selectedMedicalSpecialties.length === medicalSpecialties.length - 1
    ) {
      selectedVisit.length === typesOfVisits.length - 1 ? setSelectedVisit(typesOfVisits) : selectedVisit;
      selectedTypeOfEmergency.length === typesOfEmergency.length - 1
        ? setSelectedTypeOfEmergency(typesOfEmergency)
        : selectedTypeOfEmergency;
      selectedProfession.length === professions.length - 1 ? setSelectedProfession(professions) : selectedProfession;
      selectedContactReason.length === contactReasons.length - 1
        ? setSelectedContactReason(contactReasons)
        : selectedContactReason;
      selectedCareType.length === careTypes.length - 1 ? setSelectedCareType(careTypes) : selectedCareType;
      selectedContactType.length === contactTypes.length - 1
        ? setSelectedContactType(contactTypes)
        : selectedContactType;
      selectedMedicalSpecialties.length === medicalSpecialties.length - 1
        ? setSelectedMedicalSpecialties(medicalSpecialties)
        : selectedMedicalSpecialties;
    } else {
      if (
        selectedVisit.length > 0 &&
        selectedTypeOfEmergency.length > 0 &&
        selectedProfession.length > 0 &&
        selectedContactReason.length > 0 &&
        selectedCareType.length > 0 &&
        selectedContactType.length > 0 &&
        selectedMedicalSpecialties.length > 0
      ) {
        setOutPatientMultiSelectRequest({
          ...outPatientMultiSelectRequest,
          contactTypeIds:
            isEqual(selectedContactType, contactTypes) || selectedContactType.length === 0
              ? [-1]
              : mapSelectedValues(selectedContactType),
          typesOfVisit:
            isEqual(selectedVisit, typesOfVisits) || selectedVisit.length === 0
              ? [-1]
              : mapSelectedValues(selectedVisit),
          typesOfEmergency: isEqual(selectedTypeOfEmergency, typesOfEmergency)
            ? [-1]
            : mapSelectedValues(selectedTypeOfEmergency),
          professionIds: isEqual(selectedProfession, professions) ? [-1] : mapSelectedValues(selectedProfession),
          contactReasonGroupingIds: isEqual(selectedContactReason, contactReasons)
            ? [-1]
            : mapSelectedValues(selectedContactReason),
          careTypeIds:
            isEqual(selectedCareType, careTypes) || selectedCareType.length === 0
              ? [-1]
              : mapSelectedValues(selectedCareType),
          medicalSpecialtyIds: isEqual(selectedMedicalSpecialties, medicalSpecialties)
            ? [-1]
            : mapSelectedValues(selectedMedicalSpecialties),
          contactReasonGroupingEnabled: selectedContactReasonFilterVisibility,
          careTypeEnabled: selectedCareTypeFilterVisibility,
          professionEnabled: selectedProfessionFilterVisibility,
          contactTypeEnabled: selectedContactTypeFilterVisibility,
          newVisitEnabled: selectedVisitFilterVisibility,
          typeOfEmergencyEnabled: selectedTypeOfEmergencyFilterVisibility,
          medicalSpecialtyEnabled: selectedMedicalSpecialtyFilterVisibility,
        });
      }
    }
  }, [
    selectedVisit,
    selectedTypeOfEmergency,
    selectedProfession,
    selectedContactReason,
    selectedCareType,
    selectedContactType,
    selectedMedicalSpecialties,
  ]);

  const getDimensionsForProdPlan = () => {
    const defaultFirstOption: MultiSelectOption = {
      id: uuid(),
      value: "null",
      label: translate("all"),
    };

    const professions: MultiSelectOption[] = [];
    const contactTypes: MultiSelectOption[] = [];
    const contactReasons: MultiSelectOption[] = [];
    const careTypes: MultiSelectOption[] = [];
    const medicalSpecs: MultiSelectOption[] = [];
    const typesOfVisit: MultiSelectOption[] = [];
    const typesOfEmergency: MultiSelectOption[] = [];

    professions.length === 0 && professions.push(defaultFirstOption);
    contactTypes.length === 0 && contactTypes.push(defaultFirstOption);
    contactReasons.length === 0 && contactReasons.push(defaultFirstOption);
    careTypes.length === 0 && careTypes.push(defaultFirstOption);
    medicalSpecs.length === 0 && medicalSpecs.push(defaultFirstOption);
    typesOfVisit.length === 0 && typesOfVisit.push(defaultFirstOption);
    typesOfEmergency.length === 0 && typesOfEmergency.push(defaultFirstOption);

    outpatientDimensions.data.contactTypes.map((obj: DetailPlanFilterOptionResponse) => {
      contactTypes.push({
        id: uuid(),
        value: obj.id.toString(),
        label: obj.name,
      });
    });

    outpatientDimensions.data.professions.map((obj: DetailPlanFilterOptionResponse) => {
      professions.push({
        id: uuid(),
        value: obj.id.toString(),
        label: obj.name,
      });
    });

    outpatientDimensions.data.contactReasonGroupings.map((obj: DetailPlanFilterOptionResponse) => {
      contactReasons.push({
        id: uuid(),
        value: obj.id.toString(),
        label: obj.name,
      });
    });

    outpatientDimensions.data.careTypes.map((obj: DetailPlanFilterOptionResponse) => {
      careTypes.push({
        id: uuid(),
        value: obj.id.toString(),
        label: obj.name,
      });
    });

    outpatientDimensions.data.medicalSpecialties.map((obj: DetailPlanFilterOptionResponse) => {
      medicalSpecs.push({
        id: uuid(),
        value: obj.id.toString(),
        label: obj.name,
      });
    });

    type_of_visits.map((obj: MultiSelectOption) => {
      typesOfVisit.push({
        id: obj.id,
        value: obj.value,
        label: obj.label,
      });
    });

    type_of_emergency.map((obj: MultiSelectOption) => {
      typesOfEmergency.push({
        id: obj.id,
        value: obj.value,
        label: obj.label,
      });
    });

    setProfessions(professions);
    setContactTypes(contactTypes);
    setContactReasons(contactReasons);
    setCareTypes(careTypes);
    setMedicalSpecialties(medicalSpecs);
    setTypesOfVisit(typesOfVisit);
    setTypesOfEmergency(typesOfEmergency);

    setSelectedContactType(contactTypes);
    setSelectedContactReason(contactReasons);
    setSelectedProfession(professions);
    setSelectedCareType(careTypes);
    setSelectedMedicalSpecialties(medicalSpecs);
    setSelectedVisit(typesOfVisit);
    setSelectedTypeOfEmergency(typesOfEmergency);
  };

  const getFilterGroups = async () => {
    if (careUnitId) {
      await PlanningObjectService.getPlanningObjects([careUnitId]).then(
        (res: AxiosResponse<PlanningObjectSchema[]>) => {
          const response = res.data as PlanningObjectSchema[];
          setFilterGroupList(response);
        }
      );
    }
  };

  useEffect(() => {
    const defaultFirstOption: ISelectOption = {
      value: "null",
      label: translate("all"),
    };

    const filterGroups: ISelectOption[] = [];
    filterGroups.push(defaultFirstOption);

    filterGroupList.map((obj: PlanningObjectSchema) => {
      filterGroups.push({
        value: obj.id.toString(),
        label: translate(obj.planningObjectName),
      });
    });

    setFilterGroupDropDownList(filterGroups);
  }, [filterGroupList]);

  useEffect(() => {
    if (careUnitId !== null && careUnitTypeId != null && careUnitTypeId == 1) {
      getFilterGroups();
    }
  }, [careUnitId]);

  useEffect(() => {
    if (careUnitId !== null && careUnitTypeId != null && careUnitTypeId == 1) {
      getDimensionsForProdPlan();
    }
  }, [outpatientDimensions.data]);

  const handleTypeOfVisitSelect = (option: MultiSelectOption) => {
    let selectedTypeOfVisitsFromSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedVisit(typesOfVisits);
    } else {
      const isSelectedOptionExists = selectedVisit.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedTypeOfVisitsFromSelect = selectedVisit.filter((x) => x.value !== "null");
        selectedTypeOfVisitsFromSelect = [...selectedTypeOfVisitsFromSelect, option];
        setSelectedVisit(selectedTypeOfVisitsFromSelect);
      }
    }
  };

  const handleTypeOfVisitDeselect = (option: MultiSelectOption) => {
    if (selectedVisit.length <= typesOfVisits.length && selectedVisit.length > 1) {
      if (option.value === "null") {
        setSelectedVisit([]);
      } else {
        const isDeselectedOptionExists =
          selectedVisit.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedVisit(() => {
            return selectedVisit.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleTypeOfEmergencySelect = (option: MultiSelectOption) => {
    let selectedTypesOfEmergencyFromSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedTypeOfEmergency(typesOfEmergency);
    } else {
      const isSelectedOptionExists =
        selectedTypeOfEmergency.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedTypesOfEmergencyFromSelect = selectedTypeOfEmergency.filter((x) => x.value !== "null");
        selectedTypesOfEmergencyFromSelect = [...selectedTypesOfEmergencyFromSelect, option];
        setSelectedTypeOfEmergency(selectedTypesOfEmergencyFromSelect);
      }
    }
  };

  const handleTypeOfEmergencyDeselect = (option: MultiSelectOption) => {
    if (selectedTypeOfEmergency.length <= typesOfEmergency.length && selectedTypeOfEmergency.length > 1) {
      if (option.value === "null") {
        setSelectedTypeOfEmergency([]);
      } else {
        const isDeselectedOptionExists =
          selectedTypeOfEmergency.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedTypeOfEmergency(() => {
            return selectedTypeOfEmergency.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleContactTypeSelect = (option: MultiSelectOption) => {
    let selectedContactTypeFromSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedContactType(contactTypes);
    } else {
      const isSelectedOptionExists =
        selectedContactType.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedContactTypeFromSelect = selectedContactType.filter((x) => x.value !== "null");
        selectedContactTypeFromSelect = [...selectedContactTypeFromSelect, option];
        setSelectedContactType(selectedContactTypeFromSelect);
      }
    }
  };

  const handleContactTypeDeselect = (option: MultiSelectOption) => {
    if (selectedContactType.length <= contactTypes.length && selectedContactType.length > 1) {
      if (option.value === "null") {
        setSelectedContactType([]);
      } else {
        const isDeselectedOptionExists =
          selectedContactType.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedContactType(() => {
            return selectedContactType.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleProfessionSelect = (option: MultiSelectOption) => {
    let selectedProfessionsSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedProfession(professions);
    } else {
      const isSelectedOptionExists =
        selectedProfession.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedProfessionsSelect = selectedProfession.filter((x) => x.value !== "null");
        selectedProfessionsSelect = [...selectedProfessionsSelect, option];
        setSelectedProfession(selectedProfessionsSelect);
      }
    }
  };

  const handleProfessionDeselect = (option: MultiSelectOption) => {
    if (selectedProfession.length <= professions.length && selectedProfession.length > 1) {
      if (option.value === "null") {
        setSelectedProfession([]);
      } else {
        const isDeselectedOptionExists =
          selectedProfession.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedProfession(() => {
            return selectedProfession.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleContactReasonSelect = (option: MultiSelectOption) => {
    let selectedContactReasonSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedContactReason(contactReasons);
    } else {
      const isSelectedOptionExists =
        selectedContactReason.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedContactReasonSelect = selectedContactReason.filter((x) => x.value !== "null");
        selectedContactReasonSelect = [...selectedContactReasonSelect, option];
        setSelectedContactReason(selectedContactReasonSelect);
      }
    }
  };

  const handleContactReasonDeselect = (option: MultiSelectOption) => {
    if (selectedContactReason.length <= contactReasons.length && selectedContactReason.length > 1) {
      if (option.value === "null") {
        setSelectedContactReason([]);
      } else {
        const isDeselectedOptionExists =
          selectedContactReason.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedContactReason(() => {
            return selectedContactReason.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleCareTypeSelect = (option: MultiSelectOption) => {
    let selectedCareTypeSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedCareType(careTypes);
    } else {
      const isSelectedOptionExists = selectedCareType.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedCareTypeSelect = selectedCareType.filter((x) => x.value !== "null");
        selectedCareTypeSelect = [...selectedCareTypeSelect, option];
        setSelectedCareType(selectedCareTypeSelect);
      }
    }
  };

  const handleCareTypeDeselect = (option: MultiSelectOption) => {
    if (selectedCareType.length <= careTypes.length && selectedCareType.length > 1) {
      if (option.value === "null") {
        setSelectedCareType([]);
      } else {
        const isDeselectedOptionExists =
          selectedCareType.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedCareType(() => {
            return selectedCareType.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleMedicalSpecialtiesSelect = (option: MultiSelectOption) => {
    let selectedMedicalSpecialtiesSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedMedicalSpecialties(medicalSpecialties);
    } else {
      const isSelectedOptionExists =
        selectedMedicalSpecialties.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedMedicalSpecialtiesSelect = selectedMedicalSpecialties.filter((x) => x.value !== "null");
        selectedMedicalSpecialtiesSelect = [...selectedMedicalSpecialtiesSelect, option];
        setSelectedMedicalSpecialties(selectedMedicalSpecialtiesSelect);
      }
    }
  };

  const handleMedicalSpecialtiesDeselect = (option: MultiSelectOption) => {
    if (selectedMedicalSpecialties.length <= medicalSpecialties.length && selectedMedicalSpecialties.length > 1) {
      if (option.value === "null") {
        setSelectedMedicalSpecialties([]);
      } else {
        const isDeselectedOptionExists =
          selectedMedicalSpecialties.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedMedicalSpecialties(() => {
            return selectedMedicalSpecialties.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const setEnableFilterCount = (visibility: boolean) => {
    const currentFilters = enabledFilters;
    if (visibility) {
      setEnabledFilters(currentFilters + 1);
    } else {
      setEnabledFilters(currentFilters - 1);
    }
  };

  const toggleTypeOfVisitFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedVisitFilterVisibility;
    setSelectedVisitFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    outPatientMultiSelectRequest &&
      setOutPatientMultiSelectRequest({ ...outPatientMultiSelectRequest, newVisitEnabled: !visibility });
  };

  const toggleContactTypeFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedContactTypeFilterVisibility;
    setSelectedContactTypeFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    outPatientMultiSelectRequest &&
      setOutPatientMultiSelectRequest({ ...outPatientMultiSelectRequest, contactTypeEnabled: !visibility });
  };

  const toggleProfessionFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedProfessionFilterVisibility;
    setSelectedProfessionFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    outPatientMultiSelectRequest &&
      setOutPatientMultiSelectRequest({ ...outPatientMultiSelectRequest, professionEnabled: !visibility });
  };

  const toggleContactReasonFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedContactReasonFilterVisibility;
    setSelectedContactReasonFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    outPatientMultiSelectRequest &&
      setOutPatientMultiSelectRequest({ ...outPatientMultiSelectRequest, contactReasonGroupingEnabled: !visibility });
  };

  const toggleCareTypeFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedCareTypeFilterVisibility;
    setSelectedCareTypeFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    outPatientMultiSelectRequest &&
      setOutPatientMultiSelectRequest({ ...outPatientMultiSelectRequest, careTypeEnabled: !visibility });
  };

  const toggleTypeOfEmergencyFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedTypeOfEmergencyFilterVisibility;
    setSelectedTypeOfEmergencyFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    outPatientMultiSelectRequest &&
      setOutPatientMultiSelectRequest({ ...outPatientMultiSelectRequest, typeOfEmergencyEnabled: !visibility });
  };

  const toggleMedicalSpecialtyFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedMedicalSpecialtyFilterVisibility;
    setSelectedMedicalSpecialtyFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    outPatientMultiSelectRequest &&
      setOutPatientMultiSelectRequest({ ...outPatientMultiSelectRequest, medicalSpecialtyEnabled: !visibility });
  };

  const getFilterGroupDimensions = async (selectedFilterGroupId: number) => {
    await PlanningObjectService.getDimensionsForPlanningObject(selectedFilterGroupId).then(
      (res: AxiosResponse<PlanningObjectimensions>) => {
        const response = res.data as PlanningObjectimensions;

        const professionIds = professions.filter((r) => response.professionIds.includes(Number(r.value.toString())));
        setSelectedProfession(professionIds);

        const careTypeIds = careTypes.filter((r) => response.careTypeIds.includes(Number(r.value.toString())));
        setSelectedCareType(careTypeIds);

        const contactTypeIds = contactTypes.filter((r) => response.contactTypeIds.includes(Number(r.value.toString())));
        setSelectedContactType(contactTypeIds);

        const contactReasonGroupingIds = contactReasons.filter((r) =>
          response.contactReasonGroupingIds.includes(Number(r.value.toString()))
        );
        setSelectedContactReason(contactReasonGroupingIds);

        const isNewVisits = typesOfVisits.filter((r) => response.isNewVisits.includes(Number(r.value.toString())));
        setSelectedVisit(isNewVisits);

        const isEmergencies = typesOfEmergency.filter((r) =>
          response.isEmergencies.includes(Number(r.value.toString()))
        );
        setSelectedTypeOfEmergency(isEmergencies);

        const medicalSpecialtyIds = medicalSpecialties.filter((r) =>
          response.medicalSpecialtyIds.includes(Number(r.value.toString()))
        );
        setSelectedMedicalSpecialties(medicalSpecialtyIds);
      }
    );
  };

  const handleFilterGroupSelect = (selectedFilterGroup: ISelectOption) => {
    setSelectedFilterGroup(selectedFilterGroup);
  };

  useEffect(() => {
    if (selectedFilterGroup.value !== "null") {
      const selectedFilterGroupId = Number(selectedFilterGroup.value);
      getFilterGroupDimensions(selectedFilterGroupId);
    } else {
      setSelectedProfession(professions);
      setSelectedCareType(careTypes);
      setSelectedContactType(contactTypes);
      setSelectedContactReason(contactReasons);
      setSelectedVisit(typesOfVisits);
      setSelectedTypeOfEmergency(typesOfEmergency);
      setSelectedMedicalSpecialties(medicalSpecialties);
    }
  }, [selectedFilterGroup]);

  return (
    <>
      <div className="mr-0">
        <div className="flex w-full items-center">
          <div className="w-5/6">
            <MultiSelect
              label={translate("type_of_visit")}
              selectedOptions={selectedVisit}
              options={typesOfVisits}
              onSelectItem={handleTypeOfVisitSelect}
              onRemoveSelectItem={handleTypeOfVisitDeselect}
            />
          </div>
          <div className="w-1/6 pl-1 text-center">
            <HideToggle
              disabled={!selectedVisitFilterVisibility && enabledFilters >= 1}
              isVisible={selectedVisitFilterVisibility}
              onToggle={toggleTypeOfVisitFilterVisibilityAndFetch}
              className="xl:mt-1"
            />
          </div>
        </div>

        <div className="flex w-full items-center">
          <div className="w-5/6">
            <MultiSelect
              label={translate("contact_type")}
              selectedOptions={selectedContactType}
              options={contactTypes}
              onSelectItem={handleContactTypeSelect}
              onRemoveSelectItem={handleContactTypeDeselect}
            />
          </div>
          <div className="w-1/6 pl-1 text-center">
            <HideToggle
              disabled={!selectedContactTypeFilterVisibility && enabledFilters >= 1}
              isVisible={selectedContactTypeFilterVisibility}
              onToggle={toggleContactTypeFilterVisibilityAndFetch}
              className="xl:mt-1"
            />
          </div>
        </div>

        <div className="flex w-full items-center">
          <div className="w-5/6">
            <MultiSelect
              label={translate("profession")}
              selectedOptions={selectedProfession}
              options={professions}
              onSelectItem={handleProfessionSelect}
              onRemoveSelectItem={handleProfessionDeselect}
            />
          </div>
          <div className="w-1/6 pl-1 text-center">
            <HideToggle
              disabled={!selectedProfessionFilterVisibility && enabledFilters >= 1}
              isVisible={selectedProfessionFilterVisibility}
              onToggle={toggleProfessionFilterVisibilityAndFetch}
              className="xl:mt-1"
            />
          </div>
        </div>

        <div className="flex w-full items-center">
          <div className="w-5/6">
            <MultiSelect
              label={translate("contact_reason")}
              selectedOptions={selectedContactReason}
              options={contactReasons}
              onSelectItem={handleContactReasonSelect}
              onRemoveSelectItem={handleContactReasonDeselect}
            />
          </div>
          <div className="w-1/6 pl-1 text-center">
            <HideToggle
              disabled={!selectedContactReasonFilterVisibility && enabledFilters >= 1}
              isVisible={selectedContactReasonFilterVisibility}
              onToggle={toggleContactReasonFilterVisibilityAndFetch}
              className="xl:mt-1"
            />
          </div>
        </div>

        <>
          <div className="flex w-full items-center">
            <div className="w-5/6">
              <MultiSelect
                label={translate("care_type")}
                selectedOptions={selectedCareType}
                options={careTypes}
                onSelectItem={handleCareTypeSelect}
                onRemoveSelectItem={handleCareTypeDeselect}
              />
            </div>
            <div className="w-1/6 pl-1 text-center">
              <HideToggle
                disabled={!selectedCareTypeFilterVisibility && enabledFilters >= 1}
                isVisible={selectedCareTypeFilterVisibility}
                onToggle={toggleCareTypeFilterVisibilityAndFetch}
                className="xl:mt-1"
              />
            </div>
          </div>

          <div className="flex w-full items-center">
            <div className="w-5/6">
              <MultiSelect
                label={translate("type_of_emergency")}
                selectedOptions={selectedTypeOfEmergency}
                options={typesOfEmergency}
                onSelectItem={handleTypeOfEmergencySelect}
                onRemoveSelectItem={handleTypeOfEmergencyDeselect}
              />
            </div>
            <div className="w-1/6 pl-1 text-center">
              <HideToggle
                disabled={!selectedTypeOfEmergencyFilterVisibility && enabledFilters >= 1}
                isVisible={selectedTypeOfEmergencyFilterVisibility}
                onToggle={toggleTypeOfEmergencyFilterVisibilityAndFetch}
                className="xl:mt-1"
              />
            </div>
          </div>

          <div className="flex w-full items-center">
            <div className="w-5/6">
              <MultiSelect
                label={translate("medical_speciality")}
                selectedOptions={selectedMedicalSpecialties}
                options={medicalSpecialties}
                onSelectItem={handleMedicalSpecialtiesSelect}
                onRemoveSelectItem={handleMedicalSpecialtiesDeselect}
              />
            </div>
            <div className="w-1/6 pl-1 text-center">
              <HideToggle
                disabled={!selectedMedicalSpecialtyFilterVisibility && enabledFilters >= 1}
                isVisible={selectedMedicalSpecialtyFilterVisibility}
                onToggle={toggleMedicalSpecialtyFilterVisibilityAndFetch}
                className="xl:mt-1"
              />
            </div>
          </div>

          {physicianSubUnits.length <= 1 ? (
            <div className="mt-2 flex w-full items-center">
              <div className="w-full">
                <Select
                  placeholder={translate("patient_flow")}
                  options={filterGroupDropDownList}
                  onSelectOption={handleFilterGroupSelect}
                  selectedOption={selectedFilterGroup}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      </div>
    </>
  );
};

export default OutpatientMultiselectFilterWithToggles;
