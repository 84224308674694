import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadState } from "../../utils/storage/loadStorage";
import { CareUnitTypeEnum } from "../../utils/constants/careUnitTypes";
import { GLOBAL_FILTER_STORAGE_KEY } from "../../utils/constants/globalFilter";

export interface FilterCareUnits {
  units: number[];
  year: number;
  careUnitType: CareUnitTypeEnum;
}

export interface GlobalFilterState {
  filterYear: number;
  filterScenario: number;
  careProviderId: number | null;
  filterCareUnit: number | null;
  filterCareUnitLabel: string | null;
  filterCareUnits: FilterCareUnits;
  parentCareUnits: number[];
  childCareUnits: number[];
  filterCareUnitType: CareUnitTypeEnum | null;
  isReady: boolean;
  isLoading: boolean;
}

interface SetCareUnitPayLoad {
  filterCareUnit: number | null;
  filterCareUnits: number[];
  filterCareUnitType: CareUnitTypeEnum | null;
  filterCareUnitLabel: string | null;
  parentCareUnits: number[];
  childCareUnits: number[];
}

const getInitialState = (): GlobalFilterState => {
  const localstorageGlobalFilter = loadState<GlobalFilterState>(GLOBAL_FILTER_STORAGE_KEY);
  let state: GlobalFilterState;
  if (localstorageGlobalFilter) {
    state = { ...localstorageGlobalFilter, isLoading: true };
  } else {
    state = {
      isReady: false,
      filterCareUnits: {
        units: [],
        year: new Date().getFullYear(),
        careUnitType: CareUnitTypeEnum.INPATIENT,
      },
      filterYear: new Date().getFullYear(),
      filterScenario: 1,
      filterCareUnitType: null,
      careProviderId: null,
      filterCareUnit: null,
      filterCareUnitLabel: null,
      parentCareUnits: [],
      childCareUnits: [],
      isLoading: true,
    };

    localStorage.setItem(GLOBAL_FILTER_STORAGE_KEY, JSON.stringify(state));
  }

  return state;
};

const initialState: GlobalFilterState = getInitialState();

export const slice = createSlice({
  name: "globalFilter",
  initialState,
  reducers: {
    setGlobalFilter: (state) => {
      // When user login in state configuring
      const value = getInitialState();
      state.careProviderId = value.careProviderId;
      state.filterCareUnit = value.filterCareUnit;
      state.filterCareUnitLabel = value.filterCareUnitLabel;
      state.filterCareUnits = value.filterCareUnits;
      state.parentCareUnits = value.parentCareUnits;
      state.childCareUnits = value.childCareUnits;
      state.filterCareUnitType = value.filterCareUnitType;
      state.isReady = value.isReady;
      state.filterYear = value.filterYear;
      state.filterScenario = value.filterScenario;
      state.isLoading = value.isLoading;
    },
    setGlobalFilterIsLoadingTrue: (state) => {
      state.isLoading = true;
    },
    setGlobalFilterIsLoadingFalse: (state) => {
      state.isLoading = false;
    },
    setFilterYear: (state, action: PayloadAction<number>) => {
      state.filterYear = action.payload;
    },
    setFilterScenario: (state, action: PayloadAction<number>) => {
      state.filterScenario = action.payload;
    },
    setCareProviderId: (state, action: PayloadAction<number>) => {
      if (state.careProviderId !== action.payload) {
        state.careProviderId = action.payload;
        state.filterCareUnit = null;
        state.filterCareUnitLabel = null;
        state.filterCareUnits = {
          units: [],
          year: state.filterYear,
          careUnitType: CareUnitTypeEnum.INPATIENT,
        };
        state.filterCareUnitType = null;
        state.parentCareUnits = [];
        state.childCareUnits = [];
        state.isReady = false;
      }
    },
    setFilterCareUnit: (state, action: PayloadAction<SetCareUnitPayLoad>) => {
      state.filterCareUnit = action.payload.filterCareUnit;
      state.filterCareUnitType = action.payload.filterCareUnitType;
      state.parentCareUnits = action.payload.parentCareUnits;
      state.childCareUnits = action.payload.childCareUnits;
      state.filterCareUnitLabel = action.payload.filterCareUnitLabel;

      if (action.payload.filterCareUnits.length === 0) {
        if (action.payload.filterCareUnit) {
          state.filterCareUnits = {
            units: [action.payload.filterCareUnit],
            careUnitType: action.payload.filterCareUnitType
              ? action.payload.filterCareUnitType
              : state.filterCareUnits.careUnitType,
            year: state.filterYear,
          };
        }
      } else {
        state.filterCareUnits = {
          units: action.payload.filterCareUnits,
          year: state.filterYear,
          careUnitType: state.filterCareUnits.careUnitType,
        };
      }

      state.isReady = true;
    },
    setFilterDashboardCareUnitType: (state, action: PayloadAction<CareUnitTypeEnum>) => {
      state.filterCareUnits = { ...state.filterCareUnits, careUnitType: action.payload };

      if (action.payload === null) {
        state.isReady = false;
      } else {
        state.isReady = true;
      }
    },
    resetFilterCareUnit: (state) => {
      state.isReady = false;
      state.filterCareUnits = {
        units: [],
        year: new Date().getFullYear(),
        careUnitType: CareUnitTypeEnum.INPATIENT,
      };
      state.filterYear = new Date().getFullYear();
      state.filterScenario = 1;
      state.filterCareUnitType = null;
      state.careProviderId = null;
      state.filterCareUnit = null;
      state.filterCareUnitLabel = null;
      state.parentCareUnits = [];
      state.childCareUnits = [];
    },
  },
});

export const {
  setFilterYear,
  setFilterScenario,
  setCareProviderId,
  setFilterCareUnit,
  setFilterDashboardCareUnitType,
  resetFilterCareUnit,
  setGlobalFilterIsLoadingTrue,
  setGlobalFilterIsLoadingFalse,
  setGlobalFilter,
} = slice.actions;
export const globalFilterReducer = slice.reducer;
