import range from "lodash/range";
export const adjustColumnHeaderColour = (col: number, TH: HTMLElement) => {
  const darkBlueHeaderIndexes = range(0, 2);
  const lightBlueHeaderIndexes = range(2, 8);
  const quaterOneHeaderIndexes = range(8, 21);
  const quaterTwoHeaderIndexes = range(21, 34);
  const quaterThreeHeaderIndexes = range(34, 47);
  const quaterFourHeaderIndexes = range(47, 60);

  const lightBlueHeaders = [...lightBlueHeaderIndexes, ...quaterOneHeaderIndexes, ...quaterThreeHeaderIndexes];

  const darkBlueHeaders = [...darkBlueHeaderIndexes, ...quaterTwoHeaderIndexes, ...quaterFourHeaderIndexes];

  if (darkBlueHeaders.includes(col)) {
    TH.classList.add("dark-blue-column");
  }

  if (lightBlueHeaders.includes(col)) {
    TH.classList.add("light-blue-column");
  }

  TH.classList.add("htMiddle");
};
