import { useState } from "react";
import { ProductionVsPlanService } from "../../../../../../services";
import { AxiosResponse } from "axios";
import {
  ProductionOutcomeReponse,
  ProductionPlanPhasedResponse,
  RecoveryPlanReponse,
} from "../../../../../../interfaces/responses/productionvsanalyticsresponse";
import { OutPatientMultiSelectRequest } from "../../../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";

const useProductionVsPlanData = (
  productionPlanType: number,
  careUnitsList: number[],
  year: number,
  scenarioId: number,
  outPatientMultiSelectFilters: OutPatientMultiSelectRequest | null | undefined,
  onChartDataLoading?: (isChartDataLoading: boolean) => void
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [recoveryPlan, setRecoveryPlan] = useState<RecoveryPlanReponse[]>();
  const [areaChartData, setAreaChartData] = useState<ProductionPlanPhasedResponse>([]);
  const [columnChartData, setColumnChartData] = useState<ProductionOutcomeReponse>();

  const fetchAreaChartData = async () => {
    onChartDataLoading && onChartDataLoading(true);
    setIsLoading(true);

    await ProductionVsPlanService.getProductionVsPlanData(
      productionPlanType,
      careUnitsList,
      year,
      scenarioId,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.professionIds &&
          outPatientMultiSelectFilters.professionIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.professionIds
        : null,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.contactTypeIds &&
          outPatientMultiSelectFilters.contactTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.contactTypeIds
        : null,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.typesOfVisit &&
          outPatientMultiSelectFilters.typesOfVisit.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.typesOfVisit
        : null,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.typesOfEmergency &&
          outPatientMultiSelectFilters.typesOfEmergency.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.typesOfEmergency
        : null,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.contactReasonGroupingIds &&
          outPatientMultiSelectFilters.contactReasonGroupingIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.contactReasonGroupingIds
        : null,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.careTypeIds &&
          outPatientMultiSelectFilters.careTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.careTypeIds
        : null,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.medicalSpecialtyIds &&
          outPatientMultiSelectFilters.medicalSpecialtyIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.medicalSpecialtyIds
        : null
    )
      .then((res: AxiosResponse<ProductionPlanPhasedResponse | ProductionOutcomeReponse>[]) => {
        const productionPhasedResponse = res[0].data as ProductionPlanPhasedResponse;
        const productionPhased = [];

        for (let i = 0; i < productionPhasedResponse.length + 1; i++) {
          if (i === 0) {
            productionPhased.push(0);
          } else {
            productionPhased.push(productionPhasedResponse[i - 1]);
          }
        }

        setAreaChartData(productionPhased);
        const outcome = res[1].data as ProductionOutcomeReponse;

        const outcomeSeries = [];
        const isWithinLimitSeries = [];

        for (let i = 0; i < outcome.outcome.length + 1; i++) {
          if (i === 0) {
            outcomeSeries.push(0);
          } else {
            outcomeSeries.push(outcome.outcome[i - 1]);
          }
        }

        for (let i = 0; i < outcome.isWithinLimit.length + 1; i++) {
          if (i === 0) isWithinLimitSeries.push(false);
          else isWithinLimitSeries.push(outcome.isWithinLimit[i - 1]);
        }

        outcome.outcome = outcomeSeries;
        outcome.isWithinLimit = isWithinLimitSeries;

        setColumnChartData(outcome);
      })
      .catch((err) => {
        console.error(err);
      });

    setIsLoading(false);
    onChartDataLoading && onChartDataLoading(false);
  };

  const fetchRecoveryPlan = async () => {
    await ProductionVsPlanService.getRecoveryPlan(
      productionPlanType,
      careUnitsList,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.professionIds &&
          outPatientMultiSelectFilters.professionIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.professionIds
        : null,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.contactTypeIds &&
          outPatientMultiSelectFilters.contactTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.contactTypeIds
        : null,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.typesOfVisit &&
          outPatientMultiSelectFilters.typesOfVisit.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.typesOfVisit
        : null,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.typesOfEmergency &&
          outPatientMultiSelectFilters.typesOfEmergency.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.typesOfEmergency
        : null,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.contactReasonGroupingIds &&
          outPatientMultiSelectFilters.contactReasonGroupingIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.contactReasonGroupingIds
        : null,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.careTypeIds &&
          outPatientMultiSelectFilters.careTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.careTypeIds
        : null,
      productionPlanType === 1
        ? outPatientMultiSelectFilters?.medicalSpecialtyIds &&
          outPatientMultiSelectFilters.medicalSpecialtyIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.medicalSpecialtyIds
        : null,
      year,
      scenarioId
    )
      .then((response: AxiosResponse<RecoveryPlanReponse[]>) => {
        const recoveryPlan = response.data.map((rp) => {
          return {
            recoveryPlan: rp.recoveryPlan !== null ? Math.round(parseFloat(rp.recoveryPlan.toString())) : null,
            weekId: rp.weekId,
          };
        });

        setRecoveryPlan(recoveryPlan);
      })
      .catch((error) => {
        setRecoveryPlan([]);
        console.error(error);
      });
  };

  return {
    isLoading,
    recoveryPlan,
    areaChartData,
    columnChartData,
    fetchAreaChartData,
    fetchRecoveryPlan,
  };
};

export default useProductionVsPlanData;
