import { useEffect } from "react";

import { useState } from "react";
import { WorkShiftGroup } from "../../../../interfaces/productivity/workShifts/workShiftGroup";
import ShiftService from "../../../../services/shiftService";

const useGetWorkShiftGroups = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<WorkShiftGroup[]>([]);

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);

    await ShiftService.getWorkShiftGroups()
      .then((res) => {
        const responseData: WorkShiftGroup[] = res.data;
        setData(responseData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    data,
    fetch: fetchData,
    isLoading,
  };
};

export default useGetWorkShiftGroups;
