import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import range from "lodash/range";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { usePlanvsActualResult } from "../../Hooks/usePlanvsActualResult";
import LoadingWithGrey from "../../../../../components/loading/LoadingWithGrey";
import { OutpatientResults } from "../../../../../interfaces/production-plan/outpatient/outpatientResults";

const ResultsChartV2 = ({
  outpatientResults,
}: {
  outpatientResults: OutpatientResults;
}) => {
  const { t: translate } = useTranslation();
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const [planVsActual] = usePlanvsActualResult(outpatientResults.getLastYearOutpatientResuls, outpatientResults.incomingVisits);
  const [isChartLoading, setIsChartLoading] = useState(false);
  const chartData = [
    {
      name: translate("no_of_visits_plan"),
      data: outpatientResults.incomingVisits,
      color: "#4265A2",
    },
    {
      name: translate("queue_reduction_plan"),
      data: outpatientResults.queueCuttingVisits,
      color: {
        pattern: {
          backgroundColor: "#7EA4CF",
          path: {
            d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
            stroke: "white",
          },
          width: 10,
          height: 10,
        },
      },
    },
  ];

  useEffect(() => {
    setIsChartLoading(!outpatientResults.isDataLoaded);
  }, [outpatientResults]);

  const getRoundedValue = (value: number | undefined) =>
    value === undefined ? 0 : isNaN(value) ? 0 : Math.round(value);

  const options = {
    chart: {
      style: {
        fontFamily: "Open Sans",
      },
      backgroundColor: "transparent",
    },

    title: {
      text: undefined,
    },

    xAxis: {
      categories: range(1, 53),

      title: {
        text: translate("year") + " " + year + ", " + translate("week"),
        style: {
          fontSize: 16,
        },
      },

      labels: {
        // step: 1, // - Show all the labels in the x axis,
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      // Primary yAxis
      reversedStacks: false,
      title: {
        text: undefined,
      },

      labels: {
        style: {
          fontSize: 16,
        },
      },
    },
    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: 16,
      },
      style: {
        fontSize: 16,
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        marker: {
          enabled: false,
        },
        borderWidth: 0,
      },
    },
    tooltip: {
      pointFormatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const x = this.x ?? 0;
        if (this.series.name === translate("carried_out_visits")) {
          const actual = getRoundedValue(outpatientResults.getLastYearOutpatientResuls[Number(x)]);
          const planedResultForColumn = getRoundedValue(chartData[0]?.data[Number(x)]);
          const queueCuttingPlanForColumn = getRoundedValue(chartData[1]?.data[Number(x)]);
          const plan = planedResultForColumn + queueCuttingPlanForColumn;
          const plannedVsActualDifferenceForColumn = getRoundedValue(planVsActual[Number(x)]);
          const difference = plannedVsActualDifferenceForColumn + queueCuttingPlanForColumn;
          const differenceWithPlusAndMin = difference > 0 ? " +" + difference.toString() : "" + difference.toString();

          return (
            translate("contacts_plan") +
            " : " +
            plan +
            "</br>" +
            translate("contacts_actual") +
            " : " +
            actual +
            "</br>" +
            translate("contacts_difference") +
            " : " +
            differenceWithPlusAndMin
          );
        } else {
          return this.series.name + ":" + this.y + "</br>";
        }
      },
    },

    series: [
      {
        data: chartData[0]?.data,
        type: "column",
        color: chartData[0]?.color,
        name: chartData[0]?.name,
      },
      {
        data: chartData[1]?.data,
        type: "column",
        color: chartData[1]?.color,
        name: chartData[1]?.name,
      },
      {
        data: outpatientResults.getLastYearOutpatientResuls,
        type: "line",
        color: "#AD1E23",
        name: translate("carried_out_visits"),
        tooltip: {
          valueDecimals: 0,
        },
      },
    ],
  };

  return (
    <div className="h-full">
      {isChartLoading ? (
        <div className="flex h-full items-center justify-center">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      ) : (
        <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default ResultsChartV2;
