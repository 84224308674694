import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { competence_shares, competence_shares_colours, hoursOfTheDay } from "../../../../utils/highcharts/capacity/constants";
import { MultiSelectOption } from "../../../../interfaces";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { PlannedCompPerHourResponse, PlannedCompetenceDataItem } from "../../../../interfaces/capacity";
import { CapacityService } from "../../../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import LoadingWithGrey from "../../../../components/loading/LoadingWithGrey";

const PlannedCompetenceChart = ({
 selectedWeekDaySelectedOptions,
}: {
    selectedWeekDaySelectedOptions: MultiSelectOption[];
}) => {
    const [competenceBarChartSeries, setCompetenceBarChartSeries] = useState([]);
    const { t: translate } = useTranslation();
    const [isChartLoading, setIsChartLoading] = useState<boolean>(false);
    const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
    const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
    
    const competenceBarChartCategories = [
        {
          name: translate("Mon"),
          categories: hoursOfTheDay,
        },
        {
          name: translate("Tue"),
          categories: hoursOfTheDay,
        },
        {
          name: translate("Wed"),
          categories: hoursOfTheDay,
        },
        {
          name: translate("Thu"),
          categories: hoursOfTheDay,
        },
        {
          name: translate("Fri"),
          categories: hoursOfTheDay,
        },
        {
          name: translate("Sat"),
          categories: hoursOfTheDay,
        },
        {
          name: translate("Sun"),
          categories: hoursOfTheDay,
        },
    ];
    
    useEffect(() => {
        if (careUnitId !== null && selectedWeekDaySelectedOptions.length !== 0) {
          setIsChartLoading(true);
    
          const selectedWeekDays = selectedWeekDaySelectedOptions.map((day) => {
            return Number(day.value);
          });
    
          CapacityService.getPlannedCompPerHour(selectedWeekDays, careUnitId, scenario).then((res) => {
            const data = res.data.map((competence: PlannedCompPerHourResponse) => {
              return {
                name: competence.competenceName,
                data: competence.weeklyWorkingHours,
                color: competence_shares_colours[competence.competenceName],
              };
            });
    
            // Sort the array based on competence
            const sortedData = data.sort(
              (a: PlannedCompetenceDataItem, b: PlannedCompetenceDataItem) =>
                competence_shares.indexOf(a.name) - competence_shares.indexOf(b.name)
            );
            setCompetenceBarChartSeries(sortedData);
          });
          setIsChartLoading(false);
        }
    }, [careUnitId, selectedWeekDaySelectedOptions]);
    
    const options = {
        chart: {
          type: "column",
          style: {
            fontFamily: "Open Sans",
          },
          backgroundColor: "transparent",
        },
    
        title: {
          text: undefined,
        },
    
        xAxis: {
          categories: selectedWeekDaySelectedOptions.map((selection: any) => {
            return competenceBarChartCategories[selection.value - 1];
          }),
    
          labels: {
            style: {
              fontSize: 16,
            },
          },
        },
    
        yAxis: {
          reversedStacks: false,
          title: {
            text: undefined,
          },
    
          labels: {
            style: {
              fontSize: 16,
            },
          },
        },
        legend: {
          verticalAlign: "top",
          itemStyle: {
            fontSize: 16,
          },
          style: {
            fontSize: 16,
          },
        },
        plotOptions: {
          series: {
            stacking: "normal",
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 0,
          },
        },
    
        series: competenceBarChartSeries,
      };

      return (
        <>
            {!isChartLoading ? (
                <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />
            )
            :
            (
                <div className="flex h-full items-center justify-center">
                  <LoadingWithGrey sizeInPixel={40} />
                </div>
            )}
        </>
      )
};

export default PlannedCompetenceChart;