import range from "lodash/range";

export const adjustCompensationColumnHeaderColour = (col: number, TH: HTMLElement) => {
  const darkBlueHeaderIndexes = range(0, 3);
  const lightBlueHeaderIndexes = range(3, 11);

  const lightBlueHeaders = [...lightBlueHeaderIndexes];

  const darkBlueHeaders = [...darkBlueHeaderIndexes];

  const columnsWithFilters = [0, 1, 2];

  if (darkBlueHeaders.includes(col)) {
    TH.classList.add("dark-blue-column");
  }

  if (lightBlueHeaders.includes(col)) {
    TH.classList.add("light-blue-column");
  }

  if (!columnsWithFilters.includes(col)) {
    TH.classList.add("remove-filter");
  }

  TH.classList.add("htMiddle");
};
