import React from "react";
import { registerAllModules } from "handsontable/registry";

import "handsontable/dist/handsontable.full.min.css";
import { registerSvLang, regitserHandsontableCustomCellTypes } from "./utils/handsontable-configuration";
import { RouterProvider } from "react-router-dom";
import router from "./routes/router";

regitserHandsontableCustomCellTypes.registerKeyValueDropdownCellType();
regitserHandsontableCustomCellTypes.registerMultiSelectDropdownCellType();
registerAllModules();
registerSvLang();

function App() {
  return <RouterProvider router={router} />;
}

export default App;
