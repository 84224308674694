import React from "react";
import { useTranslation } from "react-i18next";
import { WaterfallKpi } from "../../../interfaces/productivity";
import "./productivity-kpis.css";
import { InpatientProductivityKps } from "../../../interfaces/productivity/waterfall-kpi";
import { useNumberFormat } from "../../../hooks/use-number-format";

interface IProps {
  outpatientKpis: WaterfallKpi[];
  inpatientKpis: InpatientProductivityKps;
  careUnitType: number;
}

const ProductivityKpis: React.FC<IProps> = ({ outpatientKpis, inpatientKpis, careUnitType }) => {
  const { t: translate, i18n } = useTranslation();
  const { localize, round } = useNumberFormat();

  return (
    <>
      {careUnitType === 1
        ? outpatientKpis &&
          outpatientKpis.length > 0 && (
            <div className="flex h-full w-full justify-around text-center">
              <div className="bottomarea__text--container">
                <h1 className="bottomarea__text--title">{translate(outpatientKpis[0].kpiName)}</h1>
                <p className={`bottomarea__text--value`}>
                  {localize(round(outpatientKpis[0].kpiValue || 0, 1), i18n.language)}
                </p>
              </div>

              <div className="bottomarea__text--container">
                <h1 className="bottomarea__text--title">{translate(outpatientKpis[1].kpiName)}</h1>
                <p className={`bottomarea__text--value`}>
                  {localize(round(outpatientKpis[1].kpiValue || 0, 1), i18n.language)}
                </p>
              </div>
              <div className="bottomarea__text--container">
                <h1 className="bottomarea__text--title">{translate(outpatientKpis[2].kpiName)}</h1>
                <p className={`bottomarea__text--value`}>{Math.round(outpatientKpis[2].kpiValue * 100)}%</p>
              </div>
            </div>
          )
        : inpatientKpis && (
            <div className="flex h-full w-full justify-around text-center">
              <div className="bottomarea__text--container">
                <h1 className="bottomarea__text--title">{translate("planned_productivity")}</h1>
                <p className={`bottomarea__text--value`}>
                  {localize(round(inpatientKpis.plannedProductivity, 1), i18n.language)}
                </p>
              </div>

              {inpatientKpis.actualProductivity && (
                <div className="bottomarea__text--container">
                  <h1 className="bottomarea__text--title">{translate("actual_producitivity")}</h1>
                  <p className={`bottomarea__text--value`}>
                    {localize(round(inpatientKpis.actualProductivity, 1), i18n.language)}
                  </p>
                </div>
              )}

              <div className="bottomarea__text--container">
                <h1 className="bottomarea__text--title">{translate("patient_per_rn")}</h1>
                <p className={`bottomarea__text--value`}>
                  {localize(round(inpatientKpis.patientsPerRn || 0, 1), i18n.language)}
                </p>
              </div>

              <div className="bottomarea__text--container">
                <h1 className="bottomarea__text--title">{translate("cost_per_care_day")}</h1>
                <p className={`bottomarea__text--value`}>{inpatientKpis.costPerCareDay}</p>
              </div>
            </div>
          )}
      {}
    </>
  );
};

export default ProductivityKpis;
