import { EmploymentType } from "../../../../interfaces/employment-type/employment-type";
import FormalCompetence from "../../../../interfaces/competence/FormalCompetence";
import { HandsOnTableColumn } from "../../../../interfaces/handsOnTable/handsOnTableColumn";
import {
  nullStringValidator,
  numberBetWeenZeroAndOneInAvailabilityValidator,
  numberBetweenZeroandFourValidator,
  numberBetweenZeroandFourtyValidator,
  numberBetweenZeroandThreeValidator,
} from "../../../../utils/handsontable-configuration/validators/validators";
import useRenderers from "../../../../common/hooks/handsontable/useRenderers";
interface StaffColumnsReturn {
  getColumns: (
    culture: string,
    formalCompetences: FormalCompetence[],
    employmentTypes: EmploymentType[]
  ) => HandsOnTableColumn[];
}

const useStaffColumnsConfigurator = (culture: string): StaffColumnsReturn => {
  const render = useRenderers();

  const getAvailabilityWeekColumns = (): HandsOnTableColumn[] => {
    const cols: HandsOnTableColumn[] = [];
    for (let i = 1; i <= 52; i++) {
      const col: HandsOnTableColumn = {
        meta: {
          data: `staffAvailability${i}`,
          validator: numberBetWeenZeroAndOneInAvailabilityValidator,
          type: "numeric",
          numericFormat: {
            pattern: "0,0",
            culture,
          },
          renderer: render.yellowBackGround,
        },
        headerTitle: `${i}`,
        width: 25,
      };

      cols.push(col);
    }

    return cols;
  };

  const addWorkCompetenceAllocations = (
    culture: string,
    formalCompetences: FormalCompetence[]
  ): HandsOnTableColumn[] => {
    const cols: HandsOnTableColumn[] = [];

    formalCompetences = formalCompetences.sort((a, b) => a.sortOrder - b.sortOrder);

    formalCompetences.forEach((formalCompetence) => {
      if (formalCompetence && formalCompetence.workCompetences) {
        formalCompetence.workCompetences.forEach((workCompetence) => {
          cols.push({
            meta: {
              data: `${workCompetence.id}`,
              renderer: render.percentage,
            },
            headerColor: formalCompetence.color,
            headerTitle: `${
              workCompetence.shortName ? workCompetence.shortName.replace("<", "").replace(">", "") : null
            }`,
            isFilterable: true,
            width: 90,
          });
        });
      }
    });

    return cols;
  };

  const getColumns = (
    culture: string,
    formalCompetences: FormalCompetence[],
    employmentTypes: EmploymentType[]
  ): HandsOnTableColumn[] => {
    const cols: HandsOnTableColumn[] = [
      {
        meta: {
          data: "staffFirstName",
          validator: nullStringValidator,
        },
        headerTitle: "FirstName",
        width: 150,
      },
      {
        meta: {
          data: "staffLastName",
        },
        headerTitle: "LastName",
        width: 150,
      },

      {
        meta: {
          allowEmpty: false,
          data: "formalCompetenceShortName",
          source: formalCompetences.map((r) => r.shortName),
          type: "dropdown",
        },
        headerTitle: "Competence",
        isFilterable: true,
        width: 200,
      },
      {
        meta: {
          allowEmpty: false,
          data: "employmentTypeName",
          source: employmentTypes.map((r) => r.name),
          type: "dropdown",
        },
        headerTitle: "EmploymentType",
        isFilterable: true,
        width: 150,
      },
      {
        meta: {
          data: "workingHoursPerWeek",
          validator: numberBetweenZeroandFourtyValidator,
          type: "numeric",
          numericFormat: {
            pattern: "0,0.00",
            culture,
          },
        },
        headerTitle: "WorkingHoursPerWeek",
        width: 200,
      },

      {
        meta: {
          data: "employmentRate",
          validator: numberBetweenZeroandThreeValidator,
          type: "numeric",
          numericFormat: {
            pattern: "0,0.00",
            culture,
          },
          allowEmpty: false,
        },
        headerTitle: "EmploymentRate",
        width: 150,
      },

      {
        meta: {
          data: "weeksendsPerWeek",
          validator: numberBetweenZeroandFourValidator,
          type: "numeric",
          numericFormat: {
            pattern: "0,0.00",
            culture,
          },
        },
        headerTitle: "WeekendsPerWeek",
        width: 170,
      },

      {
        meta: {
          data: "comment",
        },
        headerTitle: "Comment",
        width: 200,
      },
    ];

    const availabilityWeekColumns = getAvailabilityWeekColumns();

    cols.push(...availabilityWeekColumns);

    const workCompetenceAllocations = addWorkCompetenceAllocations(culture, formalCompetences);

    cols.push(...workCompetenceAllocations);

    return cols;
  };

  return { getColumns };
};

export default useStaffColumnsConfigurator;
