import { useState, useEffect } from "react";

const useGetFourWeekRangeDates = (year: number) => {
  const currentDate: Date = new Date();
  const [formattedDates, setFormattedDates] = useState<string[]>([]);

  useEffect(() => {
    const generateDates = (): string[] => {
      const dateArray: string[] = [];

      const adjustedCurrentDate = new Date(currentDate);
      adjustedCurrentDate.setFullYear(year);

      const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const isWithinPassedYear = (date: Date): boolean => {
        return date.getFullYear() === year;
      };

      const generateFullWeek = (startDate: Date): string[] => {
        const weekArray: string[] = [];
        for (let i = 0; i < 7; i++) {
          const weekDate = new Date(startDate);
          weekDate.setDate(startDate.getDate() + i);

          if (!isWithinPassedYear(weekDate)) {
            return [];
          }

          weekArray.push(formatDate(weekDate));
        }
        return weekArray;
      };

      // Get the Monday of the current week using the adjusted date with the passed year
      let dayOfWeek = adjustedCurrentDate.getDay();
      if (dayOfWeek === 0) dayOfWeek = 7;
      const mondayOfCurrentWeek = new Date(adjustedCurrentDate);
      mondayOfCurrentWeek.setDate(adjustedCurrentDate.getDate() - (dayOfWeek - 1));

      // Generate past 4 full weeks
      for (let i = 4; i > 0; i--) {
        const pastMonday = new Date(mondayOfCurrentWeek);
        pastMonday.setDate(mondayOfCurrentWeek.getDate() - i * 7);
        const pastWeek = generateFullWeek(pastMonday);
        if (pastWeek.length > 0) {
          dateArray.push(...pastWeek);
        }
      }

      // Generate the current full week
      const currentWeek = generateFullWeek(mondayOfCurrentWeek);
      if (currentWeek.length > 0) {
        dateArray.push(...currentWeek);
      }

      // Generate future 3 full weeks
      for (let i = 1; i <= 3; i++) {
        const futureMonday = new Date(mondayOfCurrentWeek);
        futureMonday.setDate(mondayOfCurrentWeek.getDate() + i * 7);
        const futureWeek = generateFullWeek(futureMonday);
        if (futureWeek.length > 0) {
          dateArray.push(...futureWeek);
        }
      }

      return dateArray;
    };

    setFormattedDates(generateDates());
  }, [year]);

  return formattedDates;
};

export default useGetFourWeekRangeDates;
