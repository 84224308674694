import React from "react";
import { OutPatientMultiSelectRequest } from "../../../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";
import { OutpatientHistoricalDataService } from "../../../../../../services";
import { OutpatientWeeklyStatRequest } from "../../../../../../interfaces/production-plan/outpatient/outpatientWeeklyStatRequest";
import { AxiosResponse } from "axios";
import { OutpatientWeeklyStat } from "../../../../../../interfaces/production-plan/outpatient/outPatientWeeklyStat";

const useGetOutpatientWeeklyData = (
  careUnitIds: number[],
  year: number,
  scenario: number,
  outPatientMultiSelectRequest: OutPatientMultiSelectRequest
) => {
  const [outpatientWeeklyStat, setOutpatientWeeklyStat] = React.useState<OutpatientWeeklyStat[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const fetchData = async () => {
    if (outPatientMultiSelectRequest) {
      setIsLoading(true);

      const payload: OutpatientWeeklyStatRequest = {
        year,
        scenario,
        careUnitIds: careUnitIds,
        professionIds:
          outPatientMultiSelectRequest?.professionIds.findIndex((r) => r === -1) > -1
            ? []
            : outPatientMultiSelectRequest?.professionIds,
        contactTypeIds:
          outPatientMultiSelectRequest?.contactTypeIds.findIndex((r) => r === -1) > -1
            ? []
            : outPatientMultiSelectRequest?.contactTypeIds,
        typesOfVisit:
          outPatientMultiSelectRequest?.typesOfVisit.findIndex((r) => r === -1) > -1
            ? []
            : outPatientMultiSelectRequest?.typesOfVisit,
        typesOfEmergency:
          outPatientMultiSelectRequest?.typesOfEmergency.findIndex((r) => r === -1) > -1
            ? []
            : outPatientMultiSelectRequest?.typesOfEmergency,
        contactReasonGroupingIds:
          outPatientMultiSelectRequest?.contactReasonGroupingIds.findIndex((r) => r === -1) > -1
            ? []
            : outPatientMultiSelectRequest?.contactReasonGroupingIds,
        careTypeIds:
          outPatientMultiSelectRequest?.careTypeIds.findIndex((r) => r === -1) > -1
            ? []
            : outPatientMultiSelectRequest?.careTypeIds,
        medicalSpecialtyIds:
          outPatientMultiSelectRequest?.medicalSpecialtyIds.findIndex((r) => r === -1) > -1
            ? []
            : outPatientMultiSelectRequest?.medicalSpecialtyIds,
      };

      await OutpatientHistoricalDataService.getOutpatientWeeklyStats(payload)
        .then((res: AxiosResponse<OutpatientWeeklyStat[]>) => {
          setOutpatientWeeklyStat(res.data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return { fetchData, isLoading, outpatientWeeklyStat };
};

export default useGetOutpatientWeeklyData;
