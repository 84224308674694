import { useEffect, useState } from "react";
import { PlanningObjectSchema } from "../../../../../interfaces/planning-object/planningObjectSchema";
import PlanningObjectService from "../../../../../services/planningObjectService";

interface ReturnGetPlanningObjectsData<T> {
  data: T;
  fetch(): void;
}

const usePlanningObjects = (careUnitIds: number[]): ReturnGetPlanningObjectsData<PlanningObjectSchema[]> => {
  const [filterGroups, setFilterGroups] = useState<PlanningObjectSchema[]>([]);

  const fetchData = async (): Promise<void> => {
    await PlanningObjectService.getPlanningObjects(careUnitIds).then((res) => {
      const responseData: PlanningObjectSchema[] = res?.data;

      setFilterGroups(responseData);
    });
  };

  useEffect(() => {
    if (careUnitIds.length > 0) {
      fetchData();
    }
  }, [careUnitIds]);

  return { data: filterGroups, fetch: fetchData };
};

export default usePlanningObjects;
