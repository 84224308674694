import { HandsOnTableColumn } from "../../../../../../interfaces/handsOnTable/handsOnTableColumn";

const useOutcomeVsPlanTableColumns = () => {
  const colWidth = 50;

  const cols: HandsOnTableColumn[] = [
    {
      meta: {
        data: "name",
        type: "text",
      },
      headerTitle: "",
      width: 150,
    },
    {
      meta: {
        data: "0",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "1",
      width: colWidth,
    },
    {
      meta: {
        data: "1",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "2",
      width: colWidth,
    },
    {
      meta: {
        data: "2",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "3",
      width: colWidth,
    },
    {
      meta: {
        data: "3",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "4",
      width: colWidth,
    },
    {
      meta: {
        data: "4",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "5",
      width: colWidth,
    },
    {
      meta: {
        data: "5",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "6",
      width: colWidth,
    },
    {
      meta: {
        data: "6",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "7",
      width: colWidth,
    },
    {
      meta: {
        data: "7",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "8",
      width: colWidth,
    },
    {
      meta: {
        data: "8",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "9",
      width: colWidth,
    },
    {
      meta: {
        data: "9",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "10",
      width: colWidth,
    },
    {
      meta: {
        data: "10",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "11",
      width: colWidth,
    },
    {
      meta: {
        data: "11",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "12",
      width: colWidth,
    },
    {
      meta: {
        data: "12",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "13",
      width: colWidth,
    },
    {
      meta: {
        data: "13",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "14",
      width: colWidth,
    },
    {
      meta: {
        data: "14",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "15",
      width: colWidth,
    },
    {
      meta: {
        data: "15",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "16",
      width: colWidth,
    },
    {
      meta: {
        data: "16",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "17",
      width: colWidth,
    },
    {
      meta: {
        data: "17",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "18",
      width: colWidth,
    },
    {
      meta: {
        data: "18",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "19",
      width: colWidth,
    },
    {
      meta: {
        data: "19",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "20",
      width: colWidth,
    },
    {
      meta: {
        data: "20",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "21",
      width: colWidth,
    },
    {
      meta: {
        data: "21",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "22",
      width: colWidth,
    },
    {
      meta: {
        data: "22",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "23",
      width: colWidth,
    },
    {
      meta: {
        data: "23",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "24",
      width: colWidth,
    },
    {
      meta: {
        data: "24",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "25",
      width: colWidth,
    },
    {
      meta: {
        data: "25",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "26",
      width: colWidth,
    },
    {
      meta: {
        data: "26",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "27",
      width: colWidth,
    },
    {
      meta: {
        data: "27",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "28",
      width: colWidth,
    },
    {
      meta: {
        data: "28",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "29",
      width: colWidth,
    },
    {
      meta: {
        data: "29",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "30",
      width: colWidth,
    },
    {
      meta: {
        data: "30",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "31",
      width: colWidth,
    },
    {
      meta: {
        data: "31",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "32",
      width: colWidth,
    },
    {
      meta: {
        data: "32",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "33",
      width: colWidth,
    },
    {
      meta: {
        data: "33",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "34",
      width: colWidth,
    },
    {
      meta: {
        data: "34",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "35",
      width: colWidth,
    },
    {
      meta: {
        data: "35",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "36",
      width: colWidth,
    },
    {
      meta: {
        data: "36",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "37",
      width: colWidth,
    },
    {
      meta: {
        data: "37",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "38",
      width: colWidth,
    },
    {
      meta: {
        data: "38",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "39",
      width: colWidth,
    },
    {
      meta: {
        data: "39",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "40",
      width: colWidth,
    },
    {
      meta: {
        data: "40",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "41",
      width: colWidth,
    },
    {
      meta: {
        data: "41",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "42",
      width: colWidth,
    },
    {
      meta: {
        data: "42",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "43",
      width: colWidth,
    },
    {
      meta: {
        data: "43",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "44",
      width: colWidth,
    },
    {
      meta: {
        data: "44",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "45",
      width: colWidth,
    },
    {
      meta: {
        data: "45",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "46",
      width: colWidth,
    },
    {
      meta: {
        data: "46",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "47",
      width: colWidth,
    },
    {
      meta: {
        data: "47",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "48",
      width: colWidth,
    },
    {
      meta: {
        data: "48",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "49",
      width: colWidth,
    },
    {
      meta: {
        data: "49",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "50",
      width: colWidth,
    },
    {
      meta: {
        data: "50",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "51",
      width: colWidth,
    },
    {
      meta: {
        data: "51",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 0,
          },
        },
      },
      headerTitle: "52",
      width: colWidth,
    },
  ];

  return cols;
};

export default useOutcomeVsPlanTableColumns;
