import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import clsx from "clsx";

import { MultiSelectOption } from "../../interfaces";
import { MultiSelectProps } from "../../interfaces/props";
import Checkbox from "../Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import Truncate from "../Truncate/Truncate";
import Icon from "../icons/Icons";

const MultiSelect = ({
  label,
  options,
  selectedOptions,
  onSelectItem,
  onRemoveSelectItem,
  maxHeight,
  isSmallFont,
}: MultiSelectProps) => {
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isLargeDropDown, setIsLargeDropDown] = useState(false);

  const { t: translate } = useTranslation();

  useEffect(() => {
    options.forEach((option) => {
      if (option.label.length > 30) {
        setIsLargeDropDown(true);
      }
    });
  }, [options]);

  const handleOptionSelect = (option: MultiSelectOption) => {
    if (selectedOptions.includes(option)) {
      deselectItem(option);
    } else {
      selectItem(option);
    }
  };

  const selectItem = (option: MultiSelectOption) => {
    onSelectItem(option);
  };

  const deselectItem = (optionToDeselect: MultiSelectOption) => {
    onRemoveSelectItem(optionToDeselect);
  };

  const toggleSelect = () => {
    if (isSelectOpen) {
      setIsSelectOpen(false);
    } else {
      setIsSelectOpen(true);
    }
  };

  const getSelectionText = (): string => {
    const nullOption = selectedOptions.find((x) => x.value === "null");

    if (nullOption) {
      return nullOption.label;
    }

    if (options.length === selectedOptions.length) {
      return translate("all");
    }

    return selectedOptions.map((r) => r.label).join(", ");
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsSelectOpen(false);
      }}
    >
      <div className="relative flex h-full w-full flex-col items-center py-2">
        <div
          className="flex w-full rounded-[0.438rem] border border-[#ADADAD] hover:cursor-pointer hover:bg-[#EDEDED] lg:p-[0.20rem] lg:!py-0.5 lg:px-4 lg:!pl-4  xl:!p-1.5 xl:!px-3 xl:!pl-4"
          onClick={toggleSelect}
        >
          <div className="flex w-11/12 flex-col">
            <div className={`text-[#747474] ${selectedOptions.length === 0 ? "p-1-v-1" : "p-3-v-1"}`}>{label}</div>
            <div className="multiselect-selected-options p-2-v-1 flex text-xs font-normal leading-none">
              <Truncate>{getSelectionText()}</Truncate>
            </div>
          </div>
          <div className="-mr-1.5 flex w-8 items-center border-gray-200 py-1 pl-2 pr-1 text-gray-300">
            <button className="h-6 w-6 cursor-pointer text-gray-600 outline-none focus:outline-none">
              {isSelectOpen ? <Icon variant="arrowdown" /> : <Icon variant="arrowup" />}
            </button>
          </div>
        </div>
        {isSelectOpen && (
          <div
            className={clsx(
              "mult-select-z-index max-h-select absolute right-0 top-full z-40  overflow-y-auto rounded bg-white shadow",
              { "min-w-max": isLargeDropDown, "w-full": !isLargeDropDown }
            )}
          >
            <div
              className={clsx("flex flex-col", {
                [`overscroll-y-auto ${maxHeight}`]: maxHeight,
              })}
            >
              {options.map((option, i) => (
                <div
                  onClick={() => handleOptionSelect(option)}
                  key={i}
                  className={`cursor-pointer border-b border-gray-100 hover:bg-[#EDEDED] ${
                    i === 0 ? "rounded-t" : i === options.length - 1 && "rounded-b"
                  }`}
                >
                  <div className=" flex items-center border-l-2 border-transparent p-2">
                    <div
                      className={clsx("mx-2 w-11/12", {
                        "text-sm": isSmallFont,
                      })}
                    >
                      {option.label}
                    </div>

                    <div className="1/12 mx-2 flex items-center justify-end">
                      <Checkbox checked={selectedOptions.includes(option)} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default MultiSelect;
