import { ProductionVsAnalyticsBody } from "../interfaces/requests";
import HttpCommonService from "./httpCommonService";

const ProductionVsPlanService = {
  getProductionVsPlanData: async (
    productionPlanType: number | null,
    careUnits: number[],
    year: number | null,
    scenarioId: number | null,
    professionIds: number[] | null | undefined,
    contactTypeIds: number[] | null | undefined,
    typesOfVisit: number[] | null | undefined,
    typesOfEmergency: number[] | null | undefined,
    contactReasonGroupingIds: number[] | null | undefined,
    careTypeIds: number[] | null | undefined,
    medicalSpecialityIds: number[] | null | undefined
  ) => {
    const paths = [`api/results/productionplanphased`, `api/results/productionoutcome`];

    const body = {
      productionPlanType,
      careUnitList: careUnits,
      professionIds: professionIds,
      contactTypeIds: contactTypeIds,
      typesOfVisit: typesOfVisit,
      typesOfEmergency: typesOfEmergency,
      contactReasonGroupingIds: contactReasonGroupingIds,
      careTypeIds: careTypeIds,
      medicalSpecialityIds: medicalSpecialityIds,
      year: year,
      scenarioId: scenarioId,
    };

    const bodies: ProductionVsAnalyticsBody[] = [body, body];

    return await HttpCommonService.postParallelAsync<ProductionVsAnalyticsBody>(paths, bodies)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getProductionYtdData: async (
    productionPlanType: number,
    careUnits: number[],
    professionIds: number[] | null,
    contactTypeIds: number[] | null,
    typesOfVisit: number[] | null,
    typesOfEmergency: number[] | null,
    contactReasonGroupingIds: number[] | null,
    careTypeIds: number[] | null,
    medicalSpecialityIds: number[] | null,
    year: number | null,
    scenarioId: number
  ) => {
    const body = {
      productionPlanType,
      careUnitList: careUnits,
      professionIds: professionIds,
      contactTypeIds: contactTypeIds,
      typesOfVisit: typesOfVisit,
      typesOfEmergency: typesOfEmergency,
      contactReasonGroupingIds: contactReasonGroupingIds,
      careTypeIds: careTypeIds,
      medicalSpecialityIds: medicalSpecialityIds,
      year: year,
      scenarioId: scenarioId,
    };

    return await HttpCommonService.postAsync(`api/results/productionytd`, body)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getRecoveryPlan: async (
    productionPlanType: number,
    careUnits: number[],
    professionIds: number[] | null | undefined,
    contactTypeIds: number[] | null | undefined,
    typesOfVisit: number[] | null | undefined,
    typesOfEmergency: number[] | null | undefined,
    contactReasonGroupingIds: number[] | null | undefined,
    careTypeIds: number[] | null | undefined,
    medicalSpecialityIds: number[] | null | undefined,
    year: number | null,
    scenarioId: number
  ) => {
    const body = {
      careUnitList: careUnits,
      productionPlanType,
      year: year,
      scenarioId: scenarioId,
      professionIds: professionIds,
      contactTypeIds: contactTypeIds,
      typesOfVisit: typesOfVisit,
      typesOfEmergency: typesOfEmergency,
      contactReasonGroupingIds: contactReasonGroupingIds,
      careTypeIds: careTypeIds,
      medicalSpecialityIds: medicalSpecialityIds,
    };

    return await HttpCommonService.postAsync(`api/results/recovery-plan`, body)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getProdVsPlanData: async (
    careUnitIds: number[],
    year: number | null,
    scenarioId: number,
    careUnitTypeId: number,
    typeOfTimePeriod: number,
    professionIds: number[] | null | undefined,
    contactTypeIds: number[] | null | undefined,
    typesOfVisit: number[] | null | undefined,
    typesOfEmergency: number[] | null | undefined,
    contactReasonGroupingIds: number[] | null | undefined,
    careTypeIds: number[] | null | undefined,
    medicalSpecialtyIds: number[] | null | undefined
  ) => {
    const body = {
      careUnitIds: careUnitIds,
      careUnitTypeId: careUnitTypeId,
      year: year,
      scenarioId: scenarioId,
      typeOfTimePeriod: typeOfTimePeriod,
      professionIds: professionIds,
      contactTypeIds: contactTypeIds,
      typesOfVisit: typesOfVisit,
      typesOfEmergency: typesOfEmergency,
      contactReasonGroupingIds: contactReasonGroupingIds,
      careTypeIds: careTypeIds,
      medicalSpecialtyIds: medicalSpecialtyIds,
    };

    return await HttpCommonService.postAsync(`api/actualvsplan`, body)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default ProductionVsPlanService;
