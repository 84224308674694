import { HandsOnTableColumn } from "../../../../interfaces/handsOnTable/handsOnTableColumn";
import {
  numberBetWeenZeroAndOneValidator,
  positiveNumberValidator,
  timeCellValidator,
} from "../../../../utils/handsontable-configuration";
import FormalCompetence from "../../../../interfaces/competence/FormalCompetence";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import { WorkShiftGroup } from "../../../../interfaces/productivity/workShifts/workShiftGroup";
import { ProdType } from "../../../../interfaces/productivity/workShifts/prodType";

const useShiftsTableColumns = () => {
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.WorkShiftGroup);
  const isDynamicProdTypesEnabled = useFeatureFlagIsEnabled(FeatureFlags.DynamicProdTypes);

  const getCompetenceColumnIds = (formalCompetences: FormalCompetence[]): string[] => {
    const competenceColumnIds: string[] = [];

    formalCompetences.forEach((formalCompetence) => {
      if (formalCompetence && formalCompetence.workCompetences) {
        formalCompetence.workCompetences.forEach((competence) => {
          competenceColumnIds.push(`${competence.id}`);
        });
      }
    });

    return competenceColumnIds;
  };

  const getCols = (
    culture: string,
    formalCompetences: FormalCompetence[],
    workShiftGroups: WorkShiftGroup[],
    prodTypes: ProdType[]
  ) => {
    const workShiftColumnSet1: HandsOnTableColumn[] = [
      isFeatureFlagEnabled
        ? {
            meta: {
              data: "workShiftGroupName",
              source: workShiftGroups.map((r) => r.name),
              type: "dropdown",
            },
            headerColor: "#2f4f74",
            headerTitle: "Workshift_Group",
            isFilterable: true,
            width: 200,
          }
        : {
            meta: {
              data: "unit",
              type: "text",
            },
            headerColor: "#2f4f74",
            headerTitle: "Workshift_Group",
            isFilterable: true,
            width: 200,
          },
      {
        meta: {
          data: "shiftName",
          type: "text",
        },
        headerColor: "#2f4f74",
        headerTitle: "Work_Shift",
        isFilterable: true,
        width: 285,
      },

      {
        meta: {
          data: "shiftStart",
          type: "time",
          timeFormat: "HH:mm",
          className: "htRight",
          validator: timeCellValidator,
        },
        headerTitle: "Start",
        isFilterable: true,
        width: 65,
      },

      {
        meta: {
          data: "shiftStop",
          type: "time",
          timeFormat: "HH:mm",
          className: "htRight",
          validator: timeCellValidator,
        },
        headerTitle: "Stop",
        isFilterable: true,
        width: 65,
      },

      {
        meta: {
          data: "shiftBreak",
          type: "time",
          timeFormat: "HH:mm",
          className: "htRight",
          validator: timeCellValidator,
        },
        headerTitle: "Break",
        isFilterable: true,
        width: 73,
      },

      {
        meta: {
          data: "activeWeekDayMon",
          type: "numeric",
          numericFormat: {
            pattern: "0,0",
            culture,
          },
          validator: positiveNumberValidator,
        },
        headerTitle: "Mon",
        width: 50,
      },

      {
        meta: {
          data: "activeWeekDayTue",
          type: "numeric",
          numericFormat: {
            pattern: "0,0",
            culture,
          },
          validator: positiveNumberValidator,
        },
        headerTitle: "Tue",
        width: 50,
      },

      {
        meta: {
          data: "activeWeekDayWed",
          type: "numeric",
          numericFormat: {
            pattern: "0,0",
            culture,
          },
          validator: positiveNumberValidator,
        },
        headerTitle: "Wed",
        width: 50,
      },

      {
        meta: {
          data: "activeWeekDayThu",
          type: "numeric",
          numericFormat: {
            pattern: "0,0",
            culture,
          },
          validator: positiveNumberValidator,
        },
        headerTitle: "Thu",
        width: 50,
      },

      {
        meta: {
          data: "activeWeekDayFri",
          type: "numeric",
          numericFormat: {
            pattern: "0,0",
            culture,
          },
          validator: positiveNumberValidator,
        },
        headerTitle: "Fri",
        width: 50,
      },

      {
        meta: {
          data: "activeWeekDaySat",
          type: "numeric",
          numericFormat: {
            pattern: "0,0",
            culture,
          },
          validator: positiveNumberValidator,
        },
        headerTitle: "Sat",
        width: 50,
      },

      {
        meta: {
          data: "activeWeekDaySun",
          type: "numeric",
          numericFormat: {
            pattern: "0,0",
            culture,
          },
          validator: positiveNumberValidator,
        },
        headerTitle: "Sun",
        width: 50,
      },

      {
        meta: { data: "comment", type: "text" },
        headerTitle: "Comment",
        width: 320,
      },

      {
        meta: {
          data: "backfillPercentage",
          type: "numeric",
          numericFormat: {
            culture: culture,
            pattern: {
              output: "percent",
              mantissa: 0,
            },
          },
          validator: numberBetWeenZeroAndOneValidator,
        },
        headerTitle: "Backfill",
        width: 100,
      },

      {
        meta: {
          data: "onCall",
          type: "checkbox",
          className: "htCenter htMiddle",
        },
        headerTitle: "On_Call",
        isFilterable: true,
        width: 85,
      },

      {
        meta: {
          data: "onCallUtilization",
          type: "numeric",
          numericFormat: {
            pattern: "0,0",
            culture,
          },
          validator: numberBetWeenZeroAndOneValidator,
        },
        headerTitle: "On_Call_Utilization",
        width: 100,
      },

      {
        meta: {
          data: "onCallIdleComp",
          type: "numeric",
          numericFormat: {
            pattern: "0,0",
            culture,
          },
          validator: numberBetWeenZeroAndOneValidator,
        },
        headerTitle: "On_Call_Idle_Comp",
        width: 80,
      },
      {
        meta: {
          data: "budget",
          type: "checkbox",
          className: "htCenter htMiddle",
        },
        headerTitle: "Budget",
        isFilterable: true,
        width: 80,
      },
      {
        meta: {
          data: "staff",
          type: "checkbox",
          className: "htCenter htMiddle",
        },
        headerTitle: "Staff",
        isFilterable: true,
        width: 80,
      },
    ];

    const sortedFormalCompetences = formalCompetences.sort((a, b) => a.sortOrder - b.sortOrder);

    sortedFormalCompetences.forEach((formalCompetence) => {
      if (formalCompetence && formalCompetence.workCompetences) {
        formalCompetence.workCompetences.forEach((workCompetence) => {
          workShiftColumnSet1.push({
            meta: {
              data: `${workCompetence.id}`,
              type: "numeric",
              numericFormat: {
                culture: culture,
                pattern: {
                  output: "percent",
                  mantissa: 0,
                },
              },
            },
            headerTitle: `${workCompetence.shortName}`,
            width: 80,
            headerColor: formalCompetence.color,
          });
        });
      }
    });

    const sortedProdTypes = prodTypes.sort((a, b) => {
      if (a.sortOrder == null) return 1;
      if (b.sortOrder == null) return -1;

      return a.sortOrder - b.sortOrder;
    });

    const workShiftColumnSet2: HandsOnTableColumn[] = isDynamicProdTypesEnabled
      ? sortedProdTypes.map((prodType) => {
          return {
            meta: {
              data: `${prodType.name}`,
              type: "numeric",
              numericFormat: {
                culture: culture,
                pattern: {
                  output: "percent",
                  mantissa: 0,
                },
              },
              validator: numberBetWeenZeroAndOneValidator,
            },
            headerTitle: `${prodType.name}`,
            width: 80,
            headerColor: prodType.color,
            isFilterable: true,
          };
        })
      : [
          {
            meta: {
              data: "productionShareEdVisit",
              type: "numeric",
              numericFormat: {
                culture: culture,
                pattern: {
                  output: "percent",
                  mantissa: 0,
                },
              },
              validator: numberBetWeenZeroAndOneValidator,
            },
            headerTitle: "Ed_Visits",
            isFilterable: true,
            width: 85,
          },

          {
            meta: {
              data: "productionShareOutpatientVisit",
              type: "numeric",
              numericFormat: {
                culture: culture,
                pattern: {
                  output: "percent",
                  mantissa: 0,
                },
              },
              validator: numberBetWeenZeroAndOneValidator,
            },
            headerTitle: "Outpatient_Visits",
            isFilterable: true,
            width: 140,
          },

          {
            meta: {
              data: "productionShareWard",
              type: "numeric",
              numericFormat: {
                culture: culture,
                pattern: {
                  output: "percent",
                  mantissa: 0,
                },
              },
              validator: numberBetWeenZeroAndOneValidator,
            },
            headerTitle: "Inpatient_Care",
            isFilterable: true,
            width: 125,
          },

          {
            meta: {
              data: "productionShareSurgery",
              type: "numeric",
              numericFormat: {
                culture: culture,
                pattern: {
                  output: "percent",
                  mantissa: 0,
                },
              },
              validator: numberBetWeenZeroAndOneValidator,
            },
            headerTitle: "Surgery",
            isFilterable: true,
            width: 85,
          },

          {
            meta: {
              data: "productionShareConsultant",
              type: "numeric",
              numericFormat: {
                culture: culture,
                pattern: {
                  output: "percent",
                  mantissa: 0,
                },
              },
              validator: numberBetWeenZeroAndOneValidator,
            },
            headerTitle: "Consultant",
            isFilterable: true,
            width: 105,
          },

          {
            meta: {
              data: "productionShareStExternalTraining",
              type: "numeric",
              numericFormat: {
                culture: culture,
                pattern: {
                  output: "percent",
                  mantissa: 0,
                },
              },
              validator: numberBetWeenZeroAndOneValidator,
            },
            headerTitle: "Resident_Training",
            isFilterable: true,
            width: 145,
          },

          {
            meta: {
              data: "productionShareManagement",
              type: "numeric",
              numericFormat: {
                culture: culture,
                pattern: {
                  output: "percent",
                  mantissa: 0,
                },
              },
              validator: numberBetWeenZeroAndOneValidator,
            },
            headerTitle: "Management",
            isFilterable: true,
            width: 120,
          },

          {
            meta: {
              data: "productionShareNonClinicalTime",
              type: "numeric",
              numericFormat: {
                culture: culture,
                pattern: {
                  output: "percent",
                  mantissa: 0,
                },
              },
              validator: numberBetWeenZeroAndOneValidator,
            },
            headerTitle: "Non_Clinical_Time",
            isFilterable: true,
            width: 150,
          },
        ];

    const workShiftColumnSet3: HandsOnTableColumn[] = [
      {
        meta: {
          data: "activeWeek1",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "1",
        width: 50,
      },

      {
        meta: {
          data: "activeWeek2",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "2",
        width: 50,
      },

      {
        meta: {
          data: "activeWeek3",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "3",
        width: 50,
      },

      {
        meta: {
          data: "activeWeek4",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "4",
        width: 50,
      },

      {
        meta: {
          data: "activeWeek5",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "5",
        width: 50,
      },

      {
        meta: {
          data: "activeWeek6",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "6",
        width: 50,
      },

      {
        meta: {
          data: "activeWeek7",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "7",
        width: 50,
      },

      {
        meta: {
          data: "activeWeek8",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "8",
        width: 50,
      },

      {
        meta: {
          data: "activeWeek9",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "9",
        width: 50,
      },

      {
        meta: {
          data: "activeWeek10",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "10",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek11",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "11",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek12",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "12",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek13",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "13",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek14",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "14",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek15",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "15",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek16",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "16",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek17",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "17",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek18",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "18",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek19",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "19",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek20",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "20",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek21",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "21",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek22",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "22",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek23",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "23",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek24",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "24",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek25",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "25",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek26",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "26",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek27",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "27",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek28",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "28",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek29",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "29",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek30",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "30",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek31",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "31",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek32",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "32",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek33",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "33",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek34",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "34",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek35",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "35",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek36",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "36",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek37",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "37",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek38",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "38",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek39",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "39",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek40",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "40",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek41",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "41",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek42",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "42",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek43",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "43",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek44",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "44",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek45",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "45",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek46",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "46",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek47",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "47",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek48",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "48",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek49",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "49",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek50",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "50",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek51",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "51",
        width: 55,
      },

      {
        meta: {
          data: "activeWeek52",
          type: "numeric",
          validator: numberBetWeenZeroAndOneValidator,
          numericFormat: {
            pattern: "0,0",
            culture,
          },
        },
        headerTitle: "52",
        width: 55,
      },
      {
        meta: {
          data: "totalCost",
          type: "numeric",
          readOnly: true,
          numericFormat: {
            pattern: { mantissa: 0, thousandSeparated: true },
            culture,
          },
        },
        headerTitle: "cost",
        width: 120,
        headerColor: "#35552e",
      },
      {
        meta: {
          data: "totalFte",
          type: "numeric",
          readOnly: true,
          numericFormat: {
            pattern: { output: "number", mantissa: 1 },
            culture,
          },
        },
        headerTitle: "fte",
        width: 120,
        headerColor: "#35552e",
      },
      {
        meta: {
          data: "weekendShifts",
          type: "numeric",
          readOnly: true,
          numericFormat: {
            culture: culture,
            pattern: "",
          },
          validator: positiveNumberValidator,
        },
        headerTitle: "weekend_shifts",
        width: 100,
        headerColor: "#739768",
      },
      {
        meta: {
          data: "fteWeekendShifts",
          readOnly: true,
          type: "numeric",
          numericFormat: {
            culture: culture,
            pattern: "",
          },
          validator: positiveNumberValidator,
        },
        headerTitle: "fte_weekend_shifts",
        width: 100,
        headerColor: "#739768",
      },
    ];

    return [...workShiftColumnSet1, ...workShiftColumnSet2, ...workShiftColumnSet3];
  };

  return { getCols, getCompetenceColumnIds };
};

export default useShiftsTableColumns;
