import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import DashboardCommentService from "../../../services/dashboardCommentService";
import { DashboardCommentProps } from "../../../interfaces/props/dashboard-comment";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components";

import "./comment-modal.css";
import CloseButton from "../../../components/close-button/close-button";
import DeleteButton from "../../../components/delete-button/delete-button";
import NotificationBox from "../../../components/NotificationBox/NotificationBox";
import startCase from "lodash/startCase";

const DashboardComment = ({ isOpen, onClose, selectedCareUnits, setCommentStatus }: DashboardCommentProps) => {
  const { t: translate } = useTranslation();
  const [commentContent, setCommentContent] = useState<string>("");
  const [commentId, setCommentId] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const modalRef = useRef<HTMLDivElement>(null);
  const [notificationBoxState, setNotificationBoxState] = useState<{
    isOpen: boolean;
    entityName: string | undefined;
  }>({
    isOpen: false,
    entityName: undefined,
  });

  const handleNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCommentContent(event.target.value);
  };

  useEffect(() => {
    if (selectedCareUnits.length === 1 && selectedCareUnits[0] !== 0) {
      fetchComment();
    }
  }, [selectedCareUnits]);

  const saveComment = async () => {
    const content = commentContent.replace(/\r?\n/g, "\n");
    await DashboardCommentService.saveComment(commentId, selectedCareUnits[0], content).then((res) => {
      setCommentContent(() => res?.commentBody);
      setCommentId(() => res?.id);
      if (res?.data?.commentBody === "") {
        setCommentStatus(false);
      } else {
        setCommentStatus(true);
      }
    });
  };

  const handleDeleteComment = async () => {
    await DashboardCommentService.saveComment(commentId, selectedCareUnits[0], "").then((res) => {
      setCommentContent(() => res?.commentBody);
      setCommentId(() => res?.id);
      setCommentStatus(false);
    });
  };

  const fetchComment = async () => {
    await DashboardCommentService.getComment(selectedCareUnits[0])
      .then((res) => {
        if (res?.data === null) {
          setCommentStatus(false);
          setCommentContent(() => "");
          setCommentId(() => null);
        } else {
          setCommentContent(() => res?.data?.commentBody);
          setCommentId(() => res?.data?.id);

          if (res?.data?.commentBody === "") {
            setCommentStatus(false);
          } else {
            setCommentStatus(true);
          }
        }
      })
      .catch(() => {
        setCommentContent(() => "");
        setCommentId(() => null);
        setCommentStatus(false);
      });
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target instanceof HTMLDivElement && event.target.id === "outer_layer") {
      setIsDragging(true);
      const rect = modalRef.current!.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const offsetY = event.clientY - rect.top;
      setOffset({ x: offsetX, y: offsetY });
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging || !modalRef.current) return;

    const newX = e.clientX - offset.x;
    const newY = e.clientY - offset.y;

    modalRef.current.style.left = `${newX}px`;
    modalRef.current.style.top = `${newY}px`;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleNotificationBoxLeftButtonClick = async () => {
    setNotificationBoxState(() => ({
      entityName: undefined,
      entityId: undefined,
      isOpen: false,
    }));
    await handleDeleteComment();
  };

  const handleNotificationBoxRightButtonClick = () => {
    setNotificationBoxState(() => ({
      entityName: undefined,
      entityId: undefined,
      isOpen: false,
    }));
  };

  const deleteUserPrompt = async () => {
    setNotificationBoxState(() => ({
      entityName: "comment",
      isOpen: true,
    }));
  };

  if (!isOpen) return null;
  return (
    <>
      {notificationBoxState.isOpen && (
        <NotificationBox
          content={translate("comment_delete_confirmation")}
          title={`${translate("delete")} ${startCase(notificationBoxState.entityName)}`}
          key={12}
          leftButtonLabel={translate("yes")}
          rightButtonLabel={translate("no")}
          variant="primary"
          onLeftButtonClick={handleNotificationBoxLeftButtonClick}
          onRightButtonClick={handleNotificationBoxRightButtonClick}
        />
      )}
      <div
        id="outer_layer"
        ref={modalRef}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseDown={handleMouseDown}
        className="fixed right-52 top-28 z-50 flex w-96 cursor-move items-center justify-center rounded border bg-white p-4 shadow-md"
      >
        <div className="flex w-full cursor-default flex-col" id="inner_layer">
          <div className="w-full basis-1/6 justify-end text-right">
            <CloseButton onClose={onClose} />
          </div>
          <div className="basis-4/6">
            <textarea
              onChange={handleNoteChange}
              value={commentContent}
              className="h-56 w-full rounded border border-[#e5e7eb] p-2"
            />
          </div>
          <div className="basis-1/6">
            <div className="mt-4 flex flex-row">
              <Button text={translate("save_comment")} variant="secondary" onClick={saveComment} />
              <DeleteButton onClick={deleteUserPrompt} disable={commentContent === "" ? true : false} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardComment;
