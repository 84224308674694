import React from "react";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import ProductionVsPlanV1 from "./V1/ProductionVsPlanV1";
import ProductionVsPlanV2 from "./V2/ProductionVsPlanV2";

const ProdVsPlan = () => {
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.ActualVsPlan);

  return <>{isFeatureFlagEnabled ? <ProductionVsPlanV2 /> : <ProductionVsPlanV1 />}</>;
};

export default ProdVsPlan;
