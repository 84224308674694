import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Accordian } from "../../../../components";

import { WeekdayPhasing } from "../../../../interfaces/production-plan";
import WeekdayPhasingInput from "../../Components/WeekdayPhasingInput/WeekdayPhasingInput";

interface WeekdayPhasingProps {
  weekDayPhasing: WeekdayPhasing;
  handleWeekdayPhasingChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleWeekdayPhasingLeftIconClick: (key: string) => void;
  handleWeekdayPhasingRightIconClick: (key: string) => void;
  readonly?: boolean;
}

const WeekdayPhasingSet = ({
  weekDayPhasing,
  handleWeekdayPhasingChange,
  handleWeekdayPhasingLeftIconClick,
  handleWeekdayPhasingRightIconClick,
  readonly,
}: WeekdayPhasingProps) => {
  const { t: translate } = useTranslation();
  return (
    <Accordian title={translate("weekday_phasing")}>
      <WeekdayPhasingInput
        name="monday"
        weekday="Monday"
        onChange={handleWeekdayPhasingChange}
        value={weekDayPhasing.monday}
        handleLeftIconClick={handleWeekdayPhasingLeftIconClick}
        handleRightIconClick={handleWeekdayPhasingRightIconClick}
        placeholder={translate("monday")}
        disabled={readonly}
      />
      <WeekdayPhasingInput
        name="tuesday"
        weekday="Tuesday"
        onChange={handleWeekdayPhasingChange}
        value={weekDayPhasing.tuesday}
        handleLeftIconClick={handleWeekdayPhasingLeftIconClick}
        handleRightIconClick={handleWeekdayPhasingRightIconClick}
        placeholder={translate("tuesday")}
        disabled={readonly}
      />
      <WeekdayPhasingInput
        name="wednesday"
        weekday="Wednesday"
        onChange={handleWeekdayPhasingChange}
        value={weekDayPhasing.wednesday}
        handleLeftIconClick={handleWeekdayPhasingLeftIconClick}
        handleRightIconClick={handleWeekdayPhasingRightIconClick}
        placeholder={translate("wednesday")}
        disabled={readonly}
      />
      <WeekdayPhasingInput
        name="thursday"
        weekday="Thursday"
        onChange={handleWeekdayPhasingChange}
        value={weekDayPhasing.thursday}
        handleLeftIconClick={handleWeekdayPhasingLeftIconClick}
        handleRightIconClick={handleWeekdayPhasingRightIconClick}
        placeholder={translate("thursday")}
        disabled={readonly}
      />
      <WeekdayPhasingInput
        name="friday"
        weekday="Friday"
        onChange={handleWeekdayPhasingChange}
        value={weekDayPhasing.friday}
        handleLeftIconClick={handleWeekdayPhasingLeftIconClick}
        handleRightIconClick={handleWeekdayPhasingRightIconClick}
        placeholder={translate("friday")}
        disabled={readonly}
      />
      <WeekdayPhasingInput
        name="saturday"
        weekday="Saturday"
        onChange={handleWeekdayPhasingChange}
        value={weekDayPhasing.saturday}
        handleLeftIconClick={handleWeekdayPhasingLeftIconClick}
        handleRightIconClick={handleWeekdayPhasingRightIconClick}
        placeholder={translate("saturday")}
        disabled={readonly}
      />
      <WeekdayPhasingInput
        name="sunday"
        weekday="Sunday"
        onChange={handleWeekdayPhasingChange}
        value={weekDayPhasing.sunday}
        handleLeftIconClick={handleWeekdayPhasingLeftIconClick}
        handleRightIconClick={handleWeekdayPhasingRightIconClick}
        placeholder={translate("sunday")}
        disabled={readonly}
      />
    </Accordian>
  );
};

export default WeekdayPhasingSet;
