import { v4 as uuid } from "uuid";
import { MultiSelectOption } from "../../../interfaces";

export const competence_shares_colours: { [key: string]: string } = {
  ÖL: "#681215",
  BÖL: "#8A181C",
  SPEC: "#AD1E23",
  "ST+": "#BD4B4F",
  ST: "#CE787B",
  LEG: "#EB9B9D",

  // Yellow Shades
  RAND: "#B67D16",
  AT: "#E5A32B",
  UL: "#F5C773",

  // Blue Shades
  "SSK++": "#00283C",
  "SSK+": "#426FA2",
  SSK: "#7EA4CF",

  // Green
  "USK+": "#416031",
  USK: "#739768",
  Övrigt: "#747474",
};

export const hoursOfTheDay = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

export const competence_shares = [
  "ÖL",
  "BÖL",
  "SPEC",
  "ST+",
  "ST",
  "RAND",
  "LEG",
  "AT",
  "UL",
  "SSK++",
  "SSK+",
  "SSK",
  "USK+",
  "USK",
  "Övrigt",
];

export const competence_share_options: MultiSelectOption[] = [
  {
    id: uuid(),
    label: "ÖL",
    value: "ÖL",
  },

  {
    id: uuid(),
    label: "BÖL",
    value: "BÖL",
  },

  {
    id: uuid(),
    label: "SPEC",
    value: "SPEC",
  },

  {
    id: uuid(),
    label: "ST+",
    value: "ST+",
  },

  {
    id: uuid(),
    label: "ST",
    value: "ST",
  },

  {
    id: uuid(),
    label: "RAND",
    value: "RAND",
  },

  {
    id: uuid(),
    label: "LEG",
    value: "LEG",
  },

  {
    id: uuid(),
    label: "AT",
    value: "AT",
  },

  {
    id: uuid(),
    label: "UL",
    value: "UL",
  },

  {
    id: uuid(),
    label: "SSK++",
    value: "SSK++",
  },

  {
    id: uuid(),
    label: "SSK+",
    value: "SSK+",
  },

  {
    id: uuid(),
    label: "SSK",
    value: "SSK",
  },

  {
    id: uuid(),
    label: "USK+",
    value: "USK+",
  },

  {
    id: uuid(),
    label: "USK",
    value: "USK",
  },

  {
    id: uuid(),
    label: "Övrigt",
    value: "Övrigt",
  },
];

export const weekDays: MultiSelectOption[] = [
  {
    id: uuid(),
    label: "Mon",
    value: 1,
  },

  {
    id: uuid(),
    label: "Tue",
    value: 2,
  },

  {
    id: uuid(),
    label: "Wed",
    value: 3,
  },

  {
    id: uuid(),
    label: "Thu",
    value: 4,
  },

  {
    id: uuid(),
    label: "Fri",
    value: 5,
  },

  {
    id: uuid(),
    label: "Sat",
    value: 6,
  },

  {
    id: uuid(),
    label: "Sun",
    value: 7,
  },
];

export const competencebarchartcategories = [
  {
    name: "Mon",
    categories: hoursOfTheDay,
  },
  {
    name: "Tue",
    categories: hoursOfTheDay,
  },
  {
    name: "Wed",
    categories: hoursOfTheDay,
  },
  {
    name: "Thu",
    categories: hoursOfTheDay,
  },
  {
    name: "Fri",
    categories: hoursOfTheDay,
  },
  {
    name: "Sat",
    categories: hoursOfTheDay,
  },
  {
    name: "Sun",
    categories: hoursOfTheDay,
  },
];
