import React from "react";
import HighchartsReact from "highcharts-react-official";
import HighStock from "highcharts/highstock";
import useOverviewChartConfig from "./hooks/useOverviewChartConfig";
import useOverviewChartData from "./hooks/useOverviewChartData";
import { OverviewChartProps } from "../../../../interfaces/analytics/orgOverviewChartProps";

const OverviewChart = ({ data }: OverviewChartProps) => {
  const { careUnitList, productivityPLan, productivityTarget, sortedDataSet } = useOverviewChartData(data);

  const { options } = useOverviewChartConfig(productivityPLan, productivityTarget, careUnitList, sortedDataSet);

  return <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={HighStock} options={options} />;
};

export default OverviewChart;
