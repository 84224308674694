import React, { useEffect, useState } from "react";
import HotTable from "@handsontable/react";
import useResourceColumns from "./hooks/useResourceColumns";
import usePlanningObjects from "../../analytics/ActivityAnalysis/FilterGroup/hooks/usePlanningObjects";
import { Button } from "../../../components";
import { useTranslation } from "react-i18next";
import useResourceTypes from "./hooks/useResourceTypes";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import useResources from "./hooks/useResources";
import useResourceTableConfig from "./hooks/useResourceTableConfig";

const Resources: React.FC = () => {
  const { t: translate } = useTranslation();

  const resourceTypes = useResourceTypes();

  const [careUnitIds, setCareUnitIds] = useState<number[]>([]);

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const scenarioId = useSelector((state: RootState) => state.globalFilter.filterScenario);

  useEffect(() => {
    if (careUnitId) {
      setCareUnitIds([careUnitId]);
    }
  }, [careUnitId]);

  const planningObjects = usePlanningObjects(careUnitIds);

  const columns = useResourceColumns({
    resourceTypes: resourceTypes.data,
    planningObjects: planningObjects.data,
  });

  const { data, saveResources } = useResources(careUnitId, scenarioId);

  const { props, table } = useResourceTableConfig(columns, data);

  const handleOnSaveClick = () => {
    console.log("save");

    console.log("data", data);
    // saveResources(data);
  };

  const isSaveButtonDisabled = false;

  return (
    <div className="col-start-1 col-end-13">
      <div className="rounded px-2">
        <div className="h-[79vh]">
          <div className="my-2">
            <div className="flex justify-end">
              <Button
                onClick={handleOnSaveClick}
                disabled={isSaveButtonDisabled}
                text={translate("save_changes")}
                variant={"primary"}
                additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
              />
            </div>
          </div>

          <div className="relative h-[65vh] overflow-y-scroll">
            <HotTable {...props} ref={table} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
