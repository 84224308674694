import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import range from "lodash/range";
import { useTranslation } from "react-i18next";
import { OUTPATIENT_PHASING_CHART_DATA } from "../../../../../utils/constants/outpatientPlan";
import { PhasingChartProps } from "../../../../../interfaces/props/outpatient-phasing-chart";
import { AppInsightService } from "../../../../../services";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";

const PhasingChart = ({ chartData, historicData, onChartUnmount, setChartData }: PhasingChartProps) => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "PhasingChart - Outpatient" });
  }, []);
  const { t: translate } = useTranslation();
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const options = {
    chart: {
      animation: false,
    },

    title: {
      text: undefined,
    },

    xAxis: {
      title: {
        text: translate("year") + " " + year + ", " + translate("week"),
        style: {
          fontSize: 16,
        },
      },
      categories: range(1, 53),
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      title: {
        text: translate("phasing_full_production_week"),
        style: {
          fontSize: 16,
        },
      },

      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: 14,
        fontWeight: "bold",
      },
      style: {
        fontSize: 14,
        fontWeight: "bold",
      },
    },

    plotOptions: {
      series: {
        pointWidth: 16,

        stickyTracking: false,
        dragDrop: {
          draggableY: true,
        },
        point: {
          events: {
            drop: function (this: Highcharts.Chart, e: Highcharts.PointDropEventObject) {
              const index = e.target.index;

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const newValue = e.newPoint.y;

              const data = JSON.parse(localStorage.getItem(OUTPATIENT_PHASING_CHART_DATA) ?? "");
              data[index] = newValue;
              localStorage.setItem(OUTPATIENT_PHASING_CHART_DATA, JSON.stringify(data));
              setChartData(data);
            },
          },
        },
      },
      column: {
        stacking: "normal",
        minPointLength: 2,
      },
      line: {
        cursor: "ns-resize",
        marker: {
          enabled: false,
        },
      },
    },

    tooltip: {
      valueDecimals: 2,
      enabled: false,
    },

    series: [
      {
        name: translate("phasing_template_value"),
        data: historicData,
        enableMouseTracking: false,
        type: "line",
        color: "#B33033",
        dragDrop: {
          draggableY: false,
        },
      },
      {
        name: translate("phasing_plan"),
        data: chartData,
        type: "column",
        color: "#5386BF",
        dragDrop: {
          dragMinY: 0,
        },
      },
    ],
  };

  useEffect(() => {
    return () => {
      onChartUnmount();
    };
  }, []);

  return <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }} />;
};

export default PhasingChart;
