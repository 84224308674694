import { useEffect, useState } from "react";
import BaseDataRetrieveHookReturn from "../../../interfaces/hooks/baseDataRetrieveHookReturn";
import { EmploymentType } from "../../../interfaces/employment-type/employment-type";
import EmploymentTypeService from "../../../services/employmentTypeService";

const useGetEmploymentTypes = (): BaseDataRetrieveHookReturn<EmploymentType[]> => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<EmploymentType[]>([]);

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);

    await EmploymentTypeService.getEmploymentTypes()
      .then((res) => {
        const responseData: EmploymentType[] = res;
        setData(responseData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    data,
    fetch: fetchData,
    isLoading,
  };
};

export default useGetEmploymentTypes;
