import { AxiosResponse } from "axios";
import { InpatientProductivityAnalysis } from "../interfaces/analytics/orgOverview/inpatientProductivityAnalysis";
import HttpCommonService from "./httpCommonService";

const InpatientProductivityService = {
  getProductivityAnalysis: async (
    careProviderId: number,
    scenarioId: number,
    year: number
  ): Promise<AxiosResponse<InpatientProductivityAnalysis[]>> => {
    return await HttpCommonService.getAsync(
      `api/inpatient/productivity/analysis/${careProviderId}/${year}/${scenarioId}`
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
  getProductivityAnalysisByCareUnit: async (
    scenarioId: number,
    year: number,
    careUnitIds: number[],
    careLevelIds: number[]
  ): Promise<AxiosResponse<InpatientProductivityAnalysis[]>> => {
    const payload = {
      scenarioId: scenarioId,
      year: year,
      careUnitIds: careUnitIds,
      careLevelIds: careLevelIds,
    };

    return await HttpCommonService.postAsync("api/inpatient/productivity/analysis", payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default InpatientProductivityService;
