import React, { useEffect } from "react";
import { AppInsightService } from "../../../../../services";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import range from "lodash/range";
import {
  PhasingChartData,
  PhasingChartWithDimensionsProps,
} from "../../../../../interfaces/production-plan/outpatient/Phasing/props/phasingChartWithDimensionsProps";

const PhasingChartWithDimensions = ({
  phasingChartData,
  onChartUnmount,
  setPhasingChartData,
  currentPlanChartPattern,
}: PhasingChartWithDimensionsProps) => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "PhasingChart - Outpatient" });
  }, []);

  const { t: translate } = useTranslation();
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const barChartData = phasingChartData.map((series: PhasingChartData) => ({
    name: translate(series.name),
    visible: true,
    data: series.data,
    color: series.color,
    type: series.type,
    dragDrop:
      series.type === "column"
        ? {
            dragMinY: 0,
            draggableY: phasingChartData.length > 1 ? false : true,
          }
        : undefined,
    point:
      series.type === "column"
        ? {
            events: {
              drop: function (e: Highcharts.PointDropEventObject) {
                const index = e.target.index;

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const newValue = e.newPoint.y;

                const chartDataSet = [...phasingChartData[0].data];
                chartDataSet[index] = newValue;

                setPhasingChartData((prevData) => {
                  const newChartData = [...prevData];
                  newChartData[0].data = chartDataSet;
                  return newChartData;
                });
              },
            },
          }
        : undefined,
  }));

  const lineChartData = {
    name: translate("phasing_template_value"),
    data: currentPlanChartPattern,
    enableMouseTracking: false,
    type: "line",
    color: "#B33033",
  };

  const options = {
    chart: {
      animation: false,
    },

    title: {
      text: undefined,
    },

    xAxis: {
      title: {
        text: translate("year") + " " + year + ", " + translate("week"),
        style: {
          fontSize: 16,
        },
      },
      categories: range(1, 53),
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      reversedStacks: false,
      title: {
        text: translate("phasing_full_production_week"),
        style: {
          fontSize: 16,
        },
      },

      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: 14,
        fontWeight: "bold",
      },
      style: {
        fontSize: 14,
        fontWeight: "bold",
      },
    },

    plotOptions: {
      series: {
        pointWidth: 16,
        stickyTracking: false,
      },
      column: {
        stacking: "normal",
        minPointLength: 2,
      },
      line: {
        cursor: "ns-resize",
        marker: {
          enabled: false,
        },
      },
    },

    tooltip: {
      valueDecimals: 2,
      enabled: false,
    },

    series: phasingChartData.length > 1 ? barChartData : [...barChartData, lineChartData],
  };

  useEffect(() => {
    return () => {
      onChartUnmount();
    };
  }, []);

  return <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }} />;
};

export default PhasingChartWithDimensions;
