import { AxiosResponse } from "axios";
import { CARE_LEVELS_ENDPOINT } from "../utils/apiendpoints";
import HttpCommonService from "./httpCommonService";
import { CareLevel } from "../interfaces/analytics/orgOverview/careLevel";

const careLevelService = {
  getCareLevels: async (): Promise<AxiosResponse<CareLevel[]>> => {
    return await HttpCommonService.getAsync(CARE_LEVELS_ENDPOINT)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default careLevelService;
