import React, { useEffect, useState } from "react";
import NotificationFloat from "../../../../components/NotificationFloat/NotificationFloat";
import DetailPlanChart from "./DetailPlanChart/DetailPlanChart";
import { useTranslation } from "react-i18next";
import LoadingWithGrey from "../../../../components/loading/LoadingWithGrey";
import { DetailedPlanRequest } from "../../../../interfaces/production-plan";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  ChartData,
  DetailedAnnualPlanRequest,
  OutpatientDetailedPlanDataRequest,
  OutpatientDetailedPlanRequest,
  OutpatientPlanFilteredRequestPayload,
  PlanDetails,
} from "../../../../interfaces/production-plan/outpatient/detailedPlan/detailed-plan";
import OutpatientPlanService from "../../../../services/outpatientPlanService";
import { OutpatientHistoricalDataService } from "../../../../services";
import {
  ChartDataYearFormat,
  OutPatinetDateRangeWeek,
  OutPatinetDateRangeYear,
  ReferralYearData,
} from "../../../../interfaces/production-plan/outpatient/referral-intake-chart-data";
import toLower from "lodash/toLower";
import { DetailPlanProps } from "../../../../interfaces/props";
import { ToastContainer, toast } from "react-toastify";
import DetailPlanSidePanel from "./DetailPlanSidePanel/DetailPlanSidePanel";
import NotificationBox from "../../../../components/NotificationBox/NotificationBox";
import { OutPatientMultiSelectWithTogglesRequest } from "../../../../interfaces/production-plan/outpatient/outpatientMultiselectFilterWithToggles";

const shadesOfGreen: string[] = ["#416031", "#548147", "#739768", "#a9c9a0", "#B0CBA4"];

const shadesOfBlue: string[] = ["#426fa2", "#5386bf", "#7ea4cf", "#adc8e5", "#b8c9e0"];

const DetailPlan = ({
  selectedTabIndex,
  nextAvailableTabIndex,
  setNextAvailableTabIndex,
  setSelectedTabIndex,
  isSaveButtonClicked,
  setIsSaveButtonClicked,
  isResetButtonClicked,
  setIsResetButtonClicked,
  isTabBarClicked,
  setIsTabBarClicked,
  clickedTabIndex,
  isOutpatientParametersModifying,
  setIsOutpatientParametersModifying,
}: DetailPlanProps) => {
  const { t: translate } = useTranslation();

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const [annualDetailedPlan, setAnnualDetailedPlan] = useState<number>(0);
  const [detailedPlanRequest, setDetailedPlanRequest] = useState<OutPatientMultiSelectWithTogglesRequest | null>(null);
  const [outpatientDetailedPlan, setOutpatientDetailedPlan] = useState<number>(0);
  const [outpatientDetailedPlanBeforeEdit, setOutpatientDetailedPlanBeforeEdit] = useState<number>(0);

  const [isDetailedPlanEdited, setIsDetailedPlanEdited] = useState<boolean>(false);
  const [detailedPlanChartDateRange, setDetailedPlanChartDateRange] = useState<ChartDataYearFormat[]>([]);
  const [detailPlanData, setDetailPlanData] = useState<ChartData[]>([]);
  const [isDetailPlanLoading, setIsDetailPlanLoading] = useState<boolean>(false);
  const [isSaveConfirmationModalBoxOpen, setIsSaveConfirmationModalBoxOpen] = useState<boolean>(false);

  const fetchAnnualDetailPlanData = async (detailPlanRequestParam: OutPatientMultiSelectWithTogglesRequest) => {
    if (careUnitId && careUnitId > 0 && detailPlanRequestParam.careTypeIds.length > 0) {
      const annualDetailedPlanRequest: DetailedAnnualPlanRequest = {
        careUnitId: careUnitId,
        year: year,
        scenarioId: scenario,
        professionIds: detailPlanRequestParam.professionIds[0] === -1 ? [] : detailPlanRequestParam.professionIds,
        contactTypeIds: detailPlanRequestParam.contactTypeIds[0] === -1 ? [] : detailPlanRequestParam.contactTypeIds,
        medicalSpecialityIds:
          detailPlanRequestParam.medicalSpecialtyIds[0] === -1 ? [] : detailPlanRequestParam.medicalSpecialtyIds,
        isNewVisits:
          detailPlanRequestParam.typesOfVisit[0] === -1
            ? []
            : detailPlanRequestParam.typesOfVisit.map((x) => (x === 1 ? true : false)),
        contactReasonGroupingIds:
          detailPlanRequestParam.contactReasonGroupingIds[0] === -1
            ? []
            : detailPlanRequestParam.contactReasonGroupingIds,
        careTypeIds: detailPlanRequestParam.careTypeIds[0] === -1 ? [] : detailPlanRequestParam.careTypeIds,
        typesOfEmergency:
          detailPlanRequestParam.typesOfEmergency[0] === -1
            ? []
            : detailPlanRequestParam.typesOfEmergency.map((x) => (x === 1 ? true : false)),
      };

      await OutpatientPlanService.getAnnualDetailedPlan(annualDetailedPlanRequest).then((res) => {
        const data = res;
        setAnnualDetailedPlan(data);
        setOutpatientDetailedPlan(parseInt(data, 10));
        setOutpatientDetailedPlanBeforeEdit(parseInt(data, 10));
      });
    }
  };

  const saveDetailedPlanData = async () => {
    isSaveConfirmationModalBoxOpen ? setIsSaveConfirmationModalBoxOpen(false) : null;

    if (careUnitId && detailedPlanRequest) {
      const toastId = toast(
        <NotificationFloat varaint="loading" content={translate("saving_outpatient_parameters")} />,
        {
          icon: false,
          className: "custom-toast",
          containerId: "notificationBox",
          autoClose: false,
        }
      );
      setIsOutpatientParametersModifying(true);

      const filterProperties: OutpatientDetailedPlanDataRequest = {
        careUnitId: careUnitId,
        detailProductionPlan: outpatientDetailedPlan,
        professionIds: detailedPlanRequest.professionIds[0] === -1 ? [] : detailedPlanRequest.professionIds,
        contactTypeIds: detailedPlanRequest.contactTypeIds[0] === -1 ? [] : detailedPlanRequest.contactTypeIds,
        medicalSpecialityIds:
          detailedPlanRequest.medicalSpecialtyIds[0] === -1 ? [] : detailedPlanRequest.medicalSpecialtyIds,
        typesOfVisit: detailedPlanRequest.typesOfVisit[0] === -1 ? [] : detailedPlanRequest.typesOfVisit,
        contactReasonGroupingIds:
          detailedPlanRequest.contactReasonGroupingIds[0] === -1 ? [] : detailedPlanRequest.contactReasonGroupingIds,
        careTypeIds: detailedPlanRequest.careTypeIds[0] === -1 ? [] : detailedPlanRequest.careTypeIds,
        typesOfEmergency: detailedPlanRequest.typesOfEmergency[0] === -1 ? [] : detailedPlanRequest.typesOfEmergency,
      };

      const data: OutpatientDetailedPlanRequest = {
        year: year,
        updateOutPatientDetailPlanDto: filterProperties,
        scenarioId: scenario,
      };

      await OutpatientPlanService.saveDetailedPlan(data)
        .then(() => {
          toast.update(toastId, {
            render: <NotificationFloat varaint="success" content={translate("data_save_success_message")} />,
            type: "success",
            autoClose: 3000,
          });

          setAnnualDetailedPlan(filterProperties.detailProductionPlan);
          setIsDetailedPlanEdited(false);
          setOutpatientDetailedPlanBeforeEdit(outpatientDetailedPlan);
          setIsSaveButtonClicked(false);
          setIsOutpatientParametersModifying(false);
          fetchAnnualDetailPlanData(detailedPlanRequest);
          fetchDetailPlanChartDataWithHiddenFilters(detailedPlanRequest);
        })
        .catch(() => {
          toast.update(toastId, {
            render: <NotificationFloat varaint="error" content={translate("something_went_wrong")} />,
            type: "error",
            autoClose: 3000,
          });
          setIsSaveButtonClicked(false);
          setIsOutpatientParametersModifying(false);
        });
    }
  };

  const resetDetailedPlanData = async (): Promise<boolean> => {
    const toastId = toast(<NotificationFloat varaint="loading" content={translate("prod_plan_reset_message")} />, {
      icon: false,
      className: "custom-toast",
      containerId: "notificationBox",
      autoClose: false,
    });

    setIsOutpatientParametersModifying(true);

    let response = true;
    await OutpatientPlanService.resetOutpatientPlan(careUnitId ? careUnitId : 0, scenario, year)
      .then(() => {
        toast.update(toastId, {
          render: <NotificationFloat varaint="success" content={translate("data_deletion_success_message")} />,
          autoClose: 3000,
          containerId: "notificationBox",
        });

        setIsResetButtonClicked(false);
        setIsOutpatientParametersModifying(false);

        if (detailedPlanRequest) {
          fetchAnnualDetailPlanData(detailedPlanRequest);
          fetchDetailPlanChartDataWithHiddenFilters(detailedPlanRequest);
        }

        response = true;
      })
      .catch(() => {
        toast.update(toastId, {
          render: <NotificationFloat varaint="error" content={translate("data_deletion_fail_message")} />,
          autoClose: 3000,
          containerId: "notificationBox",
        });

        setIsResetButtonClicked(false);
        setIsOutpatientParametersModifying(false);
        response = false;
      });

    return response;
  };

  useEffect(() => {
    if (isSaveButtonClicked && selectedTabIndex === 2) {
      saveDetailedPlanData();
    }
  }, [isSaveButtonClicked]);

  useEffect(() => {
    if (isResetButtonClicked && selectedTabIndex === 2) {
      resetDetailedPlanData();
    }
  }, [isResetButtonClicked]);

  const handleNotificationBoxSaveButtonClick = async () => {
    if (selectedTabIndex === 2) {
      await saveDetailedPlanData();
      setIsDetailedPlanEdited(false);
      if (isTabBarClicked) {
        setSelectedTabIndex(() => clickedTabIndex);
        setIsTabBarClicked(false);
      } else {
        setSelectedTabIndex(nextAvailableTabIndex);
      }
    }
  };

  const handleNotificationBoxDiscardSaveButtonClick = () => {
    setIsSaveConfirmationModalBoxOpen(false);
    setIsDetailedPlanEdited(false);
    if (isTabBarClicked) {
      setSelectedTabIndex(() => clickedTabIndex);
      setIsTabBarClicked(false);
    } else {
      setSelectedTabIndex(nextAvailableTabIndex);
    }
  };

  useEffect(() => {
    if (selectedTabIndex == 2 && careUnitId && detailedPlanRequest && detailedPlanChartDateRange.length > 0) {
      fetchAnnualDetailPlanData(detailedPlanRequest);
      fetchDetailPlanChartDataWithHiddenFilters(detailedPlanRequest);
    }
  }, [careUnitId, scenario, selectedTabIndex, detailedPlanRequest, detailedPlanChartDateRange]);

  useEffect(() => {
    if (isTabBarClicked && selectedTabIndex === 2) {
      if (isDetailedPlanEdited) {
        setIsSaveConfirmationModalBoxOpen(true);
      } else {
        setSelectedTabIndex(() => clickedTabIndex);
        setIsTabBarClicked(false);
      }
    }
  }, [isTabBarClicked]);

  useEffect(() => {
    const getXAxisData = async () => {
      if (year !== null && selectedTabIndex === 2) {
        await OutpatientHistoricalDataService.getOutPatientDataDateRange(year).then((res) => {
          const years: OutPatinetDateRangeYear[] = res.data;
          const chartXAxisData: ChartDataYearFormat[] = years.map((date) => {
            const convertedDate: ChartDataYearFormat = {
              name: date.id,
              categories: date.weeks.map((week) => {
                return week.theISOWeek;
              }),
            };

            return convertedDate;
          });

          const populatingWeekRange: OutPatinetDateRangeWeek[] = [];

          years.forEach((year) => {
            year.weeks.forEach((week) => {
              populatingWeekRange.push(week);
            });
          });

          setDetailedPlanChartDateRange(chartXAxisData);
        });
      }
    };

    getXAxisData();
  }, [year]);

  const handleDetailedPlanNextAndPreviousClick = async (nextIndex: number) => {
    if (isDetailedPlanEdited && selectedTabIndex === 2 && outpatientDetailedPlan !== outpatientDetailedPlanBeforeEdit) {
      setNextAvailableTabIndex(() => nextIndex);
      setIsSaveConfirmationModalBoxOpen(true);
    } else {
      setSelectedTabIndex(() => nextIndex);
    }
  };

  const fetchDetailPlanChartDataWithHiddenFilters = async (
    detailPlanRequestParam: OutPatientMultiSelectWithTogglesRequest
  ) => {
    const data: ChartData[] = [];
    setIsDetailPlanLoading(true);

    if (careUnitId && careUnitId > 0 && detailPlanRequestParam.careTypeIds.length > 0) {
      const detailedPlanRequest: DetailedPlanRequest = {
        careUnitId: careUnitId,
        year: year,
        scenarioId: scenario,
        groupRank: 10,
        professionIds: detailPlanRequestParam.professionIds[0] === -1 ? [] : detailPlanRequestParam.professionIds,
        contactTypeIds: detailPlanRequestParam.contactTypeIds[0] === -1 ? [] : detailPlanRequestParam.contactTypeIds,
        medicalSpecialityIds:
          detailPlanRequestParam.medicalSpecialtyIds[0] === -1 ? [] : detailPlanRequestParam.medicalSpecialtyIds,
        typesOfVisit: detailPlanRequestParam.typesOfVisit[0] === -1 ? [] : detailPlanRequestParam.typesOfVisit,
        contactReasonGroupingIds:
          detailPlanRequestParam.contactReasonGroupingIds[0] === -1
            ? []
            : detailPlanRequestParam.contactReasonGroupingIds,
        careTypeIds: detailPlanRequestParam.careTypeIds[0] === -1 ? [] : detailPlanRequestParam.careTypeIds,
        typesOfEmergency:
          detailPlanRequestParam.typesOfEmergency[0] === -1 ? [] : detailPlanRequestParam.typesOfEmergency,
        contactReasonGroupingEnabled: detailPlanRequestParam.contactReasonGroupingEnabled,
        careTypeEnabled: detailPlanRequestParam.careTypeEnabled,
        professionEnabled: detailPlanRequestParam.professionEnabled,
        contactTypeEnabled: detailPlanRequestParam.contactTypeEnabled,
        newVisitEnabled: detailPlanRequestParam.newVisitEnabled,
        typeOfEmergencyEnabled: detailPlanRequestParam.typeOfEmergencyEnabled,
        medicalSpecialtyEnabled: detailPlanRequestParam.medicalSpecialtyEnabled,
      };

      await OutpatientPlanService.getDetailedPlan(detailedPlanRequest)
        .then((res) => {
          const maxNoOfGroups = 10;

          res.details.map((detailPlanData: PlanDetails, index: number) => {
            const seriesName = getTranslatedSeriesName(detailPlanData.level);
            data.push({
              name: seriesName,
              data: detailPlanData.outcome,
              color:
                detailPlanData.level.indexOf("Other") > -1
                  ? "#d1d1d1"
                  : detailPlanData.level.indexOf("new_visit") > -1
                  ? shadesOfGreen[index <= maxNoOfGroups / 2 ? index : maxNoOfGroups - index]
                  : detailPlanData.level.indexOf("follow_up_visit") > -1
                  ? shadesOfBlue[index <= maxNoOfGroups / 2 ? index : maxNoOfGroups - index]
                  : shadesOfGreen.concat(shadesOfBlue)[index],
              type: "column",
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });

      const detailedPlanFilteredPayload: OutpatientPlanFilteredRequestPayload = {
        careUnitId: careUnitId,
        year: year,
        filters: {
          professionIds: detailedPlanRequest.professionIds,
          contactTypeIds: detailedPlanRequest.contactTypeIds,
          typesOfVisit: detailedPlanRequest.typesOfVisit,
          contactReasonGroupingIds: detailedPlanRequest.contactReasonGroupingIds,
          careTypeIds: detailedPlanRequest.careTypeIds,
          typesOfEmergency: detailedPlanRequest.typesOfEmergency,
          medicalSpecialityIds: detailedPlanRequest.medicalSpecialityIds,
        },
      };

      await OutpatientHistoricalDataService.getAvgOfOutpatientOutcomeData(
        "api/outpatientproduction/referrals-avarage/filter",
        detailedPlanFilteredPayload
      )
        .then((avgData) => {
          const dataHistoricalRollingAvg: ReferralYearData<number>[] = avgData.data;
          const historicalRollingAvg: number[] = [];

          dataHistoricalRollingAvg.forEach((year) => {
            year.weeks.forEach((week) => {
              historicalRollingAvg.push(week.value);
            });
          });

          const referralAvarageSeries: ChartData = {
            name: translate("moving_average_52_weeks_chart"),
            data: historicalRollingAvg,
            color: "#416031",
            type: "line",
          };

          data.push(referralAvarageSeries);
        })
        .catch((error) => {
          console.error(error);
        });

      await OutpatientHistoricalDataService.getSumOfOutpatientOutcomeData(
        "api/outpatientproduction/sumofoutpatientproductionoutcome/filter",
        detailedPlanFilteredPayload
      )
        .then((res) => {
          const dataOutPatientProductionRollingAvg: ReferralYearData<number>[] = res.data;
          const outPatientProductionRollingAvg: number[] = [];

          dataOutPatientProductionRollingAvg.forEach((year) => {
            year.weeks.forEach((week) => {
              outPatientProductionRollingAvg.push(week.value);
            });
          });

          const outpatientOutcomeSeries: ChartData = {
            name: translate("carried_out_visits"),
            data: outPatientProductionRollingAvg,
            color: "#ad1e23",
            type: "line",
          };

          data.push(outpatientOutcomeSeries);
        })
        .catch((error) => {
          console.error(error);
        });

      setDetailPlanData(data);
      setIsDetailPlanLoading(false);
    }
  };

  const getTranslatedSeriesName = (name: string) => {
    if (name !== "Other") {
      const words = name.split(", ");
      const wordsWithoutEmptyStrings = words.filter((str) => str.trim() !== "");
      const translatedWords: string[] = [];

      wordsWithoutEmptyStrings.forEach((element) => {
        if (
          element === "follow_up_visit" ||
          element === "new_visit" ||
          element === "is_planned" ||
          element === "is_emergency" ||
          element === "All"
        ) {
          translatedWords.push(translate(toLower(element)));
        } else {
          translatedWords.push(element);
        }
      });

      const text = translatedWords.join(", ");
      const escapedInput = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
      return escapedInput;
    } else {
      return translate("other");
    }
  };

  return (
    <>
      <ToastContainer
        containerId={"notificationBox"}
        style={{ width: "400px" }}
        position="top-right"
        hideProgressBar={true}
        closeButton={false}
      />
      {isSaveConfirmationModalBoxOpen ? (
        <NotificationBox
          content={translate("do_you_want_to_save_changes")}
          title={translate("save_changes")}
          key={12}
          leftButtonLabel={translate("yes")}
          rightButtonLabel={translate("no")}
          variant="primary"
          onLeftButtonClick={handleNotificationBoxSaveButtonClick}
          onRightButtonClick={handleNotificationBoxDiscardSaveButtonClick}
          isLeftButtonDisabled={false}
          isRightButtonDisabled={false}
        />
      ) : null}
      <div className="h-full w-5/6">
        {isDetailPlanLoading ? (
          <div className="dashboard__chart__loading-container">
            <LoadingWithGrey sizeInPixel={40} />
          </div>
        ) : (
          <DetailPlanChart
            data={detailPlanData}
            dateRange={detailedPlanChartDateRange}
            isLoading={isDetailPlanLoading}
          />
        )}
      </div>
      <div className="ml-5 w-1/6">
        <ToastContainer
          containerId={"notificationBox"}
          style={{ width: "400px" }}
          position="top-right"
          hideProgressBar={true}
          closeButton={false}
        />
        <DetailPlanSidePanel
          detailedPlanRequest={detailedPlanRequest}
          annualDetailedPlan={annualDetailedPlan}
          outpatientDetailedPlan={outpatientDetailedPlan}
          setOutpatientDetailedPlan={setOutpatientDetailedPlan}
          setIsDetailedPlanEdited={setIsDetailedPlanEdited}
          isDetailedPlanEdited={isDetailedPlanEdited}
          setDetailedPlanRequest={setDetailedPlanRequest}
          handleDetailedPlanNextAndPreviousClick={handleDetailedPlanNextAndPreviousClick}
          selectedTabIndex={selectedTabIndex}
          isOutpatientParametersModifying={isOutpatientParametersModifying}
        />
      </div>
    </>
  );
};

export default DetailPlan;
