import React from "react";
import { useTranslation } from "react-i18next";
import { ProductionFilterProps } from "../../../../../interfaces/productivity";
import { Select } from "../../../../../components";

const ProductionFilters = ({ typesOfInputs, selectedInput, handleTypesOfInputSelect }: ProductionFilterProps) => {
  const { t: translate } = useTranslation();
  return (
    <div>
      <Select
        placeholder={translate("type_of_input")}
        options={typesOfInputs}
        onSelectOption={handleTypesOfInputSelect}
        selectedOption={selectedInput}
      />
    </div>
  );
};

export default ProductionFilters;
