import { AxiosResponse } from "axios";
import HttpCommonService from "./httpCommonService";
import { OutpatientProductivityAnalysis } from "../interfaces/analytics/orgOverview/outpatientProductivityAnalysis";

const OutpatientProductivityService = {
  getProductivityAnalysis: async (
    scenarioId: number,
    year: number,
    careUnitIds: number[]
  ): Promise<AxiosResponse<OutpatientProductivityAnalysis[]>> => {
    const payload = {
      year: year,
      scenarioId: scenarioId,
      careUnitIds: careUnitIds,
    };
    return await HttpCommonService.postAsync("api/outpatient/productivity/analysis", payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default OutpatientProductivityService;
