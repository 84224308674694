import React, { useEffect, useState } from "react";
import ChatPanel from "../../../../../components/ChatPanel/ChatPanel";
import ChatGptService from "../../../../../services/chatGptService";
import { useChatMessages } from "../../../../../common/hooks/chat/useChatMessages";
import usePhysicianUnitComparisonData from "./hooks/usePhysicianUnitsProductivityComparisonData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { FeatureFlags } from "../../../../../utils/constants/featureFlags";
import { useFeatureFlagIsEnabled } from "../../../../../hooks/useFeatureFlagIsEnabled";
import usePhysicianUnitsComparisonPrompt from "./hooks/usePhysicianUnitsComparisonPrompt";
import { AiChatMessageType, ChatMessage } from "../../../../../interfaces/chat/ChatMessage";

interface ProductivityChatProps {
  isPanelOpen: boolean;
}

const ProductivityChatV2 = ({ isPanelOpen }: ProductivityChatProps): JSX.Element => {
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.ChatGptDemo);

  const { data } = usePhysicianUnitComparisonData(careUnitId as number, scenario, year);

  const prompt = usePhysicianUnitsComparisonPrompt(isFeatureFlagEnabled, data);

  const { messages, appendAiMessage } = useChatMessages([
    new ChatMessage(
      1,
      ["EvidenceAI – Genom att jämföra med liknande vårdenheter går det att dra följande slutsatser:"],
      AiChatMessageType.Header
    ),
  ]);

  const [isGenerating, setIsGenerating] = useState<boolean>(true);

  const generateResponse = async (responseBody: ReadableStream<Uint8Array>) => {
    const index = messages.length;

    const reader = responseBody.getReader();
    const decoder = new TextDecoder("utf-8");

    let done = false;

    while (!done) {
      const { value, done: streamDone } = await reader.read();
      done = streamDone;

      if (value) {
        const chunk = decoder.decode(value, { stream: true });
        appendAiMessage(chunk, index);
      }
    }
  };

  useEffect(() => {
    const fetchOpenAiResponse = async () => {
      if (prompt && prompt.length > 0) {
        try {
          const responseBody = await ChatGptService.postChatMessageStream({ message: prompt });
          await generateResponse(responseBody);
        } catch (error) {
          console.error("Error while fetching the stream:", error);
        } finally {
          setIsGenerating(false);
        }
      }
    };

    if (isPanelOpen) {
      fetchOpenAiResponse();
    }
  }, [isPanelOpen, prompt]);

  return <ChatPanel isGenerating={isGenerating} messages={messages} />;
};

export default ProductivityChatV2;
