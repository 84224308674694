import HttpCommonService from "./httpCommonService";

const ProductivityService = {
  getTotalsByProductionShares: async (type: string, careUnitId: number | null, scenarioId: number) => {
    return await HttpCommonService.getAsync(`api/results/totalsbyprodtype/${type}/${careUnitId}/${scenarioId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getTotalsByCompetences: async (
    type: string,
    competenceType: string,
    careUnitId: number | null,
    scenarioId: number
  ) => {
    return await HttpCommonService.getAsync(
      `api/results/totalsbycomptype/${type}/${competenceType}/${careUnitId}/${scenarioId}`
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getPatientCareValuesPerTotals: async (type: string, careUnitId: number | null) => {
    return await HttpCommonService.getAsync(`api/results/careepisodespertotals/${type}/${careUnitId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getPhysicianComparison: async (careUnitId: number | null, scenarioId: number, year: number) => {
    return await HttpCommonService.getAsync(`api/waterfallkpi/doctor/comparison/${careUnitId}/${year}/${scenarioId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default ProductivityService;
