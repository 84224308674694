import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { hoursOfTheDay } from "../../../../utils/highcharts/capacity/constants";
import { HeatMapData, PlannedCompPerHourHeatMapResponse } from "../../../../interfaces/capacity";
import { AxiosResponse } from "axios";
import { CapacityService } from "../../../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { MultiSelectOption } from "../../../../interfaces";
import LoadingWithGrey from "../../../../components/loading/LoadingWithGrey";

const CapacityHeatmap = ({
    selectedCompetenceShareOptions,
}: {
    selectedCompetenceShareOptions: MultiSelectOption[];
}) => {

    const { t: translate } = useTranslation();
    const [isChartLoading, setIsChartLoading] = useState<boolean>(false);
    const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
    const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
    const [chartData, setChartData] = useState<HeatMapData>({
        name: undefined,
        data: [],
      });

    const daysOfWeek = [
        translate("Mon"),
        translate("Tue"),
        translate("Wed"),
        translate("Thu"),
        translate("Fri"),
        translate("Sat"),
        translate("Sun"),
      ];

    const chartOptions = {
        chart: {
          type: "heatmap",
          marginTop: 80,
          marginBottom: 60,
          plotBorderWidth: 1,
          backgroundColor: "transparent",
          style: {
            fontFamily: "Open Sans",
            textOutline: 0,
            fontSize: 12,
          },
        },
    
        tooltip: {
          formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
            const point = this.point as Highcharts.Point;
    
            const tooltipInfo = `
            ${daysOfWeek[point.x]} <br />
            ${point.y} <br /> 
            ${point.value}%`;
            return tooltipInfo;
          },
        },
    
        title: {
          text: translate("heatmap_1_title"),
          style: {
            fontSize: 16,
          },
        },
    
        xAxis: {
          categories: [
            translate("Mon"),
            translate("Tue"),
            translate("Wed"),
            translate("Thu"),
            translate("Fri"),
            translate("Sat"),
            translate("Sun"),
          ],
          opposite: true,
          labels: {
            style: {
              fontSize: 12,
            },
            rotation: 0,
          },
        },
    
        yAxis: {
          categories: hoursOfTheDay,
          title: null,
          reversed: true,
          labels: {
            style: {
              fontSize: 12,
            },
          },
        },
    
        accessibility: {
          point: {},
        },
    
        colorAxis: {
          stops: [
            [0, "#7EA4CF"],
            [0.5, "#426FA2"],
            [1, "#00283C"],
          ],
          labels: {
            format: "{value}%",
          },
        },
    
        legend: {
          align: "right",
          layout: "vertical",
          margin: 0,
          verticalAlign: "top",
          y: 25,
          symbolHeight: 280,
          style: {
            fontSize: 12,
          },
        },
    
        series: [
          {
            ...chartData,
            borderWidth: 1,
            borderColor: "#ADADAD",
            dataLabels: {
              enabled: true,
              color: "white",
              formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                return this.point.value + "%";
              },
              style: {
                textOutline: 0,
                fontWeight: "normal",
                fontSize: 12,
              },
            },
          },
        ],
    
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                yAxis: {
                  labels: {},
                },
              },
            },
          ],
        },
      };

      useEffect(() => {
        if (careUnitId !== null && selectedCompetenceShareOptions.length > 0) {
          setIsChartLoading(true);
    
          const competences = selectedCompetenceShareOptions.map((share) => {
            return String(share.value);
          });
    
          CapacityService.getWeeklyWorkingHoursHeatMapData(competences, careUnitId, scenario).then(
            (res: AxiosResponse<PlannedCompPerHourHeatMapResponse>) => {
              setChartData(() => ({
                name: competences.toString(),
                data: res.data.weeklyWorkingHours,
              }));
            }
          );
          setIsChartLoading(false);
        }
      }, [careUnitId, selectedCompetenceShareOptions]);

      return (
        <>
            {!isChartLoading ? (
                <HighchartsReact
                    containerProps={{ style: { height: "100%" } }}
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            )
            : 
            (
                <div className="flex h-full items-center justify-center">
                  <LoadingWithGrey sizeInPixel={40} />
                </div>
            )}
        </>
      )
};

export default CapacityHeatmap;