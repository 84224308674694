import React from "react";
import ArrowDownIcon from "./ArrowDownIcon";
import ArrowUpIcon from "./ArrowUpIcon";
import AiIcon from "./AiIcon";
import CloseIcon from "./closeIcon/CloseIcon";
import PlusBoldIcon from "./PlusBoldIcon/PlusBoldIcon";

interface IconProps {
  variant: "arrowup" | "arrowdown" | "ai" | "close" | "plus-bold";
  height?: number;
  width?: number;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ variant, height, width, className }: IconProps): JSX.Element => {
  let Component: React.ReactNode;

  switch (variant) {
    case "arrowup":
      Component = <ArrowUpIcon />;
      break;
    case "arrowdown":
      Component = <ArrowDownIcon height={height} width={width} />;
      break;
    case "ai":
      Component = <AiIcon />;
      break;

    case "close":
      Component = <CloseIcon height={height} width={width} />;
      break;

    case "plus-bold":
      Component = <PlusBoldIcon height={height} width={width} className={className} />;
      break;
    // Add more cases for other variants
    default:
      Component = <></>;
      break;
  }

  return Component;
};

export default Icon;
