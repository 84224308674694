import { useEffect, useState } from "react";
import BaseDataRetrieveHookReturn from "../../../../interfaces/hooks/baseDataRetrieveHookReturn";

import ShiftService from "../../../../services/shiftService";
import { ShiftProductionTypeAllocation } from "../../../../interfaces/productivity/workShifts/shiftProductionTypeAllocation";

const useGetShiftProductionAllocations = (
  careUnitId: number | null,
  scenario: number | null
): BaseDataRetrieveHookReturn<ShiftProductionTypeAllocation[]> => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shiftProdTypeAllocations, setShiftProdTypeAllocations] = useState<ShiftProductionTypeAllocation[]>([]);

  const fetchData = async (): Promise<void> => {
    if (careUnitId && scenario) {
      setIsLoading(true);

      await ShiftService.getShiftProductionAllocations(careUnitId, scenario)
        .then((res) => {
          const responseData: ShiftProductionTypeAllocation[] = res.data;
          setShiftProdTypeAllocations(responseData);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [careUnitId, scenario]);

  return {
    data: shiftProdTypeAllocations,
    fetch: fetchData,
    isLoading,
  };
};

export default useGetShiftProductionAllocations;
