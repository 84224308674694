import React from "react";
import { ReactComponent as CloseSVG } from "../../../assets/svg/close.svg";

interface CloseIconProps {
  width?: number;
  height?: number;
}

const CloseIcon: React.FC<CloseIconProps> = ({ width = 24, height = 24 }): JSX.Element => {
  return <CloseSVG width={width} height={height} />;
};

export default CloseIcon;
