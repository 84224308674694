import React, { useEffect, useState } from "react";
import OverviewGrid from "./OverviewGrid/OverviewGrid";
import CareLevelTargetGrid from "./CareLevelTargetGrid/CareLevelTargetGrid";
import OverviewKPI from "./OverviewKPI/OverviewKPI";
import useGetInpatientProdAnalysisData from "../hooks/useGetInpatientProdAnalysisData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import OverviewChart from "../OverviewChart/OverviewChart";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import useGetCareLevelTargetGridData from "../hooks/useGetCareLevelTargetGridData";
import { MultiSelect } from "../../../../components";
import { useTranslation } from "react-i18next";
import { MultiSelectOption } from "../../../../interfaces";
import { v4 as uuid } from "uuid";
import { isEqual } from "lodash";

const InpatientOverview = () => {
  const { t: translate } = useTranslation();
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const careProviderId = useSelector((state: RootState) => state.globalFilter.careProviderId);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.organizationalOverviewChart);
  const isOrgOverviewFilterEnabled = useFeatureFlagIsEnabled(FeatureFlags.OrgOverviewFilter);

  const careLevelTargetGridDataHandler = useGetCareLevelTargetGridData();

  const [careLevelOptions, setCareLevelOptions] = useState<MultiSelectOption[]>([]);
  const [selectedCareLevelOptions, setSelectedCareLevelOptions] = useState<MultiSelectOption[]>([]);
  const [selectedCareLevels, setSelectedCareLevels] = useState<number[] | null>(null);

  const inpatientProductivityAnalysisDataHandler = useGetInpatientProdAnalysisData(
    careProviderId,
    scenario,
    year,
    selectedCareLevels
  );

  useEffect(() => {
    if (careLevelTargetGridDataHandler.data.length > 0) {
      const defaultFirstOption: MultiSelectOption = {
        id: uuid(),
        value: "null",
        label: translate("all"),
      };
      const careLevels: MultiSelectOption[] = [];

      careLevels.length === 0 && careLevels.push(defaultFirstOption);

      careLevelTargetGridDataHandler.data.map((item) => {
        careLevels.push({
          id: uuid(),
          value: item.id.toString(),
          label: item.levelName,
        });
      });

      setCareLevelOptions(careLevels);
      setSelectedCareLevelOptions(careLevels);
    }
  }, [careLevelTargetGridDataHandler.data]);

  useEffect(() => {
    const mapSelectedValues = (selectedValues: MultiSelectOption[]) =>
      selectedValues.filter((x) => x.value !== "null").map((x) => parseInt(x.value.toString(), 10));

    if (selectedCareLevelOptions.length === careLevelOptions.length - 1) {
      setSelectedCareLevelOptions(careLevelOptions);
    } else {
      if (selectedCareLevelOptions.length > 0) {
        setSelectedCareLevels(
          isEqual(selectedCareLevelOptions, careLevelOptions) ? [-1] : mapSelectedValues(selectedCareLevelOptions)
        );
      }
    }
  }, [selectedCareLevelOptions]);

  const handleCareLevelSelect = (option: MultiSelectOption) => {
    let selectedCareLevelsSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedCareLevelOptions(careLevelOptions);
    } else {
      const isSelectedOptionExists =
        selectedCareLevelOptions.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedCareLevelsSelect = selectedCareLevelOptions.filter((x) => x.value !== "null");
        selectedCareLevelsSelect = [...selectedCareLevelsSelect, option];
        setSelectedCareLevelOptions(selectedCareLevelsSelect);
      }
    }
  };

  const handleCareLevelDeselect = (option: MultiSelectOption) => {
    if (selectedCareLevelOptions.length <= careLevelOptions.length && selectedCareLevelOptions.length > 1) {
      if (option.value === "null") {
        setSelectedCareLevelOptions([]);
      } else {
        const isDeselectedOptionExists =
          selectedCareLevelOptions.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedCareLevelOptions(() => {
            return selectedCareLevelOptions.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  return (
    <div className="flex h-full w-full">
      {isFeatureFlagEnabled ? (
        <div className="mx-4 my-4 flex w-5/6 flex-col gap-10">
          <div className="h-1/2">
            <OverviewChart
              data={
                inpatientProductivityAnalysisDataHandler.data.map((item) => {
                  return {
                    productvityPlan: item.productivityPlan || 0,
                    productivityTarget: item.productivityTarget || 0,
                    productvtyplanvstarget: item.staffingPotential || 0,
                    careUnitName: item.careUnitName || "",
                  };
                }) || []
              }
            />
          </div>
          <div className="h-1/2">
            <OverviewGrid
              data={inpatientProductivityAnalysisDataHandler.data}
              isLoading={inpatientProductivityAnalysisDataHandler.isLoading}
            />
          </div>
        </div>
      ) : (
        <div className="h-dvh w-dvw w-5/6 px-4 py-4">
          <OverviewGrid
            data={inpatientProductivityAnalysisDataHandler.data}
            isLoading={inpatientProductivityAnalysisDataHandler.isLoading}
          />
        </div>
      )}
      <div className="h-dvhw w-1/6 py-4 pr-4">
        <div>
          <CareLevelTargetGrid data={careLevelTargetGridDataHandler.data} />
        </div>
        {isOrgOverviewFilterEnabled ? (
          <MultiSelect
            label={translate("care_level")}
            selectedOptions={selectedCareLevelOptions}
            options={careLevelOptions}
            onSelectItem={handleCareLevelSelect}
            onRemoveSelectItem={handleCareLevelDeselect}
          />
        ) : null}
        <div className="mx-8 mt-16">
          <OverviewKPI data={inpatientProductivityAnalysisDataHandler.data} />
        </div>
      </div>
    </div>
  );
};

export default InpatientOverview;
