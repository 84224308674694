import Core from "handsontable/core";
import { autocompleteRenderer } from "handsontable/renderers";

export const keyValueListRenderer = function (
  hot: Core,
  TD: HTMLTableCellElement,
  row: number,
  col: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prop: any,
  value: number | string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellProperties: any
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const item = cellProperties?.source?.find((item: any) => item._id === value);

  TD.innerHTML = "<div class='htAutocompleteArrow htMiddle'>▼</div>";

  if (item) {
    value = item.label;
    autocompleteRenderer.call(
      hot,
      hot,
      TD,
      row,
      col,
      prop,
      value,
      cellProperties
    );
  }
};
