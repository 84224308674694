import React from "react";
import "./ButtonSmall.css";
import clsx from "clsx";

interface ButtonSmallProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
}

const ButtonSmall: React.FC<ButtonSmallProps> = ({ onClick, children, className }) => {
  return (
    <button className={clsx("button-small group", className)} onClick={onClick}>
      {children}
    </button>
  );
};

export default ButtonSmall;
