import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TabbarTeritary from "../../../components/tab-bar/tabbar-teritary/TabbarTeritary";
import { ISelectOption, MultiSelectOption } from "../../../interfaces";
import { CareLevel, MedicalSpeciality } from "../../../interfaces/orgstructure";
import { GridLayout } from "../../../layouts";
import { RootState } from "../../../redux/store";
import { CARE_LEVELS_ENDPOINT, MEDICAL_SPECIALITIES_ENDPOINT } from "../../../utils/apiendpoints";
import Highcharts from "highcharts";
import "./benchmark.css";
import { AppInsightService } from "../../../services";
import NotificationFloat from "../../../components/NotificationFloat/NotificationFloat";
import OutpatientFilters from "./Filters/OutpatientFilters";
import InpatientFilters from "./Filters/InpatientFilters";
import EmergencyFilters from "./Filters/EmergencyFilters";
import DateFilters from "./Filters/DateFilters";
import BenchmarkingChart from "./Chart/BenchmarkingChart";
import OrgService from "../../../services/orgService";
import { BenchmarkDateRange } from "../../../interfaces/productivity/benchmarkingDateRange";

const Benchmark = () => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "Benchmark" });
  }, []);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const { t: translate, i18n } = useTranslation();
  //org user
  const careUnitTypeId = useSelector((state: RootState) => state.globalFilter.filterCareUnits.careUnitType);
  const [errorNotification, setErrorNotification] = useState("");
  const [endDateErrors, setEndDateErrors] = useState<string[]>([]);

  useEffect(() => {
    if (errorNotification !== "") {
      const timer = setTimeout(() => {
        setErrorNotification("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [errorNotification]);

  //tabs
  const tabs = [translate("InternalBenchmarking"), translate("ExternalBenchmarking")];

  //predefined data for filters
  const type_of_competence: ISelectOption[] = [
    {
      label: translate("CompetenceName"),
      value: "competenceName",
    },

    {
      label: translate("competenceGroupLvl1"),
      value: "competenceGroupLvl1",
    },

    {
      label: translate("competenceGroupLvl2"),
      value: "competenceGroupLvl2",
    },

    {
      label: translate("Profession"),
      value: "profession",
    },
  ];
  const type_of_inputs: ISelectOption[] = [
    {
      label: translate("working_hours"),
      value: "working_hours",
    },
    {
      label: translate("cost") + " (kSEK)",
      value: "cost",
    },
  ];
  const production_types: MultiSelectOption[] = [
    {
      id: "ProductionShareEdVisit",
      label: translate("Ed_Visits"),
      value: 1,
    },

    {
      id: "ProductionShareOutpatientVisit",
      label: translate("Outpatient_Visits"),
      value: 2,
    },

    {
      id: "ProductionShareWard",
      label: translate("Inpatient_Care"),
      value: 3,
    },

    {
      id: "ProductionShareSurgery",
      label: translate("Surgery"),
      value: 4,
    },

    {
      id: "ProductionShareConsultant",
      label: translate("Consultant"),
      value: 5,
    },

    {
      id: "ProductionShareStExternalTraining",
      label: translate("Resident_Training"),
      value: 6,
    },

    {
      id: "ProductionShareManagement",
      label: translate("Management"),
      value: 7,
    },

    {
      id: "ProductionShareNonClinicalTime",
      label: translate("Non_Clinical_Time"),
      value: 8,
    },
  ];
  const competence_types: ISelectOption[] = [
    {
      label: translate("all"),
      value: "",
    },
    {
      label: translate("physician"),
      value: "Läkare",
    },
    {
      label: translate("other_professions"),
      value: "Övriga",
    },
  ];
  const contact_types: ISelectOption[] = [
    {
      label: translate("all"),
      value: "",
    },
    {
      label: translate("physical_visits"),
      value: "Mottagningsbesök",
    },
    {
      label: translate("other_contact_types"),
      value: "Övriga",
    },
  ];

  //competence type for the filter
  const competenceTypes = competence_types;
  const [selectedCompetenceType, setSelectedCompetenceType] = useState<ISelectOption>(competence_types[0]);

  //contact type for the filter
  const contactTypes = contact_types;
  const [selectedContactType, setSelectedContactType] = useState<ISelectOption>(contact_types[0]);

  //production types for the filter
  const [productionTypes] = useState<MultiSelectOption[]>(production_types);
  const [selectedProductionTypes, setSelectedProductionTypes] = useState<MultiSelectOption[]>([]);

  //care levels for filtering
  const [careLevelsList, setCareLevelsList] = useState<ISelectOption[]>([]);
  const [selectedCareLevel, setSelectedCareLevel] = useState<ISelectOption>({
    label: "all",
    value: "",
    isSelected: false,
  });

  //input type for filters
  const [typesOfInputs] = useState(type_of_inputs);
  const [selectedInput, setSelectedInput] = useState<ISelectOption>(type_of_inputs[0]);

  //competence type for filters
  const [selectedCompetenceInput] = useState<ISelectOption>(type_of_competence[2]);

  //medical specs for filters
  const [medicalSpecialityList, setMedicalSpecialityList] = useState<ISelectOption[]>([]);
  const [selectedMedicalSpeciality, setSelectedMedicalSpeciality] = useState<ISelectOption>({
    label: "all",
    value: "",
    isSelected: false,
  });

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const [, setChartDataLoaded] = useState<boolean>(false);

  const [dateRange, setDateRange] = useState<BenchmarkDateRange>({
    startDate: new Date(Date.UTC(new Date().getFullYear(), 0, 1)),
    endDate: new Date(Date.UTC(new Date().getFullYear(), 11, 31)),
  });

  useEffect(() => {
    setDateRange((prevState) => {
      return {
        ...prevState,
        startDate: new Date(Date.UTC(year, 0, 1)),
        endDate: new Date(Date.UTC(year, 11, 31)),
      };
    });
  }, [year]);

  const handleafterFetchingChartData = () => {
    setChartDataLoaded(true);
  };

  // --------DATA LOADING FUNCTIONS------

  //mediacal specialities loader
  const fetchMedicalSpecialitiesArray = async () => {
    try {
      setMedicalSpecialityList([]);

      medicalSpecialityList.push({
        label: translate("all"),
        value: "",
      });

      const medicalSpecialityArray: MedicalSpeciality[] = await OrgService.getOrgAsync(MEDICAL_SPECIALITIES_ENDPOINT);

      medicalSpecialityList.length > 1
        ? medicalSpecialityList.push({
            value: medicalSpecialityArray[medicalSpecialityArray.length - 1].id.toString(),
            label: medicalSpecialityArray[medicalSpecialityArray.length - 1].medicalSpecialityName,
          })
        : medicalSpecialityArray.map((medicalspeciality) => {
            medicalSpecialityList.push({
              value: medicalspeciality.id.toString(),
              label: medicalspeciality.medicalSpecialityName,
            });
          });

      setMedicalSpecialityList(() => medicalSpecialityList);
      setSelectedMedicalSpeciality(() => medicalSpecialityList[0]);
      return medicalSpecialityList;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  //care levels loader
  const fetchCareLevelsArray = async () => {
    try {
      const careLevelArray: CareLevel[] = await OrgService.getOrgAsync(CARE_LEVELS_ENDPOINT);

      careLevelsList.push({
        label: translate("all"),
        value: "",
      });

      careLevelsList.length > 1
        ? careLevelsList.push({
            value: careLevelArray[careLevelArray.length - 1].id.toString(),
            label: careLevelArray[careLevelArray.length - 1].levelName,
          })
        : careLevelArray.map((carelevel) => {
            careLevelsList.push({
              value: carelevel.id.toString(),
              label: carelevel.levelName,
            });
          });

      setCareLevelsList(() => careLevelsList);
      setSelectedCareLevel(() => careLevelsList[0]);
      return careLevelsList;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  //-------------END OF DATA LOADING METHODS-----------

  //processing production types and select defaults production types for each unit type
  const processProductionTypes = () => {
    try {
      const modifiedProductionTypes: MultiSelectOption[] = [];
      if (careUnitTypeId === 2) {
        productionTypes.map((type) => {
          if (type.value === 3 || type.value === 6 || type.value === 7 || type.value === 8) {
            modifiedProductionTypes.push(type);
          }
        });

        setSelectedProductionTypes(() => modifiedProductionTypes);
      } else if (careUnitTypeId === 1) {
        productionTypes.map((type) => {
          if (type.value === 2 || type.value === 6 || type.value === 7 || type.value === 8) {
            modifiedProductionTypes.push(type);
          }
        });
        setSelectedProductionTypes(() => modifiedProductionTypes);
      } else {
        productionTypes.map((type) => {
          if (type.value === 1 || type.value === 5 || type.value === 6 || type.value === 7 || type.value === 8) {
            modifiedProductionTypes.push(type);
          }
        });
        setSelectedProductionTypes(() => modifiedProductionTypes);
      }

      return modifiedProductionTypes;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  //defining chart colors

  // //callind APIs and reponse modifiers in initial load and care unit change

  useEffect(() => {
    processProductionTypes();
  }, [careUnitTypeId]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchCareLevelsArray();
      await fetchMedicalSpecialitiesArray();
    };
    fetchData();
  }, []);

  //handle full-stop change
  useEffect(() => {
    if (i18n.language === "sv") {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ",",
        },
      });
    } else {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ".",
        },
      });
    }
  }, [i18n]);

  const handleTabbarClick = (clickedIndex: number) => {
    setSelectedTabIndex(() => clickedIndex);
  };

  //-----------------FILTERS------------------
  //date filters
  const handleStartDateChange = (date: Date): void => {
    setDateRange((prevState) => {
      return {
        ...prevState,
        startDate: date,
      };
    });

    endDateValidator(dateRange.endDate);
  };

  const handleEndDateChange = (date: Date): void => {
    setDateRange((prevState) => {
      return {
        ...prevState,
        endDate: date,
      };
    });

    endDateValidator(date);
  };

  const endDateValidator = (value: Date) => {
    const errors = validateEndDate(value);
    setEndDateErrors(errors);
  };

  const validateEndDate = (value: Date) => {
    const errors: string[] = [];

    if (value && dateRange.startDate) {
      if (value < dateRange.startDate) {
        errors.push(translate("enddate_greaterthan_startdate"));
      }
    }

    return errors;
  };

  //emergency filters
  const handleBenchmarkProductionTypeSelect = (option: MultiSelectOption) => {
    const selectedTypes = [...selectedProductionTypes, option];
    setSelectedProductionTypes(selectedTypes);
  };

  const handleBenchmarkProductionTypeDeSelect = (optionToDeselect: MultiSelectOption) => {
    if (selectedProductionTypes.length > 1) {
      setSelectedProductionTypes(
        selectedProductionTypes.filter((option) => {
          return option.id !== optionToDeselect.id;
        })
      );
    }
  };

  const handleTypesOfInputSelect = (selectedOption: ISelectOption) => {
    setSelectedInput(selectedOption);
  };

  //outpatient filters
  const handleTypesOfCompetenceSelect = (selectedOption: ISelectOption) => {
    setSelectedCompetenceType(selectedOption);
  };

  const handleTypesOfContactTypeSelect = (selectedOption: ISelectOption) => {
    setSelectedContactType(selectedOption);
  };

  //inpatient filters
  const handleCareLevelSelectChange = (option: ISelectOption) => {
    setSelectedCareLevel(option);
  };

  const handleMedicalSpecSelectChange = (option: ISelectOption) => {
    setSelectedMedicalSpeciality(option);
  };

  const [selectedProductionTypeIds, setSelectedProductionTypeIds] = useState<string[]>([]);

  useEffect(() => {
    const ids: string[] = [];
    selectedProductionTypes.map((type) => {
      ids.push(type.id);
    });
    setSelectedProductionTypeIds(ids);
  }, [selectedProductionTypes]);

  return (
    <GridLayout additionalStyles="mt-0 px-0">
      <div className="col-start-1 col-end-13">
        <div className="mt-0 flex items-center justify-between px-2">
          <TabbarTeritary tabs={tabs} onClick={handleTabbarClick} />
          {errorNotification !== "" && (
            <div className="mb-4">
              <NotificationFloat varaint="error" content={translate("something_went_wrong")} />
            </div>
          )}
        </div>
        <div className="mt-0 overflow-auto bg-[#F8F8F8] px-2 pt-4 lg:h-[79vh] xl:h-[80vh]">
          {selectedTabIndex == 0 && (
            <div className="flex h-full flex-row" id="wraper">
              <div className="h-full w-5/6">
                <BenchmarkingChart
                  dateRange={dateRange}
                  selectedCareLevel={selectedCareLevel}
                  selectedMedicalSpeciality={selectedMedicalSpeciality}
                  selectedCompetenceInput={selectedCompetenceInput}
                  selectedCompetenceType={selectedCompetenceType}
                  selectedContactType={selectedContactType}
                  selectedInput={selectedInput}
                  selectedProductionTypes={selectedProductionTypeIds}
                  afterFetchingChartData={handleafterFetchingChartData}
                  setErrorNotification={setErrorNotification}
                  endDateErrors={endDateErrors}
                />
              </div>
              <div className="ml-5 w-1/6">
                {careUnitTypeId !== null &&
                  (careUnitTypeId === 1 ? (
                    <OutpatientFilters
                      competenceTypes={competenceTypes}
                      contactTypes={contactTypes}
                      handleBenchmarkProductionTypeDeSelect={handleBenchmarkProductionTypeDeSelect}
                      handleBenchmarkProductionTypeSelect={handleBenchmarkProductionTypeSelect}
                      handleTypesOfCompetenceSelect={handleTypesOfCompetenceSelect}
                      handleTypesOfContactTypeSelect={handleTypesOfContactTypeSelect}
                      handleTypesOfInputSelect={handleTypesOfInputSelect}
                      productionTypes={productionTypes}
                      selectedCompetenceType={selectedCompetenceType}
                      selectedContactType={selectedContactType}
                      selectedInput={selectedInput}
                      selectedProductionTypes={selectedProductionTypes}
                      typesOfInputs={typesOfInputs}
                    />
                  ) : careUnitTypeId === 2 ? (
                    <InpatientFilters
                      careLevelsList={careLevelsList}
                      handleBenchmarkProductionTypeDeSelect={handleBenchmarkProductionTypeDeSelect}
                      handleBenchmarkProductionTypeSelect={handleBenchmarkProductionTypeSelect}
                      handleCareLevelSelectChange={handleCareLevelSelectChange}
                      handleMedicalSpecSelectChange={handleMedicalSpecSelectChange}
                      handleTypesOfInputSelect={handleTypesOfInputSelect}
                      medicalSpecialityList={medicalSpecialityList}
                      productionTypes={productionTypes}
                      selectedInput={selectedInput}
                      selectedProductionTypes={selectedProductionTypes}
                      typesOfInputs={typesOfInputs}
                    />
                  ) : careUnitTypeId === 4 ? (
                    <EmergencyFilters
                      handleBenchmarkProductionTypeDeSelect={handleBenchmarkProductionTypeDeSelect}
                      handleBenchmarkProductionTypeSelect={handleBenchmarkProductionTypeSelect}
                      handleTypesOfInputSelect={handleTypesOfInputSelect}
                      productionTypes={productionTypes}
                      selectedInput={selectedInput}
                      selectedProductionTypes={selectedProductionTypes}
                      typesOfInputs={typesOfInputs}
                    />
                  ) : null)}

                {careUnitTypeId !== null && (
                  <DateFilters
                    dateRange={dateRange}
                    handleEndDateChange={handleEndDateChange}
                    handleStartDateChange={handleStartDateChange}
                    endDateErrors={endDateErrors}
                  />
                )}
              </div>
            </div>
          )}
          {selectedTabIndex == 1 && <div id="sankey" className="h-full"></div>}
        </div>
      </div>
    </GridLayout>
  );
};

export default Benchmark;
