import { RowObject } from "handsontable/common";
import numbro from "numbro";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";

export const useWorkShiftValidators = () => {
  const isDynamicProdTypesEnabled = useFeatureFlagIsEnabled(FeatureFlags.DynamicProdTypes);

  const competenceAndProductionsSharesSumValidator = (
    data: RowObject[] | undefined,
    competencesSharesColumns: string[],
    productionsSharesColumns: string[],
    dynamicProductionShareColumns: string[]
  ) => {
    const isValid = data?.every((col) => {
      let productionShareColumnsTotal = numbro(0);
      let competenceShareColumnsTotal = numbro(0);
      let dynamicProductionShareColumnsTotal = numbro(0);

      Object.keys(col).forEach((key) => {
        if (productionsSharesColumns.includes(key) && !isDynamicProdTypesEnabled) {
          if (col[key] === null || col[key] === " " || col[key] === "") {
            col[key] = 0;
          }

          productionShareColumnsTotal = productionShareColumnsTotal.add(col[key]);
        } else if (dynamicProductionShareColumns.includes(key) && isDynamicProdTypesEnabled) {
          if (col[key] === null || col[key] === " " || col[key] === "") {
            col[key] = 0;
          }

          dynamicProductionShareColumnsTotal = dynamicProductionShareColumnsTotal.add(col[key]);
        } else if (competencesSharesColumns.includes(key)) {
          if (col[key] === null || col[key] === " " || col[key] === "") {
            col[key] = 0;
          }

          competenceShareColumnsTotal = competenceShareColumnsTotal.add(col[key]);
        }
      });

      if (!isDynamicProdTypesEnabled && productionShareColumnsTotal.value() !== 1) {
        return false;
      }

      if (isDynamicProdTypesEnabled && Number(dynamicProductionShareColumnsTotal.value().toFixed(2)) !== 1) {
        return false;
      }

      if (competenceShareColumnsTotal.value() !== 1) {
        return false;
      }

      return true;
    });

    return isValid;
  };

  return { competenceAndProductionsSharesSumValidator };
};
