import React from "react";
import InPatientPlan from "../features/production/InpatientPlan/InpatientPlan";
import OutPatientPlan from "../features/production/OutpatientPlan/OutpatientPlan";
import Imaging from "../features/production/Imaging/Imaging";
import ED from "../features/production/ED/ED";
import OR from "../features/production/OR/OR";
import PrimaryCare from "../features/production/PrimaryCare/PrimaryCare";

const ProductionRoutes = [
  {
    path: "inpatient",
    element: <InPatientPlan />,
  },
  {
    path: "outpatient",
    element: <OutPatientPlan />,
  },
  {
    path: "imaging",
    element: <Imaging />,
  },
  {
    path: "ed",
    element: <ED />,
  },
  {
    path: "or",
    element: <OR />,
  },
  {
    path: "primary-care",
    element: <PrimaryCare />,
  },
];

export default ProductionRoutes;
