import React from "react";
import { HotTableClass as HotTable } from "@handsontable/react";
import useOutcomeVsPlanGridConfigs from "./hooks/useOutcomeVsPlanGridConfigs";
import { DataTableProps } from "../../../../../interfaces/analytics/prodVsPlan/dataTableProps";

const DataTable = ({ data, timePeriodType, dateRange }: DataTableProps) => {
  const props = useOutcomeVsPlanGridConfigs(timePeriodType, dateRange);

  return (
    <>
      <HotTable data={data} {...props} />
    </>
  );
};

export default DataTable;
