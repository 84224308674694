import { HandsOnTableColumn } from "../../../../../../interfaces/handsOnTable/handsOnTableColumn";

const useOutcomeVsPlanTableColumns = (timePeriodType: number, dateRange: string[]) => {
  const colWidth = 40;

  if (timePeriodType === 2) {
    const columnsForDayOption: HandsOnTableColumn[] = [
      {
        meta: {
          data: "name",
          type: "text",
        },
        headerTitle: "",
        width: 140,
      },
      ...Array.from({ length: 56 }, (_, index) => ({
        meta: {
          data: index.toString(),
          type: "numeric",
          numericFormat: {
            pattern: {
              mantissa: 0,
            },
          },
        },
        headerTitle: dateRange[index],
        width: 30,
      })),
    ];

    return columnsForDayOption;
  } else {
    const columnsForWeekOption: HandsOnTableColumn[] = [
      {
        meta: {
          data: "name",
          type: "text",
        },
        headerTitle: "",
        width: 140,
      },
      ...Array.from({ length: 52 }, (_, index) => ({
        meta: {
          data: index.toString(),
          type: "numeric",
          numericFormat: {
            pattern: {
              mantissa: 0,
            },
          },
        },
        headerTitle: (index + 1).toString(),
        width: colWidth,
      })),
    ];

    return columnsForWeekOption;
  }
};

export default useOutcomeVsPlanTableColumns;
