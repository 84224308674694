import { useEffect, useState } from "react";
import ResourceService from "../../../../services/resourceService";
import { Resource } from "../../../../interfaces/resources/resource";

const useResources = (careUnitId: number | null, scenarioId: number | null) => {
  const [data, setData] = useState<Resource[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetch = async () => {
    try {
      setIsLoading(true);
      const response = await ResourceService.getResources(careUnitId as number, scenarioId as number);
      setData(response.data);
      setError(null);
    } catch (err) {
      setError(err as Error);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const saveResources = async (resources: Resource[]) => {
    try {
      setIsLoading(true);
      await ResourceService.saveResources({
        careUnitId: careUnitId as number,
        scenarioId: scenarioId as number,
        resources: resources.map((resource) => ({
          id: resource.id,
          name: resource.name,
          resourceTypeId: resource.resourceTypeId,
          fromTime: resource.fromTime,
          toTime: resource.toTime,
          occupancy: resource.occupancy,
          planningObjects: [],
        })),
      });
      setError(null);
      await fetch();
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (careUnitId && scenarioId) {
      fetch();
    }
  }, [careUnitId, scenarioId]);

  return {
    data,
    isLoading,
    error,
    fetch,
    setData,
    saveResources,
  };
};

export default useResources;
