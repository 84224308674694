import React from "react";
import { useTranslation } from "react-i18next";
import { useNumberFormat } from "../../../../../hooks/use-number-format";
import { OutpatientResultsKPI } from "../../../../../interfaces/production-plan/outpatient/outpatientResultsKPI";

const OutPatientResultPanelV2 = ({
  outpatientResultsKPI
}:
{
  outpatientResultsKPI : OutpatientResultsKPI
}) => {
  const { t: translate } = useTranslation();
  const { round } = useNumberFormat();

  return (
    <div className="px-4">
      <div className="sidearea__text--container-v2">
        <div>
          <h1 className="sidearea__text--title-v2">{translate("totalnumber_of_visits")}</h1>
          <p className="sidearea__text--value-v2">
            {(outpatientResultsKPI.productionPlan + outpatientResultsKPI.queueCuttingPlan).toString()}
          </p>
        </div>
        <div>
          <h1 className="sidearea__text--title-v2">{translate("outpatient_result_plan_vs_actual_r12")}</h1>
          <p className="sidearea__text--value-v2">{round(outpatientResultsKPI.overloadPlanAverage, 0)}</p>
        </div>
      </div>
    </div>
  );
};

export default OutPatientResultPanelV2;
