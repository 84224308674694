import { Dispatch, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RowObject } from "handsontable/common";
import { HotTableClass as HotTable } from "@handsontable/react";
import { HyperFormula } from "hyperformula";

import useActivityAnalysisProductionColumns from "./useActivityAnalysisProductionColumns";
import useAdjustColumnHeader from "../../hooks/useAdjustColumnHeader";
import { DataTableProps } from "../../../../../interfaces/props/data-table-props";
import { ActivityAnalysisProductionConfigReturntype } from "../../../../../interfaces/analytics/activity-analysis-production-config-returntype";
import { ActivityAnalysisProductionSchema } from "../../../../../interfaces/analytics/activityAnalysisProductionSchema";
import useAfterChange from "./useAfterChange";
import useAfterCreateRow from "./useAfterCreateRow";
import useBeforePaste from "./useBeforePaste";
import useBeforeCreateRow from "./useBeforeCreateRow";
import useBeforeValidate from "../../hooks/useBeforeValidate";
import useCells from "../../hooks/useCells";
import useAfterRemoveRow from "../../hooks/useAfterRemoveRow";
import useContextMenu from "../../hooks/useContextMenu";
import useModifyColumnWidth from "../../hooks/useAfterModifyColumnWidth";
import { PlanningObjectSchema } from "../../../../../interfaces/planning-object/planningObjectSchema";
import useAddNewRow from "../../hooks/useAddNewRow";
import { ActivityAnalysisIsEditedRefType } from "../../../../../interfaces/analytics/activity-analysis-is-edited-ref-type";
import WorkCompetence from "../../../../../interfaces/competence/WorkCompetence";
import useRenderers from "../../../../../common/hooks/handsontable/useRenderers";

const useActivityAnalysisProductionConfigs = (
  setData: Dispatch<React.SetStateAction<ActivityAnalysisProductionSchema[]>>,
  data: ActivityAnalysisProductionSchema[],
  filterGroups: PlanningObjectSchema[],
  careUnitId: number,
  scenarioId: number,
  year: number,
  isEditedRef: React.MutableRefObject<ActivityAnalysisIsEditedRefType>,
  workCompetencesForCareUnit: WorkCompetence[]
): ActivityAnalysisProductionConfigReturntype => {
  let culture = localStorage.getItem("i18nextLng");
  const { t: translate } = useTranslation();
  if (culture === "en") {
    culture = "en-US";
  } else if (culture === "en-US") {
    culture = "en-US";
  } else {
    culture = "sv-SE";
  }

  const cols = useActivityAnalysisProductionColumns(culture, workCompetencesForCareUnit, filterGroups);

  const dataTable = useRef<HotTable>(null);

  const defaultData: RowObject = {
    productionUnit: "",
    competenceGroupLvl2: "",
    timePerContact: null,
    productionPlan: 0,
    timeSpent: 0,
    weeklyWorkingHours: "",
    fteProductionPlan: 0,
    isProductionPlanReloaded: false,
  };

  const adjustActivityAnalysisProductionColumnHeaderColour = useAdjustColumnHeader();

  const { afterChange } = useAfterChange(
    careUnitId,
    scenarioId,
    year,
    data,
    filterGroups,
    dataTable,
    setData,
    isEditedRef
  );
  const { afterCreateRow } = useAfterCreateRow(data, dataTable, setData);
  const { beforePaste } = useBeforePaste(dataTable);
  const { beforeCreateRow } = useBeforeCreateRow(dataTable);
  const { beforeValidate } = useBeforeValidate(dataTable);
  const { cells } = useCells(dataTable);
  const { afterRemoveRow } = useAfterRemoveRow(dataTable);
  const { disableRemoveRow, disableRowBelow } = useContextMenu();
  const { addNewRow } = useAddNewRow(dataTable);
  const { afterRenderer } = useRenderers();

  const ACTIVITY_PRODUCTION_COLUMN_WIDTHS = "activity_analysis_production_column_widths";

  const { afterColumnResize, modifyColWidth } = useModifyColumnWidth(ACTIVITY_PRODUCTION_COLUMN_WIDTHS);

  const props: DataTableProps = {
    afterChange: afterChange,
    afterCreateRow: afterCreateRow,
    afterGetColHeader: adjustActivityAnalysisProductionColumnHeaderColour,
    afterColumnResize: afterColumnResize,
    afterRemoveRow: afterRemoveRow,
    afterRenderer: (TD, row, column) => afterRenderer(TD, row, column, dataTable, translate("total")),
    beforeCreateRow: beforeCreateRow,
    beforePaste: beforePaste,
    beforeValidate: beforeValidate,
    cells: cells,
    colHeaders: cols.map((c) => translate(c.headerTitle).replace("\n", "<br />")),
    columns: cols.map((c) => c.meta),
    columnSorting: true,
    colWidths: cols.map((c) => c.width),
    contextMenu: {
      items: {
        row_above: {},
        remove_row: {
          disabled: disableRemoveRow,
        },
        row_below: {
          disabled: disableRowBelow,
        },
        alignment: {},
        copy: {},
        cut: {},
      },
    },
    dataSchema: defaultData,
    dropdownMenu: ["filter_by_condition", "filter_action_bar", "filter_by_value"],
    formulas: {
      engine: HyperFormula,
    },
    height: "65vh",
    language: culture,
    licenseKey: "non-commercial-and-evaluation",
    manualColumnResize: true,
    modifyColWidth: modifyColWidth,
    manualRowMove: false,
    numericFormat: {
      culture: culture,
      pattern: {},
    },
    ref: dataTable,
    rowHeaders: true,
    rowHeaderWidth: 30,
    rowHeights: 35,
    wordWrap: false,
  };

  return { props, dataTable, addNewRow };
};

export default useActivityAnalysisProductionConfigs;
