import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { AppInsightService } from "../../../services";
import { ChartDataYearFormat } from "../../../interfaces/production-plan";
import { WaitingListProps } from "../../../interfaces/props/wating-list";
import { useChartData } from "./hooks/useChartData";
import { useChartObject } from "./hooks/useChartObject";
import { ChartData } from "../../../interfaces/common/chartdata";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

const WaitingListChart = ({ filterCareUnits: filterCareUnits, onChartDataFetching }: WaitingListProps) => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "WaitingListChart - Dashboard" });
  }, []);
  const [queueDetails, setQueueDetails] = useState<ChartData[]>([]);
  const [queueCategories, setQueueCategories] = useState<ChartDataYearFormat[]>([]);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const [queueDetailsFetchedData, queueCategoriesFetchedData, isLoading] = useChartData({
    filterCareUnits,
    year,
  });

  const [isChartEmpty, setIsChartEmpty] = useState<boolean>(false);

  const [chartOptions] = useChartObject(queueDetails, queueCategories, isChartEmpty);

  useEffect(() => {
    onChartDataFetching && onChartDataFetching(isLoading);
    if (queueCategoriesFetchedData && queueDetailsFetchedData) {
      setChartSeries(queueDetailsFetchedData, queueCategoriesFetchedData);
      setQueueCategories(queueCategoriesFetchedData);
      onChartDataFetching && onChartDataFetching(isLoading);
    } else {
      setQueueDetails([]);
      setQueueCategories([]);
      onChartDataFetching && onChartDataFetching(isLoading);
    }
  }, [queueDetailsFetchedData, queueCategoriesFetchedData, filterCareUnits, isLoading]);

  const setChartSeries = (queueData: ChartData[], categories: ChartDataYearFormat[]) => {
    if (queueData.length === 0) {
      const emptyData = categories.map((c) => {
        return {
          name: "No Data",
          data: Array(c.categories.length).fill(0),
          color: "#000",
        };
      });

      setQueueDetails(emptyData);
      setIsChartEmpty(true);
    } else {
      setQueueDetails(queueDetailsFetchedData);
      setIsChartEmpty(false);
    }
  };

  return (
    <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={chartOptions} />
  );
};

export default WaitingListChart;
