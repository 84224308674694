import React from "react";
import { HotTableClass as HotTable } from "@handsontable/react";
import useOutcomeVsPlanGridConfigs from "./hooks/useOutcomeVsPlanGridConfigs";
import { ProdVsPlanTableData } from "../../../../../interfaces/analytics/prodVsPlan/prodVsPlanTableData";

interface DataTableProps {
  data: ProdVsPlanTableData[];
}

const DataTable = ({ data }: DataTableProps) => {
  const props = useOutcomeVsPlanGridConfigs();

  return (
    <>
      <HotTable data={data} {...props} />
    </>
  );
};

export default DataTable;
