import { useTranslation } from "react-i18next";
import { ActivityAnalysisResultsType } from "../../../../../interfaces/analytics/activity-analysis-results";
import useGetUniqueWorkComptences from "./useGetUniqueWorkComptences";

const useGetResultsPivotData = (tableData: ActivityAnalysisResultsType[]): string[][] => {
  const { t: translate } = useTranslation();

  const data: string[][] = [];

  const workCompetences = tableData.map((result: ActivityAnalysisResultsType) => {
    return { id: result.workCompetenceId, name: result.workCompetenceName };
  });

  const competences = useGetUniqueWorkComptences(workCompetences);

  const productionTypesOrder: string[] = [
    "activity_production_plan",
    "activities_within_shift",
    "other_activities",
    "activity_result_mapping",
    "ProductionShareConsultant",
    "ProductionShareManagement",
    "ProductionShareNonClinicalTime",
    "ProductionShareOutpatientVisit",
    "ProductionShareStExternalTraining",
    "result_capacity_plan",
  ];

  for (const category of productionTypesOrder) {
    const prodType = tableData.map((x) => x.productionType).indexOf(category) !== -1;
    
    if (prodType) {
      const rowData: string[] = [];
      rowData.push(translate(category));

      for (const competence of competences) {
        const filteredHours = tableData
          .filter(
            (x) =>
              x.productionType === category &&
              x.workCompetenceName === competence.name &&
              x.workCompetenceId === competence.id
          )
          .map((x) => x.hours)
          .toString();

        rowData.push(filteredHours || "0");

        const filteredFte = tableData
          .filter(
            (x) =>
              x.productionType === category &&
              x.workCompetenceName === competence.name &&
              x.workCompetenceId === competence.id
          )
          .map((x) => x.fte)
          .toString();

        rowData.push(filteredFte || "0");
      }

      data.push(rowData);
    }
  }

  return data;
};

export default useGetResultsPivotData;
