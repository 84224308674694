import React, { useEffect } from "react";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";

const DynamicReports = () => {
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.Metabase);

  const openNewWindow = () => {
    window.open("https://evidencemetabase.azurewebsites.net/", "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    if (isFeatureFlagEnabled) {
      openNewWindow();
    }
  }, []);

  return <></>;
};

export default DynamicReports;
