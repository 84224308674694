import React from "react";
import DynamicReports from "../features/analytics/DynamicReports/DynamicReports";
import OrganizationalOverviewHome from "../features/analytics/OrganizationalOverview/OrganizationalOverviewHome";
import StaffCostsVsBudget from "../features/analytics/StaffCostsVsBudget/StaffCostsVsBudget";
import ICDAnalysisHome from "../features/analytics/ICDAnalysis/ICDAnalysisHome";
import ActivityAnalysisHome from "../features/analytics/ActivityAnalysis/ActivityAnalysisHome";
import GlobalFilterReadyGuard from "./guards/GlobalFilterReadyGuard";
import ProdVsPlan from "../features/analytics/ProductionVsPlan/ProdVsPlan";
import CareBedsPlan from "../features/analytics/CarebedsPlan/CareBedsPlan";
import { Navigate } from "react-router-dom";

const AnalyticsRoutes = [
  {
    path: "",
    element: <Navigate to="production-vs-plan" replace />,
  },
  {
    path: "production-vs-plan",
    element: (
      <GlobalFilterReadyGuard>
        <ProdVsPlan />
      </GlobalFilterReadyGuard>
    ),
  },
  {
    path: "carebeds-plan",
    element: <CareBedsPlan />,
  },
  {
    path: "staffing-costs-vs-budget",
    element: <StaffCostsVsBudget />,
  },
  {
    path: "organization-performance-review",
    element: <OrganizationalOverviewHome />,
  },
  {
    path: "dynamic-reports",
    element: <DynamicReports />,
  },
  {
    path: "activity-analysis",
    element: <ActivityAnalysisHome />,
  },
  {
    path: "icd-analysis",
    element: <ICDAnalysisHome />,
  },
];

export default AnalyticsRoutes;
