import clsx from "clsx";
import React from "react";

interface TruncateProps {
  children: React.ReactNode;
  lines?: number; // Optional prop to specify the number of lines to display
}

const Truncate = ({ children, lines = 1 }: TruncateProps): JSX.Element => {
  const className = lines === 1 ? "truncate" : `line-clamp-${lines}`;

  return <span className={clsx(className, "pb-0.5")}>{children}</span>;
};

export default Truncate;
