import React, { useEffect, useRef, useState } from "react";
import { CareLevel } from "../../../../../interfaces/analytics/orgOverview/careLevel";
import { HotTableClass as HotTable } from "@handsontable/react";
import { DataTableProps } from "../../../../../interfaces/props/data-table-props";
import useCareLevelTargetGridConfigs from "./hooks/useCareLevelTargetGridConfigs";

const CareLevelTargetGrid = ({ data }: { data: CareLevel[] }) => {
  const dataTable = useRef<HotTable>(null);

  const [tableProps, setTableProps] = useState<DataTableProps>({
    ref: dataTable,
  });

  const tableConfiguration = useCareLevelTargetGridConfigs(dataTable);

  useEffect(() => {
    const tableConfigs = tableConfiguration.getConfigs();
    setTableProps(tableConfigs);
  }, [data]);

  return (
    <div>
      <HotTable data={data} {...tableProps} licenseKey="non-commercial-and-evaluation" />
    </div>
  );
};

export default CareLevelTargetGrid;
