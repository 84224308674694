import React, { useEffect, useRef, useState } from "react";
import PhasingSidePanel from "./PhasingSidePanel/PhasingSidePanel";
import PhasingChart from "./PhasingChart/PhasingChart";
import PhasingService from "../../../../services/phasingService";
import { OutpatientWeeklyPhasingResponse } from "../../../../interfaces/production/outpatient-weeklyphasing";
import { WeekdayPhasing } from "../../../../interfaces/production-plan";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { ISelectOption } from "../../../../interfaces";
import { useTranslation } from "react-i18next";
import { CURRENT_PLAN, FLAT_100 } from "../../../../utils/constants/inpatient-plan";
import { AxiosResponse } from "axios";
import { PhasingTemplate } from "../../../../interfaces/production-plan/phasing-template";
import { OUTPATIENT_PHASING_CHART_DATA } from "../../../../utils/constants/outpatientPlan";
import NotificationFloat from "../../../../components/NotificationFloat/NotificationFloat";
import { ToastContainer, toast } from "react-toastify";
import NotificationBox from "../../../../components/NotificationBox/NotificationBox";
import LoadingWithGrey from "../../../../components/loading/LoadingWithGrey";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import PhasingSidePanelWithMultiSelect from "./PhasingSidePanel/PhasingSidePanelWithMultiSelect";
import { OutPatientMultiSelectWithTogglesRequest } from "../../../../interfaces/production-plan/outpatient/outpatientMultiselectFilterWithToggles";
import PhasingChartWithDimensions from "./PhasingChart/PhasingChartWithDimensions";
import {
  ModifyOutpatientPhasingRequest,
  RetrieveOutpatientPhasingResponse,
} from "../../../../interfaces/production-plan/outpatient/Phasing/outpatientPhasing";
import toLower from "lodash/toLower";
import { PhasingChartData } from "../../../../interfaces/production-plan/outpatient/Phasing/props/phasingChartWithDimensionsProps";
import { PhasingProps } from "../../../../interfaces/production-plan/outpatient/Phasing/props/phasingProps";
import { PhasingOutcome } from "../../../../interfaces/production-plan/outpatient/Phasing/weeklyPhasing";

const shadesOfGreen: string[] = ["#416031", "#548147", "#739768", "#a9c9a0", "#B0CBA4"];
const shadesOfBlue: string[] = ["#426fa2", "#5386bf", "#7ea4cf", "#adc8e5", "#b8c9e0"];

const Phasing = ({
  selectedTabIndex,
  isSaveButtonClicked,
  setIsSaveButtonClicked,
  setNextAvailableTabIndex,
  setSelectedTabIndex,
  nextAvailableTabIndex,
  isTabBarClicked,
  setIsTabBarClicked,
  clickedTabIndex,
  isOutpatientParametersModifying,
  setIsOutpatientParametersModifying,
}: PhasingProps) => {
  const { t: translate } = useTranslation();

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const isCareUnitReadOnly = useSelector((state: RootState) => state.permission.careUnitReadOnly);
  const isPhasingMultiSelectEnabled = useFeatureFlagIsEnabled("Outpatient Phasing Multi Select");

  const weekDayPhasingInitialState = {
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
  };

  const defaultOption: ISelectOption = {
    label: translate("current_plan"),
    value: CURRENT_PLAN,
  };

  const [typeOfTemplates, setTypeOfTemplates] = useState<ISelectOption[]>([defaultOption]);
  const selectedTemplateBeforeChangeTemplate = typeOfTemplates[0];
  const [selectedTemplate, setSelectedTemplate] = useState<ISelectOption>(typeOfTemplates[0]);
  const [historicData, setHistoricData] = useState<number[]>([]);
  const [chartData, setChartData] = useState<number[]>([]);
  const [phasingChartDataBeforeEdit, setPhasingChartDataBeforeEdit] = useState<number[]>([]);
  const [weekDayPhasing, setWeekdayPhasing] = useState<WeekdayPhasing>(weekDayPhasingInitialState);
  const [weekDayPhasingBeforeEdit, setWeekdayPhasingBeforeEdit] = useState<WeekdayPhasing>(weekDayPhasingInitialState);
  const [isSaveConfirmationModalBoxOpen, setIsSaveConfirmationModalBoxOpen] = useState<boolean>(false);

  const [outpatientPhasingRequest, setOutpatientPhasingRequest] =
    useState<OutPatientMultiSelectWithTogglesRequest | null>(null);
  const [phasingChartData, setPhasingChartData] = useState<PhasingChartData[]>([]);
  const [currentPlanChartPattern, setCurrentPlanChartPattern] = useState<number[]>([]);
  const [isPhasingSaved, setIsPhasingSaved] = useState(false);

  const phasingChartDataUneditedRef = useRef<PhasingChartData[] | null>(null);

  useEffect(() => {
    if (
      !phasingChartDataUneditedRef.current ||
      phasingChartDataUneditedRef.current.length === 0 ||
      (phasingChartDataUneditedRef.current.length > 1 && phasingChartData.length === 1) ||
      isPhasingSaved
    ) {
      phasingChartDataUneditedRef.current = phasingChartData.map((series) => ({
        ...series,
        data: [...series.data],
      }));

      isPhasingSaved ? setIsPhasingSaved(false) : null;
    }
  }, [phasingChartData]);

  const getTranslatedSeriesName = (name: string) => {
    if (name !== "Other") {
      const words = name.split(", ");
      const wordsWithoutEmptyStrings = words.filter((str) => str.trim() !== "");
      const translatedWords: string[] = [];

      wordsWithoutEmptyStrings.forEach((element) => {
        if (
          element === "follow_up_visit" ||
          element === "new_visit" ||
          element === "is_planned" ||
          element === "is_emergency" ||
          element === "All"
        ) {
          translatedWords.push(translate(toLower(element)));
        } else {
          translatedWords.push(element);
        }
      });

      const text = translatedWords.join(", ");
      const escapedInput = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
      return escapedInput;
    } else {
      return translate("other");
    }
  };

  const fetchCurrentPlanWeekdayPhasingData = async (careUnitId: number, year: number, scenario: number) => {
    await PhasingService.getWeekdayPhasing(careUnitId, year, scenario)
      .then((data: OutpatientWeeklyPhasingResponse) => {
        setWeekdayPhasing({
          monday: data[0] >= 0 ? data[0] : 100,
          tuesday: data[0] >= 0 ? data[1] : 100,
          wednesday: data[0] >= 0 ? data[2] : 100,
          thursday: data[0] >= 0 ? data[3] : 100,
          friday: data[0] >= 0 ? data[4] : 100,
          saturday: data[0] >= 0 ? data[5] : 100,
          sunday: data[0] >= 0 ? data[6] : 100,
        });

        setWeekdayPhasingBeforeEdit({
          monday: data[0] >= 0 ? data[0] : 100,
          tuesday: data[0] >= 0 ? data[1] : 100,
          wednesday: data[0] >= 0 ? data[2] : 100,
          thursday: data[0] >= 0 ? data[3] : 100,
          friday: data[0] >= 0 ? data[4] : 100,
          saturday: data[0] >= 0 ? data[5] : 100,
          sunday: data[0] >= 0 ? data[6] : 100,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchCurrentPlanWeeklyPhasingData = async (careUnitId: number, year: number, scenario: number) => {
    await PhasingService.getWeeklyPhasing(careUnitId, year, scenario)
      .then((res: OutpatientWeeklyPhasingResponse) => {
        if (res.length === 0) {
          setSelectedTemplate(typeOfTemplates[1]);
          convertToDefaultPhasingTemplate();
        } else {
          setSelectedTemplate(typeOfTemplates[0]);
          setHistoricData([...res]);
          setChartData(res);
          setPhasingChartDataBeforeEdit(res);
          addPhasingToLocalStorage([...res]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchOutpatientPhasingData = async (
    outpatientPhasingRequest: OutPatientMultiSelectWithTogglesRequest | null
  ) => {
    if (careUnitId && outpatientPhasingRequest) {
      const data: PhasingChartData[] = [];

      const phasingRequest: OutPatientMultiSelectWithTogglesRequest = {
        professionIds: outpatientPhasingRequest.professionIds[0] === -1 ? [] : outpatientPhasingRequest.professionIds,
        contactTypeIds:
          outpatientPhasingRequest.contactTypeIds[0] === -1 ? [] : outpatientPhasingRequest.contactTypeIds,
        medicalSpecialtyIds:
          outpatientPhasingRequest.medicalSpecialtyIds[0] === -1 ? [] : outpatientPhasingRequest.medicalSpecialtyIds,
        typesOfVisit: outpatientPhasingRequest.typesOfVisit[0] === -1 ? [] : outpatientPhasingRequest.typesOfVisit,
        contactReasonGroupingIds:
          outpatientPhasingRequest.contactReasonGroupingIds[0] === -1
            ? []
            : outpatientPhasingRequest.contactReasonGroupingIds,
        careTypeIds: outpatientPhasingRequest.careTypeIds[0] === -1 ? [] : outpatientPhasingRequest.careTypeIds,
        typesOfEmergency:
          outpatientPhasingRequest.typesOfEmergency[0] === -1 ? [] : outpatientPhasingRequest.typesOfEmergency,
        contactReasonGroupingEnabled: outpatientPhasingRequest.contactReasonGroupingEnabled,
        careTypeEnabled: outpatientPhasingRequest.careTypeEnabled,
        professionEnabled: outpatientPhasingRequest.professionEnabled,
        contactTypeEnabled: outpatientPhasingRequest.contactTypeEnabled,
        newVisitEnabled: outpatientPhasingRequest.newVisitEnabled,
        typeOfEmergencyEnabled: outpatientPhasingRequest.typeOfEmergencyEnabled,
        medicalSpecialtyEnabled: outpatientPhasingRequest.medicalSpecialtyEnabled,
      };

      await PhasingService.getOutpatientPhasing(careUnitId, year, scenario, phasingRequest)
        .then((res: RetrieveOutpatientPhasingResponse) => {
          const weeklyPhasingDataList = res.weeklyPhasing.detailedPhasingOutcome;
          const weekdayPhasingDataList = res.weekdayPhasing;

          if (weeklyPhasingDataList.length === 0) {
            setSelectedTemplate(typeOfTemplates[1]);
            convertToDefaultPhasingTemplate();
          } else {
            const maxNoOfGroups = 10;

            weeklyPhasingDataList.map((weeklyPhasingData: PhasingOutcome, index: number) => {
              const seriesName = getTranslatedSeriesName(weeklyPhasingData.level);
              data.push({
                name: seriesName,
                data: weeklyPhasingData.phasingOutcome,
                color:
                  weeklyPhasingData.level.indexOf("Other") > -1
                    ? "#d1d1d1"
                    : weeklyPhasingData.level.indexOf("new_visit") > -1
                    ? shadesOfGreen[index <= maxNoOfGroups / 2 ? index : maxNoOfGroups - index]
                    : weeklyPhasingData.level.indexOf("follow_up_visit") > -1
                    ? shadesOfBlue[index <= maxNoOfGroups / 2 ? index : maxNoOfGroups - index]
                    : shadesOfGreen.concat(shadesOfBlue)[index],
                type: "column",
              });
            });

            if (weeklyPhasingDataList.length === 1) {
              setCurrentPlanChartPattern(weeklyPhasingDataList[0].phasingOutcome);
            }

            setSelectedTemplate(typeOfTemplates[0]);
            setPhasingChartData(data);
          }

          if (weekdayPhasingDataList.length > 0) {
            setWeekdayPhasing({
              monday: weekdayPhasingDataList[0].phasingValue >= 0 ? weekdayPhasingDataList[0].phasingValue : 100,
              tuesday: weekdayPhasingDataList[1].phasingValue >= 0 ? weekdayPhasingDataList[1].phasingValue : 100,
              wednesday: weekdayPhasingDataList[2].phasingValue >= 0 ? weekdayPhasingDataList[2].phasingValue : 100,
              thursday: weekdayPhasingDataList[3].phasingValue >= 0 ? weekdayPhasingDataList[3].phasingValue : 100,
              friday: weekdayPhasingDataList[4].phasingValue >= 0 ? weekdayPhasingDataList[4].phasingValue : 100,
              saturday: weekdayPhasingDataList[5].phasingValue >= 0 ? weekdayPhasingDataList[5].phasingValue : 100,
              sunday: weekdayPhasingDataList[6].phasingValue >= 0 ? weekdayPhasingDataList[6].phasingValue : 100,
            });

            setWeekdayPhasingBeforeEdit({
              monday: weekdayPhasingDataList[0].phasingValue >= 0 ? weekdayPhasingDataList[0].phasingValue : 100,
              tuesday: weekdayPhasingDataList[1].phasingValue >= 0 ? weekdayPhasingDataList[1].phasingValue : 100,
              wednesday: weekdayPhasingDataList[2].phasingValue >= 0 ? weekdayPhasingDataList[2].phasingValue : 100,
              thursday: weekdayPhasingDataList[3].phasingValue >= 0 ? weekdayPhasingDataList[3].phasingValue : 100,
              friday: weekdayPhasingDataList[4].phasingValue >= 0 ? weekdayPhasingDataList[4].phasingValue : 100,
              saturday: weekdayPhasingDataList[5].phasingValue >= 0 ? weekdayPhasingDataList[5].phasingValue : 100,
              sunday: weekdayPhasingDataList[6].phasingValue >= 0 ? weekdayPhasingDataList[6].phasingValue : 100,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const updateOutpatientPhasing = async () => {
    if (careUnitId && outpatientPhasingRequest) {
      let response = true;
      setIsOutpatientParametersModifying(true);
      isSaveConfirmationModalBoxOpen ? setIsSaveConfirmationModalBoxOpen(false) : null;

      const toastId = toast(<NotificationFloat varaint="loading" content={translate("saving_outpatient_data")} />, {
        icon: false,
        className: "custom-toast",
        containerId: "notificationBox",
        autoClose: false,
      });

      const weekdayPhasingKeys: (keyof WeekdayPhasing)[] = Object.keys(weekDayPhasing) as (keyof WeekdayPhasing)[];
      const weekdayPhasing: number[] = weekdayPhasingKeys.map((key) => Number(weekDayPhasing[key]));

      const weeklyPhasing: number[] = phasingChartData.length === 1 ? phasingChartData[0].data : [];

      const modifyPhasingRequest: ModifyOutpatientPhasingRequest = {
        careUnitId: careUnitId,
        year: year,
        scenarioId: scenario,
        professionIds: outpatientPhasingRequest.professionIds[0] === -1 ? [] : outpatientPhasingRequest.professionIds,
        contactTypeIds:
          outpatientPhasingRequest.contactTypeIds[0] === -1 ? [] : outpatientPhasingRequest.contactTypeIds,
        medicalSpecialtyIds:
          outpatientPhasingRequest.medicalSpecialtyIds[0] === -1 ? [] : outpatientPhasingRequest.medicalSpecialtyIds,
        typesOfVisit: outpatientPhasingRequest.typesOfVisit[0] === -1 ? [] : outpatientPhasingRequest.typesOfVisit,
        contactReasonGroupingIds:
          outpatientPhasingRequest.contactReasonGroupingIds[0] === -1
            ? []
            : outpatientPhasingRequest.contactReasonGroupingIds,
        careTypeIds: outpatientPhasingRequest.careTypeIds[0] === -1 ? [] : outpatientPhasingRequest.careTypeIds,
        typesOfEmergency:
          outpatientPhasingRequest.typesOfEmergency[0] === -1 ? [] : outpatientPhasingRequest.typesOfEmergency,
        weeklyPhasingList: weeklyPhasing,
        weekdayPhasingList: weekdayPhasing,
      };

      await PhasingService.modifyOutpatientPhasing(modifyPhasingRequest)
        .then(() => {
          toast.update(toastId, {
            render: <NotificationFloat varaint="success" content={translate("data_save_success_message")} />,
            type: "success",
            autoClose: 3000,
          });
          setIsSaveButtonClicked(false);
          setIsOutpatientParametersModifying(false);
          response = true;
          if (phasingChartData.length === 1) {
            setCurrentPlanChartPattern(phasingChartData[0].data);
          }

          setIsPhasingSaved(true);
          fetchOutpatientPhasingData(outpatientPhasingRequest);
        })
        .catch(() => {
          toast.update(toastId, {
            render: <NotificationFloat varaint="error" content={translate("something_went_wrong")} />,
            type: "error",
            autoClose: 3000,
          });
          setIsSaveButtonClicked(false);
          setIsOutpatientParametersModifying(false);
          response = false;
        });
      return response;
    }
  };

  const updateWeeklyPhasing = async () => {
    isSaveConfirmationModalBoxOpen ? setIsSaveConfirmationModalBoxOpen(false) : null;

    const toastId = toast(<NotificationFloat varaint="loading" content={translate("saving_outpatient_data")} />, {
      icon: false,
      className: "custom-toast",
      containerId: "notificationBox",
      autoClose: false,
    });

    const weekdayPhasingKeys: (keyof WeekdayPhasing)[] = Object.keys(weekDayPhasing) as (keyof WeekdayPhasing)[];
    const weekDayTotals: number[] = weekdayPhasingKeys.map((key) => Number(weekDayPhasing[key]));
    const data = JSON.parse(getPhasingFromLocalStorage() ?? "");

    if (careUnitId) {
      let response = true;
      setChartData(data);
      setIsOutpatientParametersModifying(true);

      await PhasingService.updateWeeklyPhasing(careUnitId, year, data, weekDayTotals, scenario)
        .then(() => {
          toast.update(toastId, {
            render: <NotificationFloat varaint="success" content={translate("data_save_success_message")} />,
            type: "success",
            autoClose: 3000,
          });
          setPhasingChartDataBeforeEdit(data);
          setIsSaveButtonClicked(false);
          setIsOutpatientParametersModifying(false);
          response = true;
        })
        .catch(() => {
          toast.update(toastId, {
            render: <NotificationFloat varaint="error" content={translate("something_went_wrong")} />,
            type: "error",
            autoClose: 3000,
          });
          setIsSaveButtonClicked(false);
          setIsOutpatientParametersModifying(false);
          response = false;
        });
      return response;
    }
  };

  const fetchTemplateWeekdayPhasingData = async (templateId: number) => {
    await PhasingService.getWeekdayPhasingWithTemplates(templateId).then((data: OutpatientWeeklyPhasingResponse) => {
      setWeekdayPhasing({
        monday: data[0] >= 0 ? data[0] : 100,
        tuesday: data[1] >= 0 ? data[1] : 100,
        wednesday: data[2] >= 0 ? data[2] : 100,
        thursday: data[3] >= 0 ? data[3] : 100,
        friday: data[4] >= 0 ? data[4] : 100,
        saturday: data[5] >= 0 ? data[5] : 100,
        sunday: data[6] >= 0 ? data[6] : 100,
      });

      setWeekdayPhasingBeforeEdit({
        monday: data[0] >= 0 ? data[0] : 100,
        tuesday: data[1] >= 0 ? data[1] : 100,
        wednesday: data[2] >= 0 ? data[2] : 100,
        thursday: data[3] >= 0 ? data[3] : 100,
        friday: data[4] >= 0 ? data[4] : 100,
        saturday: data[5] >= 0 ? data[5] : 100,
        sunday: data[6] >= 0 ? data[6] : 100,
      });
    });
  };

  const fetchTemplateWeeklyPhasing = async (templateId: number, templateName?: string) => {
    if (templateId) {
      await PhasingService.getWeeklyPhasingWithTemplates(templateId).then((res: number[]) => {
        if (isPhasingMultiSelectEnabled) {
          const phasingTemplate: PhasingChartData = {
            name: templateName ? templateName : "",
            data: res,
            color: "#5386BF",
            type: "column",
          };
          setPhasingChartData([phasingTemplate]);
        } else {
          setChartData(res);
          setPhasingChartDataBeforeEdit(res);
          addPhasingToLocalStorage([...res]);
        }
      });
    }
  };

  const convertToDefaultPhasingTemplate = async () => {
    const res: AxiosResponse<PhasingTemplate[]> = await PhasingService.getPhasingTemplates();

    const defaultTemplate = res.data[0].id;

    if (defaultTemplate !== null) {
      await fetchTemplateWeeklyPhasing(defaultTemplate);
      await fetchTemplateWeekdayPhasingData(defaultTemplate);
    }
  };

  const addPhasingToLocalStorage = (data: number[]) => {
    localStorage.setItem(OUTPATIENT_PHASING_CHART_DATA, JSON.stringify(data));
  };

  const removePhasingFromLocalStorage = () => {
    localStorage.removeItem(OUTPATIENT_PHASING_CHART_DATA);
  };

  const getPhasingFromLocalStorage = () => {
    return localStorage.getItem(OUTPATIENT_PHASING_CHART_DATA);
  };

  const handlePhasingChartUnmount = () => {
    setSelectedTemplate(typeOfTemplates[0]);
    removePhasingFromLocalStorage();
  };

  const handlePhasingDataSave = async () => {
    if (!isCareUnitReadOnly) {
      await updateWeeklyPhasing();
    }
    if (selectedTemplate !== undefined) {
      if (selectedTemplate.value === FLAT_100) {
        setSelectedTemplate(typeOfTemplates[0]);
      }
    }
  };

  const hasEditedPhasing = (): boolean => {
    let hasEdited = false;

    const hasPhasingTemplateChanged =
      selectedTemplate && selectedTemplate.value !== selectedTemplateBeforeChangeTemplate.value;
    const hasWeekdayPhasingEdited = JSON.stringify(weekDayPhasing) !== JSON.stringify(weekDayPhasingBeforeEdit);

    if (isPhasingMultiSelectEnabled && phasingChartData.length === 1) {
      phasingChartData[0].data.forEach((data: number, i: number) => {
        if (phasingChartDataUneditedRef.current && data !== phasingChartDataUneditedRef.current[0].data[i])
          hasEdited = true;
      });
    } else if (!isPhasingMultiSelectEnabled) {
      const storedPhasing = getPhasingFromLocalStorage();
      const chartData = storedPhasing && JSON.parse(storedPhasing ?? "");

      if (chartData && chartData.length > 0) {
        chartData.forEach((data: string, i: number) => {
          if (Number(data) !== phasingChartDataBeforeEdit[i]) hasEdited = true;
        });
      }
    }

    if (hasEdited || hasWeekdayPhasingEdited || hasPhasingTemplateChanged) {
      return true;
    } else return false;
  };

  const displayOutpatientPhasingSaveNotification = (nextIndex: number) => {
    const hasEdited = hasEditedPhasing();

    if (hasEdited) {
      setChartData(chartData);
      setIsSaveConfirmationModalBoxOpen(true);
      setNextAvailableTabIndex(() => nextIndex);
    } else {
      setSelectedTabIndex(() => nextIndex);
    }
  };

  const handleNotificationBoxSaveButtonClick = async () => {
    if (selectedTabIndex === 1) {
      if (isPhasingMultiSelectEnabled) {
        await updateOutpatientPhasing();
      } else {
        await updateWeeklyPhasing();
      }

      if (isTabBarClicked) {
        setSelectedTabIndex(() => clickedTabIndex);
        setIsTabBarClicked(false);
      } else {
        setSelectedTabIndex(nextAvailableTabIndex);
      }
    }
  };

  const handleNotificationBoxDiscardSaveButtonClick = () => {
    setIsSaveConfirmationModalBoxOpen(false);

    if (isTabBarClicked) {
      setSelectedTabIndex(() => clickedTabIndex);
      setIsTabBarClicked(false);
    } else {
      setSelectedTabIndex(nextAvailableTabIndex);
    }
  };

  useEffect(() => {
    if (isSaveButtonClicked && selectedTabIndex === 1) {
      if (isPhasingMultiSelectEnabled) {
        updateOutpatientPhasing();
      } else {
        handlePhasingDataSave();
      }
    }
  }, [isSaveButtonClicked]);

  useEffect(() => {
    if (isTabBarClicked && selectedTabIndex === 1) {
      const hasEdited = hasEditedPhasing();

      if (hasEdited) {
        setIsSaveConfirmationModalBoxOpen(true);
      } else {
        setIsTabBarClicked(false);
        setSelectedTabIndex(() => clickedTabIndex);
      }
    }
  }, [isTabBarClicked]);

  useEffect(() => {
    PhasingService.getPhasingTemplates()
      .then((res) => {
        const data = res.data.map((template: PhasingTemplate) => {
          return {
            label: template.name,
            value: template.id,
          };
        });

        setTypeOfTemplates([...typeOfTemplates, ...data]);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  useEffect(() => {
    if (careUnitId !== null && careUnitId > 0 && year > 0 && scenario > 0) {
      if (isPhasingMultiSelectEnabled && outpatientPhasingRequest && outpatientPhasingRequest.careTypeIds.length > 0) {
        fetchOutpatientPhasingData(outpatientPhasingRequest);
      } else if (!isPhasingMultiSelectEnabled) {
        /* get weekly & weekday phasing data for phasing chart */
        fetchCurrentPlanWeekdayPhasingData(careUnitId, year, scenario);
        fetchCurrentPlanWeeklyPhasingData(careUnitId, year, scenario);
      }
    }
  }, [year, scenario, outpatientPhasingRequest]);

  return (
    <>
      <ToastContainer
        containerId={"notificationBox"}
        style={{ width: "400px" }}
        position="top-right"
        hideProgressBar={true}
        closeButton={false}
      />
      {isSaveConfirmationModalBoxOpen ? (
        <NotificationBox
          content={translate("do_you_want_to_save_changes")}
          title={translate("save_changes")}
          key={12}
          leftButtonLabel={translate("yes")}
          rightButtonLabel={translate("no")}
          variant="primary"
          onLeftButtonClick={handleNotificationBoxSaveButtonClick}
          onRightButtonClick={handleNotificationBoxDiscardSaveButtonClick}
          isLeftButtonDisabled={false}
          isRightButtonDisabled={false}
        />
      ) : null}
      {isPhasingMultiSelectEnabled ? (
        <>
          <div className="h-full w-5/6">
            {selectedTemplate && phasingChartData.length > 0 ? (
              <PhasingChartWithDimensions
                phasingChartData={phasingChartData}
                setPhasingChartData={setPhasingChartData}
                onChartUnmount={handlePhasingChartUnmount}
                currentPlanChartPattern={currentPlanChartPattern}
              />
            ) : (
              <div className="flex h-full items-center justify-center">
                <LoadingWithGrey sizeInPixel={40} />
              </div>
            )}
          </div>
          <div className="ml-5 w-1/6">
            <PhasingSidePanelWithMultiSelect
              weekDayPhasing={weekDayPhasing}
              setWeekdayPhasing={setWeekdayPhasing}
              fetchCurrentPhasingData={fetchOutpatientPhasingData}
              convertToDefaultPhasingTemplate={convertToDefaultPhasingTemplate}
              fetchTemplateWeekdayPhasingData={fetchTemplateWeekdayPhasingData}
              fetchTemplateWeeklyPhasing={fetchTemplateWeeklyPhasing}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              typeOfTemplates={typeOfTemplates}
              displayOutpatientPhasingSaveNotification={displayOutpatientPhasingSaveNotification}
              selectedTabIndex={selectedTabIndex}
              isOutpatientParametersModifying={isOutpatientParametersModifying}
              outpatientPhasingRequest={outpatientPhasingRequest}
              setOutpatientPhasingRequest={setOutpatientPhasingRequest}
            />
          </div>
        </>
      ) : (
        <>
          <div className="h-full w-5/6">
            {selectedTemplate ? (
              <PhasingChart
                chartData={chartData}
                historicData={historicData}
                onChartUnmount={handlePhasingChartUnmount}
                setChartData={setChartData}
              />
            ) : (
              <div className="flex h-full items-center justify-center">
                <LoadingWithGrey sizeInPixel={40} />
              </div>
            )}
          </div>
          <div className="ml-5 w-1/6">
            <PhasingSidePanel
              weekDayPhasing={weekDayPhasing}
              setWeekdayPhasing={setWeekdayPhasing}
              fetchCurrentPlanWeeklyPhasingData={fetchCurrentPlanWeeklyPhasingData}
              convertToDefaultPhasingTemplate={convertToDefaultPhasingTemplate}
              fetchTemplateWeekdayPhasingData={fetchTemplateWeekdayPhasingData}
              fetchTemplateWeeklyPhasing={fetchTemplateWeeklyPhasing}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              typeOfTemplates={typeOfTemplates}
              displayOutpatientPhasingSaveNotification={displayOutpatientPhasingSaveNotification}
              selectedTabIndex={selectedTabIndex}
              isOutpatientParametersModifying={isOutpatientParametersModifying}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Phasing;
