import numbro from "numbro";

function setNumbroLocale(locale: string): void {
  try {
    if (!numbro.languages()[locale]) {
      // Unfortunately we need to include the languages file directly.
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const languages = require("numbro/dist/languages.min.js");
      if (languages) {
        const numbroLanguage = languages[locale];
        if (numbroLanguage) {
          numbro.registerLanguage(numbroLanguage);
        }
      }
    }
    numbro.setLanguage(locale);
  } catch (error) {
    if (error instanceof Error) {
      console.log(
        "Error with configuring numbro" +
          (error.message ? ": " + error.message : ".")
      );
      console.log(error);
    } else {
      console.log("An unknown error occurred.");
    }
  }
}
export { setNumbroLocale };
