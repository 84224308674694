import { ChangePassword, CreatePassword, EditUserDto, UserDto } from "../interfaces/user-management";
import { ResetPassword } from "../interfaces/user-management/create-password";
import HttpCommonService from "./httpCommonService";

const UserManagementService = {
  addUserAsync: async (user: UserDto) => {
    return await HttpCommonService.postAsync("api/users", {
      email: user.userDto.email,
      firstName: user.userDto.firstName,
      lastName: user.userDto.lastName,
      phoneNumber: user.userDto.phoneNumber,
      roleId: user.userDto.userRoleId,
      userName: user.userDto.email,
      type: user.userDto.type,
      CareUnitId: user.userDto.careUnitId && user.userDto.careUnitId?.length > 0 ? user.userDto.careUnitId : null,
      careProviderId: user.userDto.careProviderId,
      parentCareUnitId: user.userDto.parentCareUnitId,
      locale: user.userDto.locale,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  editUserAsync: async (user: EditUserDto) => {
    return await HttpCommonService.putAsync("api/users", {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      roleId: user.userRoleId,
      userName: user.email,
      type: user.type,
      CareUnitId: user.careUnitId && user.careUnitId?.length > 0 ? user.careUnitId : null,
      careProviderId: user.careProviderId,
      parentCareUnitId: user.parentCareUnitId,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getUsersAsync: async (path: string) => {
    return await HttpCommonService.getAsync(path)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  deleteUsersAsync: async (id: string) => {
    return await HttpCommonService.deletAsync(`api/users/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  createPasswordAsync: async (user: CreatePassword) => {
    return await HttpCommonService.postAsync("api/account/create-password", {
      userId: user.userId,
      token: user.token,
      password: user.password,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  changePasswordAsync: async (user: ChangePassword) => {
    return await HttpCommonService.postAsync("api/account/reset-password", {
      userId: user.userId,
      currentPassword: user.oldPassword,
      newPassword: user.newPassword,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  ResetPasswordAsync: async (user: ResetPassword) => {
    return await HttpCommonService.postAsync("api/account/reset-password", user)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default UserManagementService;
