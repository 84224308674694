import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FilterCareUnits, RootState } from "../../../redux/store";
import { KpiResponse } from "../../../interfaces/dashboard/kpis";
import { DashboardService } from "../../../services";
import ProductionVsPlanKpis from "./production-vs-plan-kpis";
import { useTranslation } from "react-i18next";
import "../styles/common-chart-styles.css";
import LoadingWithGrey from "../../../components/loading/LoadingWithGrey";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import ProductionVsPlanDashboardChartV2 from "../../analytics/ProductionVsPlan/V2/Chart/ProductionVsPlanDashboardChartV2";
import ProductionVsPlanDashboardChartV1 from "../../analytics/ProductionVsPlan/V1/Chart/ProductionVsPlanDashboardChartV1";

const ProductionVsPlanComponent = ({ filterCareUnits }: { filterCareUnits: FilterCareUnits }) => {
  const { t: translate } = useTranslation();

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const isProdVsPlanFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.ActualVsPlan);

  const [kpis, setKpis] = useState<KpiResponse>({
    actualR12: 0,
    actualVsPlan: 0,
    plannedTotal: 0,
    productionPercentage: 0,
    recoveryPlan: 0,
  });
  const [isKpisLoading, setIsKpisLoading] = useState<boolean>(false);

  useEffect(() => {
    const getDashboardProductionKpis = async () => {
      setIsKpisLoading(true);
      await DashboardService.getProductionKpis(filterCareUnits.units, year, scenario, filterCareUnits.careUnitType)
        .then((res: KpiResponse) => {
          setKpis(res);
          setIsKpisLoading;
        })
        .catch((err) => {
          console.error(err);
        });

      setIsKpisLoading(false);
    };

    if (filterCareUnits !== null && filterCareUnits.units.length > 0 && filterCareUnits.units[0] !== 0 && year > 0) {
      getDashboardProductionKpis();
    }
  }, [filterCareUnits, scenario]);

  const selectedYear = useSelector((state: RootState) => state.globalFilter.filterYear);
  const navigate = useNavigate();

  const handleChartClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      (event.target as HTMLElement).closest(".highcharts-legend-item") ||
      (event.target as HTMLElement).closest(".highcharts-legend")
    ) {
      return;
    }
    navigate("/analytics/production-vs-plan");
  };

  // chart loading state
  const [isChartDataLoading, setIsChartDataLoading] = useState<boolean>(false);
  const isLoading = isChartDataLoading || isKpisLoading;

  return (
    <>
      {isLoading && (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      )}
      <div
        className={`${isLoading ? "hidden" : "flex"} !h-full !max-h-full w-full cursor-pointer`}
        onClick={handleChartClick}
      >
        <div className="!h-full !max-h-full w-full flex-col">
          <div className="!h-full max-h-full">
            <div className="flex !h-full max-h-full flex-col">
              <div className="chart-title">
                <span className="">{translate("production")}</span>
              </div>
              <div className="flex h-full w-full">
                <div className="flex h-full w-full">
                  <div className="chart-bg !h-full max-h-full basis-5/6">
                    {isProdVsPlanFeatureFlagEnabled ? (
                      <ProductionVsPlanDashboardChartV2
                        year={selectedYear}
                        filterCareUnits={filterCareUnits}
                        chartTitle=""
                        isPlacedOnDashboard={true}
                        onChartDataLoading={(isChartDataLoading: boolean) => {
                          setIsChartDataLoading(() => isChartDataLoading);
                        }}
                      />
                    ) : (
                      <ProductionVsPlanDashboardChartV1
                        year={selectedYear}
                        filterCareUnits={filterCareUnits}
                        chartTitle=""
                        isPlacedOnDashboard={true}
                        onChartDataLoading={(isChartDataLoading: boolean) => {
                          setIsChartDataLoading(() => isChartDataLoading);
                        }}
                      />
                    )}
                  </div>

                  <div className="chart-kpi-root basis-1/6">
                    {!isKpisLoading ? (
                      <ProductionVsPlanKpis
                        actualR12={kpis.actualR12}
                        plannedTotal={kpis.plannedTotal}
                        actualVsPlan={kpis.actualVsPlan}
                        productionPercentage={kpis.productionPercentage}
                        recoveryPlan={kpis.recoveryPlan}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductionVsPlanComponent;
