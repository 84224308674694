import { AxiosResponse } from "axios";
import HttpCommonService from "./httpCommonService";

interface ResourceType {
  id: number;
  name: string;
}

interface Resource {
  id: number;
  name: string;
  resourceTypeId: number;
  resourceTypeName: string | null;
  fromTime: string;
  toTime: string;
  occupancy: number;
}

interface PlanningObject {
  id: number;
}

interface ResourcePayload {
  id: number;
  name: string;
  resourceTypeId: number;
  fromTime: string;
  toTime: string;
  occupancy: number;
  planningObjects: PlanningObject[];
}

interface SaveResourcesRequest {
  careUnitId: number;
  scenarioId: number;
  resources: ResourcePayload[];
}

const ResourceService = {
  getResourceTypes: async (): Promise<AxiosResponse<ResourceType[]>> => {
    return await HttpCommonService.getAsync('api/resources/types')
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getResources: async (careUnitId: number, scenarioId: number): Promise<AxiosResponse<Resource[]>> => {
    return await HttpCommonService.getAsync(`api/resources/careUnit/${careUnitId}/scenario/${scenarioId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  saveResources: async (payload: SaveResourcesRequest): Promise<AxiosResponse> => {
    return await HttpCommonService.postAsync('api/resources', payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default ResourceService; 