import React from "react";
import { ReactComponent as PlusBoldSVG } from "../../../assets/svg/plus-bold.svg";

interface PlusBoldIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const PlusBoldIcon: React.FC<PlusBoldIconProps> = ({ width = 24, height = 24, className }): JSX.Element => {
  return <PlusBoldSVG className={className} width={width} height={height} />;
};

export default PlusBoldIcon;
