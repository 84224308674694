import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import { useTranslation } from "react-i18next";
import TabbarTeritary from "../../../components/tab-bar/tabbar-teritary/TabbarTeritary";
import { GridLayout } from "../../../layouts";
import { AppInsightService } from "../../../services";
import CompetenceWaterfall from "./CompetenceWaterfall/CompetenceWaterfall";
import ProductionWaterfall from "./ProductionWaterfall/ProductionWaterfall";
import "./productivity.css";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import ProductivityTabIndex from "../../../common/enums/productivityTabIndex";
import ButtonSmall from "../../../components/ButtonSmall/ButtonSmall";
import Icon from "../../../components/icons/Icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { CARE_UNIT_TYPES, CareUnitTypeEnum } from "../../../utils/constants/careUnitTypes";

HighchartsMore(Highcharts);

const Productivity = () => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "Productivity" });
  }, []);

  const { t: translate, i18n } = useTranslation();

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const tabs = [translate("CompetenceWaterfall"), translate("ProductionWaterfall")];

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.ChatGpt);

  const careUnitTypeId = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);

  const handleTabBarClick = (clickedIndex: number) => {
    setSelectedTabIndex(() => clickedIndex);
  };

  useEffect(() => {
    if (i18n.language === "sv") {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ",",
        },
      });
    } else {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ".",
        },
      });
    }
  }, [i18n]);

  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  useEffect(() => {
    if (careUnitTypeId !== CareUnitTypeEnum.PHYSICIAN && isPanelOpen) {
      setIsPanelOpen(false);
    }
  }, [careUnitTypeId]);

  return (
    <GridLayout additionalStyles="mt-0 px-0">
      <div className="col-start-1 col-end-13">
        <div className="mt-0 flex items-center justify-between px-2">
          <TabbarTeritary tabs={tabs} onClick={handleTabBarClick} />
          {isFeatureFlagEnabled &&
          selectedTabIndex === ProductivityTabIndex.ProductionWaterfall &&
          careUnitTypeId === CARE_UNIT_TYPES.PHYSICIAN ? (
            <ButtonSmall className="bg-button-green" onClick={togglePanel}>
              <Icon variant="ai" />
            </ButtonSmall>
          ) : null}
        </div>

        <div className="flex h-full w-full flex-row bg-[#F8F8F8] px-2 pt-4 lg:h-[79vh] xl:h-[80vh]" id="wraper">
          {selectedTabIndex === ProductivityTabIndex.CompetenceWaterfall ? (
            <CompetenceWaterfall />
          ) : (
            <ProductionWaterfall isPanelOpen={isPanelOpen} onTogglePanel={togglePanel} />
          )}
        </div>
      </div>
    </GridLayout>
  );
};

export default Productivity;
