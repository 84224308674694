import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import range from "lodash/range";
import { CarebedsPlanChartProps } from "../../../interfaces/analytics/number-of-beds";
import { ChartDataResponse, NumberOfCareBedsChartData } from "../../../interfaces/dashboard/planned-care-beds";
import { useMediaQuery } from "../../../hooks";
import { SCREEN_SIZES } from "../../../utils/constants/mediaQueries";
import { getChartFontSize } from "../../../utils/highcharts/common";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

const CarebedsPlanChart = ({
  getData,
  chartData,
  pointWidth,
  chartTitle,
  isOnDashboard,
  chartComponentRef,
}: CarebedsPlanChartProps) => {
  const { t: translate } = useTranslation();
  const [numberOfCareBedsChartSeries, setNumberOfCareBedsChartSeries] = useState<NumberOfCareBedsChartData[]>([]);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const isLargeScreen = useMediaQuery(SCREEN_SIZES.lg);

  const chartFontSize = getChartFontSize(isOnDashboard ? true : false, isLargeScreen);

  useEffect(() => {
    const modifiedChartData: NumberOfCareBedsChartData[] = [];

    if (chartData !== null && chartData !== undefined) {
      if (isOnDashboard) {
        chartData.forEach((dataObj: ChartDataResponse, index: number) => {
          if (index < 6) {
            modifiedChartData.push({
              data: dataObj.data,
              showInLegend: true,
              name: dataObj.name,
              type: dataObj.type,
            });
          } else {
            modifiedChartData.push({
              data: dataObj.data,
              showInLegend: false,
              name: dataObj.name,
              type: dataObj.type,
            });
          }
        });
      } else {
        chartData.forEach((dataObj: ChartDataResponse) => {
          modifiedChartData.push({
            data: dataObj.data,
            showInLegend: true,
            name: dataObj.name,
            type: dataObj.type,
          });
        });
      }
    }

    setNumberOfCareBedsChartSeries(() => modifiedChartData);
  }, [chartData]);

  const sums = (x: string | number | undefined) => {
    let sum = 0;
    const chart = chartComponentRef?.current?.chart as Highcharts.Chart;
    chart.series?.forEach((series: Highcharts.Series) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const yValue = series?.processedYData[x.name - 1];
      if (series.visible && yValue !== undefined && series.type !== "line") {
        sum += yValue;
      }
    });

    return sum;
  };

  const options = {
    chart: {
      style: {
        fontFamily: "Open Sans",
      },
      backgroundColor: "white",
    },

    colors: ["#426fa2", "#416031", "#739768", "#E5A32B", "#7ea4cf", "#adadad"],

    title: {
      text: chartTitle,
      style: {
        fontSize: "20px",
      },
    },

    xAxis: {
      categories: range(1, 53),
      title: {
        text: isOnDashboard ? "" : translate("year") + " " + year + ", " + translate("week"),
        style: {
          fontSize: chartFontSize,
        },
      },
      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },

    yAxis: {
      reversedStacks: false,
      title: {
        text: translate("no_of_care_beds_per_week"),
        style: {
          fontSize: chartFontSize,
        },
      },

      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },
    legend: {
      maxHeight: isOnDashboard && 80,
      enabled: true,
      maxItems: 2,
      verticalAlign: "top",
      itemStyle: {
        fontSize: chartFontSize,
      },
      style: {
        fontSize: chartFontSize,
      },
    },

    tooltip: {
      headerFormat: "",
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const series = this.series as Highcharts.Series;
        const point = this.point as Highcharts.Point;
        if (series.name.includes(translate("last_52_weeks"))) {
          const tooltipInfo = `${series.name} <br/> ${translate("week")} ${point.x + 1} <br /> ${translate(
            "no_of_care_beds"
          )} ${point.y}`;
          return tooltipInfo;
        } else {
          const xPoint: string | number | undefined = this.x;
          const sum = sums(xPoint);
          const tooltipInfo = `${series.name} <br/> ${translate("week")} ${point.x + 1} <br /> ${translate(
            "no_of_care_beds"
          )} ${point.y}
              <br /> ${translate("total_no_of_planned_care_beds")} ${sum}`;
          return tooltipInfo;
        }
      },
    },

    plotOptions: {
      series: {
        stacking: "normal",
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        pointWidth: pointWidth,
        marker: {
          enabled: false,
        },

        events: {
          legendItemClick: function (this: Highcharts.Series): boolean {
            const chart = this.chart as Highcharts.Chart;

            let visibleItems: string[] = [];
            let invisibleItems: string[] = [];

            if (this.visible) {
              invisibleItems = [...invisibleItems, this.name];
            } else {
              visibleItems = [...visibleItems, this.name];
            }

            chart.series.forEach((series) => {
              if (series !== this && !series.visible && !series.name.includes(translate("last_52_weeks"))) {
                invisibleItems = [...invisibleItems, series.name];
              } else if (series !== this && series.visible && !series.name.includes(translate("last_52_weeks"))) {
                visibleItems = [...visibleItems, series.name];
              }
            });

            getData?.(visibleItems);
            return true;
          },
        },
      },
    },

    series: numberOfCareBedsChartSeries,
  };
  return (
    <HighchartsReact
      ref={chartComponentRef}
      containerProps={{ style: { height: "100%" } }}
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default CarebedsPlanChart;
