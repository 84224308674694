import React, { useRef } from "react";
import { SearchProps } from "../../interfaces/props";
import "./search.css";

const Search = ({ id, name, placeholder, handleSearchValueChange, onFocus }: SearchProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleOnInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus !== undefined) {
      onFocus(e);
    }
  };

  const handleOnSearch = (e: React.FocusEvent<HTMLInputElement>) => {
    handleSearchValueChange?.(e.target.value);
  };

  return (
    <div className="search__container">
      <div className={`input group`}>
        <input
          id={id}
          className={`input__element p-2-v-1 peer`}
          placeholder={placeholder}
          onChange={handleOnSearch}
          name={name}
          onFocus={handleOnInputFocus}
          ref={inputRef}
        />
        <div className="input__icon">
          <svg className="h-4 w-4 fill-[#000000]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.25 18.25">
            <path
              id="Search"
              d="M17.5,15.971h-.832l-.312-.261a6.9,6.9,0,0,0,1.612-4.432,6.733,6.733,0,1,0-6.707,6.778,6.968,6.968,0,0,0,4.419-1.616l.312.261v.834l5.2,5.214,1.56-1.564Zm-6.239,0a4.693,4.693,0,1,1,4.679-4.693A4.667,4.667,0,0,1,11.259,15.971Z"
              transform="translate(-4.5 -4.5)"
            />
          </svg>
        </div>

        <label
          htmlFor={id}
          className="input__label p-3-v-1 peer-placeholder-shown:p-1-v-1 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"
        >
          {placeholder}
        </label>
      </div>
    </div>
  );
};

export default Search;
