import React from "react";
import useGetOutpatientProdAnalysisData from "../hooks/useGetOutpatientProdAnalysisData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import OverviewGrid from "./OverviewGrid/OverviewGrid";
import OverviewKPI from "./OverviewKPI/OverviewKPI";
import OverviewChart from "../OverviewChart/OverviewChart";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";

const OutpatientOverview = () => {
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.organizationalOverviewChart);

  const outpatientProductivityAnalysisDataHandler = useGetOutpatientProdAnalysisData(scenario, year);

  return (
    <div className="flex h-full w-full">
      {isFeatureFlagEnabled ? (
        <div className="mx-4 my-4 flex w-5/6 flex-col gap-10">
          <div className="h-1/2">
            <OverviewChart
              data={
                outpatientProductivityAnalysisDataHandler.data.map((item) => {
                  return {
                    productvityPlan: item.productivityPlan || 0,
                    productivityTarget: item.productivityTarget || 0,
                    productvtyplanvstarget: item.staffingPotential || 0,
                    careUnitName: item.careUnitName || "",
                  };
                }) || []
              }
            />
          </div>
          <div className="h-1/2">
            <OverviewGrid
              data={outpatientProductivityAnalysisDataHandler.data}
              isLoading={outpatientProductivityAnalysisDataHandler.isLoading}
            />
          </div>
        </div>
      ) : (
        <div className="h-dvh mx-4 my-4 w-5/6">
          <OverviewGrid
            data={outpatientProductivityAnalysisDataHandler.data}
            isLoading={outpatientProductivityAnalysisDataHandler.isLoading}
          />
        </div>
      )}
      <div className="h-dvh mx-4 my-4  flex w-1/6 flex-col justify-normal">
        <div className="mx-8 mt-16">
          <OverviewKPI data={outpatientProductivityAnalysisDataHandler.data} />
        </div>
      </div>
    </div>
  );
};

export default OutpatientOverview;
