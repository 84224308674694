import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import { FilterCareUnits, RootState } from "../../../redux/store";
import { DashboardService } from "../../../services";
import { useMediaQuery } from "../../../hooks";
import { SCREEN_SIZES } from "../../../utils/constants/mediaQueries";
import { getChartFontSize } from "../../../utils/highcharts/common";
import LoadingWithGrey from "../../../components/loading/LoadingWithGrey";
import { useNavigate } from "react-router-dom";
import BudgetKpis from "./budget-kpi";
import { BudgetKpis as IBudgetKpis } from "../../../interfaces/dashboard/budget-kpis";

interface ChartData {
  data: number[];
  color: string;
}

interface ChartResponseSeries {
  name: string;
  data: number[];
}

interface ChartDataResponse {
  categories: string[];
  chartData: ChartResponseSeries[];
}

const BudgetComponent = ({ filterCareUnits }: { filterCareUnits: FilterCareUnits }) => {
  const { t: translate } = useTranslation();

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const navigate = useNavigate();
  const careUnitLabel = useSelector((state: RootState) => state.globalFilter.filterCareUnitLabel);

  const chartCategories = [translate("actual_r12"), translate("budget"), translate("model")];

  const [chartData, setChartData] = useState<ChartData[]>([]);

  const isLargeScreen = useMediaQuery(SCREEN_SIZES.lg);

  const chartFontSize = getChartFontSize(true, isLargeScreen);

  // chartDataLoading
  const [isChartDataLoading, setIsChartDataLoading] = useState(false);

  const [chartKpis, setChartKpis] = useState<IBudgetKpis>({
    actualVsBudget: 0,
    actualVsModel: 0,
  });

  const calculateKpis = (actualValue: number, budgetValue: number, modelValue: number) => {
    const actualVsBudget = (actualValue - budgetValue) / 1000000;
    const actualVsModel = (actualValue - modelValue) / 1000000;

    setChartKpis((prevState) => {
      return {
        ...prevState,
        actualVsBudget,
        actualVsModel,
      };
    });
  };

  const fetchChartData = async () => {
    setIsChartDataLoading(() => true);
    await DashboardService.getBudgetForDashboard(
      filterCareUnits.units,
      scenario,
      filterCareUnits.careUnitType,
      filterCareUnits.year
    )
      .then((res: ChartDataResponse) => {
        let budgetValue = 0;
        let modelValue = 0;
        let actualValue = 0;

        const chartDataSeries = res?.chartData.map((c: ChartResponseSeries) => {
          actualValue += c.data[0];
          budgetValue += c.data[1];
          modelValue += c.data[2];

          return {
            name: translate(c.name),
            data: c.data,
            color: c.name === "personal_cost" ? "#45683D" : c.name === "overtime" ? "#F2A414" : "#BF2C1F",
          };
        });

        calculateKpis(actualValue, budgetValue, modelValue);
        setChartData(chartDataSeries);
      })
      .catch((err) => {
        console.error(err);
      });

    setIsChartDataLoading(() => false);
  };

  useEffect(() => {
    if (filterCareUnits.units.length) {
      fetchChartData();
    }
  }, [filterCareUnits, scenario]);

  const handleChartClick = () => {
    navigate("/productivity");
  };

  const budgetChartOptions = {
    chart: {
      type: "bar",
      height: "50%",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: chartCategories,
      labels: {
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          // eslint-disable-next-line
          // @ts-ignore
          const valueName = this.value.name;

          if (valueName !== undefined && valueName === careUnitLabel) {
            return (
              `<div><span style="color: #548147; font-weight: bold; font-size: ${chartFontSize}px">` +
              valueName +
              "</span></div>"
            );
          } else if (valueName !== undefined && valueName !== careUnitLabel) {
            return `<span style="font-weight: normal; font-size: ${chartFontSize}px">` + valueName + "</span>";
          } else {
            return valueName;
          }
        },
      },
    },
    yAxis: {
      reversedStacks: false,
      min: 0,
      title: {
        text: "",

        style: {
          fontSize: chartFontSize,
        },
      },
      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
          let label = this.axis.defaultLabelFormatter.call(this);
          label = label.substring(0, label.length - 1);
          return label;
        },
        style: {
          fontSize: chartFontSize,
        },
      },
    },
    legend: {
      enabled: true,
      verticalAlign: "top",
      itemStyle: {
        fontSize: chartFontSize,
      },
      style: {
        fontSize: chartFontSize,
      },
    },
    tooltip: {
      headerFormat: "",
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const y = Math.round((this.y ?? 0) / 1000000);
        return `${this.series.name}: ${y} MKr`;
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        point: {},
      },
    },
    series: chartData,
  };

  const isLoading = isChartDataLoading;

  return (
    <>
      {isLoading && (
        <div className="dashboard__chart__loading-container">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      )}

      <div className={`${isLoading ? "hidden" : "flex"} w-full cursor-pointer`} onClick={handleChartClick}>
        <div className="flex w-full flex-col">
          <div className="chart-title">
            <span>{translate("personal_cost")}</span>
          </div>

          <div className="chart-bg flex-1">
            <HighchartsReact
              highcharts={Highcharts}
              options={budgetChartOptions}
              containerProps={{ style: { height: "100%" } }}
            />
          </div>

          <div className="chart-kpi-bottom ">
            <BudgetKpis chartKpis={chartKpis} />
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetComponent;
